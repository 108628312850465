import React, { ReactNode } from "react";
import Geocoder from "react-map-gl-geocoder";
import { MapRef } from "react-map-gl";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { setCenter, setMapMarkerLocation } from "store/map/mapActions";
import { MapActionTypes } from "store/map/mapTypes";

import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import classes from "./GeocoderWrapper.module.css";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import ScrollableText from "../../../../../../_Library/ScrollableText/ScrollableText";
import ReactDOM from "react-dom";
import cx from "classnames";
import { registerAnalyticsEvent } from "store/matomo/matomoActions";

export interface OwnProps {
    leftMapRef: React.RefObject<MapRef>;
    geocoderContainerRef: React.RefObject<HTMLDivElement>;
}

interface DispatchProps {
    setCenter: typeof setCenter;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
    setMapMarkerLocation: typeof setMapMarkerLocation;
}

type GeocoderWrapperProps = OwnProps & DispatchProps;

interface SearchResult {
    place_name: string;
    bbox?: [number, number, number, number];
    center: [number, number];
}

interface SearchesType {
    searches: SearchResult[];
}

interface SearchType {
    result: SearchResult;
}

interface GeocoderWrapperState {
    pastSearches: SearchesType;
}

class GeocoderWrapper extends React.Component<
    GeocoderWrapperProps,
    GeocoderWrapperState
> {
    searchhistoryroot: HTMLDivElement;
    el: HTMLDivElement;

    constructor(props: GeocoderWrapperProps) {
        super(props);
        this.searchhistoryroot = document.getElementById(
            "search-history-root",
        ) as HTMLDivElement;
        this.el = document.createElement("div");

        this.state = {
            pastSearches: { searches: [] },
        };
    }

    componentDidMount() {
        this.searchhistoryroot.appendChild(this.el);

        let pastSearches: SearchesType = { searches: [] };

        if (localStorage["pastSearches"]) {
            pastSearches = JSON.parse(localStorage["pastSearches"]);

            this.setState({
                pastSearches: pastSearches,
            });
        }
    }

    storeSearches(search: SearchType) {
        let pastSearches: SearchesType = this.state.pastSearches;

        const duplicateSearch = pastSearches.searches.find((pastSearch) => {
            //Check if search already exists
            return pastSearch.place_name === search.result.place_name;
        });

        if (!duplicateSearch) {
            if (search.result.bbox) {
                pastSearches.searches.push({
                    place_name: search.result.place_name,
                    bbox: search.result.bbox,
                    center: search.result.center,
                });
            } else {
                pastSearches.searches.push({
                    place_name: search.result.place_name,
                    center: search.result.center,
                });
            }
        }

        localStorage.setItem("pastSearches", JSON.stringify(pastSearches));

        this.setState({
            pastSearches: pastSearches,
        });

        let searchBox = document.getElementsByClassName(
            "mapboxgl-ctrl-geocoder--input",
        )[0] as HTMLInputElement;
        searchBox.value = "";
    }

    showSearchHistory() {
        let history = this.state.pastSearches;

        return (
            <div className={classes.PastSearch}>
                <div className={classes.PastSearchHeader}>
                    <h4>Search History</h4>
                    <div
                        className={cx(classes.Clickable, classes.MassDelete)}
                        onClick={() => {
                            localStorage.removeItem("pastSearches");
                            this.setState(
                                {
                                    pastSearches: { searches: [] },
                                },
                                () => {
                                    let searchBox =
                                        document.getElementsByClassName(
                                            "mapboxgl-ctrl-geocoder--input",
                                        )[0] as HTMLInputElement;
                                    searchBox.value = "";
                                },
                            );
                        }}
                    >
                        <p>Delete All</p>
                        <Icon path={mdiTrashCan} size={1} />
                    </div>
                </div>

                <div className={classes.HistoryContainer}>
                    {history.searches.reverse().map((search) => {
                        return (
                            <div className={classes.HistoryResult}>
                                <div
                                    className={cx(
                                        classes.Clickable,
                                        classes.SearchItem,
                                    )}
                                >
                                    <div
                                        className={classes.SearchText}
                                        onClick={() => {
                                            let searchBox =
                                                document.getElementsByClassName(
                                                    "mapboxgl-ctrl-geocoder--input",
                                                )[0] as HTMLInputElement;
                                            searchBox.value = search.place_name;
                                            let zoom: number;

                                            if (search.bbox) {
                                                zoom =
                                                    this.props.leftMapRef.current
                                                        ?.getMap()
                                                        .cameraForBounds([
                                                            [
                                                                search.bbox[0],
                                                                search.bbox[1],
                                                            ],
                                                            [
                                                                search.bbox[2],
                                                                search.bbox[3],
                                                            ],
                                                        ]).zoom;
                                            } else {
                                                zoom = 16;
                                            }

                                            this.props.setMapMarkerLocation(
                                                search.center,
                                            );

                                            this.props.setCenter({
                                                latitude: search.center[1]!,
                                                longitude: search.center[0]!,
                                                zoom: zoom,
                                            });
                                        }}
                                    >
                                        <ScrollableText
                                            text={search.place_name}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cx(
                                        classes.Clickable,
                                        classes.SearchDelete,
                                    )}
                                    onClick={() => {
                                        let newHistory =
                                            this.state.pastSearches.searches.filter(
                                                (object) => {
                                                    return (
                                                        object.place_name !==
                                                        search.place_name
                                                    );
                                                },
                                            );

                                        this.setState(
                                            {
                                                pastSearches: {
                                                    searches: newHistory,
                                                },
                                            },
                                            () => {
                                                if (
                                                    this.state.pastSearches
                                                        .searches.length === 0
                                                ) {
                                                    localStorage.removeItem(
                                                        "pastSearches",
                                                    );
                                                } else {
                                                    localStorage.setItem(
                                                        "pastSearches",
                                                        JSON.stringify(
                                                            this.state
                                                                .pastSearches,
                                                        ),
                                                    );
                                                }

                                                let searchBox =
                                                    document.getElementsByClassName(
                                                        "mapboxgl-ctrl-geocoder--input",
                                                    )[0] as HTMLInputElement;
                                                searchBox.value = "";
                                            },
                                        );
                                    }}
                                >
                                    <Icon path={mdiTrashCan} size={1} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    render(): ReactNode {
        let currentContainerRef = this.props.geocoderContainerRef.current;

        if (
            currentContainerRef !== null &&
            currentContainerRef.children.length > 1
        ) {
            for (let child of currentContainerRef.children) {
                child.remove();
            }
        }

        return (
            <>
                <Geocoder
                    mapRef={this.props.leftMapRef}
                    mapboxApiAccessToken={
                        "pk.eyJ1IjoibWlrZS1taXMiLCJhIjoidjBXS01iUSJ9.RW5YqGmR3hyfu28gO7l8ow"
                    }
                    onViewportChange={(newViewport: any) => {
                        return this.props.setCenter(newViewport);
                    }}
                    marker={false}
                    containerRef={this.props.geocoderContainerRef}
                    onResult={(result: any) => {
                        this.storeSearches(result);

                        this.props.setMapMarkerLocation([
                            result.result.center[0],
                            result.result.center[1],
                        ]);

                        this.props.registerAnalyticsEvent({
                            category: "Report",
                            action: "location search",
                        });
                    }}
                    onClear={() => {
                        this.props.setMapMarkerLocation(null);
                    }}
                    localGeocoder={(query: string) => {
                        let matches = query.match(
                            /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
                        );
                        if (!matches) {
                            return null;
                        }

                        function coordinateFeature(lng: number, lat: number) {
                            return {
                                center: [lng, lat],
                                geometry: {
                                    type: "Point",
                                    coordinates: [lng, lat],
                                },
                                place_name: "Lat: " + lat + " Lng: " + lng,
                                place_type: ["coordinate"],
                                properties: {},
                                type: "Feature",
                            };
                        }

                        function latitudeError(lng: number, lat: number) {
                            lat = 90;
                            return {
                                center: [lng, lat],
                                geometry: {
                                    type: "Point",
                                    coordinates: [lng, lat],
                                },
                                place_name:
                                    "Lat should be less than 90, Lat: " +
                                    lat +
                                    " Lng: " +
                                    lng,
                                place_type: ["coordinate"],
                                properties: {},
                                type: "Feature",
                            };
                        }

                        let coord1 = Number(matches[1]);
                        let coord2 = Number(matches[2]);
                        let geocodes = [];

                        if (coord1 > 90 && coord2 > 90) {
                            geocodes.push(latitudeError(coord1, coord2));
                            geocodes.push(latitudeError(coord2, coord1));
                        } else {
                            if (coord1 < -90 || coord1 > 90) {
                                geocodes.push(
                                    coordinateFeature(coord1, coord2),
                                );
                            }

                            if (coord2 < -90 || coord2 > 90) {
                                geocodes.push(
                                    coordinateFeature(coord2, coord1),
                                );
                            }
                        }

                        if (geocodes.length === 0) {
                            geocodes.push(coordinateFeature(coord1, coord2));
                            geocodes.push(coordinateFeature(coord2, coord1));
                        }

                        return geocodes;
                    }}
                />

                {this.state.pastSearches.searches.length !== 0 &&
                    ReactDOM.createPortal(this.showSearchHistory(), this.el)}
            </>
        );
    }
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => ({
    setCenter: bindActionCreators(setCenter, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
    setMapMarkerLocation: bindActionCreators(setMapMarkerLocation, dispatch),
});

export default connect(null, mapDispatchToProps)(GeocoderWrapper);
