import {
    ADD_CUSTOM_LAYER,
    AddCustomLayerAction,
    BasemapType,
    ConfigSource,
    MapActionTypes,
    MapState,
    REMOVE_CUSTOM_LAYER,
    RemoveCustomLayerAction,
    REPLACE_INSIGHTS_LAYER_DATA,
    ReplaceInsightsLayerDataAction,
    SET_ACTIVE_TAB,
    SET_BASEMAP,
    SET_CENTER,
    SET_CLICKED_FEATURE_PROPERTIES,
    SET_EVENT_TYPE,
    SET_GROUP_VISIBILITY,
    SET_HIGHLIGHTED_LAYER,
    SET_INTERACTION_MODE,
    SET_LAYER_FILTER,
    SET_LAYER_VIEW,
    SET_LAYER_VISIBILITY,
    SET_LEGEND_POPUP,
    SET_MAP_CONFIG,
    SET_MAP_MARKER_LOCATION,
    SET_MAP_TYPE,
    SET_MOUSE_POSITION,
    SET_VIEWPORT,
    SetActiveTabAction,
    SetBasemapAction,
    SetCenterAction,
    SetClickedFeaturePropertiesAction,
    SetEventTypeAction,
    SetGroupVisibilityAction,
    SetInteractionModeAction,
    SetLayerFilterAction,
    SetLayerViewAction,
    SetLayerVisibilityAction,
    SetLegendPopupAction,
    SetMapConfigAction,
    SetMapMarkerLocationAction,
    SetMapTypeAction,
    SetMousePositionAction,
    SetSelectedLayerAction,
    SetViewportAction,
    TOGGLE_LOCATION_LABELS,
    TOGGLE_MENU,
    ToggleLocationLabelsAction,
    ToggleMenuAction,
} from "./mapTypes";
import * as MapboxGL from "mapbox-gl";
import { getComplexAttrs } from "../../utils/PaintHelpers";
import { FlyToInterpolator } from "react-map-gl";
import { ConfigMenuGroup, ConfigMenuLayer } from "store/system/systemTypes";
import { getLayers } from "utils/Layers";
import { generateHash } from "../../utils/Maths";
import { Reducer } from "redux";
import _ from "lodash";

const initState: MapState = {
    mapConfig: {
        mapType: "single",
        viewport: {
            width: 0,
            height: 0,
            latitude: 0,
            longitude: 0,
            zoom: 0,
            bearing: 0,
            pitch: 0,
            altitude: 0,
            maxZoom: 0,
            minZoom: 0,
            maxPitch: 0,
            minPitch: 0,
        },
        sources: {},
        menuIndex: [],
    },
    menuConfig: {
        menuHidden: false,
        activeTab: "Layer",
    },
    highlightedLayer: null,
    legendPopup: null,
    clickedFeatureProperties: {},
    mousePosition: [0, 0],
    interactiveLayerIds: [],
    basemaps: ["satellite", "dark"],
    basemapOptions: {
        dark: "mike-mis/cjz9un4sf01c91cp4qtyxs2vg",
        light: "mike-mis/ckcfxe3fg0krb1iqrcm0fiyy1",
        satellite: "mike-mis/ckebg0mb317xs1aqlqcbspm7y",
    },  
    interactionMode: "standard",
    eventType: "flood",
    mapMarkerLocation: null,
    locationLabels: true,
    mapboxToken: import.meta.env.VITE_MAPBOX_TOKEN!,
};

// HELPERS
const findGroupById = (
    groupId: string,
    parentIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
): ConfigMenuGroup | undefined => {
    return findGroupBy("id", groupId, parentIndex);
};

const findGroupBy = (
    property: keyof ConfigMenuGroup,
    searchTerm: string,
    parentIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
): ConfigMenuGroup | undefined => {
    let foundItem;

    let recursiveLookup = (index: (ConfigMenuGroup | ConfigMenuLayer)[]) => {
        for (let i = 0; i < index.length; i++) {
            let element = index[i];
            if (element.type === "group") {
                if (element[property] === searchTerm) {
                    foundItem = element;
                    break;
                }
                recursiveLookup(element.children);
            }
        }
    };

    recursiveLookup(parentIndex);

    return foundItem;
};

export const mapReducer: Reducer<MapState, MapActionTypes> = (
    state = initState,
    action,
): MapState => {
    switch (action.type) {
        case SET_MAP_CONFIG:
            return Reduce_SetMapConfig(state, action);
        case SET_MOUSE_POSITION:
            return Reduce_SetMousePosition(state, action);
        case SET_VIEWPORT:
            return Reduce_SetViewport(state, action);
        case SET_CENTER:
            return Reduce_SetCenter(state, action);
        case SET_LAYER_VISIBILITY:
            return Reduce_SetLayerVisibility(state, action);
        case SET_GROUP_VISIBILITY:
            return Reduce_SetGroupVisibility(state, action);
        case TOGGLE_MENU:
            return Reduce_ToggleMenu(state, action);
        case SET_MAP_TYPE:
            return Reduce_SetMapType(state, action);
        case SET_ACTIVE_TAB:
            return Reduce_SetActiveTab(state, action);
        case SET_HIGHLIGHTED_LAYER:
            return Reduce_SetSelectedLayer(state, action);
        case SET_LEGEND_POPUP:
            return Reduce_SetLegendPopup(state, action);
        case SET_CLICKED_FEATURE_PROPERTIES:
            return Reduce_SetClickedFeatureProperties(state, action);
        case SET_BASEMAP:
            return Reduce_SetBasemap(state, action);
        case SET_INTERACTION_MODE:
            return Reduce_SetInteractionMode(state, action);
        case ADD_CUSTOM_LAYER:
            return Reduce_AddCustomLayer(state, action);
        case REMOVE_CUSTOM_LAYER:
            return Reduce_RemoveCustomLayer(state, action);
        case REPLACE_INSIGHTS_LAYER_DATA:
            return Reduce_ReplaceInsightsLayerData(state, action);
        case SET_LAYER_VIEW:
            return Reduce_SetLayerView(state, action);
        case SET_LAYER_FILTER:
            return Reduce_SetLayerFilter(state, action);
        case SET_EVENT_TYPE:
            return Reduce_SetEventType(state, action);
        case SET_MAP_MARKER_LOCATION:
            return Reduce_SetMapMarkerLocation(state, action);
        case TOGGLE_LOCATION_LABELS:
            return Reduce_ToggleLocationLabels(state, action);
        default:
            return state;
    }
};

const Reduce_SetEventType = (
    state: MapState,
    action: SetEventTypeAction,
): MapState => {
    return { ...state, eventType: action.payload };
};

const Reduce_SetMapMarkerLocation = (
    state: MapState,
    action: SetMapMarkerLocationAction,
): MapState => {
    return { ...state, mapMarkerLocation: action.payload };
};

const Reduce_SetLayerView = (
    state: MapState,
    action: SetLayerViewAction,
): MapState => {
    let sourceName = action.payload.sourceName;
    let viewOn = action.payload.viewOn;
    let mapConfig = state.mapConfig;
    let sources = mapConfig.sources;
    let layer = sources[sourceName];
    layer.viewOn = viewOn;
    return { ...state, mapConfig: { ...mapConfig } };
};

const Reduce_AddCustomLayer = (
    state: MapState,
    action: AddCustomLayerAction,
): MapState => {
    // Add custom layer to mapconfig.source
    let customPaint;
    switch (action.payload.layerType) {
        case "circle":
            customPaint = {
                "circle-color": action.payload.layerColor,
                "circle-radius": 5,
                "circle-stroke-color": action.payload.layerStroke ?? "white",
                "circle-stroke-width": 2,
            };
            break;
        case "line":
            customPaint = {
                "line-color": action.payload.layerColor,
                "line-width": 3,
            };
            break;
        case "fill":
            customPaint = {
                "fill-color": action.payload.layerColor,
                "fill-opacity": 0.75,
            };
            break;
        default:
            customPaint = {};
    }

    let geojson = action.payload.geojsonData;

    geojson.features.forEach((feature, index) => {
        feature.properties!.feature_id = String(index);
    });

    const geoJsonSource: ConfigSource = {
        layerName: action.payload.layerName,
        layerType: action.payload.layerType,
        layout: {
            visibility: "visible",
        },
        paint: customPaint,
        viewOn: "both",
        // @ts-ignore
        complexPaintProperties: ["circle-color"],
        interactive: true,
        type: "geojson",
        data: geojson,
    };

    let sources = state.mapConfig.sources;
    const sourceName =
        action.payload.sourceName ?? action.payload.layerName + "-source";
    sources[sourceName] = geoJsonSource;

    // Add custom layer to mapconfig.menuIndex
    let customLayer: ConfigMenuLayer = {
        id: generateHash(),
        type: "layer",
        layerName: action.payload.layerName,
        layerSource: sourceName,
    };

    const groupName = action.payload.groupName ?? "Your Custom Data";

    let customCategory = state.mapConfig.menuIndex.find(
        (category) =>
            category.type === "group" && category.groupName === groupName,
    ) as ConfigMenuGroup | undefined;

    if (!customCategory) {
        state.mapConfig.menuIndex.unshift({
            id: generateHash(),
            type: "group",
            groupName: groupName,
            children: [customLayer],
        });
    } else {
        customCategory.children.unshift(customLayer);
    }

    return {
        ...state,
        interactiveLayerIds: [
            ...state.interactiveLayerIds,
            action.payload.layerName,
        ],
        mapConfig: {
            ...state.mapConfig,
            sources,
            menuIndex: state.mapConfig.menuIndex,
        },
    };
};

const Reduce_RemoveCustomLayer = (
    state: MapState,
    action: RemoveCustomLayerAction,
): MapState => {
    let recursiveLookup = (index: (ConfigMenuGroup | ConfigMenuLayer)[]) => {
        for (let i = 0; i < index.length; i++) {
            let element = index[i];
            if (element.type === "layer") {
                if (element.layerName === action.payload.layerName) {
                    delete state.mapConfig.sources[element.layerSource];
                    index.splice(i, 1);
                }
            } else {
                recursiveLookup(element.children);
                if (element.children.length === 0) {
                    index.splice(i--, 1);
                }
            }
        }
    };

    recursiveLookup(state.mapConfig.menuIndex);

    return { ...state, mapConfig: { ...state.mapConfig } };
};

const Reduce_ReplaceInsightsLayerData = (
    state: MapState,
    action: ReplaceInsightsLayerDataAction,
): MapState => {
    state.mapConfig.sources[action.payload.sourceName].data =
        action.payload.data;
    return {
        ...state,
        mapConfig: {
            ...state.mapConfig,
            menuIndex: [...state.mapConfig.menuIndex],
            sources: { ...state.mapConfig.sources },
        },
    };
};

const Reduce_SetInteractionMode = (
    state: MapState,
    action: SetInteractionModeAction,
): MapState => {
    // Toggling interaction mode when already in that mode will return to standard mode
    if (state.interactionMode === action.payload) {
        return { ...state, interactionMode: "standard" };
    }
    return { ...state, interactionMode: action.payload };
};

const Reduce_SetBasemap = (
    state: MapState,
    action: SetBasemapAction,
): MapState => {
    let basemaps: [BasemapType, BasemapType] = [...state.basemaps];
    basemaps[action.payload.mapIndex] = action.payload.basemap;
    return { ...state, basemaps };
};

const Reduce_SetMapConfig = (
    state: MapState,
    action: SetMapConfigAction,
): MapState => {
    let interactiveLayerIds: string[] = [];
    const sources = action.payload.sources;
    for (let sourceName in sources) {
        let layer: ConfigSource = sources[sourceName];
        let menuIndexLayers: ConfigMenuLayer[] = getLayers(
            action.payload.menuIndex,
        );
        for (let layer of menuIndexLayers) {
            if (sourceName === layer.layerSource) {
                interactiveLayerIds.push(layer.layerName);
                break;
            }
        }

        let complexPaintProperties: Array<keyof MapboxGL.AnyPaint> = [];
        switch (layer.layerType) {
            case "fill":
                complexPaintProperties = complexPaintProperties.concat(
                    getComplexAttrs<MapboxGL.FillPaint>(
                        layer.paint as MapboxGL.FillPaint,
                        ["fill-outline-color", "fill-color"],
                    ) as Array<keyof MapboxGL.AnyPaint>,
                );
                break;
            case "circle":
                complexPaintProperties = complexPaintProperties.concat(
                    getComplexAttrs<MapboxGL.CirclePaint>(
                        layer.paint as MapboxGL.CirclePaint,
                        [
                            "circle-radius",
                            "circle-stroke-color",
                            "circle-color",
                            "circle-stroke-width",
                        ],
                    ) as Array<keyof MapboxGL.AnyPaint>,
                );
                break;
            case "line":
                complexPaintProperties = complexPaintProperties.concat(
                    getComplexAttrs<MapboxGL.LinePaint>(
                        layer.paint as MapboxGL.LinePaint,
                        ["line-color", "line-width"],
                    ) as Array<keyof MapboxGL.AnyPaint>,
                );
                break;
            case "symbol":
                //@ts-ignore
                complexPaintProperties = complexPaintProperties.push(
                    //@ts-ignore
                    "this is used to trick misgis into thinking this is a complex paint",
                );
                break;
        }
        layer.complexPaintProperties = complexPaintProperties;
    }

    let currentTheme = document.getElementsByTagName("html")[0].dataset
        .theme as BasemapType;

    let basemaps: [BasemapType, BasemapType] = [
        action.payload.mapType !== "single" ? "satellite" : currentTheme,
        currentTheme,
    ];

    return {
        ...state,
        basemaps,
        mapConfig: action.payload,
        interactiveLayerIds,
    };
};

const Reduce_SetMousePosition = (
    state: MapState,
    action: SetMousePositionAction,
): MapState => {
    return {
        ...state,
        mousePosition: action.payload,
    };
};

const Reduce_SetCenter = (
    state: MapState,
    action: SetCenterAction,
): MapState => {
    return {
        ...state,
        mapConfig: {
            ...state.mapConfig,
            viewport: {
                ...state.mapConfig.viewport,
                ...action.payload,
                transitionDuration: 1000,
                transitionInterpolator: new FlyToInterpolator(),
            },
        },
    };
};

const Reduce_SetViewport = (
    state: MapState,
    action: SetViewportAction,
): MapState => {
    delete action.payload.height;
    delete action.payload.width;
    action.payload.transitionDuration = 0;
    return {
        ...state,
        mapConfig: { ...state.mapConfig, viewport: { ...action.payload } },
    };
};

const Reduce_SetLayerVisibility = (
    state: MapState,
    action: SetLayerVisibilityAction,
): MapState => {
    let sourceName = action.payload.sourceName;
    let visibility = action.payload.visibility;
    let mapConfig = state.mapConfig;
    let sources = mapConfig.sources;
    let layer;

    if (sources[sourceName]) {
        layer = sources[sourceName];
        layer.layout.visibility = visibility;
    }
    mapConfig.menuIndex = _.cloneDeep(mapConfig.menuIndex);

    return { ...state, mapConfig: { ...mapConfig } };
};

const Reduce_SetGroupVisibility = (
    state: MapState,
    action: SetGroupVisibilityAction,
): MapState => {
    let foundGroup: ConfigMenuGroup | undefined = findGroupById(
        action.payload.groupId,
        state.mapConfig.menuIndex,
    );
    if (foundGroup) {
        const mapConfig = state.mapConfig;
        const setGroupVisibility = (
            index: (ConfigMenuGroup | ConfigMenuLayer)[],
        ) => {
            index.forEach((element) => {
                if (element.type === "layer") {
                    state.mapConfig.sources[
                        element.layerSource
                    ].layout.visibility = action.payload.visibility;
                } else {
                    setGroupVisibility(element.children);
                    element.id = generateHash();
                }
            });
        };
        setGroupVisibility(foundGroup.children);

        let menuIndex = _.cloneDeep(mapConfig.menuIndex);

        return { ...state, mapConfig: { ...mapConfig, menuIndex } };
    }
    return state;
};

const Reduce_SetMapType = (
    state: MapState,
    action: SetMapTypeAction,
): MapState => {
    return {
        ...state,
        mapConfig: { ...state.mapConfig, mapType: action.payload },
    };
};

const Reduce_SetSelectedLayer = (
    state: MapState,
    action: SetSelectedLayerAction,
): MapState => {
    return { ...state, highlightedLayer: action.payload };
};

const Reduce_SetLegendPopup = (
    state: MapState,
    action: SetLegendPopupAction,
): MapState => {
    return { ...state, legendPopup: action.payload };
};

const Reduce_SetClickedFeatureProperties = (
    state: MapState,
    action: SetClickedFeaturePropertiesAction,
): MapState => {
    return { ...state, clickedFeatureProperties: action.payload };
};

const Reduce_ToggleMenu = (
    state: MapState,
    action: ToggleMenuAction,
): MapState => {
    return {
        ...state,
        menuConfig: {
            ...state.menuConfig,
            menuHidden: !state.menuConfig.menuHidden,
        },
    };
};

const Reduce_SetActiveTab = (
    state: MapState,
    action: SetActiveTabAction,
): MapState => {
    return {
        ...state,
        menuConfig: {
            ...state.menuConfig,
            menuHidden: false,
            activeTab: action.payload,
        },
    };
};

const Reduce_SetLayerFilter = (
    state: MapState,
    action: SetLayerFilterAction,
): MapState => {
    let sourceName = action.payload.sourceName;
    let customFilter = action.payload.customFilter;
    let mapConfig = state.mapConfig;
    let sources = mapConfig.sources;
    let layer = sources[sourceName];
    layer.customFilter = customFilter;
    return { ...state, mapConfig: { ...mapConfig } };
};

const Reduce_ToggleLocationLabels = (
    state: MapState,
    action: ToggleLocationLabelsAction,
) => {
    return { ...state, locationLabels: action.payload };
};
