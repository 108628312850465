import React, { FC, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import AlertContainer from "../_Library/AlertContainer/AlertContainer";
import DefaultProviders from "./DefaultProviders";
import ProductTour from "../Pages/Report/ProductTour/ProductTour";
import CookieBanner from "./Permissions/CookieBanner/CookieBanner";
import ThemeProvider from "./ThemeProvider";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { NEW_THEME_COOKIE, getCookie } from "utils/Cookies";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const App: FC = () => {
    const { load } = useAnalytics();
    useEffect(() => {
        if (load) {
            load();
        }
    });

    let htmlElem = document.getElementsByTagName("html")[0];
    htmlElem.dataset["theme"] = getCookie(NEW_THEME_COOKIE) ?? "light";
    return (
        <BrowserRouter>
            <QueryClientProvider client={new QueryClient()}>
                <DefaultProviders>
                    <ThemeProvider>
                        <AlertContainer />
                        <Router />
                        <ProductTour />
                        <CookieBanner />
                    </ThemeProvider>
                </DefaultProviders>
            </QueryClientProvider>
            <div id={"modal-root"} />
        </BrowserRouter>
    );
};

export default App;
