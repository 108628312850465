import { isObject } from "./TypeGuards";
import { Dict } from "../types/misgis";

// @ts-ignore
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source: object | undefined = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            // @ts-ignore
            if (isObject(source[key])) {
                // @ts-ignore
                if (!target[key]) Object.assign(target, { [key]: {} });
                // @ts-ignore
                mergeDeep(target[key], source[key]);
            } else {
                // @ts-ignore
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

// all child objects' properties moved to parent recursively and returned as new
export const flattenObject = (obj: Dict<any>): Dict<any> => {
    const flattened: Dict<any> = {};

    Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === "object" && value !== null) {
            Object.assign(flattened, flattenObject(value));
        } else {
            flattened[key] = value;
        }
    });
    return flattened;
};
