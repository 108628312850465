/**
 * The Report component currently handles the dashboard logic.
 * It fetches the system, and displays the components within css grid dashboard.
 *
 */

import React, { ReactNode } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

import {
    AppConfig,
    DashboardView,
    DashboardViews,
    SystemActionTypes,
} from "store/system/systemTypes";
import {
    initiateReport,
    setConfig,
    setDashboardView,
} from "store/system/systemActions";
import { RefActionTypes } from "store/ref/refTypes";
import Dashboard from "components/Pages/Report/Dashboard/Dashboard";

import classes from "components/Pages/Report/Report.module.css";
import { fetchAllEventData } from "../../../store/service/serviceActions";
import { EventSchema } from "../../../crud/eventsCRUD";
import {
    setActiveTab,
    setHighlightedLayer,
    setMapMarkerLocation,
    setViewport,
} from "../../../store/map/mapActions";
import { Icon } from "@mdi/react";
import { mdiArrowLeftBold } from "@mdi/js";
import ReactTooltip from "react-tooltip";
import { setEventId } from "store/insights/insightsActions";
import { registerPageChange } from "../../../store/matomo/matomoActions";
import { RootState } from "../../../store/store";
import { getStoreAtNamespaceKey } from "../../../store/storeSelectors";

interface OwnProps {
    reportConfig: AppConfig;
    eventName?: string;
}
interface StateProps {
    eventData?: EventSchema[];
}
interface DispatchProps {
    initiateReport: typeof initiateReport;
    setDashboardView: typeof setDashboardView;
    fetchEventData: typeof fetchAllEventData;
    setViewport: typeof setViewport;
    setEventId: typeof setEventId;
    setConfig: typeof setConfig;
    setActiveTab: typeof setActiveTab;
    setSelectedLayer: typeof setHighlightedLayer;
    setMapMarkerLocation: typeof setMapMarkerLocation;
    registerPageChange: typeof registerPageChange;
}

type Extensions = IOktaContext &
    RouteComponentProps<{
        id: string;
        config_id: string;
        longitude: string;
        latitude: string;
        zoom: string;
    }>;
type AppProps = DispatchProps & StateProps & OwnProps & Extensions;

interface AppState {
    eventId: string | undefined;
}

class Report extends React.Component<AppProps, AppState> {
    eventID = this.props.match.params.id;
    configID = this.props.match.params.config_id;
    reportId = this.props.reportConfig.reportId;
    preview = this.props.match.path.includes("preview_report");

    state: AppState = {
        eventId: undefined,
    };

    componentDidMount() {
        const { id, longitude, latitude, zoom } = this.props.match.params;
        const initialViewport =
            longitude && latitude && zoom
                ? {
                      latitude: parseFloat(latitude),
                      longitude: parseFloat(longitude),
                      zoom: parseFloat(zoom),
                  }
                : null;

        const page = new URLSearchParams(this.props.location.search).get(
            "page",
        );
        this.props.setDashboardView({
            view: page && this.isDashboardView(page) ? page : "report",
        });
        this.props.setActiveTab("Layer");
        this.props.setSelectedLayer(null);
        this.props.setMapMarkerLocation(null);

        // If app is loaded directly into report page, fetch event data.
        let eventData = this.props.eventData;
        if (!eventData?.length) {
            const token = this.props.oktaAuth.getAccessToken();
            if (token) {
                this.props.fetchEventData(token);
            }
        } else {
            if (this.props.location.pathname.includes("/report/")) {
                let currentEvent = eventData.find(
                    (index) => index.id === this.eventID,
                );
                this.props.registerPageChange({
                    href: this.props.location.pathname,
                    title: `GEO - ${currentEvent?.name}`,
                });
            } else if (this.props.location.pathname.includes("/tutorial/")) {
                this.props.registerPageChange({
                    href: this.props.location.pathname,
                    title: `GEO - Tutorial`,
                });
            }
        }

        this.props.initiateReport(initialViewport, this.props.reportConfig);
        this.setState({ eventId: id });
    }

    isDashboardView(page: string): page is DashboardView {
        return DashboardViews.includes(page as DashboardView);
    }

    componentWillUnmount() {
        this.props.setConfig(null);
    }

    componentDidUpdate(
        prevProps: Readonly<AppProps>,
        prevState: Readonly<AppState>,
        snapshot?: any,
    ) {
        if (!prevProps.eventData?.length && this.props.eventData?.length) {
            let currentEvent = this.props.eventData.find(
                (index) => index.id === this.eventID,
            );
            this.props.registerPageChange({
                href: this.props.location.pathname,
                title: `GEO - ${currentEvent?.name}`,
            });
        }
    }

    render(): ReactNode {
        return (
            <div className={classes.Container}>
                <Dashboard
                    eventId={this.state.eventId}
                    reportId={this.reportId}
                />
                {this.preview && (
                    <Link to={`/admin/events/${this.eventID}/${this.configID}`}>
                        <div
                            className={classes.ReturnButton}
                            data-for={"TextTooltip"}
                            data-tip={"Return to Admin"}
                        >
                            <Icon
                                className={classes.ReturnIcon}
                                path={mdiArrowLeftBold}
                            />
                        </div>
                        <ReactTooltip
                            id={"TextTooltip"}
                            place={"top"}
                            effect={"float"}
                        />
                    </Link>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    eventData: getStoreAtNamespaceKey(state, "service").eventData,
});

// Thunk dispatch is used as fetchConfig is an async action.
const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes | RefActionTypes>,
) => ({
    initiateReport: bindActionCreators(initiateReport, dispatch),
    setDashboardView: bindActionCreators(setDashboardView, dispatch),
    fetchEventData: bindActionCreators(fetchAllEventData, dispatch),
    setViewport: bindActionCreators(setViewport, dispatch),
    setEventId: bindActionCreators(setEventId, dispatch),
    setConfig: bindActionCreators(setConfig, dispatch),
    setActiveTab: bindActionCreators(setActiveTab, dispatch),
    setSelectedLayer: bindActionCreators(setHighlightedLayer, dispatch),
    setMapMarkerLocation: bindActionCreators(setMapMarkerLocation, dispatch),
    registerPageChange: bindActionCreators(registerPageChange, dispatch),
});

// connect is used to link the component to the state store
export default withRouter(
    withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(Report)),
);
