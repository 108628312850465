import React, { ReactNode } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Icon from "@mdi/react";
import { ThunkDispatch } from "redux-thunk";
import {
    mdiChevronDown,
    mdiChevronUp,
    mdiDotsHorizontal,
} from "@mdi/js/commonjs/mdi";
import { mdiClose } from "@mdi/js";

import { getCssVar } from "../../../../../../../../../utils/CSSHelpers";
import {
    ConfigMenuGroup,
    ConfigMenuLayer,
} from "../../../../../../../../../store/system/systemTypes";
import {
    MapActionTypes,
    Visibility,
} from "../../../../../../../../../store/map/mapTypes";
import {
    setLayerVisibility,
    setHighlightedLayer,
    setGroupVisibility,
} from "../../../../../../../../../store/map/mapActions";
import { RootState } from "../../../../../../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";

import classes from "../LayersTab.module.css";
import { registerAnalyticsEvent } from "../../../../../../../../../store/matomo/matomoActions";

interface OwnProps {
    group: ConfigMenuGroup;
    renderChildren: (
        children: (ConfigMenuGroup | ConfigMenuLayer)[],
    ) => ReactNode;
    initiallyExpanded: boolean;
}

interface StateProps {
    highlightedLayer: { layerName: string; sourceName: string } | null;
}

interface DispatchProps {
    setLayerVisibility: typeof setLayerVisibility;
    setGroupVisibility: typeof setGroupVisibility;
    setHighlightedLayer: typeof setHighlightedLayer;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
}
type GroupListItemProps = OwnProps & StateProps & DispatchProps;

interface GroupListItemState {
    isOpen: boolean;
    isContextOpen: boolean;
}

export class GroupListItem extends React.Component<
    GroupListItemProps,
    GroupListItemState
> {
    constructor(props: GroupListItemProps) {
        super(props);
        this.state = {
            isOpen: props.initiallyExpanded,
            isContextOpen: false,
        };
    }

    openGroup = () => {
        this.setState({
            isOpen: true,
        });
    };

    closeGroup = () => {
        this.setState({
            isOpen: false,
        });
    };

    toggleGroupOpen = () => {
        this.props.setHighlightedLayer(null);
        this.setState(
            (state) => {
                return { isOpen: !state.isOpen };
            },
            () => {
                let status = this.state.isOpen ? "opened" : "closed";
                this.props.registerAnalyticsEvent({
                    category: "Layers",
                    action:
                        this.props.group.groupName + " layer group " + status,
                });
            },
        );
    };

    toggleContextOpen = () => {
        this.setState((state) => {
            return { isContextOpen: !state.isContextOpen };
        });
    };

    setGroupVisibility(group: ConfigMenuGroup, status: Visibility) {
        this.props.setGroupVisibility({
            groupId: group.id,
            visibility: status,
        });
    }

    renderContextMenu(group: ConfigMenuGroup) {
        return (
            <>
                <div
                    className={classes.MenuItem}
                    onClick={(e) => {
                        this.setGroupVisibility(group, "visible");
                        this.toggleContextOpen();
                        e.stopPropagation();
                    }}
                >
                    {"Show All Layers"}
                </div>
                <div
                    className={classes.MenuItem}
                    onClick={(e) => {
                        this.setGroupVisibility(group, "none");
                        this.toggleContextOpen();
                        e.stopPropagation();
                    }}
                >
                    {"Hide All Layers"}
                </div>
            </>
        );
    }

    containsHighlightedLayer = (group: ConfigMenuGroup) => {
        return group.children.find((child) => {
            if (child.type === "layer") {
                return (
                    child.layerName ===
                        this.props.highlightedLayer?.layerName &&
                    child.layerSource ===
                        this.props.highlightedLayer?.sourceName
                );
            } else {
                this.containsHighlightedLayer(child);
            }
            return false;
        });
    };

    render() {
        const group = this.props.group;

        return (
            <div
                id={`touridLayerGroup${group.groupName}`}
                key={group.groupName}
                className={classes.Group}
            >
                <div className={classes.GroupHeader}>
                    <span className={classes.GroupLabel}>
                        {group.groupName}
                    </span>
                    <div className={classes.LayerMenu}>
                        <ReactTooltip
                            id={"LayerGroupToolTip"}
                            place={"left"}
                            effect={"solid"}
                        />
                        <div
                            data-tip={"Open/Close Group"}
                            data-for={"LayerGroupToolTip"}
                            onClick={this.toggleGroupOpen}
                            id={`touridLayerGroupOpen${group.groupName}`}
                        >
                            <Icon
                                path={
                                    this.state.isOpen
                                        ? mdiChevronUp
                                        : mdiChevronDown
                                }
                            />
                        </div>
                        {!this.props.group.isInsights && (
                            <div
                                data-tip={"Show/Hide All Layers Menu"}
                                data-for={"ShowHideAllLayers"}
                                onClick={this.toggleContextOpen}
                            >
                                <ReactTooltip
                                    id={"ShowHideAllLayers"}
                                    place={"left"}
                                    effect={"solid"}
                                />
                                <Icon
                                    path={
                                        this.state.isContextOpen
                                            ? mdiClose
                                            : mdiDotsHorizontal
                                    }
                                    color={getCssVar(
                                        this.state.isContextOpen
                                            ? "--highlight-color"
                                            : "--text-color",
                                    )}
                                />
                            </div>
                        )}
                        {this.state.isContextOpen && (
                            <div className={classes.ContextMenu}>
                                {this.renderContextMenu(group)}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={classes.GroupBody}
                    style={{
                        display:
                            this.state.isOpen ||
                            this.containsHighlightedLayer(group)
                                ? "block"
                                : "none",
                    }}
                >
                    {this.props.renderChildren(group.children)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    highlightedLayer: getStoreAtNamespaceKey(state, "map").highlightedLayer,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => ({
    setLayerVisibility: bindActionCreators(setLayerVisibility, dispatch),
    setGroupVisibility: bindActionCreators(setGroupVisibility, dispatch),
    setHighlightedLayer: bindActionCreators(setHighlightedLayer, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(GroupListItem);
