//@ts-nocheck
import { TutorialConfig } from "./index";
import React from "react";
import { mdiFilterOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { NEW_THEME_COOKIE, getCookie } from "../../utils/Cookies";
import { mdiMIS_legendPoly } from "assets/icons/paths";

const filtering_tutorial: TutorialConfig = {
    name: "Filtering",
    description: "An overview of filtering data",
    tags: ["filtering", "data"],
    version: 2.0,
    InsightsBannerWrapper: {
        published: true,
        insight_id: "",
        description: {
            as_at_date: "2023-03-01",
            exposure_revision: "2",
            claims_revision: "8",
            event_id: "tutorial",
        },
        details:
            "Company,LocationID,PerilCode,Policy Reference,InsuredName,Inception Date,Expiry Date,SQFT,YearBuilt,CountryCode,Country,Region,SubRegion,City,PostalCode,Longitude,Latitude,Property Value (Original Currency),Contents Value (Original Currency),BI Value (Original Currency),Ground Up TIV (Original Currency),Exposure Layer Assessment,Claims Layer Assessment,Cause,Function\n" +
            "Demo,1,WS,AIILM21,Florida State Department for Health,44805,45170,250,1961.0,US,United States,FLORIDA,PUTNAM COUNTY,PALATKA,32177,-80.22993569,25.8127367,238544341,210796,407853,239162990,No Damage,,,\n" +
            "Demo,5,WS,GYSHW21,Florida State Department for Health,44805,45170,480,,US,United States,FLORIDA,PUTNAM COUNTY,PALATKA,32177,-82.29728838,28.79985638,785973770,812074,509799,787295643,No Damage,,,\n" +
            "Demo,6,WS,GYSHW21,Florida State Department for Health,44805,45170,720,2006.0,US,United States,FLORIDA,PUTNAM COUNTY,PALATKA,32177,-81.80553221,27.81555216,477267462,856471,976217,479100150,Moderate Damage,,,\n" +
            "Demo,7,WS,OPUAR22,Florida State Department for Health,44805,45170,9000,,US,United States,FLORIDA,PUTNAM COUNTY,PALATKA,32177,-81.80524721,27.81566416,165865301,851125,778804,167495230,Moderate Damage,,,\n" +
            "Demo,9,WS,GYSHW21,Florida State Department for Health,44805,45170,532,2003.0,US,United States,FLORIDA,PUTNAM COUNTY,PALATKA,32177,-80.24208674,27.26394206,511271386,364308,979993,512615687,No Damage,,,\n" +
            "Demo,1056,WS,DKTMX22,Florida State Department for Health,44805,45170,23015,1989.0,US,United States,FLORIDA,ST. LUCIE COUNTY,FORT PIERCE,34950,-81.90490321,26.80663791,353723705,622852,792954,355139511,Moderate Damage,,,\n" +
            "Demo,1076,WS,RXAOM21,Florida State Department for Health,44805,45170,120,,US,United States,FLORIDA,ST. LUCIE COUNTY,FORT PIERCE,34950,-81.90491021,26.80664191,762805930,190894,971539,763968363,Moderate Damage,,,\n" +
            "Demo,1653,WS,JVIAF19,Florida State Department for Health,44805,45170,464,,US,United States,FLORIDA,PASCO COUNTY,NEW PORT RICHEY,34654,-81.82261122,27.90145018,244878499,822042,835187,246535728,Moderate Damage,,,\n" +
            "Demo,1959,WS,TVOMN22,Florida State Department for Health,44805,45170,0,,US,United States,FLORIDA,SUMTER COUNTY,BUSHNELL,33513,-82.00821326,27.5945181,139711521,305198,521287,140538006,Substantial Damage,,,\n" +
            "Demo,2097,WS,CAKHF20,Florida State Department for Health,44805,45170,3840,1971.0,US,United States,FLORIDA,MARION COUNTY,WEIRSDALE,32195,-81.67023915,27.20899501,342535683,919463,412309,343867455,Moderate Damage,,,\n" +
            "Demo,2114,WS,QPIPY22,Florida State Department for Health,44805,45170,720,1993.0,US,United States,FLORIDA,COLLIER COUNTY,MARCO ISLAND,34145,-81.67023915,27.20898901,837258584,588918,335398,838182900,Moderate Damage,,,\n" +
            "Demo,2129,WS,VWSOF22,Florida State Department for Health,44805,45170,888,,US,United States,FLORIDA,COLLIER COUNTY,NAPLES,34114,-81.67024615,27.20899501,715117610,573189,744280,716435079,Moderate Damage,,,\n" +
            "Demo,2323,WS,YJYGD19,Florida State Department for Education,44805,45170,3750,1998.0,US,United States,FLORIDA,ST. JOHNS COUNTY,SAINT AUGUSTINE,32084,-82.00820326,27.5945141,704992825,921709,460955,706375489,Substantial Damage,,,\n" +
            "Demo,2457,WS,LGKQN22,Florida State Department for Education,44805,45170,0,,US,United States,FLORIDA,ST. JOHNS COUNTY,ST AUGUSTINE,32084,-82.00820626,27.5945211,567824981,509807,545466,568880254,Substantial Damage,,,\n" +
            "Demo,2694,WS,TBCKA20,Florida State Department for Education,44805,45170,3040,1992.0,US,United States,FLORIDA,INDIAN RIVER COUNTY,SEBASTIAN,32958,-82.00820326,27.5945181,728958663,365284,354360,729678307,Substantial Damage,,,\n" +
            "Demo,3520,WS,MVDFK20,Florida State Department for Education,44805,45170,252,2005.0,US,United States,FLORIDA,PINELLAS COUNTY,CLEARWATER,33765,-81.9099402,26.68606488,186537623,701508,564594,187803725,Severe Damage,Moderate Damage,Wind,Moderate Impact to Functionality\n" +
            "Demo,5531,WS,EOEUA22,Florida State Department for Environment,44805,45170,102,1999.0,US,United States,FLORIDA,INDIAN RIVER COUNTY,SEBASTIAN,32958,-85.74418042,30.13060559,703822905,690058,256277,704769240,,,,\n" +
            "Demo,5769,WS,PGBSD19,Florida State Department for Transport,44805,45170,288,,US,United States,FLORIDA,PINELLAS COUNTY,SAINT PETERSBURG,33715,-82.5070934,27.33104102,876938368,224721,317238,877480327,Moderate Damage,No Damage,,Fully Functional\n" +
            "Demo,5933,WS,EOEUA22,Florida State Department for Transport,44805,45170,3700,1990.0,US,United States,FLORIDA,PINELLAS COUNTY,SAINT PETERSBURG,33713,-80.79418694,28.62419438,814876005,246403,363086,815485494,,,,\n" +
            "Demo,6275,WS,SOMHR22,Florida State Department for Transport,44805,45170,543,1998.0,US,United States,FLORIDA,PINELLAS COUNTY,SAINT PETERSBURG,33702,-82.63898944,27.59758808,734999921,717068,721453,736438442,,,,\n" +
            "Demo,6276,WS,GYSHW21,Florida State Department for Transport,44805,45170,543,1998.0,US,United States,FLORIDA,PINELLAS COUNTY,SAINT PETERSBURG,33702,-82.63453944,27.59499208,567784720,263968,178992,568227680,,,,\n" +
            "Demo,6297,WS,GYSHW21,Florida State Department for Transport,44805,45170,543,1998.0,US,United States,FLORIDA,PINELLAS COUNTY,SAINT PETERSBURG,33714,-82.64805945,27.60308608,990546333,857876,422521,991826730,,,,\n" +
            "Demo,6298,WS,GYSHW21,Florida State Department for Transport,44805,45170,3200,1998.0,US,United States,FLORIDA,PINELLAS COUNTY,ST PETERSBURG,33702,-82.64442644,27.59995008,536921533,627890,981935,538531358,,,,\n" +
            "Demo,6299,WS,GYSHW21,Florida State Department for Transport,44805,45170,1188,1998.0,US,United States,FLORIDA,PINELLAS COUNTY,SAINT PETERSBURG,33702,-82.65058645,27.60547608,32939039,578041,267356,33784436,,,,\n" +
            "Demo,6427,WS,KBFUX21,Florida State Department for Transport,44805,45170,680,1992.0,US,United States,FLORIDA,PINELLAS COUNTY,SAINT PETERSBURG,33713,-80.79410294,28.62444338,154691350,216733,300013,155208096,,,,\n" +
            "Demo,6432,WS,HXDZA20,Florida State Department for Transport,44805,45170,1300,1992.0,US,United States,FLORIDA,PINELLAS COUNTY,SAINT PETERSBURG,33701,-80.79404994,28.62439438,461649455,389013,520307,462558775,,,,\n" +
            "Demo,7049,WS,BCTUS22,Florida State Department for Transport,44805,45170,288,2002.0,US,United States,FLORIDA,HILLSBOROUGH COUNTY,TAMPA,33604,-81.81723617,26.43325482,2582422,910598,770603,4263623,Moderate Damage,No Damage,,Fully Functional\n" +
            "Demo,7136,WS,WCXPX19,Florida State Department for Transport,44805,45170,880,1996.0,US,United States,FLORIDA,HILLSBOROUGH COUNTY,TAMPA,33612,-81.80834817,26.63290087,433531295,901109,636304,435068708,Moderate Damage,Light Damage,Wind,Low Impact to Functionality\n" +
            "Demo,7172,WS,FMQBT22,Florida State Department for Transport,44805,45170,5528,1989.0,US,United States,FLORIDA,PASCO COUNTY,WESLEY CHAPEL,33544,-82.16089932,28.00495919,678409467,569152,931329,679909948,Light Damage,,,\n" +
            "Demo,7679,WS,EIOQM21,Florida State Department for Agriculture,44805,45170,64,,US,United States,FLORIDA,COLLIER COUNTY,NAPLES,34109,-85.40250131,29.76452652,347246873,237556,338620,347823049,,,,\n" +
            "Demo,7745,WS,QJAGE22,Florida State Department for Agriculture,44805,45170,192,1961.0,US,United States,FLORIDA,COLLIER COUNTY,NAPLES,34109,-81.28033597,24.65505738,273288804,211144,558126,274058074,,,,\n" +
            "Demo,7746,WS,YJMZB21,Florida State Department for Agriculture,44805,45170,192,1961.0,US,United States,FLORIDA,COLLIER COUNTY,NAPLES,34109,-81.28049697,24.65503238,638806364,772685,804425,640383474,,,,\n" +
            "Demo,7944,WS,BTCJP21,Florida State Department for Agriculture,44805,45170,201,1825.0,US,United States,FLORIDA,LAKE COUNTY,EUSTIS,32736,-80.15596767,25.66657766,113295019,485952,661742,114442713,,,,\n" +
            "Demo,8144,WS,HSGVL20,Florida State Department for Agriculture,44805,45170,4490,1961.0,US,United States,FLORIDA,VOLUSIA COUNTY,DELAND,32720,-81.27999997,24.65541738,87125808,565257,367755,88058820,,,,\n" +
            "Demo,8443,WS,YOEUW21,Florida State Department for Agriculture,44805,45170,720,1970.0,US,United States,FLORIDA,VOLUSIA COUNTY,ORMOND BEACH,32174,-81.51915011,27.47214308,824447037,229110,303165,824979312,Moderate Damage,,,\n" +
            "Demo,8553,WS,EOEUA22,Florida State Department for Agriculture,44805,45170,2040,2006.0,US,United States,FLORIDA,VOLUSIA COUNTY,DAYTONA BEACH,32114,-81.10718206,29.43709056,786092064,437776,499777,787029617,,,,\n" +
            "Demo,8674,WS,EOEUA22,Florida State Department for Agriculture,44805,45170,1010,1962.0,US,United States,FLORIDA,OKEECHOBEE COUNTY,OKEECHOBEE,34972,-81.40254308,27.33124505,873906100,133895,498169,874538164,Moderate Damage,,,\n" +
            "Demo,8742,WS,DXEWG20,Florida State Department for Agriculture,44805,45170,192,2002.0,US,United States,FLORIDA,BREVARD COUNTY,MERRITT ISLAND,32952,-81.4408911,27.70420314,170600387,210882,592233,171403502,Moderate Damage,,,\n" +
            "Demo,9030,WS,ODEPA20,Florida State Department for Agriculture,44805,45170,12047,1926.0,US,United States,FLORIDA,BREVARD COUNTY,MELBOURNE,32904,-82.56436941,27.38415603,374946014,877253,373735,376197002,Light Damage,No Damage,,Fully Functional\n" +
            "Demo,9394,WS,BDXYJ21,Florida State Department for Agriculture,44805,45170,748,1960.0,US,United States,FLORIDA,BREVARD COUNTY,PALM BAY,32905,-81.08903005,29.34963654,539098315,236034,638292,539972641,,,,\n" +
            "Demo,10333,WS,MVDFK20,Florida State Fire Department,44805,45170,4808,1952.0,US,United States,FLORIDA,HILLSBOROUGH COUNTY,TAMPA,33607,-82.21610339,29.75001561,66876208,464670,220829,67561707,No Damage,,,\n" +
            "Demo,10744,WS,NXYPT21,Florida State Police Department,44805,45170,950,,US,United States,FLORIDA,HILLSBOROUGH COUNTY,TAMPA,33604,-82.73531052,28.99555342,477447761,609394,928108,478985263,,,,\n" +
            "Demo,11243,WS,MGPMW21,Florida State Police Department,44805,45170,3200,,US,United States,FLORIDA,HILLSBOROUGH COUNTY,THONOTOSASSA,33592,-83.70930388,31.13950588,9755934,570827,334725,10661486,,,,\n" +
            "Demo,11866,WS,TECKN20,Florida State Police Department,44805,45170,150,2005.0,US,United States,FLORIDA,ORANGE COUNTY,APOPKA,32703,-81.94950422,26.85944692,674996443,877137,426767,676300347,Substantial Damage,,,\n" +
            "Demo,12298,WS,EOEUA22,Florida State Police Department,44805,45170,166,1961.0,US,United States,FLORIDA,SEMINOLE COUNTY,WINTER SPRINGS,32708,-81.27931097,24.65621638,422155556,870724,912745,423939025,,,,\n" +
            "Demo,12299,WS,XJHFW21,Florida State Police Department,44805,45170,166,1961.0,US,United States,FLORIDA,SEMINOLE COUNTY,WINTER SPRINGS,32708,-81.27938797,24.65610938,133683987,318570,877706,134880263,,,,\n" +
            "Demo,12301,WS,TIDPZ19,Florida State Police Department,44805,45170,166,1961.0,US,United States,FLORIDA,SEMINOLE COUNTY,LONGWOOD,32750,-81.27952597,24.65599438,781198437,103205,746031,782047673,,,,\n" +
            "Demo,12303,WS,YKHTC19,Florida State Police Department,44805,45170,166,1961.0,US,United States,FLORIDA,SEMINOLE COUNTY,CASSELBERRY,32707,-81.27965297,24.65588338,987370718,870498,149076,988390292,,,,\n" +
            "Demo,12305,WS,EOEUA22,Florida State Police Department,44805,45170,166,1961.0,US,United States,FLORIDA,SEMINOLE COUNTY,WINTER SPRINGS,32708,-81.27974997,24.65579738,31261546,671617,243751,32176914,,,,\n" +
            "Demo,12468,WS,LYVMO19,Florida State Police Department,44805,45170,45,2000.0,US,United States,FLORIDA,SEMINOLE COUNTY,WINTER SPRINGS,32708,-81.28037297,24.65455638,762385682,854378,286806,763526866,,,,\n" +
            "Demo,12469,WS,UBNKL20,Florida State Police Department,44805,45170,45,2000.0,US,United States,FLORIDA,SEMINOLE COUNTY,WINTER SPRINGS,32708,-81.28016697,24.65503738,739178538,106616,418818,739703972,,,,\n" +
            "Demo,12476,WS,PNGHO20,Florida State Police Department,44805,45170,425,1961.0,US,United States,FLORIDA,SEMINOLE COUNTY,OVIEDO,32765,-81.28002297,24.65518638,622105871,978626,126930,623211427,,,,\n" +
            "Demo,12934,WS,EOEUA22,Florida State Police Department,44805,45170,3600,1990.0,US,United States,FLORIDA,BREVARD COUNTY,TITUSVILLE,32796,-80.12902267,25.91208872,6961687,581366,585152,8128205,,,,\n" +
            "Demo,13152,WS,GYSHW21,Florida State Ambulance Department,44805,45170,180,1990.0,US,United States,FLORIDA,BREVARD COUNTY,COCOA,32922,-80.12991267,25.90890972,536921533,627890,981935,538531358,,,,\n" +
            "Demo,13357,WS,CIBXL19,Florida State Ambulance Department,44805,45170,756,1990.0,US,United States,FLORIDA,HILLSBOROUGH COUNTY,BRANDON,33510,-80.12992267,25.90862672,216250052,838434,517877,217606363,,,,\n" +
            "Demo,13359,WS,OCMVN22,Florida State Ambulance Department,44805,45170,756,1990.0,US,United States,FLORIDA,HILLSBOROUGH COUNTY,RIVERVIEW,33578,-80.12983267,25.90939372,482432172,481431,238403,483152006,,,,\n" +
            "Demo,13680,WS,OCMVN22,Florida State Ambulance Department,44805,45170,520,,US,United States,FLORIDA,POLK COUNTY,LAKELAND,33809,-82.35002543,29.63159458,203402019,153672,829179,204384870,No Damage,,,\n" +
            "Demo,14179,WS,JQZSL20,Florida State Ambulance Department,44805,45170,122141,2004.0,US,United States,FLORIDA,ORANGE COUNTY,ORLANDO,32819,-81.01960803,29.21519151,585178321,429192,579229,586186742,,,,\n" +
            "Demo,14316,WS,KJUTP21,University of Florida,44805,45170,3705,1989.0,US,United States,FLORIDA,ORANGE COUNTY,ORLANDO,32805,-84.83652019,30.71143775,604696259,562286,522390,605780935,,,,\n" +
            "Demo,14317,WS,JNPBX22,University of Florida,44805,45170,400,1983.0,US,United States,FLORIDA,ORANGE COUNTY,ORLANDO,32805,-84.83632519,30.71208575,118678266,222686,287701,119188653,,,,\n" +
            "Demo,14318,WS,VJGHB20,University of Florida,44805,45170,240,1986.0,US,United States,FLORIDA,ORANGE COUNTY,ORLANDO,32801,-84.83585419,30.71150775,9818062,658465,190928,10667455,,,,\n" +
            "Demo,16043,WS,ARIOA20,USA Military Facilities,44805,45170,11138,1994.0,US,United States,FLORIDA,HILLSBOROUGH COUNTY,PLANT CITY,33567,-82.74454349,28.12001721,626439312,645113,117750,627202175,Light Damage,,,\n" +
            "Demo,16724,WS,MVDFK20,USA Military Facilities,44805,45170,400,1981.0,US,United States,FLORIDA,BAY COUNTY,SOUTHPORT,32409,-85.0498162,29.60321649,277963858,727808,726803,279418469,,,,\n" +
            "Demo,17365,WS,MVDFK20,Florida Waterways,44805,45170,10,2001.0,US,United States,FLORIDA,BAY COUNTY,PANAMA CITY,32401,-81.83110917,26.28780178,304939584,247784,180034,305367402,,,,\n" +
            "Demo,17784,WS,FHLVL22,Florida Waterways,44805,45170,24,2014.0,US,United States,FLORIDA,GULF COUNTY,PORT SAINT JOE,32456,-84.96054618,29.6136735,900774419,989110,312909,902076438,,,,\n" +
            "Demo,18700,WS,YLFGF21,Florida Waste Management,44805,45170,50818,2012.0,US,United States,FLORIDA,LEON COUNTY,TALLAHASSEE,32317,-81.70053413,26.10406074,683963008,948901,198293,685110202,Severe Damage,No Damage,,Fully Functional\n" +
            "Demo,19206,WS,EOEUA22,Florida Waste Management,44805,45170,10449,1982.0,US,United States,FLORIDA,SUWANNEE COUNTY,LAKE CITY,32024,-81.8899172,26.55198984,219362481,447618,401564,220211663,Severe Damage,Light Damage,Wind,Low Impact to Functionality\n" +
            "Demo,19255,WS,EOEUA22,Florida Waste Management,44805,45170,1574,2009.0,US,United States,FLORIDA,LEVY COUNTY,TRENTON,32693,-80.2470157,25.87804371,917343500,956971,343213,918643684,No Damage,,,\n" +
            "Demo,20723,WS,PKCDE21,Florida Tourism Department,44805,45170,974,,US,United States,FLORIDA,SARASOTA COUNTY,SARASOTA,34231,-81.86428219,26.58112785,31116089,323267,197584,31636940,Moderate Damage,Light Damage,Wind,Low Impact to Functionality\n" +
            "Demo,20787,WS,PERWW21,Florida Tourism Department,44805,45170,120,,US,United States,FLORIDA,SARASOTA COUNTY,VAMO,34231,-76.99904828,38.89431979,491895881,135642,730294,492761817,,,,\n" +
            "Demo,20957,WS,UMAAL19,Florida Tourism Department,44805,45170,5242,,US,United States,FLORIDA,LEE COUNTY,SANIBEL,33957,-117.8823781,33.79535485,948115192,321238,350627,948787057,,,,\n" +
            "Demo,21020,WS,SRFLU22,Florida Tourism Department,44805,45170,56,,US,United States,FLORIDA,LEE COUNTY,CAPE CORAL,33904,-81.8095042,27.53338309,365982038,194857,781375,366958270,Substantial Damage,,,\n" +
            "Demo,21026,WS,LGFFQ21,Florida Tourism Department,44805,45170,3129,,US,United States,FLORIDA,LEE COUNTY,CAPE CORAL,33990,-81.86163121,27.21491001,261049534,525573,409006,261984113,Substantial Damage,Light Damage,Wind,Low Impact to Functionality\n" +
            "Demo,21039,WS,OQJCY20,Florida Tourism Department,44805,45170,7030,,US,United States,FLORIDA,LEE COUNTY,CAPE CORAL,33904,-80.10451335,40.45400197,548570561,324571,956750,549851882,,,,\n" +
            "Demo,21075,WS,YITMT19,Florida Tourism Department,44805,45170,6503,,US,United States,FLORIDA,LEE COUNTY,FORT MYERS,33908,-84.46707223,33.89959746,528322847,380546,621311,529324704,,,,\n" +
            "Demo,21210,WS,ZSDPF19,Florida Tourism Department,44805,45170,4078,,US,United States,FLORIDA,LEE COUNTY,FORT MYERS,33908,-87.89235983,41.86910984,176768016,470428,390702,177629146,,,,\n" +
            "Demo,21256,WS,MGAAC21,Florida Tourism Department,44805,45170,16199,,US,United States,FLORIDA,LEE COUNTY,NORTH FORT MYERS,33917,-81.61861724,30.30258375,419060384,893156,320458,420273998,No Damage,,,\n" +
            "Demo,21390,WS,CKCMG22,Florida Tourism Department,44805,45170,108,,US,United States,FLORIDA,POLK COUNTY,FORT MEADE,33841,-77.01870229,38.90760779,517546881,862389,694150,519103420,,,,\n" +
            "Demo,21485,WS,BUKEQ20,Florida Tourism Department,44805,45170,784,,US,United States,FLORIDA,POLK COUNTY,LAKE WALES,33853,-81.84729618,26.60260186,84455742,540777,315193,85311712,Moderate Damage,No Damage,,Fully Functional\n" +
            "Demo,21508,WS,SYZVX20,Florida Tourism Department,44805,45170,8865,,US,United States,FLORIDA,POLK COUNTY,LAKE WALES,33853,-82.7973035,27.81190513,912358579,140568,685164,913184311,,,,\n" +
            "Demo,21510,WS,VIPXR19,Florida Tourism Department,44805,45170,1785,,US,United States,FLORIDA,POLK COUNTY,LAKE WALES,33853,-80.09459367,26.45691086,533915142,491280,255052,534661474,No Damage,,,\n",
        insights: {
            exposure: [
                {
                    label: "Light Damage",
                    value: 3,
                },
                {
                    label: "Moderate Damage",
                    value: 16,
                },
                {
                    label: "No Damage",
                    value: 8,
                },
                {
                    label: "Severe Damage",
                    value: 3,
                },
                {
                    label: "Substantial Damage",
                    value: 7,
                },
            ],
            claims: [
                {
                    label: "Light Damage",
                    value: 4,
                },
                {
                    label: "Moderate Damage",
                    value: 1,
                },
                {
                    label: "No Damage",
                    value: 5,
                },
            ],
            drivers: [
                {
                    label: "Florida State Department for Health",
                    value: 12,
                },
                {
                    label: "Florida State Department for Education",
                    value: 4,
                },
                {
                    label: "Florida State Department for Environment",
                    value: 1,
                },
                {
                    label: "Florida State Department for Transport",
                    value: 12,
                },
                {
                    label: "Florida State Department for Agriculture",
                    value: 11,
                },
                {
                    label: "Florida State Fire Department",
                    value: 1,
                },
                {
                    label: "Florida State Police Department",
                    value: 12,
                },
                {
                    label: "Florida State Ambulance Department",
                    value: 5,
                },
                {
                    label: "Florida Waste Management",
                    value: 3,
                },
                {
                    label: "Florida Waterways",
                    value: 2,
                },
                {
                    label: "University of Florida",
                    value: 3,
                },
                {
                    label: "USA Military Facilities",
                    value: 2,
                },
                {
                    label: "Florida Tourism Department",
                    value: 13,
                },
            ],
        },
    },
    appConfig: {
        reportId: "",
        components: [
            {
                type: "map",
                options: {
                    mapType: "single",
                    sources: {
                        bkkczybfiv: {
                            url: "mapbox://mike-mis.ahopgtuq",
                            type: "vector",
                            paint: {
                                "line-color": "#fff",
                                "line-width": 2,
                                "line-dasharray": [2, 3],
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Hurricane_Ian-Track-2mwqko",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "line",
                            complexPaintProperties: [],
                        },
                        f1n5zq94nn: {
                            url: "mapbox://mike-mis.8rchgimj",
                            type: "vector",
                            paint: {
                                "circle-color": "#fff",
                                "circle-stroke-color": "#f44336",
                                "circle-stroke-width": 2,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Hurricane_Ian-Dates-cjk4yv",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "circle",
                            complexPaintProperties: [],
                        },
                        xaskirx5mu: {
                            url: "mapbox://mike-mis.3wvs08si",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgba(102,115,131,0.4)",
                                    "Risk of Internal Damage",
                                    "rgba(131,124,97,0.4)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(80,80,80,0.4)",
                                ],
                                "fill-outline-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgb(0,116,255)",
                                    "Risk of Internal Damage",
                                    "rgb(253,196,0)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(136,136,136,0.4)",
                                ],
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Hurricane_Ian-Florida_Exposur-5cnauo",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        bus9ys6gnb: {
                            url: "mapbox://mike-mis.cgwoe79q",
                            type: "vector",
                            paint: {
                                "fill-color": "#00dbff",
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian_-_Possible_Floo-4hpvky",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        bzhmi6k58i: {
                            url: "mapbox://mike-mis.bk5892xp",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Impact"],
                                    "Limited",
                                    "#00af25",
                                    "Minor",
                                    "#afdc00",
                                    "Moderate",
                                    "#fdc400",
                                    "Major",
                                    "#fb7a00",
                                    "Extreme",
                                    "#f40b00",
                                    "#888",
                                ],
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-Poweroutage-3_O-0snrg8",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        xgctwhpdle: {
                            url: "mapbox://mike-mis.0w51x958",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgba(102,115,131,0.4)",
                                    "Risk of Internal Damage",
                                    "rgba(131,124,97,0.4)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(80,80,80,0.4)",
                                ],
                                "fill-outline-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgb(0,116,255)",
                                    "Risk of Internal Damage",
                                    "rgb(253,196,0)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(136,136,136,0.4)",
                                ],
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-Cuba_Exposure_G-5tiovc",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        r4dj84902u: {
                            url: "mapbox://mike-mis.7gih9ue4",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Flooding"],
                                    "None",
                                    "#fff",
                                    "Light",
                                    "#93b1ce",
                                    "Moderate",
                                    "#3d7dc8",
                                    "Severe",
                                    "#1950a5",
                                    "#888",
                                ],
                                "fill-opacity": 0.3,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "HxIan_WestFlorida_NHCStormSur-1e1igw",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        "8zjpvpe24t": {
                            url: "mapbox://mike-mis.3epxo967",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Impact"],
                                    "Limited",
                                    "#00af25",
                                    "Minor",
                                    "#afdc00",
                                    "Moderate",
                                    "#fdc400",
                                    "Major",
                                    "#fb7a00",
                                    "Extreme",
                                    "#f40b00",
                                    "#888",
                                ],
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-Poweroutage-4_O-cb0fvg",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        v6ghcvyprw: {
                            url: "mapbox://mike-mis.7tls7y7k",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Impact"],
                                    "Limited",
                                    "#00af25",
                                    "Minor",
                                    "#afdc00",
                                    "Moderate",
                                    "#fdc400",
                                    "Major",
                                    "#fb7a00",
                                    "Extreme",
                                    "#f40b00",
                                    "#888",
                                ],
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-Poweroutage-2_O-276tfs",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        zt6flbmcqd: {
                            url: "mapbox://mike-mis.9mzsygvl",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Impact"],
                                    "Limited",
                                    "#00af25",
                                    "Minor",
                                    "#afdc00",
                                    "Moderate",
                                    "#fdc400",
                                    "Major",
                                    "#fb7a00",
                                    "Extreme",
                                    "#f40b00",
                                    "#888",
                                ],
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-Poweroutage-1_O-c74zup",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        gowxsw3cyn: {
                            url: "mapbox://mike-mis.9qlti3ce",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Impact"],
                                    "Limited",
                                    "#00af25",
                                    "Minor",
                                    "#afdc00",
                                    "Moderate",
                                    "#fdc400",
                                    "Major",
                                    "#fb7a00",
                                    "Extreme",
                                    "#f40b00",
                                    "#888",
                                ],
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-Poweroutage-30_-2n0e0p",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        b8mxgc6awu: {
                            url: "mapbox://mike-mis.5pkmqeq8",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Impact"],
                                    "Limited",
                                    "#00af25",
                                    "Minor",
                                    "#afdc00",
                                    "Moderate",
                                    "#fdc400",
                                    "Major",
                                    "#fb7a00",
                                    "Extreme",
                                    "#f40b00",
                                    "#888",
                                ],
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-Poweroutage-29_-3dg10f",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        y0knrwbfkj: {
                            url: "mapbox://mike-mis.6spereml",
                            type: "vector",
                            paint: {
                                "fill-color": "#f00",
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-64knt_Windswath-1cvowd",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        nb3x8e6m32: {
                            url: "mapbox://mike-mis.bbxql7uw",
                            type: "vector",
                            paint: {
                                "fill-color": "#ff5c00",
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-50knt_Windswath-41y2vn",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        p311p3fh3d: {
                            url: "mapbox://mike-mis.9iuu4m9j",
                            type: "vector",
                            paint: {
                                "fill-color": "#ffa800",
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Hurricane_Ian-34knt_Windswath-18dpfn",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                    },
                    viewport: {
                        zoom: 7,
                        latitude: 27.19839,
                        longitude: -82.18106,
                    },
                    menuIndex: [
                        {
                            id: "iko3pfwgj7",
                            type: "group",
                            children: [
                                {
                                    id: "ptouskp4z5",
                                    type: "layer",
                                    layerName: "Path",
                                    layerSource: "bkkczybfiv",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "gmv7z8ws7d",
                                    type: "layer",
                                    layerName: "Dates",
                                    layerSource: "f1n5zq94nn",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "fl1irsoscn",
                                    type: "layer",
                                    layerName:
                                        "Florida Exposure Grid - 5km x 5km",
                                    layerSource: "xaskirx5mu",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "s93mqhybce",
                                    type: "layer",
                                    layerName: "Likely Flooded Areas",
                                    layerSource: "bus9ys6gnb",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "ozcepndn3j",
                                    type: "layer",
                                    layerName:
                                        "Cuba Exposure Grid - 5km x 5km   ",
                                    layerSource: "xgctwhpdle",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "tktxmzyigg",
                                    type: "layer",
                                    layerName: "Storm Surge Flooding",
                                    layerSource: "r4dj84902u",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "b51esq9fcs",
                                    type: "group",
                                    asLayer: false,
                                    children: [
                                        {
                                            id: "v5bxcf9g5m",
                                            type: "layer",
                                            layerName: "Power Outages-4 Oct",
                                            layerSource: "8zjpvpe24t",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "e5g6mk7jyt",
                                            type: "layer",
                                            layerName: "Power Outages-3 Oct",
                                            layerSource: "bzhmi6k58i",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "0yqnbhhff8",
                                            type: "layer",
                                            layerName: "Power Outages-2 Oct",
                                            layerSource: "v6ghcvyprw",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "abz6osrkiu",
                                            type: "layer",
                                            layerName: "Power Outages-1 Oct",
                                            layerSource: "zt6flbmcqd",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "44il3wl8fz",
                                            type: "layer",
                                            layerName: "Power Outages-30 Sep",
                                            layerSource: "gowxsw3cyn",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "4on2li7kp7",
                                            type: "layer",
                                            layerName: "Power Outages-29 Sep",
                                            layerSource: "b8mxgc6awu",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Florida Power Outages",
                                },
                                {
                                    id: "wfvt3gadrr",
                                    type: "group",
                                    children: [
                                        {
                                            id: "8x0oo9lqzp",
                                            type: "layer",
                                            layerName: "64kts Wind Swathe",
                                            layerSource: "y0knrwbfkj",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "iwhowzasd8",
                                            type: "layer",
                                            layerName: "50kts Wind Swathe",
                                            layerSource: "nb3x8e6m32",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "ngr13zw8tc",
                                            type: "layer",
                                            layerName: "34kts Wind Swathe",
                                            layerSource: "p311p3fh3d",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Wind Speeds",
                                },
                            ],
                            groupName: "Exposure",
                        },
                    ],
                },
            },
            {
                type: "text",
                options: {
                    content: [
                        {
                            id: "fa1w6x0pkh",
                            title: "Welcome",
                            text: "Insights Tutorial",
                        },
                    ],
                },
            },
            {
                type: "summary",
                options: {
                    downloads: {},
                },
            },
        ],
    },
    steps: [
        {
            content: (
                <>
                    <p>Welcome to the Filtering tutorial</p>
                    <p>
                        Let's learn how to filter data based on damage
                        classifications.
                    </p>
                </>
            ),
            target: "body",
            disableOverlayClose: true,
            placement: "center",
        },
        {
            content: (
                <>
                    <p>
                        Let's open up one of these layers by clicking the{" "}
                        <span>
                            <Icon path={mdiMIS_legendPoly} size={1} />
                        </span>{" "}
                        icon to start filtering the data.
                    </p>
                    <p>
                        The "Florida Exposure Grid - 5km x 5km" layer seems like
                        a good place to start!
                    </p>
                </>
            ),
            target: "#touridfl1irsoscn",
            placement: "left",
            disableOverlayClose: true,
            spotlightClicks: true,
            targetTriggerEvent: "click",
            styles: {
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <h2>Filtering data on the layer</h2>
                    <Icon path={mdiFilterOutline} size={1.5} />
                    <p>
                        The filter icon allows you to filter the data you see on
                        the map
                    </p>
                    <p>You can toggle the filter on and off</p>
                    <p>
                        For example, if you only wanted to see the most severely
                        impacted areas, you have the ability to filter by that
                        classification!
                    </p>
                    <p>In this example, try filtering by "Severe Damage"</p>
                </>
            ),
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <h2>Filtering Insights</h2>
                    <p>
                        If Insights are available for an event, they will be
                        displayed here.
                    </p>
                    <p>
                        For more information on using MIS Insights, please view
                        the "Insights" tutorial.
                    </p>
                </>
            ),
            disableOverlayClose: true,
            target: "#insightbody_tutorial",
            placement: "left",
        },
        {
            content: (
                <>
                    <p>
                        Filtering insights is very similar to filtering layers.
                    </p>
                    <p>Open the Insights legend.</p>
                </>
            ),
            placement: "top",
            target: "#insights_openLegend_tutorial",
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            hideBackButton: true,
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <p>
                        Using the <Icon path={mdiFilterOutline} size={1} />{" "}
                        icons, you can filter by different damage assessments
                    </p>
                    <p>
                        You can display as many, or as little damage assessments
                        are you require.
                    </p>
                    <p>Try filtering the data based on "No Damage"</p>
                </>
            ),
            disableOverlayClose: true,
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            spotlightClicks: true,
        },
        {
            content: (
                <p>
                    You can also filter data-points by interacting directly with
                    the Legend Points here.
                </p>
            ),
            disableOverlayClose: true,
            target: "#tourId_PaintLegend",
            placement: "left",
            spotlightClicks: true,
        },
        {
            content: "This concludes our overview of filtering data on GEO!",
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            disableBeacon: true,
            disableOverlay: true,
            spotlightClicks: true,
            styles: {
                spotlight: {
                    outlineColor: "transparent",
                },
            },
        },
    ],
    imageUrl: `https://geoassets.blob.core.windows.net/${
        import.meta.env.VITE_ENVIRONMENT
    }/filtering_lc_bg_${getCookie(NEW_THEME_COOKIE) ?? "dark"}.png`,
};

export default filtering_tutorial;
