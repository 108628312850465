/**
 * Used to display a list of clicked layers when clicking on map features.
 * Allows user to choose which layer they are interested in getting info from.
 */

import React, { MouseEvent } from "react";
import {
    mdiEye,
    mdiInformationOutline,
    mdiLaunch,
    mdiLayersSearch,
} from "@mdi/js";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { Popup } from "react-map-gl";
import Icon from "@mdi/react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { SystemActionTypes } from "store/system/systemTypes";
import { LayerSelectPopupInfo } from "components/Pages/Report/DashboardComponents/Map/Mapping/MapContainer/MapContainer";
import { RootState } from "store/store";
import { MapConfig, TabType } from "store/map/mapTypes";
import { setHighlightedLayer } from "store/map/mapActions";
import { registerAnalyticsEvent } from "store/matomo/matomoActions";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import LayerIcon from "components/Pages/Report/DashboardComponents/Map/LayerIcon/LayerIcon";

import classes from "components/Pages/Report/DashboardComponents/Map/Popups/LayerSelectPopup/LayerSelectPopup.module.css";
import cx from "classnames";
import Modal from "../../../../../../_Library/Modal/Modal";
import { isURL } from "utils/URLs";

export interface OwnProps {
    popupInfo: LayerSelectPopupInfo; // latlong of popup and features clicked.
    setActiveTab: (tab: TabType) => object; // change tab to info tab when a layer option is chosen on popup.
    handlePopupOnClose: () => void; // remove popup.
}

interface StateProps {
    mapConfig: MapConfig;
    clickedFeatureProperties: { [key: string]: any[] };
}

interface LayerSelectPopupState {
    imageryShown: boolean;
    layerName: string;
    activePanoIndex: number;
    panoKey: string;
}

interface DispatchProps {
    setSelectedLayer: typeof setHighlightedLayer; // sets the dataset subset from the selected layer's dataset. for eg, on a country layer with attached dataset, would select the clicked country.
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
}

type LayerSelectPopupProps = OwnProps & StateProps & DispatchProps;

class LayerSelectPopup extends React.Component<
    LayerSelectPopupProps,
    LayerSelectPopupState
> {
    state: LayerSelectPopupState = {
        imageryShown: false,
        layerName: "",
        activePanoIndex: 0,
        panoKey: "",
    };

    // Triggered when a layer in the popup is selected.
    handlePopupOnClick = (layerName: string, sourceName: string, type: any) => {
        let activeTab: TabType = type === "Info" ? "Info" : "Layer";
        this.props.setActiveTab(activeTab);
        let layer = layerName
            .split(" ")
            .filter((word) => word !== "Cluster")
            .join(" ");
        this.props.setSelectedLayer({ layerName: layer, sourceName });
    };

    viewDroneImagery = (event: MouseEvent<HTMLDivElement>) => {
        const layerName = event.currentTarget.dataset["layername"]!;
        const panoKey = event.currentTarget.dataset["panoramakey"]!;

        this.setState({
            imageryShown: true,
            layerName,
            activePanoIndex: 0,
            panoKey,
        });
        this.props.registerAnalyticsEvent({
            category:
                this.state.panoKey.toLowerCase() === "panorama url"
                    ? "Panorama Imagery"
                    : "Streetview Imagery",
            action: "open",
        });
    };

    closeDroneImagery = () => {
        this.setState({
            imageryShown: false,
        });
        this.props.registerAnalyticsEvent({
            action: "close",
            category:
                this.state.panoKey.toLowerCase() === "panorama url"
                    ? "Panorama Imagery"
                    : "Streetview Imagery",
        });
    };

    renderDroneModal = () => {
        if (this.state.imageryShown) {
            let layers =
                this.props.clickedFeatureProperties[this.state.layerName];
            let panoUrl =
                layers[this.state.activePanoIndex][this.state.panoKey];
            return (
                <Modal
                    width={90}
                    height={90}
                    closeModal={this.closeDroneImagery}
                >
                    <div className={classes.ModalContainer}>
                        <div className={classes.ModalHeader}>
                            {this.state.panoKey.toLowerCase() ===
                                "panorama url" && (
                                <div className={classes.ModalText}>
                                    You clicked on {layers.length} panorama
                                    {layers.length > 1 && "s"}
                                </div>
                            )}
                            <div className={classes.ModalButtons}>
                                <div className={classes.ExternalLaunch}>
                                    <a
                                        target="_blank"
                                        href={panoUrl}
                                        rel="noreferrer"
                                    >
                                        <Icon path={mdiLaunch} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {this.state.panoKey.toLowerCase() ===
                            "panorama url" && (
                            <div className={classes.PanoTabs}>
                                {layers.map((layer, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={cx(classes.PanoName, {
                                                [classes.ActivePanoTab]:
                                                    this.state
                                                        .activePanoIndex ===
                                                    index,
                                            })}
                                            onClick={() => {
                                                this.setState({
                                                    activePanoIndex: index,
                                                });
                                            }}
                                        >
                                            Panorama {index + 1}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <div className={classes.ModalContent}>
                            {isURL(panoUrl) ? (
                                <iframe title={"Panorama"} src={panoUrl} />
                            ) : (
                                <div className={classes.NoPanoSelected}>
                                    {panoUrl}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            );
        }
    };

    render() {
        const popupInfo = this.props.popupInfo;
        const layerElements = popupInfo.layers.map((layerInfo) => {
            let isCluster = layerInfo.name.includes("Cluster");
            return (
                <div key={layerInfo.name} className={classes.LayerItemContainer}>
                    <div key={layerInfo.name} className={classes.LayerItem}>
                        <span className={classes.LayerIcon}>
                            <LayerIcon
                                complexLegend={false}
                                paint={layerInfo.paint}
                                type={layerInfo.type}
                            />
                        </span>
                        <span
                            onClick={() =>
                                this.handlePopupOnClick(
                                    layerInfo.name,
                                    layerInfo.source,
                                    "Info",
                                )
                            }
                            className={classes.LayerName}
                        >
                            {layerInfo.name}
                        </span>
                        <div className={classes.PopupIcons}>
                            {layerInfo.panoramaKey && (
                                <span
                                    data-layername={layerInfo.name}
                                    data-panoramakey={layerInfo.panoramaKey}
                                    onClick={this.viewDroneImagery}
                                >
                                    <Icon
                                        data-for={"LayerSelectPopupToolTip"}
                                        data-tip={
                                            layerInfo.panoramaKey.toLowerCase() ===
                                            "panorama url"
                                                ? "Show Drone Imagery"
                                                : "Show Streetview Imagery"
                                        }
                                        path={mdiEye}
                                    />
                                </span>
                            )}
                            {!isCluster && (
                                <span
                                    onClick={() =>
                                        this.handlePopupOnClick(
                                            layerInfo.name,
                                            layerInfo.source,
                                            "Info",
                                        )
                                    }
                                >
                                    <Icon
                                        data-for={"LayerSelectPopupToolTip"}
                                        data-tip={"Find out more"}
                                        path={mdiInformationOutline}
                                    />
                                </span>
                            )}
                            <span
                                onClick={() =>
                                    this.handlePopupOnClick(
                                        layerInfo.name,
                                        layerInfo.source,
                                        "Layer",
                                    )
                                }
                            >
                                <Icon
                                    data-for={"LayerSelectPopupToolTip"}
                                    data-tip={"Locate in menu"}
                                    path={mdiLayersSearch}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            );
        });

        // Render popup on map.
        return (
            <Popup
                className={classes.LayerSelectPopup}
                longitude={popupInfo.longitude}
                latitude={popupInfo.latitude}
                closeOnClick={false}
                onClose={this.props.handlePopupOnClose}
            >
                <div className={classes.Content}>
                    <div className={classes.Heading}>You clicked on</div>
                    <div className={classes.Body}>{layerElements}</div>
                </div>
                <ReactTooltip
                    id={"LayerSelectPopupToolTip"}
                    class={classes.Tooltip}
                    effect={"solid"}
                    place={"right"}
                />
                {this.renderDroneModal()}
            </Popup>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        mapConfig: getStoreAtNamespaceKey(state, "map").mapConfig,
        clickedFeatureProperties: getStoreAtNamespaceKey(state, "map")
            .clickedFeatureProperties,
    };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes>,
) => ({
    setSelectedLayer: bindActionCreators(setHighlightedLayer, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerSelectPopup);
