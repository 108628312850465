import * as MapboxGL from "mapbox-gl";
import { isNumber, isString } from "./TypeGuards";

export const getComplexAttrs = <T_Paint>(
    paint: T_Paint,
    triggers: Array<keyof T_Paint>,
) => {
    let complex: Array<keyof T_Paint> = [];
    for (let trigger of triggers) {
        if (Array.isArray(paint[trigger])) {
            complex.push(trigger);
        }
    }
    return complex;
};

interface Color {
    r: number;
    g: number;
    b: number;
}

export function getColorFromPaint(
    paint: MapboxGL.AnyPaint,
    att: keyof MapboxGL.AnyPaint,
    def = "#f0f",
) {
    let attrValue: string;

    if (isString(paint[att])) {
        attrValue = paint[att];
    } else if ((paint[att] as Color)?.r !== undefined) {
        let color = paint[att] as Color;
        attrValue = `rgb(${color.r * 255}, ${color.g * 255}, ${color.b * 255})`;
    } else {
        attrValue = def;
    }
    return attrValue;
}

export const getNumberFromPaint = (
    paint: MapboxGL.AnyPaint,
    att: keyof MapboxGL.AnyPaint,
    def = 0,
) => {
    return isNumber(paint[att]) ? paint[att] : def;
};
