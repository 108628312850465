import React, { MouseEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiClose, mdiMagnify } from "@mdi/js";
import cx from "classnames";

import ScrollableText from "../../../_Library/ScrollableText/ScrollableText";
import { RootState } from "../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";

import classes from "./EventDropdown.module.css";
import { DropdownVisibilityContext } from "../NavBar";
import Modal from "components/_Library/Modal/Modal";
import EventsListModal from "components/Partials/NavBar/EventDropdown/EventsListModal/EventsListModal";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { eventTypeToIconPathSwitch } from "components/Pages/Report/DashboardComponents/Summary/Summary";
import { Flex } from "@mantine/core";

export default function EventDropdown() {
    const dropdownVisibilityContext = useContext(DropdownVisibilityContext);
    const { trackUserEvent } = useAnalytics();

    const events = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "service").eventData,
    );
    const [eventFilter, setEventFilter] = useState<string>("");

    const [eventModalOpen, setEventModalOpen] = useState(false);

    return (
        <div
            className={cx(classes.NavItem, classes.EventNavItem, {
                [classes.Active]: dropdownVisibilityContext.state.event,
            })}
            onClick={() => {
                if (!dropdownVisibilityContext.state.event) {
                    trackUserEvent({ name: "events_navbar_clicked" });
                }
                dropdownVisibilityContext.setState({
                    consultancy: false,
                    help: false,
                    account: false,
                    event: !dropdownVisibilityContext.state.event,
                });
            }}
        >
            <p>Events</p>
            {dropdownVisibilityContext.state.event && (
                <div
                    className={classes.EventDropdown}
                    onClick={(e: MouseEvent) => {
                        e.stopPropagation();
                    }}
                >
                    <div className={classes.EventDropdownSearch}>
                        <Icon path={mdiMagnify} size={1} />
                        <input
                            placeholder={"Search"}
                            type="text"
                            onChange={(event) =>
                                setEventFilter(event.target.value)
                            }
                            value={eventFilter}
                        />

                        <span onClick={(e: MouseEvent) => setEventFilter("")}>
                            <Icon
                                style={{
                                    display:
                                        eventFilter === "" ? "none" : "unset",
                                }}
                                path={mdiClose}
                                size={1}
                            />
                        </span>
                    </div>
                    <div className={classes.EventList}>
                        {events
                            .filter(
                                (event) =>
                                    event.has_report &&
                                    event.name
                                        .toLowerCase()
                                        .includes(eventFilter.toLowerCase()),
                            )
                            .filter((event, index) => index < 5)
                            .map((event) => {
                                const { path, className } =
                                    eventTypeToIconPathSwitch[event.type];
                                let classNameProp = {};
                                if (className) {
                                    classNameProp = {
                                        className: `${className}`,
                                    };
                                }
                                const iconProps = {
                                    size: 1.5,
                                    path,
                                    ...classNameProp,
                                };
                                return (
                                    <div
                                        className={classes.EventDropdownItem}
                                        onClick={() => {
                                            trackUserEvent({
                                                name: "event_viewed",
                                                payload: {
                                                    source: "event_dropdown",
                                                    event_id: event.id,
                                                    event_name: event.name,
                                                },
                                            });
                                            dropdownVisibilityContext.setState({
                                                consultancy: false,
                                                help: false,
                                                account: false,
                                                event: false,
                                            });
                                        }}
                                    >
                                        <Link to={`/report/${event.id}`}>
                                            <Flex
                                                wrap="nowrap"
                                                gap="md"
                                                align="center"
                                                className={classes.ItemText}
                                            >
                                                <div
                                                    className={
                                                        classes.EventTypeIcon
                                                    }
                                                >
                                                    <Icon {...iconProps} />
                                                </div>
                                                <ScrollableText
                                                    text={event.name}
                                                />
                                            </Flex>
                                        </Link>
                                    </div>
                                );
                            })}
                        <div
                            className={cx(
                                classes.EventDropdownItem,
                                classes.EventPageLink,
                            )}
                            onClick={() => {
                                trackUserEvent({
                                    name: "show_all_events_clicked",
                                });
                                setEventModalOpen(true);
                            }}
                        >
                            <p>Show all events</p>
                        </div>
                    </div>
                </div>
            )}
            {eventModalOpen && (
                <Modal
                    contentClassName={classes.disableScroll}
                    closeModal={() => setEventModalOpen(false)}
                >
                    <EventsListModal
                        initialSearchTerm={eventFilter}
                        onEventSelect={() => {
                            setEventModalOpen(false);
                            dropdownVisibilityContext.setState({
                                consultancy: false,
                                help: false,
                                account: false,
                                event: false,
                            });
                        }}
                    />
                </Modal>
            )}
        </div>
    );
}
