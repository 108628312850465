import React from "react";
import {
    useCurrentEvent,
    useCurrentEventReturnType,
} from "hooks/useCurrentEvent";

export type withCurrentEventProps = useCurrentEventReturnType;

const withAnalytics = <T extends withCurrentEventProps>(
    Component: React.ComponentType<T>,
) => {
    return function WrappedComponent(props: any) {
        const { currentEvent } = useCurrentEvent();
        return <Component {...props} currentEvent={currentEvent} />;
    };
};

export default withAnalytics;
