import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import {
    mdiChevronDown,
    mdiChevronUp,
    mdiCloudDownload,
    mdiLayersTriple,
    mdiFolderDownload,
} from "@mdi/js";
import Icon from "@mdi/react";
import cx from "classnames";
import { useDispatch } from "react-redux";

import { SummaryConfig } from "../../../../../store/system/systemTypes";
import { setAlert } from "../../../../../store/system/systemActions";
import Spinner from "components/_Library/Spinner/Spinner";
import ScrollableText from "components/_Library/ScrollableText/ScrollableText";

import classes from "./Downloads.module.css";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { useCurrentEvent } from "hooks/useCurrentEvent";

interface DownloadsProps {
    externalDownloads: SummaryConfig["downloads"];
    reportId: string | undefined;
}

interface LayerDownload {
    name: string;
    id: string;
}

interface SectionHeaderProps {
    name: string;
    icon: string;
    open: boolean;
    onClick: () => void;
}

type LayerDownloads = LayerDownload[];

export default function Downloads(props: DownloadsProps) {
    const dispatch = useDispatch();
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    const token = useMemo(() => {
        return accessToken;
    }, [accessToken]);
    const reportId = useMemo(() => {
        return props.reportId;
    }, [props.reportId]);
    const [loading, setLoading] = useState<boolean>(true);
    const [layerDownloads, setLayerDownloads] = useState<LayerDownloads>([]);
    const [layerDownloadsOpen, setLayerDownloadOpen] = useState<boolean>(true);
    const [extDownloadsOpen, setExtDownloadOpen] = useState<boolean>(true);
    const { trackUserEvent } = useAnalytics();
    const { currentEvent } = useCurrentEvent();

    const getSASURL = async (
        layerId: string,
        fileType: string | null = null,
    ) => {
        try {
            const res = await axios.get(
                `${
                    import.meta.env.VITE_API_ROOT
                }/data/layers/${layerId}/download${
                    fileType ? `?fileType=${fileType}` : ""
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            const openInNewTab = (url: string) => {
                const win = window.open(url, "_blank");
                win?.focus();
            };
            openInNewTab(res.data.data.blob_url);
        } catch (error) {
            dispatch(
                setAlert({
                    message: "Failed to download",
                    timeout: 5000,
                    type: "Error",
                }),
            );
        }
    };

    // hook to fetch available layers for download
    useEffect(() => {
        const fetchReportLayers = async () => {
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const res = await axios.get(
                `${
                    import.meta.env.VITE_API_ROOT
                }/data/layers/downloads/${reportId}`,
                {
                    headers: headers,
                },
            );
            setLayerDownloads(res.data.data);
        };
        if (reportId) {
            fetchReportLayers().then(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [reportId, token]);

    const SectionHeader = (props: SectionHeaderProps) => (
        <div className={classes.SectionHeader} onClick={props.onClick}>
            <Icon path={props.icon} size={1.5} />
            <h3 style={{ width: "20rem" }}>{props.name}</h3>
            <Icon
                size={1.5}
                path={props.open ? mdiChevronUp : mdiChevronDown}
            />
        </div>
    );

    const generateLayerDownloads = () => {
        return (
            <>
                <SectionHeader
                    name={"Layer Downloads"}
                    icon={mdiLayersTriple}
                    open={layerDownloadsOpen}
                    onClick={() => setLayerDownloadOpen(!layerDownloadsOpen)}
                />
                <div
                    className={cx({
                        [classes.DownloadsVisible]: !layerDownloadsOpen,
                    })}
                >
                    {layerDownloads.length ? (
                        layerDownloads!.map((layer: LayerDownload) => (
                            <div
                                key={layer.id}
                                className={classes.DownloadOption}
                            >
                                <div className={classes.DownloadFileName}>
                                    <ScrollableText text={layer.name} />
                                </div>
                                <div
                                    className={classes.DownloadIcon}
                                    onClick={() => {
                                        if (currentEvent) {
                                            trackUserEvent({
                                                name: "data_layer_download_clicked",
                                                payload: {
                                                    layerName: layer.name,
                                                    dataLayerId: layer.id,
                                                    event_id: currentEvent.id,
                                                    event_name:
                                                        currentEvent.name,
                                                },
                                            });
                                        }
                                        getSASURL(layer.id);
                                    }}
                                >
                                    <Icon path={mdiCloudDownload} size={1.5} />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={classes.NoDownloads}>
                            There are no downloadable layer files available for
                            this event
                        </div>
                    )}
                </div>
            </>
        );
    };

    const generateExternalDownloads = () => {
        let filenames = Object.keys(props.externalDownloads);
        return (
            <>
                <SectionHeader
                    name={"External Downloads"}
                    icon={mdiFolderDownload}
                    open={extDownloadsOpen}
                    onClick={() => {
                        setExtDownloadOpen(!extDownloadsOpen);
                    }}
                />
                <div
                    className={cx({
                        [classes.DownloadsVisible]: !extDownloadsOpen,
                    })}
                >
                    {filenames.length ? (
                        filenames.map((filename) => (
                            <div
                                key={filename}
                                className={classes.DownloadOption}
                            >
                                <div className={classes.DownloadFileName}>
                                    <ScrollableText text={filename} />
                                </div>

                                <div className={classes.DownloadIcon}>
                                    <a
                                        href={props.externalDownloads[filename]}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            if (currentEvent) {
                                                trackUserEvent({
                                                    name: "external_downloads_download_clicked",
                                                    payload: {
                                                        name: filename,
                                                        event_id:
                                                            currentEvent.id,
                                                        event_name:
                                                            currentEvent.name,
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        <Icon
                                            path={mdiCloudDownload}
                                            size={1.5}
                                        />
                                    </a>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={classes.NoDownloads}>
                            There are no downloadable external files available
                            for this event
                        </div>
                    )}
                </div>
            </>
        );
    };

    const generateDownloads = () => {
        return (
            <>
                {generateLayerDownloads()}
                {generateExternalDownloads()}
            </>
        );
    };

    return (
        <div className={classes.DownloadsContainer}>
            {loading ? <Spinner /> : generateDownloads()}
        </div>
    );
}
