/**
 * Preview basemap style from mapbox api using the center and map style id.
 */

import React from "react";
import ReactTooltip from "react-tooltip";
import cx from "classnames";

import {
    BasemapType,
    MapCenter,
} from "../../../../../../../../../store/map/mapTypes";

import classes from "./Minimap.module.css";
import { Place } from "react-tooltip";

interface OwnProps {
    border: boolean;
    style: string;
    center: MapCenter;
    name: BasemapType;
    mapIndex: number;
    onClick?: () => any;
    active: boolean;
    mapboxToken: string;
    // If provided, will override the default tooltip placement
    tooltipPlacement?: Place;
    excludeTooltip?: boolean;
    id?: string;
}

const Minimap: React.FC<OwnProps> = ({
    border,
    id,
    style,
    center,
    name,
    mapIndex,
    active,
    onClick,
    mapboxToken,
    tooltipPlacement,
    excludeTooltip,
}: OwnProps) => {
    let toolTipPosition: Place = "right";
    if (tooltipPlacement) {
        toolTipPosition = tooltipPlacement;
    } else {
        toolTipPosition = mapIndex === 0 ? "right" : "left";
    }
    toolTipPosition = toolTipPosition as Place;
    const toolTipId = "ToolTip" + mapIndex;
    const upperName = name.charAt(0).toUpperCase() + name.slice(1);

    return (
        <div
            className={cx(classes.MinimapContainer, {
                [classes.ContainerActive]: active,
                [classes.Border]: border
            })}
        >
            <img
                data-for={`${toolTipId} ${upperName} Map ${mapIndex}`}
                data-tip={upperName + " Map"}
                className={cx(classes.Viewport, { [classes.Active]: active })}
                alt={upperName + " Map Toggle"}
                onClick={onClick}
                id={id || "tourid_Minimap_" + upperName}
                src={`https://api.mapbox.com/styles/v1/${style}/draft/static/${center.longitude},${center.latitude},${center.zoom},0,0/60x60?access_token=${mapboxToken}&logo=false&attribution=false&fresh=true`}
            />
            {!excludeTooltip && (
                <ReactTooltip
                    id={"ToolTip" + mapIndex}
                    place={toolTipPosition}
                    type={"dark"}
                    effect={"solid"}
                />
            )}
        </div>
    );
};

export default Minimap;
