import { AnyPaint, Expression } from "mapbox-gl";
import { AssessmentType, PerilType } from "store/system/systemTypes";
import { CapitaliseWord } from "utils/String";


type PerilAssessmentType = `${PerilType}_${AssessmentType}`;


const perilPointColors: {[perilAssessment in PerilAssessmentType]?: [string, string][]} = {
    flood_claims: [
        ["None", "#ffffff"],
        ["Light", "#93b1ce"],
        ["Moderate", "#3d7dc8"],
        ["Severe", "#1950a5"],
    ],
    flood_exposure: [["Potentially Flooded", "#50E3E3"]],
    wind_claims: [
        ["No Damage", "#2196f3"],
        ["Light Damage", "#00af25"],
        ["Moderate Damage", "#afdc00"],
        ["Substantial Damage", "#fdc400"],
        ["Severe Damage", "#fb7a00"],
        ["Destroyed", "#f40b00"],
        ["Low Risk of Damage", "#667383"],
        ["Risk of Internal Damage", "#837C61"],
    ],
    wind_exposure: [
        ["No Damage", "#2196f3"],
        ["Light Damage", "#00af25"],
        ["Moderate Damage", "#afdc00"],
        ["Substantial Damage", "#fdc400"],
        ["Severe Damage", "#fb7a00"],
        ["Destroyed", "#f40b00"],
        ["Low Risk of Damage", "#667383"],
        ["Risk of Internal Damage", "#837C61"],
    ],
};



const perilPointStrokeColors: {[perilType in PerilType]?: [string, string][]} = {
    flood: [
        ["None", "#ffffff"],
        ["Light", "#6d829a"],
        ["Moderate", "#306196"],
        ["Severe", "#0f356c"],
    ],
    wind: [
        ["No Damage", "rgb(0,63,114)"],
        ["Light Damage", "rgb(0,98,22)"],
        ["Moderate Damage", "rgb(87,110,0)"],
        ["Substantial Damage", "rgb(107,84,0)"],
        ["Severe Damage", "rgb(107,51,0)"],
        ["Destroyed", "rgb(114,7,0)"],
        ["Low Risk of Damage", "rgb(0,116,255)"],
        ["Risk of Internal Damage", "rgb(253,196,0)"],
    ],
};

export const getLayerColor = (
    assessmentType: AssessmentType,
    perilType: PerilType,
): Expression => {

    let perilAssessment: PerilAssessmentType = perilType === "flood"
                ? `flood_${assessmentType}`
                : `wind_${assessmentType}`

    let pointColor = perilPointColors[perilAssessment];

    return [
        "match",
        ["get", `${CapitaliseWord(assessmentType)} Layer Assessment`],
        ...pointColor!.flat(),
        "#888",
    ];
};

export const getLayerStroke = (
    assessmentType: AssessmentType,
    perilType: PerilType,
): Expression => {
    let strokeColor =
        perilPointStrokeColors[perilType === "flood" ? "flood" : "wind"];

    return [
        "match",
        ["get", `${CapitaliseWord(assessmentType)} Layer Assessment`],
        ...strokeColor!.flat(),
        "#424242",
    ];
};

export const getPointLayerPaint = (
    assessmentType: AssessmentType,
    perilType: PerilType,
): AnyPaint => {
    return {
        "circle-color": getLayerColor(assessmentType, perilType!),
        "circle-stroke-color":
            assessmentType === "exposure"
                ? "#000000"
                : getLayerStroke(assessmentType, perilType!),
        "circle-radius": 5,
        "circle-stroke-width": 2,
    };
};
