import React, { FC, useEffect } from "react";
import Report from "../Report/Report";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Tutorials from "assets/tutorials";
import { useDispatch } from "react-redux";
import { setTour } from "store/system/systemActions";

type Extensions = RouteComponentProps<{ tutorialName: string }>;
type TutorialReportProps = Extensions;

export const TutorialReport: FC<TutorialReportProps> = (
    props: TutorialReportProps,
) => {
    let tutorial = Tutorials[props.match.params.tutorialName];
    let dispatch = useDispatch();

    useEffect(() => {
        let step =
            new URL(window.location.href).searchParams.get("step") ?? "0";

        dispatch(
            setTour({
                steps: tutorial.steps,
                run: true,
                stepIndex: parseInt(step),
            }),
        );
    }, [dispatch, tutorial.steps]);

    return <Report reportConfig={tutorial.appConfig} />;
};
export default withRouter(TutorialReport);
