import React from "react";
import { RootState } from "../../../../store/store";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { UserActionTypes } from "../../../../store/user/userTypes";
import { SystemActionTypes } from "../../../../store/system/systemTypes";
import CookieSettingsModal from "../CookieSettings/CookieSettingsModal";

import classes from "./CookieBanner.module.css";
import { bindActionCreators } from "redux";
import { setCookiesSet } from "../../../../store/system/systemActions";
import { getCookie, parseCookie, setCookie } from "../../../../utils/Cookies";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";
import { setMatomoInstance } from "../../../../store/matomo/matomoActions";
import withMatomoInstance, {
    withMatomoInstanceProps,
} from "../../../_Library/HOC/withMatomoInstance";
import { MatomoActionTypes } from "../../../../store/matomo/matomoTypes";
import Button from "../../../_Library/Button/Button";

interface OwnProps {}
interface StateProps {
    cookiesSet: boolean;
}
interface DispatchProps {
    setCookiesSet: typeof setCookiesSet;
    setMatomoInstance: typeof setMatomoInstance;
}

type Extensions = StateProps & DispatchProps & withMatomoInstanceProps;
type CookieBannerProps = OwnProps & Extensions;

enum CookieOption {
    Necessary,
    Analytics,
    Functionality,
    Advertising,
}

export type CookieOptions = keyof typeof CookieOption;
export type CookieSettings = { [key in CookieOptions]: boolean };

interface CookieBannerState extends CookieSettings {
    settingsOpen: boolean;
}

export const COOKIE_SETTINGS_COOKIE_NAME = "mc_cookie_cat_settings";
const HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME = "__hs_cookie_cat_pref";
const HUBSPOT_OPT_OUT_COOKIE_NAME = "__hs_opt_out";

class CookieBanner extends React.Component<
    CookieBannerProps,
    CookieBannerState
> {
    state: CookieBannerState = {
        settingsOpen: false,
        Necessary: true,
        Analytics: false,
        Functionality: false,
        Advertising: false,
    };

    componentDidMount() {
        setCookie(HUBSPOT_OPT_OUT_COOKIE_NAME, `yes`, 182);
        setCookie(
            HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME,
            `1:false,2:false,3:false`,
            182,
        );

        let cookieSettingsStr = getCookie(COOKIE_SETTINGS_COOKIE_NAME);
        if (cookieSettingsStr) {
            let settings = parseCookie(cookieSettingsStr);
            this.updateCookieSettings(settings, true);
        }
    }

    updateCookieSettings = (
        update: Partial<CookieSettings>,
        finishProcess: boolean = false,
    ) => {
        // @ts-ignore: it seems typescript cant deal with setting state with an enum like this
        this.setState(update, () => {
            setCookie(
                COOKIE_SETTINGS_COOKIE_NAME,
                `Analytics:${this.state.Analytics},Advertising:${this.state.Advertising},Functionality:${this.state.Functionality}`,
                182,
            );
            setCookie(
                HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME,
                `1:${this.state.Analytics},2:${this.state.Advertising},3:${this.state.Functionality}`,
                182,
            );
            if (finishProcess) {
                this.completeCookieSelection();
            }
        });
    };

    completeCookieSelection = () => {
        this.props.setCookiesSet(true);
        if (this.state.Analytics) {
            this.props.matomoInstance.pushInstruction("setConsentGiven");
        }
        this.props.setMatomoInstance({
            matomoInstance: this.state.Analytics
                ? this.props.matomoInstance
                : null,
        });
    };

    render() {
        return (
            !this.props.cookiesSet && (
                <>
                    <div className={classes.Container}>
                        <div className={classes.TextContainer}>
                            <p>
                                This website stores cookies on your computer.
                                These cookies are used to collect information
                                about how you interact with our website and
                                allow us to remember you. We use this
                                information in order to improve and customize
                                your browsing experience and for analytics and
                                metrics about our visitors.
                            </p>
                            <p>
                                If you decline, your information won’t be
                                tracked when you visit this website. A single
                                cookie will be used in your browser to remember
                                your preference not to be tracked.
                            </p>
                            <div className={classes.ButtonContainer}>
                                <div
                                    className={classes.CookieSettings}
                                    onClick={() => {
                                        this.setState({ settingsOpen: true });
                                    }}
                                >
                                    Cookie Settings
                                </div>
                                <Button
                                    size={{ width: "15rem" }}
                                    onClick={() => {
                                        this.updateCookieSettings(
                                            {
                                                Analytics: true,
                                                Functionality: true,
                                                Advertising: true,
                                            },
                                            true,
                                        );
                                    }}
                                >
                                    Accept
                                </Button>
                                <Button
                                    size={{ width: "15rem" }}
                                    onClick={() => {
                                        this.updateCookieSettings(
                                            {
                                                Analytics: false,
                                                Functionality: false,
                                                Advertising: false,
                                            },
                                            true,
                                        );
                                    }}
                                >
                                    Decline
                                </Button>
                            </div>
                        </div>
                    </div>
                    {this.state.settingsOpen && (
                        <CookieSettingsModal
                            closeModal={() => {
                                this.setState({ settingsOpen: false });
                            }}
                            updateCookieSettings={this.updateCookieSettings}
                            cookieSettings={this.state}
                        />
                    )}
                </>
            )
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    cookiesSet: getStoreAtNamespaceKey(state, "system").cookiesSet,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<
        any,
        any,
        UserActionTypes | SystemActionTypes | MatomoActionTypes
    >,
) => ({
    setCookiesSet: bindActionCreators(setCookiesSet, dispatch),
    setMatomoInstance: bindActionCreators(setMatomoInstance, dispatch),
});

export type CookieBannerClass = CookieBanner;

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withMatomoInstance(CookieBanner));
