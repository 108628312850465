import { EventSchema } from "crud/eventsCRUD";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";

export interface useCurrentEventReturnType {
    currentEvent?: EventSchema;
}

export const useCurrentEvent = (): useCurrentEventReturnType => {
    let params = useParams<{ id: string }>();

    let allEventData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "service").eventData,
    );
    let currentEvent = allEventData!.find((index) => index.id === params.id);

    return { currentEvent };
};
