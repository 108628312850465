import cx from "classnames";
import classes from "./DashboardComponents/Text/Text.module.css";
import reportClasses from "./Report.module.css";
import Markdown from "markdown-to-jsx";
import Modal from "../../_Library/Modal/Modal";
import ReactTooltip from "react-tooltip";
import { TextConfig } from "../../../store/system/systemTypes";
import React, { FC, useState } from "react";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import CopyAlertButton from "components/_Library/CopyAlertButton/CopyAlertButton";
import { useCurrentEvent } from "hooks/useCurrentEvent";

type OwnProps = {
    options: TextConfig;
    onClose: () => void;
};

export const ReportModal: FC<OwnProps> = ({ options, onClose }) => {
    const textItems = options.content;
    const [activeId, setActiveId] = useState(
        textItems.length ? textItems[0].id : 0,
    );
    const currentTextItem = options.content.find((item) => {
        return item.id === activeId;
    });
    const { trackUserEvent } = useAnalytics();

    const { currentEvent } = useCurrentEvent();

    const renderTabTitles = () => {
        let tabTitles = options.content.map((textItem) => {
            return (
                <div
                    key={textItem.title}
                    className={cx(classes.TabTitle, {
                        [classes.Active]: activeId === textItem.id,
                    })}
                    onClick={() => {
                        if (currentEvent) {
                            trackUserEvent({
                                name: "report_tab_subtab_clicked",
                                payload: {
                                    tab: textItem.title,
                                    event_id: currentEvent.id,
                                    event_name: currentEvent.name,
                                },
                            });
                        }
                        setActiveId(textItem.id);
                    }}
                >
                    {textItem.title}
                </div>
            );
        });

        return (
            <div className={classes.TabContainer}>
                {tabTitles.map((tabTitle, index) => (
                    <div key={index}>{tabTitle}</div>
                ))}
                {currentTextItem?.text && (
                    <CopyAlertButton
                        value={currentTextItem!.text}
                        alertMessage="Copied to clipboard"
                        innerText="Copy to clipboard"
                        buttonClassName={reportClasses.CopyButton}
                        onCopy={() => {
                            if (currentEvent) {
                                trackUserEvent({
                                    name: "report_copy_selected",
                                    payload: {
                                        tab: currentTextItem!.title,
                                        event_id: currentEvent.id,
                                        event_name: currentEvent.name,
                                    },
                                });
                            }
                        }}
                    />
                )}
            </div>
        );
    };

    const renderTabText = () => {
        return (
            <div className={classes.TextContainer}>
                <div className={classes.Content}>
                    <Markdown>
                        {currentTextItem ? currentTextItem.text : ""}
                    </Markdown>
                </div>
            </div>
        );
    };

    const renderReportText = () => {
        if (textItems.length) {
            return (
                <>
                    {renderTabTitles()}
                    {renderTabText()}
                </>
            );
        } else {
            return (
                <div className={classes.NoTextReports}>
                    No text reports to display
                </div>
            );
        }
    };

    return (
        <div className={classes.Container}>
            <Modal
                closeModal={onClose}
                contentClassName={reportClasses.ContentPadding}
            >
                {renderReportText()}
            </Modal>
            <ReactTooltip id={"TextTooltip"} place={"top"} effect={"float"} />
        </div>
    );
};
