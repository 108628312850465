import {
    DamageAssessments,
    DamageAssessmentColors,
    DamageAssessmentBorderColors,
    DepricatedDamageAssessments,
    FloodDamageAssessments,
    DamageAssessment,
    FloodExposureAssessment,
} from "./Colors";
import React from "react";
import { AssessmentType, PerilType } from "store/system/systemTypes";
import { GeoJSONSource } from "mapbox-gl";
import _ from "lodash";

interface DonutSegmentProps {
    start: number;
    end: number;
    r: number;
    r0: number;
    color: string;
    i: number;
    damages: DamageAssessment[];
}

export default function CreateDonutChart(props: {
    [key in DamageAssessment]?: number;
}) {
    const offsets: number[] = [];
    let counts: (number | undefined)[] = [];
    let damages: DamageAssessment[] = [];
    let property: DamageAssessment;
    for (property in props) {
        counts.push(props[property]);
        damages.push(property);
    }
    let total = 0;
    for (const count of counts) {
        offsets.push(total);
        total += count ?? 0;
    }

    let fontSize: number;
    let radius: number;
    if (total < 10) {
        fontSize = 14;
        radius = 24;
    } else if (total < 100) {
        fontSize = 16;
        radius = 24;
    } else if (total < 750) {
        fontSize = 18;
        radius = 34;
    } else if (total < 10000) {
        fontSize = 20;
        radius = 44;
    } else {
        fontSize = 20;
        radius = 51;
    }
    const r0 = Math.round(radius * 0.6);
    const w = radius * 2;

    return (
        <div style={{ transform: "translate(-50%, -50%)" }}>
            <svg
                width={w}
                height={w}
                viewBox={`-2 -2 ${w + 4} ${w + 4}`}
                textAnchor="middle"
                style={{ font: `${fontSize}px sans-serif`, display: `block` }}
            >
                <circle cx={radius} cy={radius} r={r0} fill="white" />
                {counts.map((count: number | undefined, i: number) => {
                    if (count) {
                        return DonutSegment({
                            start: offsets[i] / total,
                            end: (offsets[i] + (count ?? 0)) / total,
                            r: radius,
                            r0: r0,
                            color: DamageAssessmentColors[damages[i]],
                            i,
                            damages,
                        });
                    } else {
                        return <></>;
                    }
                })}
                <text
                    dominantBaseline="central"
                    transform={`translate(${radius}, ${radius})`}
                >
                    {total.toLocaleString()}
                </text>
            </svg>
        </div>
    );
}

function DonutSegment(props: DonutSegmentProps) {
    if (props.end - props.start === 1) props.end -= 0.00001;
    const hasBorder = !!DamageAssessmentBorderColors[props.damages[props.i]];
    const a0 = 2 * Math.PI * (props.start - 0.25);
    const a1 = 2 * Math.PI * (props.end - 0.25);
    const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
    const largeArc = props.end - props.start > 0.5 ? 1 : 0;
    const pathData = [
        "M",
        props.r + props.r0 * x0,
        props.r + props.r0 * y0,
        "L",
        props.r + props.r * x0,
        props.r + props.r * y0,
        "A",
        props.r,
        props.r,
        0,
        largeArc,
        1,
        props.r + props.r * x1,
        props.r + props.r * y1,
        "L",
        props.r + props.r0 * x1,
        props.r + props.r0 * y1,
        "A",
        props.r0,
        props.r0,
        0,
        largeArc,
        0,
        props.r + props.r0 * x0,
        props.r + props.r0 * y0,
    ].join(" ");

    const styles = {
        strokeOpacity: hasBorder ? 1 : 0,
        fill: props.color,
        strokeWidth: 1.5,
        stroke:
            DamageAssessmentBorderColors[props.damages[props.i]] ?? props.color,
    };

    // draw an SVG path
    return <path key={props.i} d={pathData} style={styles} />;
}

export const createCluster = (
    features: GeoJSON.Feature[],
    assessmentType: AssessmentType,
): { [key in DamageAssessment]: number } => {
    const sorter =
        assessmentType === "exposure"
            ? "Exposure Layer Assessment"
            : "Claims Layer Assessment";
    const damageAssessments = new Map<DamageAssessment, number>();
    const allDamageAssessments: DamageAssessment[] = [
        ...DamageAssessments,
        ...FloodDamageAssessments,
        ...DepricatedDamageAssessments,
        FloodExposureAssessment,
    ];
    for (const assessment of allDamageAssessments) {
        damageAssessments.set(assessment, 0);
    }
    for (const feature of features) {
        const assessment = feature.properties![sorter] || "Other";
        if (assessment) {
            damageAssessments.set(
                assessment,
                damageAssessments.get(assessment)! + 1,
            );
        }
    }

    return Object.fromEntries(damageAssessments) as {
        [key in DamageAssessment]: number;
    };
};

export const calculateSegmentMagnitudes = _.memoize(
    (features, assessmentType) => createCluster(features, assessmentType),
);

const getClusterLeaves = (
    id: number,
    count: number,
    source: GeoJSONSource,
): Promise<GeoJSON.Feature[]> => {
    return new Promise((res, rej) => {
        source.getClusterLeaves(
            id,
            count,
            0,
            (error: any, features: GeoJSON.Feature[]) => {
                if (!error) {
                    res(features);
                }
            },
        );
    });
};

export const clusterLeavesFunc = _.memoize(
    (
        clusterId: number,
        pointCount: number,
        clusterSource: GeoJSONSource,
        assessmentType: AssessmentType,
        selectedPeril: PerilType,
    ) => getClusterLeaves(clusterId, pointCount, clusterSource),
    (clusterId, pointCount, clusterSource, assessmentType, selectedPeril) => {
        return `${clusterId}-${assessmentType}-${selectedPeril}`;
    },
);
