import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

export interface withMatomoInstanceProps {
    matomoInstance: ReturnType<typeof useMatomo>;
}

const withMatomoInstance = <T extends withMatomoInstanceProps>(
    Component: React.ComponentType<T>,
) => {
    return function WrappedComponent(props: any) {
        const matomoInstance = useMatomo();
        return <Component {...props} matomoInstance={matomoInstance} />;
    };
};

export default withMatomoInstance;
