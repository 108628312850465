import React, { FC, useContext, useState } from "react";
import cx from "classnames";
import Icon from "@mdi/react";
import { mdiOpenInNew } from "@mdi/js";
import { DropdownVisibilityContext } from "../NavBar";
import Modal from "../../../_Library/Modal/Modal";
import Button from "../../../_Library/Button/Button";

import classes from "./ConsultancyDropdown.module.css";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

const ConsultancyDropdown: FC = () => {
    const dropdownVisibilityContext = useContext(DropdownVisibilityContext);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const { trackUserEvent } = useAnalytics();

    const consultancyModal = () => {
        return (
            <div className={classes.BringToFront}>
                <Modal
                    width={55}
                    height={65}
                    closeModal={() => setModalOpen(false)}
                >
                    <div className={classes.ConsultancyButton}>
                        <h2>Consultancy</h2>
                        <a
                            href={"https://mckenzieintelligence.com/consulting"}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            <Button>
                                Get a Demo
                                <Icon path={mdiOpenInNew} size={1} />
                            </Button>
                        </a>
                    </div>
                    <p>
                        Further to GEO, MIS delivers investigative and
                        intelligence expertise to the market via its Consultancy
                        service.
                    </p>

                    <p>
                        Made up of military trained imagery and remote sensing
                        specialists, our team of consultants has supported
                        clients for over 10 years. Servicing claims teams, TPAs,
                        loss adjusters, forensic specialists, legal law firms,
                        we conduct investigations regarding property, energy,
                        conflict, aviation, and maritime policies worldwide.
                    </p>

                    <p>
                        With access to data archives dated as early as 2011, the
                        type of investigations we conduct include:
                    </p>
                    <ul>
                        <li>
                            <b>Data acquisition</b> to supplement a claims
                            decision where the loss is binary
                        </li>
                        <li>
                            <b>Causation Identification</b> following an event
                            where causation needs to be identified
                        </li>
                        <li>
                            <b>Damage Assessments</b> to corroborate causation
                            and estimate damages caused after an event
                        </li>
                        <li>
                            <b>Historic Event/Trend Analysis</b> to understand
                            the conditions that caused damages in the past or
                            over time
                        </li>
                        <li>
                            <b>Asset Recovery and Persistent Monitoring</b>{" "}
                            where the location and status of an insurable needs
                            to be maintained where traditional methods do not
                            work
                        </li>
                    </ul>
                    <p>
                        To meet our client's needs, our investigations are
                        delivered in collaboration with our clients and our
                        consultants are dedicated to servicing the client's need
                        from project initiation to conclusion. Our consultants
                        can also act as expert witnesses and have previously
                        been deposed on behalf of Lloyd's insurers involving
                        complex losses.
                    </p>
                    <p>
                        In all, through remote inspection, speed of response and
                        objective validation, our investigations gives control
                        back to our clients and confidence in their decision
                        making.
                    </p>
                    <p>
                        To organise an investigation request or capability
                        demonstration,{" "}
                        <a
                            href={"https://mckenzieintelligence.com/consulting"}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            click here
                        </a>
                    </p>

                    <p>
                        <i>
                            "In the recent matter that you were involved with,
                            the Arbitration panel have now issued their ruling,
                            and have found in favour of Reinsurers. This is a
                            tremendous result for us, and thank you for your
                            involvement."
                        </i>
                    </p>
                    <p>
                        <i>- Head of Claims, Lloyd's Syndicate</i>
                    </p>
                </Modal>
            </div>
        );
    };

    return (
        <div
            className={cx(classes.NavItem, classes.ConsultancyNavItem, {
                [classes.Active]: dropdownVisibilityContext.state.consultancy,
            })}
            onClick={() => {
                trackUserEvent({ name: "consultancy_navbar_clicked" });

                dropdownVisibilityContext.setState({
                    account: false,
                    consultancy: !dropdownVisibilityContext.state.consultancy,
                    event: false,
                    help: false,
                });
            }}
        >
            Consultancy
            {dropdownVisibilityContext.state.consultancy && (
                <div className={classes.ConsultancyMenu}>
                    <div
                        className={classes.ConsultancyLink}
                        onClick={() => setModalOpen(true)}
                    >
                        Capabilities
                    </div>
                    <a
                        className={classes.ExternalLink}
                        href={"https://mckenzieintelligence.com/consulting"}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        <div
                            className={cx(
                                classes.ConsultancyLink,
                                classes.IconLink,
                            )}
                        >
                            Get a demo
                            <Icon path={mdiOpenInNew} size={0.8} />
                        </div>
                    </a>
                </div>
            )}
            {modalOpen && consultancyModal()}
        </div>
    );
};

export default ConsultancyDropdown;
