import React, { Component } from "react";
import { connect } from "react-redux";

import Report from "../Report/Report";
import { AppConfig } from "../../../store/system/systemTypes";
import { RootState } from "../../../store/store";
import { getStoreAtNamespaceKey } from "../../../store/storeSelectors";

interface OwnProps {}
interface StateProps {
    previewConfig: AppConfig | null;
}

type PreviewReportProps = OwnProps & StateProps;

class PreviewReport extends Component<PreviewReportProps> {
    render() {
        return (
            <>
                {this.props.previewConfig && (
                    <Report reportConfig={this.props.previewConfig} />
                )}
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    previewConfig: getStoreAtNamespaceKey(state, "system").previewConfig,
});

export default connect(mapStateToProps)(PreviewReport);
