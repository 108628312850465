import React, { MouseEvent, ReactNode } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Icon from "@mdi/react";
import { ThunkDispatch } from "redux-thunk";
import { mdiDotsHorizontal, mdiEyeOutline } from "@mdi/js/commonjs/mdi";
import { mdiClose } from "@mdi/js";
import cx from "classnames";
import { featureCollection, point } from "@turf/helpers";
import bbox from "@turf/bbox";
import { WebMercatorViewport } from "react-map-gl";

import { getCssVar } from "../../../../../../../../../utils/CSSHelpers";
import { ConfigMenuGroup } from "../../../../../../../../../store/system/systemTypes";
import {
    ConfigSource,
    ConfigSources,
    MapActionTypes,
    Visibility,
} from "../../../../../../../../../store/map/mapTypes";
import {
    setCenter,
    setHighlightedLayer,
    setLayerView,
    setLayerVisibility,
} from "../../../../../../../../../store/map/mapActions";
import { RootState } from "../../../../../../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";
import ScrollableText from "../../../../../../../../_Library/ScrollableText/ScrollableText";
import LayerIcon from "../../../../LayerIcon/LayerIcon";

import classes from "../LayerListItem/LayerListItem.module.css";
import { isMapCenter } from "../../../../../../../../../utils/TypeGuards";
import Toggle from "../../../../../../../../_Library/Inputs/Toggle/Toggle";
import { mdiArrowUpDownRightBold } from "assets/icons/paths";
import { Badges } from "components/_Library/Badges/Badges";

interface OwnProps {
    group: ConfigMenuGroup;
    beta?: boolean;
    tier?: string;
}

interface StateProps {
    highlightedLayer: { layerName: string; sourceName: string } | null;
    sources: ConfigSources;
}

interface DispatchProps {
    setLayerVisibility: typeof setLayerVisibility;
    setHighlightedLayer: typeof setHighlightedLayer;
    setCenter: typeof setCenter;
    setLayerView: typeof setLayerView;
}
type GroupListItemProps = OwnProps & StateProps & DispatchProps;

interface GroupListItemState {
    isContextOpen: boolean;
    containedSources: ConfigSource[];
    visibility: boolean;
}

class GroupedLayersListItem extends React.Component<
    GroupListItemProps,
    GroupListItemState
> {
    state: GroupListItemState = {
        isContextOpen: false,
        containedSources: [],
        visibility: true,
    };

    componentDidMount() {
        const containedSources = this.collectContainedSources(this.props.group);

        let visibility = containedSources.find((source) => {
            return source.layout.visibility === "visible";
        });
        this.setState({
            containedSources: containedSources,
            visibility: !!visibility,
        });
    }

    collectContainedSources(group: ConfigMenuGroup) {
        let sources: ConfigSource[] = [];

        group.children.forEach((child) => {
            if (child.type === "layer") {
                sources.push(this.props.sources[child.layerSource]);
            } else {
                sources.concat(this.collectContainedSources(child));
            }
        });
        return sources;
    }

    toggleContextOpen = () => {
        this.setState((state) => {
            return { isContextOpen: !state.isContextOpen };
        });
    };

    toggleLayerVisibility(group: ConfigMenuGroup, status: string) {
        let visibility: Visibility = status === "show" ? "visible" : "none";
        group.children.forEach((child) => {
            if (child.type === "layer") {
                this.props.setLayerVisibility({
                    sourceName: child.layerSource,
                    layerName: child.layerName,
                    visibility: visibility,
                });
                this.setState({ visibility: visibility === "visible" });
            } else {
                this.toggleLayerVisibility(child, status);
            }
        });
    }

    handleZoomToLayer = () => {
        let latitude: number;
        let longitude: number;
        let zoom: number;

        const geometries = this.state.containedSources
            .filter((source) => {
                return source.actions?.zoomTo;
            })
            .map((source) => {
                if (isMapCenter(source.actions!.zoomTo)) {
                    return point([
                        source.actions!.zoomTo.longitude,
                        source.actions!.zoomTo.latitude,
                    ]);
                } else {
                    return point([
                        source.actions!.zoomTo!.bbox[0],
                        source.actions!.zoomTo!.bbox[1],
                    ]);
                }
            });

        const bBox = bbox(featureCollection(geometries));
        const webMercatorViewport = new WebMercatorViewport({
            width: 800,
            height: 600,
        });
        let viewport = webMercatorViewport.fitBounds(
            [
                [bBox[0], bBox[1]],
                [bBox[2], bBox[3]],
            ],
            { padding: 200 },
        );
        latitude = viewport.latitude;
        longitude = viewport.longitude;
        zoom = viewport.zoom;

        this.props.setCenter({ latitude, longitude, zoom });
        this.setState({
            isContextOpen: false,
        });
    };

    renderContextMenu = () => {
        let viewOnCount = {
            left: 0,
            both: 0,
            right: 0,
        };
        this.state.containedSources.forEach((source) => {
            ++viewOnCount[source.viewOn];
        });

        return (
            <>
                <div
                    onClick={this.handleZoomToLayer}
                    className={classes.ContextItem}
                >
                    <Icon path={mdiArrowUpDownRightBold} /> Go to
                </div>
                <div
                    className={cx(classes.ContextItem, classes.LayerViewToggle)}
                    onClick={this.handleViewSelection}
                >
                    <Icon path={mdiEyeOutline} />
                    <span
                        className={cx({
                            [classes.Active]: viewOnCount["left"],
                        })}
                    >
                        Left
                    </span>
                    <span
                        className={cx({
                            [classes.Active]: viewOnCount["both"],
                        })}
                    >
                        Both
                    </span>
                    <span
                        className={cx({
                            [classes.Active]: viewOnCount["right"],
                        })}
                    >
                        Right
                    </span>
                </div>
            </>
        );
    };

    handleViewSelection = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();

        this.setGroupView(
            this.props.group,
            (event.target as Element).innerHTML.toLowerCase() as
                | "left"
                | "right"
                | "both",
        );

        this.setState({
            isContextOpen: false,
        });
    };

    setGroupView = (
        group: ConfigMenuGroup,
        view: "left" | "right" | "both",
    ) => {
        group.children.forEach((child) => {
            if (child.type === "layer") {
                this.props.setLayerView({
                    layerName: child.layerName,
                    sourceName: child.layerSource,
                    viewOn: view,
                });
            } else {
                this.setGroupView(child, view);
            }
        });
    };

    render(): ReactNode {
        return (
            <div className={cx(classes.LayerItem)}>
                <div className={classes.Header}>
                    <div className={cx(classes.ToggleTitle, {[classes.ToggleTitleWithBadges]: (this.props.beta || this.props.tier !== "basic")})}>
                        <span
                            data-tip={"Toggle Layer Visibility"}
                            data-for={"LayerVisibility"}
                            className={classes.VisibilityIcon}
                        >
                            <Toggle
                                heightRem={"1.5rem"}
                                active={this.state.visibility}
                                onClick={() => {
                                    this.toggleLayerVisibility(
                                        this.props.group,
                                        this.state.visibility ? "hide" : "show",
                                    );
                                }}
                            />
                            <ReactTooltip
                                id={"LayerVisibility"}
                                place={"left"}
                                effect={"solid"}
                            />
                        </span>
                        <span className={classes.Label}>
                            <ScrollableText text={this.props.group.groupName} />
                        </span>
                    </div>
                    <div className={classes.Icons}>
                        {(this.props.beta || this.props.tier !== "basic") && 
                            <Badges beta={this.props.beta} tier={this.props.tier}/>
                        }
                        <div className={classes.LayerIcon}>
                            <LayerIcon
                                complexLegend={false}
                                paint={{}}
                                type={"raster"}
                            />
                        </div>

                        <div
                            className={classes.LayerMenu}
                            onClick={this.toggleContextOpen}
                        >
                            <Icon
                                path={
                                    this.state.isContextOpen
                                        ? mdiClose
                                        : mdiDotsHorizontal
                                }
                                color={getCssVar(
                                    this.state.isContextOpen
                                        ? "--highlight-color"
                                        : "--text-color",
                                )}
                            />
                            {this.state.isContextOpen && (
                                <div className={classes.ContextMenu}>
                                    {this.renderContextMenu()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    highlightedLayer: getStoreAtNamespaceKey(state, "map").highlightedLayer,
    sources: getStoreAtNamespaceKey(state, "map").mapConfig.sources,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => ({
    setLayerVisibility: bindActionCreators(setLayerVisibility, dispatch),
    setHighlightedLayer: bindActionCreators(setHighlightedLayer, dispatch),
    setCenter: bindActionCreators(setCenter, dispatch),
    setLayerView: bindActionCreators(setLayerView, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GroupedLayersListItem);
