import { makeActionWithPayload } from "utils/ReduxHelpers";
import { ThunkDispatch } from "redux-thunk";
import { eventsGetAll } from "../../crud/eventsCRUD";
import { isUnsuccessfulAPIResponse } from "../../utils/TypeGuards";
import { setAlert } from "../system/systemActions";
import { SystemActionTypes } from "../system/systemTypes";
import {
    ServiceActionTypes,
    SET_EVENT_DATA,
    SET_GROUP_DATA,
    SetEventDataPayload,
    SetGroupDataPayload,
    SET_USER_DATA,
    SetUserDataPayload,
    SET_LOADING_USER_DATA,
    SET_LOADING_GROUP_DATA,
    SET_ALL_USER_DATA,
    SetAllUserDataPayload,
    SET_ALL_GROUP_DATA,
    SetAllGroupDataPayload,
    SET_REPORT_LAYER_STYLES_DATA,
    SetReportLayerStylesDataPayload,
    SET_REPORT_LAYER_TYPES_DATA,
    SetReportLayerTypesDataPayload,
    SET_ALL_COMPANY_DATA,
    SET_LOADING_COMPANY_DATA,
    SetAllCompanyDataPayload,
} from "./serviceTypes";
import {
    companiesGet,
    groupGet,
    groupsGetAllAccess,
    userGet,
    usersGetAllAccess,
} from "../../crud/accessCRUD";
import { reportLayerStylesGetAll } from "crud/layerStylesCRUD";
import { reportLayerTypesGetAll } from "crud/layerTypesCRUD";
import _ from "lodash";
import axios from "axios";

export const createShareImageLink = (
    token: string,
    eventId: string,
    mapCenter: { lat: number; lng: number },
    imageBlob: Blob,
) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        let formData = new FormData();
        let fileName = "image.png";
        let file = new File([imageBlob], fileName);
        formData.append("file", file, fileName);
        formData.append("event_id", eventId);
        formData.append("latitude", mapCenter.lat.toFixed(5));
        formData.append("longitude", mapCenter.lng.toFixed(5));

        const shareImageLinkRes = await axios.post(
            import.meta.env.VITE_API_ROOT + "/data/images",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + token,
                },
            },
        );

        let resData = shareImageLinkRes.data;

        if (resData.success) {
            window.open("/share/" + resData.data.id, "_blank");
            window.focus();

            return resData.data;
        } else {
            if (isUnsuccessfulAPIResponse(shareImageLinkRes)) {
                setAlert({
                    message:
                        "Unable to create Image share link. Please try again later.",
                    timeout: 2000,
                    type: "Error",
                });
            }
        }
    };
};

export const fetchAllEventData = (token: string) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        const eventResponse = await eventsGetAll(token);

        if (eventResponse.success) {
            let eventData = eventResponse.data;
            dispatch(setEventData({ eventData }));
            return eventData;
        } else {
            if (isUnsuccessfulAPIResponse(eventResponse)) {
                for (const error of eventResponse.errors) {
                    dispatch(
                        setAlert({
                            message: error.msg,
                            timeout: 2000,
                        }),
                    );
                }
            }
        }
    };
};

export const fetchAllGroupData = (
    token: string,
    query: string = "",
    page: string = "1",
    signal: AbortSignal | null = null,
) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        dispatch(setLoadingGroupData(true));
        const groupResponse = await groupsGetAllAccess(
            token,
            {
                search: query,
                page: page,
                limit: 15,
            },
            {},
            signal,
        );
        if (groupResponse.success) {
            dispatch(setGroupData(groupResponse));
        } else {
            if (isUnsuccessfulAPIResponse(groupResponse)) {
                for (const error of groupResponse.errors) {
                    dispatch(
                        setAlert({
                            message: error.msg,
                            timeout: 2000,
                        }),
                    );
                }
            }
        }
        dispatch(setLoadingGroupData(false));
        return groupResponse;
    };
};

export const fetchFilteredCompanies = (
    token: string,
    query: string = "",
    page: string = "1",
    signal: AbortSignal | null = null,
) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        dispatch(setLoadingGroupData(true));
        const groupResponse = await groupsGetAllAccess(
            token,
            {
                search: query,
                page: page,
                limit: 15,
            },
            {},
            signal,
        );
        if (groupResponse.success) {
            dispatch(setGroupData(groupResponse));
        } else {
            if (isUnsuccessfulAPIResponse(groupResponse)) {
                for (const error of groupResponse.errors) {
                    dispatch(
                        setAlert({
                            message: error.msg,
                            timeout: 2000,
                        }),
                    );
                }
            }
        }
        dispatch(setLoadingGroupData(false));
        return groupResponse;
    };
};

export const fetchAllUserAccessData = (
    token: string,
    query: string = "",
    page: string = "1",
    order: string | null = null,
    signal: AbortSignal | null = null,
) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        let queryPayload = {
            search: query,
            page: page,
            limit: 15,
        };
        if (order) {
            _.set(queryPayload, "order", order);
        }
        dispatch(setLoadingUserData(true));
        const userResponse = await usersGetAllAccess(
            token,
            queryPayload,
            {},
            signal,
        );
        if (userResponse.success) {
            dispatch(setUserData(userResponse));
        } else {
            if (isUnsuccessfulAPIResponse(userResponse)) {
                for (const error of userResponse.errors) {
                    dispatch(
                        setAlert({
                            message: error.msg,
                            timeout: 2000,
                        }),
                    );
                }
            }
            return null;
        }
        dispatch(setLoadingUserData(false));
        return userResponse;
    };
};

export const fetchAllUsers = (token: string) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        const userResponse = await userGet(token);

        if (userResponse.success) {
            dispatch(setAllUserData(userResponse.data));
        }
    };
};

export const fetchAllGroups = (token: string) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        const groupResponse = await groupGet(token);

        if (groupResponse.success) {
            dispatch(setAllGroupData(groupResponse.data));
        } else {
            dispatch(
                setAlert({
                    message: `Error loading groups: ${groupResponse.errors}`,
                    type: "Error",
                    timeout: 4000,
                }),
            );
        }
    };
};

export const fetchAllCompanies = (token: string) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        const companiesResponse = await companiesGet(token, {}, {});

        if (companiesResponse.success) {
            dispatch(setAllCompaniesData(companiesResponse.data));
        } else {
            dispatch(
                setAlert({
                    message: `Error loading groups: ${companiesResponse.errors}`,
                    type: "Error",
                    timeout: 4000,
                }),
            );
        }
    };
};

export const fetchAllReportLayerStyles = (token: string) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        const reportLayerStylesRes = await reportLayerStylesGetAll(token);

        if (reportLayerStylesRes.success) {
            dispatch(setReportLayerStylesData(reportLayerStylesRes.data));
        } else {
            dispatch(
                setAlert({
                    message: `Error loading groups: ${reportLayerStylesRes.message}`,
                    type: "Error",
                    timeout: 4000,
                }),
            );
        }
    };
};

export const fetchAllReportLayerTypes = (token: string) => {
    return async (
        dispatch: ThunkDispatch<
            any,
            any,
            ServiceActionTypes | SystemActionTypes
        >,
    ) => {
        const reportLayerTypesRes = await reportLayerTypesGetAll(token);

        if (reportLayerTypesRes.success) {
            dispatch(setReportLayerTypesData(reportLayerTypesRes.data));
        } else {
            dispatch(
                setAlert({
                    message: `Error loading report layer types: ${reportLayerTypesRes.message}`,
                    type: "Error",
                    timeout: 4000,
                }),
            );
        }
    };
};

export const setUserData = makeActionWithPayload<
    typeof SET_USER_DATA,
    SetUserDataPayload
>(SET_USER_DATA);

export const setAllUserData = makeActionWithPayload<
    typeof SET_ALL_USER_DATA,
    SetAllUserDataPayload
>(SET_ALL_USER_DATA);

export const setAllGroupData = makeActionWithPayload<
    typeof SET_ALL_GROUP_DATA,
    SetAllGroupDataPayload
>(SET_ALL_GROUP_DATA);

export const setAllCompaniesData = makeActionWithPayload<
    typeof SET_ALL_COMPANY_DATA,
    SetAllCompanyDataPayload
>(SET_ALL_COMPANY_DATA);

export const setLoadingUserData = makeActionWithPayload<
    typeof SET_LOADING_USER_DATA,
    boolean
>(SET_LOADING_USER_DATA);

export const setLoadingGroupData = makeActionWithPayload<
    typeof SET_LOADING_GROUP_DATA,
    boolean
>(SET_LOADING_GROUP_DATA);

export const setLoadingCompanyData = makeActionWithPayload<
    typeof SET_LOADING_COMPANY_DATA,
    boolean
>(SET_LOADING_COMPANY_DATA);

export const setGroupData = makeActionWithPayload<
    typeof SET_GROUP_DATA,
    SetGroupDataPayload
>(SET_GROUP_DATA);

export const setEventData = makeActionWithPayload<
    typeof SET_EVENT_DATA,
    SetEventDataPayload
>(SET_EVENT_DATA);

export const setReportLayerStylesData = makeActionWithPayload<
    typeof SET_REPORT_LAYER_STYLES_DATA,
    SetReportLayerStylesDataPayload
>(SET_REPORT_LAYER_STYLES_DATA);

export const setReportLayerTypesData = makeActionWithPayload<
    typeof SET_REPORT_LAYER_TYPES_DATA,
    SetReportLayerTypesDataPayload
>(SET_REPORT_LAYER_TYPES_DATA);
