import {
    AnyLayout,
    BackgroundPaint,
    CirclePaint,
    FillExtrusionPaint,
    FillPaint,
    HeatmapPaint,
    HillshadePaint,
    LinePaint,
    RasterPaint,
    SymbolPaint
} from "mapbox-gl";

import { makeAPICall_StaticUrl } from "../utils/APIHelpers";
import { genericAPIResponse } from "./genericResponse";

export interface StyleConfig {
    [StyleCategory: string]: {
        [StyleName: string]: StyleConfig_Style;
    };
}

export interface StyleConfig_Style {
    id: string;
    name: string;
    layer_type: "Vector" | "Raster";
    geometry_type: "Point" | "Line" | "Polygon" | "Symbol" | "Raster";
    meta_type: "Mapbox" | "WMTS" | "Point";
    req_column: string;
    req_values_type: "string" | "";
    req_values: string[];
    style: {
        paint:
            | BackgroundPaint
            | FillPaint
            | FillExtrusionPaint
            | LinePaint
            | SymbolPaint
            | RasterPaint
            | CirclePaint
            | HeatmapPaint
            | HillshadePaint;
        layout?: AnyLayout;
    };
}

export const reportLayerStylesGetAll = makeAPICall_StaticUrl<genericAPIResponse<StyleConfig>>("/report_layer_styles/", "GET");
