import React from "react";
import { Layer, LayerProps, MapRef, Source, Marker } from "react-map-gl";
import moment from "moment";

import {
    ConfigSource,
    MapType,
    ConfigSources,
    MapConfig,
} from "store/map/mapTypes";
import { MarkerConfig } from "store/insights/insightsTypes";
import { TimelineConfig, ConfigMenuLayer } from "store/system/systemTypes";
import { getLayers } from "utils/Layers";
import { buildLegendTextForRows } from "utils/ExtractLegendData";
import MarkerImage from "/src/assets/images/location_marker.png";

export interface OwnProps {
    leftMapRef: React.RefObject<MapRef>;
    rightMapRef: React.RefObject<MapRef>;
    sourcesObject: ConfigSources;
    mapConfig: MapConfig;
    timelineConfig?: TimelineConfig;
    mapType: MapType;
    locationLabels: boolean;
}

export interface MarkerProps {
    marker: MarkerConfig | null;
    locationLabels: boolean;
}

export interface MarkerSearchProps {
    lnglat: [number, number];
}

const createLayerFilters = (
    timelineConfig: TimelineConfig | undefined,
    layer: ConfigSource,
    filteredIds: any[],
    identifier: string | number | null,
    legendRowTexts: any,
) => {
    let filters: any[] = [];
    if (filteredIds && filteredIds.length > 0) {
        if (Array.isArray(filteredIds[0])) {
            let rangeValues = filteredIds[0];
            filters.push(["<", ["get", identifier], rangeValues[0]]);
            filters.push([">", ["get", identifier], rangeValues[1]]);
        } else {
            if (filteredIds.includes("Other")) {
                let filtersToExclude: any[] = [];
                for (let i = 0; i < legendRowTexts.length; i++) {
                    if (!filteredIds.includes(legendRowTexts[i])) {
                        filtersToExclude.push(legendRowTexts[i]);
                    }
                }
                filtersToExclude.forEach((label) => {
                    filters.push(["!=", ["get", identifier], label]);
                });
            } else {
                filters.push([
                    "in",
                    ["get", identifier],
                    ["literal", filteredIds],
                ]);
            }
        }
    }
    let timeline = layer.dataConfig?.timeline;
    if (timelineConfig?.currentDate && timeline) {
        if (timeline.type === "filter-on") {
            filters.push([
                "==",
                ["get", timeline.data.dateColumnName],
                moment(timelineConfig!.currentDate).format(
                    timeline.data.format,
                ),
            ]);
        } else if (timeline.type === "filter-between") {
            filters.push([
                "<=",
                ["get", timeline.data.dateColumnName],
                parseInt(
                    moment(timelineConfig!.currentDate).format(
                        timeline.data.format,
                    ),
                ),
            ]);
            filters.push([
                ">",
                ["get", timeline.data.dateToColumnName],
                parseInt(
                    moment(timelineConfig!.currentDate).format(
                        timeline.data.format,
                    ),
                ),
            ]);
        } else if (timeline.type === "filter-on-array") {
            filters.push([
                "in",
                parseInt(
                    moment(timelineConfig!.currentDate).format(
                        timeline.data.format,
                    ),
                ),
                ["get", timeline.data.dateColumnName],
            ]);
        }
    }

    //mapbox needs 'all' added to beginning of array when multiple filters present
    let otherProps;
    let filter;
    if (filters.length) {
        otherProps = { filter: [] };
        if (filters.length === 1) {
            filter = filters[0];
        }
        if (filters.length > 1) {
            filter = [...filters];
            filter.splice(0, 0, "all");
        }
        otherProps.filter = filter;
    }
    return otherProps;
};

const prepareLayerInfo = (
    props: OwnProps,
    layerSource: string,
    layer: ConfigSource,
    layerName: string,
    sourceObject: any,
    legendRowTexts: any,
) => {
    let customFilter = sourceObject.customFilter ?? null;
    let filteredIds: string[] = [];
    let identifier = null;

    if (customFilter !== null && customFilter !== undefined) {
        filteredIds = customFilter.values ?? null;
        identifier = customFilter.identifier ?? null;
    }

    const layerProps: LayerProps = {
        id: layerName,
        type: layer.layerType,
        paint: { ...layer.paint },
        source: layer.source, // analogous with source-layer in regular mapbox
        layout: { ...layer.layout },
    };

    const otherProps = createLayerFilters(
        props.timelineConfig,
        layer,
        filteredIds,
        identifier,
        legendRowTexts,
    );

    layerProps["source-layer"] =
        layer["source-layer"] ?? layerProps.source ?? "";
    const reactLayer = (
        <Layer
            key={layerProps.id}
            {...layerProps}
            {...otherProps}
            beforeId={props.locationLabels ? "road-label" : ""}
        />
    );
    return reactLayer;
};

const createLayer = (
    props: OwnProps,
    layerName: string,
    layerSource: string,
    sourceObject: any,
) => {
    const sources = props.sourcesObject;
    const layer = sources[layerSource];
    const type = layer.layerType;
    const paint = layer.paint;
    const legendRowTexts = buildLegendTextForRows(paint, type);
    const layerInfo = prepareLayerInfo(
        props,
        layerSource,
        layer,
        layerName,
        sourceObject,
        legendRowTexts,
    );
    const sourceProps = {
        id: layerSource,
        type: sources[layerSource].type,
        url: sources[layerSource].url,
        data: sources[layerSource].data,
        tiles: sources[layerSource].tiles,
    };
    if (sources[layerSource].tiles) {
        delete sourceProps.url;
        delete sourceProps.data;
        //@ts-ignore
        sourceProps.tileSize = sources[layerSource].tileSize || 256;
    }
    if (sources[layerSource].data) {
        delete sourceProps.url;
        delete sourceProps.tiles;
    }
    if (sources[layerSource].url) {
        delete sourceProps.data;
        delete sourceProps.tiles;
    }

    return (
        <Source key={layerSource} {...sourceProps} maxzoom={19}>
            {layerInfo}
        </Source>
    );
};

const MapLayers = (props: OwnProps): JSX.Element[] => {
    const menuIndex = props.mapConfig.menuIndex;
    let extractedLayerObjects: Array<any> = getLayers(menuIndex);
    const mapConfig = props.mapConfig;
    let allTheLayers = extractedLayerObjects.map(
        (layerObject: ConfigMenuLayer) => {
            return createLayer(
                props,
                layerObject.layerName!,
                layerObject.layerSource!,
                mapConfig.sources[layerObject.layerSource],
            );
        },
    );
    return allTheLayers.flat().flat().reverse();
};

export const MapSearchMarker = (props: MarkerSearchProps) => {
    if (props.lnglat) {
        return (
            <Marker
                longitude={props.lnglat[0]}
                latitude={props.lnglat[1]}
                offsetTop={-45}
                offsetLeft={-16.5}
            >
                <img
                    alt={"Location Pin"}
                    src={MarkerImage}
                    style={{ width: "3.3rem", height: "4.5rem" }}
                />
            </Marker>
        );
    } else {
        return <></>;
    }
};

export const CreateLayerFromConfigSource = (source: ConfigSource) => {

    return <Source key={source.source} {...source}> 
        <Layer
            id={source.source}
            type={source.layerType}
            source={source.source}
            paint={source.paint}
            layout={source.layout}
        />
    </Source>

}

export default MapLayers;
