import React from "react";
import * as MapboxGL from "mapbox-gl";

import { LayerType } from "../types/mapbox-types";
import { Dict } from "../types/misgis";
import Line from "../components/Pages/Report/DashboardComponents/Map/LayerIcon/LineIcon/Line";
import Square from "../components/Pages/Report/DashboardComponents/Map/LayerIcon/PolygonIcon/Square";
import Circle from "../components/Pages/Report/DashboardComponents/Map/LayerIcon/PointIcon/Circle";
import { mergeDeep } from "./Objects";
import { Expression } from "./ParseExpression";

type PaintProp =
    | "fill-color"
    | "circle-color"
    | "line-color"
    | "circle-radius"
    | "line-width"
    | "circle-stroke-width"
    | "circle-stroke-color"
    | "fill-outline-color";
type ColorOrWidthForEachStep = [[number], {}] | [[number], number];
type SmallArray = (
    | number
    | { color: string; borderColor?: undefined }
    | { borderColor: string; color?: undefined }
    | { radius: any }
    | { width: any }
    | { borderWidth: any }
    | undefined
)[];

export const LayerTypeRenderData: { [key in LayerType]: Dict<any> } = {
    "fill-extrusion": {},
    background: {},
    heatmap: {},
    hillshade: {},
    raster: {},
    symbol: {},
    line: {
        paintToComponentProps: {
            "line-width": "width",
            "line-color": "color",
        },
        renderFunction: (props: any) => {
            return <Line {...props} borderStyle={"solid"} />;
        },
    },
    fill: {
        paintToComponentProps: {
            "fill-outline-color": "borderColor",
            "fill-color": "color",
        },
        renderFunction: (props: any) => {
            return <Square {...props} />;
        },
    },
    circle: {
        paintToComponentProps: {
            "circle-stroke-width": "borderWidth",
            "circle-stroke-color": "borderColor",
            "circle-color": "color",
            "circle-radius": "radius",
        },
        renderFunction: (props: any) => {
            return <Circle {...props} borderStyle={"solid"} />;
        },
    },
};

const evalInterpolatedExpr = (
    interpolationStyle: any,
    x: number,
    baseNumber: number,
    baseStyle: any,
    maxNumber: number,
    maxStyle: any,
    colorOrNumber: any,
) => {
    return Expression.parse(
        [
            "interpolate",
            interpolationStyle,
            x,
            baseNumber,
            baseStyle,
            maxNumber,
            maxStyle,
        ],
        colorOrNumber,
    ).evaluate(null!);
};

const constructRGBA = (
    property: { [x: string]: any },
    paintProp: PaintProp,
) => {
    //convert colors from - 1: Color :{a: 1, b: 0.9999955106628876, g: 0.9999914974675902, r: 0.9999859878265885} to eg {color:'rgba(99,99,99,99)'}
    //first loop thru the Color object and convert it to 255 values in an array
    let colorValuesArray: any[] = [];
    let colorValue: number;
    for (let key in property) {
        colorValue = property[key];
        if (key === "r" || key === "g" || key === "b") {
            colorValue = colorValue * 255;
            colorValue = Math.round(colorValue);
        }
        colorValuesArray.push(colorValue);
    }
    //eg - colorValuesArray=[255,255,255,1]
    //now make the rgba color from the array eg color: "rgba(255,255,255,1)" from [255,255,255,1]
    let colorString = "rgba(rr,gg,bb,aa)";
    colorString = colorString.replace("rr", colorValuesArray[0]);
    colorString = colorString.replace("gg", colorValuesArray[1]);
    colorString = colorString.replace("bb", colorValuesArray[2]);
    colorString = colorString.replace("aa", colorValuesArray[3]);
    //colorString now has the rgba values as eg rgba(227,238,246,1)
    if (
        paintProp === "fill-color" ||
        paintProp === "circle-color" ||
        paintProp === "line-color"
    ) {
        return { color: colorString };
    } else {
        return { borderColor: colorString };
    }
};

const getInterpolatedValues = (
    roundedSteps: number[],
    paintProp: PaintProp,
    baseNumber: number,
    baseStyle: string | number,
    maxNumber: number,
    maxStyle: string | number,
    interpolationStyle: any[],
) => {
    let arrayOfLabelAndValueArrays: ColorOrWidthForEachStep[] = [];
    let interimArray: ColorOrWidthForEachStep;
    roundedSteps.forEach((item) => {
        let colorOrNumber;
        if (
            paintProp === "line-width" ||
            paintProp === "circle-stroke-width" ||
            paintProp === "circle-radius"
        ) {
            colorOrNumber = "number";
            let calculatedWidth = Math.round(
                evalInterpolatedExpr(
                    interpolationStyle,
                    item,
                    baseNumber,
                    baseStyle,
                    maxNumber,
                    maxStyle,
                    colorOrNumber,
                ),
            );
            interimArray = [[item], calculatedWidth];
        } else {
            colorOrNumber = "color";
            interimArray = [
                [item],
                evalInterpolatedExpr(
                    interpolationStyle,
                    item,
                    baseNumber,
                    baseStyle,
                    maxNumber,
                    maxStyle,
                    colorOrNumber,
                ),
            ];
        }
        arrayOfLabelAndValueArrays.push(interimArray);
    });
    return arrayOfLabelAndValueArrays;
};

const buildSteps = (maxNumber: number, baseNumber: number) => {
    let numberRange: number = maxNumber - baseNumber;
    let stepsValue = numberRange / 5;
    let steps = [
        baseNumber,
        baseNumber + stepsValue,
        baseNumber + stepsValue + stepsValue,
        baseNumber + stepsValue + stepsValue + stepsValue,
        maxNumber,
    ];
    return steps.map((step) => Math.round(step));
};

const processInterpolatedColors = (
    expression: MapboxGL.Expression,
    paintProp: PaintProp,
) => {
    let colorObject: { [key: number]: { [key: string]: any } };
    const { baseNumber, baseStyle, maxNumber, maxStyle, interpolationStyle } =
        expressionArguments(expression);
    let roundedSteps = buildSteps(maxNumber, baseNumber);
    let interpolatedValues = getInterpolatedValues(
        roundedSteps,
        paintProp,
        baseNumber,
        baseStyle,
        maxNumber,
        maxStyle,
        interpolationStyle,
    );
    let styleTuples = interpolatedValues.map((smallArray) => {
        let newSmallArray: SmallArray = [];
        smallArray.forEach((property: [number] | {}) => {
            if (Array.isArray(property)) {
                newSmallArray.push(property[0]);
            } else {
                let colorTuple = constructRGBA(property, paintProp);
                newSmallArray.push(colorTuple);
            }
            return newSmallArray;
        });
        return newSmallArray;
    });
    colorObject = Object.fromEntries(styleTuples);
    return colorObject;
};

const processInterpolatedNumbers = (expression: any, paintProp: PaintProp) => {
    let numberObject: { [key: number]: { string: number } };
    const { baseNumber, baseStyle, maxNumber, maxStyle, interpolationStyle } =
        expressionArguments(expression);
    let roundedSteps: number[] = buildSteps(maxNumber, baseNumber);
    let interpolatedValues = getInterpolatedValues(
        roundedSteps,
        paintProp,
        baseNumber,
        baseStyle,
        maxNumber,
        maxStyle,
        interpolationStyle,
    );
    // let styleTuples: SmallArray[] = []
    let styleTuples = interpolatedValues.map((smallArray) => {
        let newSmallArray: SmallArray = [];
        smallArray.forEach((property) => {
            if (Array.isArray(property)) {
                newSmallArray.push(property[0]);
            } else {
                let numberTuple:
                    | { radius: any }
                    | { width: any }
                    | { borderWidth: any }
                    | undefined;
                if (paintProp === "circle-stroke-width") {
                    numberTuple = { borderWidth: property };
                }
                if (paintProp === "line-width") {
                    numberTuple = { width: property };
                }
                if (paintProp === "circle-radius") {
                    numberTuple = { radius: property };
                }
                newSmallArray.push(numberTuple);
            }
            return newSmallArray;
        });
        return newSmallArray;
    });
    numberObject = Object.fromEntries(styleTuples);
    return numberObject;
};

const expressionArguments = (expression: MapboxGL.Expression) => {
    // @ts-ignore
    let argumentsObject: {
        baseStyle: string | number;
        baseNumber: number;
        maxStyle: string | number;
        maxNumber: number;
        interpolationStyle: string[];
    } = {};
    argumentsObject.baseNumber = expression[3];
    argumentsObject.baseStyle = expression[4];
    argumentsObject.maxNumber = expression[5];
    argumentsObject.maxStyle = expression[6];
    argumentsObject.interpolationStyle = expression[1];
    return argumentsObject;
};

export const findLegendRowTexts = (propertiesForLegend: Dict<any>) => {
    let legendRowTexts: string[] = [];
    for (let featureProperty in propertiesForLegend) {
        legendRowTexts = Object.keys(propertiesForLegend[featureProperty]).map(
            (key, index) => {
                return key;
            },
        );
    }
    let firstRowText = legendRowTexts.length > 0 ? legendRowTexts[0] : null; //used to exclude the first icon for ranges (they must lie between the rows so we need one icon less)
    return { firstRowText, legendRowTexts };
};

export const convertExpressionToLegendStylingData = (
    expression: MapboxGL.Expression,
    prop: string,
    paintProp: string,
) => {
    let labelToAttr: Dict<any> = {};
    let featureProperty: string = "";
    if (expression[0] === "match") {
        featureProperty = expression[1][1];
        let labelsAndValues = expression.slice(2, expression.length - 1);
        for (let i = 0; i < labelsAndValues.length; i += 2) {
            labelToAttr[labelsAndValues[i]] = {
                [prop]: labelsAndValues[i + 1],
            };
        }
        labelToAttr["Other"] = { [prop]: expression[expression.length - 1] };
    }
    let isInterpolated: boolean = false;
    if (expression[0] === "interpolate") {
        isInterpolated = true;
        if (
            paintProp === "fill-color" ||
            paintProp === "line-color" ||
            paintProp === "circle-color" ||
            paintProp === "circle-stroke-color" ||
            paintProp === "fill-outline-color"
        ) {
            labelToAttr = processInterpolatedColors(expression, paintProp);
        }
        if (
            paintProp === "circle-stroke-width" ||
            paintProp === "line-width" ||
            paintProp === "circle-radius"
        ) {
            labelToAttr = processInterpolatedNumbers(expression, paintProp);
        }
        featureProperty = expression[2][1];
    }
    return isInterpolated
        ? { isInterpolated: isInterpolated, [featureProperty]: labelToAttr }
        : { [featureProperty]: labelToAttr };
};

export const extractStylingPropertiesForIcons = (
    paintToComponentProps: { [x: string]: any },
    paint: { [x: string]: any },
) => {
    let standardProperties: Dict<any> = {};
    let propertiesForLegend: Dict<any> = {};
    for (let paintProp in paintToComponentProps) {
        let componentProp = paintToComponentProps[paintProp];
        if (paintProp in paint) {
            let paintValue = paint[paintProp as keyof MapboxGL.AnyPaint];
            if (Array.isArray(paintValue)) {
                //if its an array then its an expression so it needs to be passed on, else we can just set it
                propertiesForLegend = mergeDeep(
                    propertiesForLegend,
                    propertiesForLegend,
                    convertExpressionToLegendStylingData(
                        paintValue as MapboxGL.Expression,
                        componentProp,
                        paintProp,
                    ),
                );
            } else {
                standardProperties[componentProp] = paintValue;
            }
        }
    }
    return { propertiesForLegend, standardProperties };
};

export function buildLegendTextForRows(
    paint:
        | MapboxGL.BackgroundPaint
        | MapboxGL.FillPaint
        | MapboxGL.FillExtrusionPaint
        | MapboxGL.LinePaint
        | MapboxGL.SymbolPaint
        | MapboxGL.RasterPaint
        | MapboxGL.CirclePaint
        | MapboxGL.HeatmapPaint
        | MapboxGL.HillshadePaint,
    type: LayerType,
) {
    let renderData = LayerTypeRenderData[type];
    let paintToComponentProps = renderData.paintToComponentProps;
    let propertiesForLegend: Dict<any> = extractStylingPropertiesForIcons(
        paintToComponentProps,
        paint,
    ).propertiesForLegend;
    return findLegendRowTexts(propertiesForLegend).legendRowTexts;
}
