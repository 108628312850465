//@ts-nocheck
import basemap_tutorial from "./basemap_tutorial";
import data_upload_tutorial from "assets/tutorials/data_upload_tutorial";
import timeline_tutorial from "./timeline_tutorial";
import tabs_tutorial from "./tabs_tutorial";
import tools_tutorial from "./tools_tutorial";
import intelligence_layers_tutorial from "./intelligence_layers_tutorial";
import { Props as JoyrideProps, Step } from "react-joyride";
import { AppConfig } from "store/system/systemTypes";
import insights_tutorial from "./insights_tutorial";
import { InsightsSchema } from "../../crud/insightsCRUD";
import filtering_tutorial from "./filtering_tutorial";

export const TutorialTags = [
    "insights",
    "data",
    "filtering",
    "layers",
    "maps",
] as const;

export type TutorialTag = (typeof TutorialTags)[number];

export interface TutorialConfig {
    name: string;
    description: string;
    tags?: TutorialTag[];
    steps: EnhancedStep[];
    imageUrl: string;
    appConfig: AppConfig;
    version: number;
    InsightsBannerWrapper?: InsightsSchema;
}

export interface EnhancedStep extends Step {
    targetTriggerEvent?: keyof HTMLElementEventMap;
    displayDelay?: number; // millis
}

export interface ProductTourConfig extends JoyrideProps {
    steps: EnhancedStep[];
}

const Tutorials: { [key: string]: TutorialConfig } = {
    basemap_tutorial,
    data_upload_tutorial,
    timeline_tutorial,
    tabs_tutorial,
    tools_tutorial,
    intelligence_layers_tutorial,
    insights_tutorial,
    filtering_tutorial,
};

export default Tutorials;
