/**
 * Used to remove some of the boilerplate on the topmost component in the tree. Usually Report.
 */

import React, { FunctionComponent, ReactNode } from "react";
import { Provider } from "react-redux";

import { getStore } from "store/store";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const instance = createInstance({
    urlBase: "https://misgeo.matomo.cloud/",
    siteId: 1,
});

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

const DefaultProviders: FunctionComponent<Props> = ({ children }) => {
    instance.pushInstruction("requireConsent");

    return (
        <MatomoProvider value={instance}>
            <Provider store={getStore()}>{children}</Provider>
        </MatomoProvider>
    );
};

export default DefaultProviders;
