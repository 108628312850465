import React, { FC } from "react";
import classes from "./AccountDropdown.module.css";
import ThemeToggle from "../../../_Library/ThemeToggle/ThemeToggle";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useHistory } from "react-router-dom";
import InitialsIcon from "../../../_Library/InitialsIcon/InitialsIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";
import ScrollableText from "../../../_Library/ScrollableText/ScrollableText";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

const AccountDropdown: FC = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const history = useHistory();
    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user,
    );

    const signOut = async () => {
        if (authState?.isAuthenticated) {
            await oktaAuth.revokeAccessToken();
            await oktaAuth.closeSession();
            history.push("/login");
        } else {
            history.push("/login");
        }
    };

    const { trackUserEvent } = useAnalytics();

    return (
        <div className={classes.DropdownContainer}>
            <div className={classes.UserCompany}>
                <ScrollableText text={user?.company_name || ""} />
            </div>
            <div className={classes.InitialIconContainer}>
                <InitialsIcon
                    width={6}
                    firstName={user?.first_name}
                    lastName={user?.last_name}
                />
            </div>
            <div className={classes.UserInfo}>
                <ScrollableText
                    className={classes.UserInfoName}
                    text={user?.first_name + " " + user?.last_name}
                />
                <ScrollableText text={user!.email!} />
                <div
                    onClick={() => {
                        trackUserEvent({
                            name: "view_profile_clicked",
                        });
                    }}
                >
                    <Link to={"/account"}>
                        <p className={classes.Link}>View Profile</p>
                    </Link>
                </div>
            </div>
            <div className={classes.ActionBar}>
                <div className={classes.ThemeToggle}>
                    <ThemeToggle />
                </div>
                <p
                    className={classes.LogoutButton}
                    onClick={() => {
                        trackUserEvent({
                            name: "sign_out_clicked",
                        });
                        signOut();
                    }}
                >
                    Sign out
                </p>
            </div>
        </div>
    );
};

export default AccountDropdown;
