import { MapEvent } from "react-map-gl/dist/es5/components/interactive-map";
import { MapActionTypes } from "store/map/mapTypes";
import React, { Component, ReactNode } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import {
    featureCollection,
    multiLineString,
    point,
    polygon,
    Position,
} from "@turf/helpers";
import { Layer, LayerProps, Source } from "react-map-gl";
import {
    InteractionModeMapProps,
    MapInteractions,
} from "../../InteractionModeContainer/InteractionModeContainer";
import { bindActionCreators } from "redux";
import area from "@turf/area";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import cx from "classnames";
import { setAlert } from "store/system/systemActions";
import { setInteractionMode } from "store/map/mapActions";
import { MeasurePopupInfo } from "components/Pages/Report/DashboardComponents/Map/Mapping/MapContainer/MapContainer";
import { setCookie, getCookie } from "utils/Cookies";
import Button from "components/_Library/Button/Button";
import classes from "../InteractionModes.module.css";
import { getCssVar } from "utils/CSSHelpers";

export interface OwnProps {
    mapContainerWidth: number;
    updateModeProps: (props: InteractionModeMapProps) => void;
}

interface DispatchProps {
    setAlert: typeof setAlert;
    setInteractionMode: typeof setInteractionMode;
}

const DRAW_STATE_IDLE = "IDLE";
const DRAW_STATE_DRAWING = "DRAWING";
const DRAW_STATE_DISPLAY = "DISPLAY";
const DRAW_STATE_INACTIVE = "INACTIVE";

type DrawState =
    | typeof DRAW_STATE_IDLE
    | typeof DRAW_STATE_DRAWING
    | typeof DRAW_STATE_DISPLAY
    | typeof DRAW_STATE_INACTIVE;

type InteractionModeMeasureAreaProps = DispatchProps & OwnProps;

export interface InteractionModeMeasureAreaState {
    clickedLngLats: Position[];
    popupInfo: MeasurePopupInfo;
    drawState: DrawState;
    hoverLngLat: Position | null;
    systemOfMeasurement: SystemOfMeasurement;
}

enum SystemOfMeasurement {
    metric = "metric",
    imperial = "imperial",
}

class InteractionModeMeasureArea extends Component<
    InteractionModeMeasureAreaProps,
    InteractionModeMeasureAreaState
> {
    areaUnitCookie = getCookie("area-unit");
    state: InteractionModeMeasureAreaState = {
        drawState: DRAW_STATE_IDLE,
        clickedLngLats: [],
        popupInfo: {
            latitude: 0,
            longitude: 0,
            measurement: null,
        },
        hoverLngLat: null,
        systemOfMeasurement: this.areaUnitCookie
            ? (this.areaUnitCookie as SystemOfMeasurement)
            : SystemOfMeasurement.metric,
    };

    measurementSystems = {
        metric: {
            unitsShort: "km²",
            subUnitsShort: "m²",
            divisor: 1000000,
        },
        imperial: {
            unitsShort: "mi²",
            subUnitsShort: "ft²",
            divisor: 27878400,
        },
    };

    handleGetCursor = (state: {
        isLoaded: boolean;
        isDragging: boolean;
        isHovering: boolean;
    }) => {
        // the cursor returned from this function take precedence over marker cursor. So return nothing.
        return "crosshair";
    };

    handleMapOnHover = (event: MapEvent) => {
        if (this.state.drawState === DRAW_STATE_DRAWING) {
            this.setState({
                hoverLngLat: event.lngLat,
            });
        }
    };

    handleMapOnClick = (event: MapEvent) => {
        // ignore clicks not originating in overlays - CW&JR 29/08/23
        if (!event.target.classList.contains("overlays")) {
            return;
        }

        let { clickedLngLats, drawState, popupInfo, hoverLngLat } = this.state;
        let { lngLat, features } = event;

        if (this.state.drawState === DRAW_STATE_IDLE) {
            drawState = DRAW_STATE_DRAWING;
            clickedLngLats.push(lngLat);
        } else if (this.state.drawState === DRAW_STATE_DRAWING) {
            let startPoint = features?.find((elem) => {
                return elem.properties.id === 0;
            });
            if (startPoint !== undefined) {
                if (clickedLngLats.length < 3) {
                    this.props.setAlert({
                        message:
                            "To measure area we need to click at least 3 different points.",
                        timeout: 5000,
                    });
                } else {
                    drawState = DRAW_STATE_DISPLAY;
                    clickedLngLats.push(clickedLngLats[0]);
                    const areaMeters = area(polygon([clickedLngLats]));
                    popupInfo = {
                        latitude: clickedLngLats[0][1],
                        longitude: clickedLngLats[0][0],
                        measurement:
                            this.state.systemOfMeasurement ===
                            SystemOfMeasurement.metric
                                ? areaMeters
                                : areaMeters * 10.7639,
                    };
                    hoverLngLat = null;
                }
            } else {
                clickedLngLats.push(lngLat);
            }
        } else if (this.state.drawState === DRAW_STATE_DISPLAY) {
            clickedLngLats = [lngLat];
            drawState = DRAW_STATE_DRAWING;
        } else if (this.state.drawState === DRAW_STATE_INACTIVE) {
            this.setState({
                popupInfo: {
                    latitude: 0,
                    longitude: 0,
                    measurement: 0,
                },
                drawState: DRAW_STATE_IDLE,
            });
            return;
        }

        this.setState({
            popupInfo: { ...popupInfo },
            clickedLngLats: [...clickedLngLats],
            drawState,
            hoverLngLat,
        });
    };

    handleMeasureReset = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        this.setState({
            drawState: DRAW_STATE_INACTIVE,
            clickedLngLats: [],
            popupInfo: {
                latitude: 0,
                longitude: 0,
                measurement: 0,
            },
        });
    };

    onContextMenu = () => {
        return null;
    };

    /**
     * Converts square feet to square meters
     * @param sqFeet
     * @returns meters squared
     */
    sqFeetToSqMeters = (sqFeet: number) => {
        return sqFeet * 0.092903;
    };

    /**
     * Converts square meters to square feet
     * @param sqMeters
     * @returns feet squared
     */
    sqMetersToSqFeet = (sqMeters: number) => {
        return sqMeters * 10.7639;
    };

    /**
     * Recalculates the area; sets user cookie for preference
     * @param e click event
     * @param sysOfMeasure the selected system of management
     */
    handleSystemUnitChange = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        sysOfMeasure: SystemOfMeasurement,
    ) => {
        e.stopPropagation();
        if (this.state.systemOfMeasurement !== sysOfMeasure) {
            const conversionFn =
                sysOfMeasure === SystemOfMeasurement.imperial
                    ? this.sqMetersToSqFeet
                    : this.sqFeetToSqMeters;
            const measurement = conversionFn(
                this.state.popupInfo.measurement ?? 0,
            );
            this.setState({
                systemOfMeasurement: sysOfMeasure,
                popupInfo: {
                    latitude: 0,
                    longitude: 0,
                    measurement,
                },
            });
            setCookie("area-unit", sysOfMeasure, 30);
        }
    };

    // this weird object returned so map types can chose which controls to display where.
    createControls = (): { [key: string]: ReactNode } => {
        return {
            tl: [            
                
                 <div className={classes.MeasurePopup}>
                 {this.state.popupInfo.measurement != null &&
                 this.state.popupInfo.measurement >=
                     this.measurementSystems[this.state.systemOfMeasurement]
                         .divisor ? (
                     <div className={classes.MeasureItem}>
                         <p>
                             {`Area: 
                                 ${(this.state.popupInfo.measurement
                                     ? this.state.popupInfo.measurement /
                                       this.measurementSystems[
                                           this.state.systemOfMeasurement
                                       ].divisor
                                     : 0
                                 ).toFixed(2)} 
                                 ${
                                     this.measurementSystems[
                                         this.state.systemOfMeasurement
                                     ].unitsShort
                                 }
                             `}
                         </p>
                     </div>
                 ) : (
                     <div className={classes.MeasureItem}>
                         <p>
                             {`Area: 
                                 ${(this.state.popupInfo.measurement
                                     ? this.state.popupInfo.measurement
                                     : 0
                                 ).toFixed(2)} 
                                 ${
                                     this.measurementSystems[
                                         this.state.systemOfMeasurement
                                     ].subUnitsShort
                                 }
                             `}
                         </p>
                     </div>
                 )}
                 <div>
                     <div
                         className={cx(classes.SegmentButton, {
                             [classes.active]:
                                 this.state.systemOfMeasurement ===
                                 SystemOfMeasurement.metric,
                         })}
                         onClick={(event) => {
                             this.handleSystemUnitChange(
                                 event,
                                 SystemOfMeasurement.metric,
                             );
                         }}
                     >
                         Metric
                     </div>
                     <div
                         className={cx(classes.SegmentButton, {
                             [classes.active]:
                                 this.state.systemOfMeasurement ===
                                 SystemOfMeasurement.imperial,
                         })}
                         onClick={(event) => {
                             this.handleSystemUnitChange(
                                 event,
                                 SystemOfMeasurement.imperial,
                             );
                         }}
                     >
                         Imperial
                     </div>
                 </div>
                 <Button
                     onClick={this.handleMeasureReset}
                     size={{ width: "8rem", height: "2.2rem" }}
                     type="neutral"
                 >
                     Reset
                 </Button>
             </div>,
             <div
                className={classes.QuitPopup}
                id={"tourid_ExitMeasureArea"}
                onClick={() => {
                    this.props.setInteractionMode("standard");
                }}
            >
                Click here to exit Measure Area mode <Icon path={mdiClose} />
            </div>,
            ],
            br: [],
            bl: [],
            tr: [
                
            ]
        };
    };

    createPopup = () => {
        return null;
    };

    createContextMenu = () => {
        return null;
    };

    createLayers = () => {
        const pointLayerStyle: LayerProps = {
            id: "measure-point",
            type: "circle",
            paint: {
                "circle-radius": 5,
                "circle-color": getCssVar("--highlight-color"),
            },
        };

        const lineLayerStyle: LayerProps = {
            id: "measure-line",
            type: "line",
            paint: { "line-color": getCssVar("--highlight-color") },
        };

        const polyLayerStyle: LayerProps = {
            id: 'measure-poly',
            type: 'fill',
            paint: {
                "fill-color": getCssVar("--highlight-color"),
                "fill-opacity": 0.8,
            },
        };

        const lngLats = this.state.hoverLngLat
            ? [...this.state.clickedLngLats, this.state.hoverLngLat]
            : [...this.state.clickedLngLats];

        const lineData = multiLineString([lngLats]);
        const pointData = lngLats.map((elem, index) => {
            return point(elem, { id: index });
        });

        let drawLayers = [
            <Source
                key={"additional-1"}
                id="additional-1"
                type="geojson"
                data={featureCollection([lineData])}
            >
                <Layer {...lineLayerStyle} />
            </Source>,
            <Source
                key={"additional-2"}
                id="additional-2"
                type="geojson"
                data={featureCollection(pointData)}
            >
                <Layer {...pointLayerStyle} />
            </Source>,
        ];

        if (this.state.drawState === DRAW_STATE_DISPLAY) {
            const polygonData = polygon([lngLats]);

            drawLayers.push(
                <Source
                    key={"additional-3"}
                    id="additional-3"
                    type="geojson"
                    data={featureCollection([polygonData])}
                >
                    <Layer {...polyLayerStyle} />
                </Source>,
            );
        }
        return drawLayers;
    };

    componentDidMount(): void {
        this.props.updateModeProps(this.getProps());
    }

    componentDidUpdate(
        prevProps: Readonly<InteractionModeMeasureAreaProps>,
        prevState: Readonly<InteractionModeMeasureAreaState>,
        snapshot?: any,
    ): void {
        this.props.updateModeProps(this.getProps());
    }

    getProps(): InteractionModeMapProps {
        const interactions: MapInteractions = {
            handleGetCursor: this.handleGetCursor,
            handleMapOnClick: this.handleMapOnClick,
            handleMapOnHover: this.handleMapOnHover,
            onContextMenu: this.onContextMenu,
        };

        return {
            interactions,
            additionalInteractiveLayerIds: ["measure-point"],
            layers: this.createLayers(),
            popup: this.createPopup(),
            controls: this.createControls(),
            contextMenu: this.createContextMenu(),
        };
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => {
    return {
        setAlert: bindActionCreators(setAlert, dispatch),
        setInteractionMode: bindActionCreators(setInteractionMode, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(InteractionModeMeasureArea);
