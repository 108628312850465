//@ts-nocheck
import React from "react";
import { TutorialConfig } from "./index";
import Icon from "@mdi/react";
import { mdiMIS_legendPoly } from "../icons/paths";
import { mdiFilterOutline, mdiPin } from "@mdi/js";
import { NEW_THEME_COOKIE, getCookie } from "../../utils/Cookies";

const tutorial: TutorialConfig = {
    name: "Intelligence Layers",
    description:
        "An overview of the types of intelligence layers available to you within a report",
    tags: ["layers", "filtering"],
    version: 2.0,
    appConfig: {
        reportId: "",
        components: [
            {
                type: "map",
                options: {
                    mapType: "single",
                    sources: {
                        "0dbg1h130w": {
                            url: "mapbox://mike-mis.3pw2dlgi",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___1-6y0zh2",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.72108093950247, 33.47746009087332,
                                        -89.68570676081177, 33.51349546743882,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        "6krt1hlbcd": {
                            url: "mapbox://mike-mis.47emb53o",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area2_JPEG_YCBCR_EPSG3857__0-850b2s",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.53836562451387, 33.05556452484732,
                                        -90.50645930739002, 33.13707889711214,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        "7h8d3j9kec": {
                            url: "mapbox://mike-mis.06jp75dc",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area2_JPEG_YCBCR_EPSG3857__2-49b7cf",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.50809559485357, 33.054354336628045,
                                        -90.47989046548847, 33.138149852904014,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        "8bn7ebuxgb": {
                            url: "mapbox://mike-mis.4zn2pab4",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___5-a9994u",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.72108093950247, 33.43879717753206,
                                        -89.68309574179834, 33.477460809875794,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        "98c4pb4auy": {
                            url: "mapbox://mike-mis.7fre6nt7",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgba(102,115,131,0.4)",
                                    "Risk of Internal Damage",
                                    "rgba(131,124,97,0.4)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(80,80,80,0.4)",
                                ],
                                "fill-outline-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgb(0,116,255)",
                                    "Risk of Internal Damage",
                                    "rgb(253,196,0)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(136,136,136,0.4)",
                                ],
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Mississippi_Tornado_5x5_Expos-55w4nc",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -91.03104032131196, 32.81029592910344,
                                        -83.12586582106017, 35.156365209490026,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        a4mqq12b4w: {
                            url: "mapbox://mike-mis.9vveg431",
                            type: "raster",

                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area2_JPEG_YCBCR_EPSG3857__1-4eoo3u",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.48135324635484, 33.054354336628045,
                                        -90.45712932034594, 33.138149852904014,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        axzo23pvk1: {
                            url: "mapbox://mike-mis.b08su6pp",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgba(102,115,131,0.4)",
                                    "Risk of Internal Damage",
                                    "rgba(131,124,97,0.4)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(80,80,80,0.4)",
                                ],
                                "fill-outline-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgb(0,116,255)",
                                    "Risk of Internal Damage",
                                    "rgb(253,196,0)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(136,136,136,0.4)",
                                ],
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Mississippi_Tornado_1x1_Claim-0f65ci",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90527618153523, 32.88576448226199,
                                        -88.43490915020655, 34.017491185170485,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        b89vt2q932: {
                            url: "mapbox://mike-mis.aelbrrn5",
                            type: "raster",

                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___4-bd26s6",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.78982426874745, 33.43653919272074,
                                        -89.75080552472537, 33.47650381230386,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        bn190kk6ni: {
                            url: "mapbox://mike-mis.38vbdp34",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgba(102,115,131,0.4)",
                                    "Risk of Internal Damage",
                                    "rgba(131,124,97,0.4)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(80,80,80,0.4)",
                                ],
                                "fill-outline-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgb(0,116,255)",
                                    "Risk of Internal Damage",
                                    "rgb(253,196,0)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(136,136,136,0.4)",
                                ],
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Mississippi_Building_Level_Da-1se6q0",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        cgykefep7i: {
                            url: "mapbox://mike-mis.b6cffiwk",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "RollingFork_JPEG_YCBCR_EPSG38-bo919e",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.88192578207787, 32.87573855341861,
                                        -90.86212906065536, 32.931753744607796,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        eas0gokjws: {
                            url: "mapbox://mike-mis.114l21tx",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "RollingFork_JPEG_YCBCR_EPSG38-7ovexj",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.86302921748124, 32.87596334279747,
                                        -90.8461722108755, 32.931753744607796,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        j4se1bw0ex: {
                            url: "mapbox://mike-mis.7h1yqmwn",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "RollingFork_JPEG_YCBCR_EPSG38-3977hc",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90558704721552, 32.87573855341861,
                                        -90.88158784944889, 32.931416772692366,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        jc799kkjtj: {
                            url: "mapbox://mike-mis.6f40vpin",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Amory_JPEG_YCBCR_EPSG3857_Cli-bfi4dx",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -88.51618000159509, 33.97223816722221,
                                        -88.43760386546629, 34.02000208971503,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        qdbpdsvmbv: {
                            url: "mapbox://mike-mis.0y7jfuze",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___6-3bifsp",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.75170459796108, 33.437869245680474,
                                        -89.71914918195574, 33.477460809875794,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        vzmjas5esp: {
                            url: "mapbox://mike-mis.0lyeng8b",
                            type: "vector",
                            paint: {
                                "line-color": [
                                    "match",
                                    ["get", "Wind Speed"],
                                    "< 65 mph",
                                    "#003bbe",
                                    "65 - 85 mph",
                                    "#0087E9",
                                    "86 - 110 mph",
                                    "#15cb00",
                                    "111 - 135 mph",
                                    "#eefb00",
                                    "136 - 165 mph",
                                    "#fba300",
                                    "166 - 200 mph",
                                    "#fb0000",
                                    "#888",
                                ],
                                "line-width": 3,
                                "line-dasharray": [1, 0.5],
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Mississippi_Preliminary_Track-4d9rjp",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.999299222, 32.84139758700002,
                                        -83.14714371835633, 35.15158775899999,
                                    ],
                                },
                            },
                            layerType: "line",
                            complexPaintProperties: [],
                        },
                        xt5x4awivj: {
                            url: "mapbox://mike-mis.1wxh9y1g",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___2-aky3go",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.75170459796108, 33.47631327433539,
                                        -89.72106628538454, 33.51349546743882,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        ydepz64a3m: {
                            url: "mapbox://mike-mis.5vli2zev",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Amory_JPEG_YCBCR_EPSG3857_Cli-b51uvh",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -88.51618000159509, 33.97223816722221,
                                        -88.43760386546629, 34.02000208971503,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        zsyjbfz0fz: {
                            url: "mapbox://mike-mis.4ms1gt5r",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___0-05bttq",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.7880218122413, 33.47631327433539,
                                        -89.75083741898204, 33.513458094860184,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                    },
                    viewport: {
                        zoom: 9.57174,
                        latitude: 34.03263,
                        longitude: -88.46186,
                    },
                    menuIndex: [
                        {
                            id: "z3lgcs8jvu",
                            type: "group",
                            children: [
                                {
                                    id: "b8rfuggxas",
                                    type: "layer",
                                    layerName: "5km x 5km Exposure Grid",
                                    layerSource: "98c4pb4auy",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "a7yaz9bllm",
                                    type: "layer",
                                    layerName: "Preliminary Tracks",
                                    layerSource: "vzmjas5esp",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                            ],
                            groupName: "Exposure",
                        },
                        {
                            id: "lchwhl2m3i",
                            type: "group",
                            children: [
                                {
                                    id: "866060eayj",
                                    type: "layer",
                                    layerName: "1km x 1km Claims Grid",
                                    layerSource: "axzo23pvk1",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "kxytrkwl8j",
                                    type: "layer",
                                    layerName: "Building Level Damage - Beta",
                                    layerSource: "bn190kk6ni",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                            ],
                            groupName: "Claims",
                        },
                        {
                            id: "8c0tzj03ei",
                            type: "group",
                            asLayer: false,
                            children: [
                                {
                                    id: "nbzcinlwfi",
                                    type: "group",
                                    asLayer: true,
                                    children: [
                                        {
                                            id: "dmeyx3fl7u",
                                            type: "layer",
                                            layerName:
                                                "Rolling Fork Tile 1 - 26 March",
                                            layerSource: "cgykefep7i",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "7rimrn4lpa",
                                            type: "layer",
                                            layerName:
                                                "Rolling Fork Tile 2 - 26 March  ",
                                            layerSource: "eas0gokjws",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "k7auhhr3p8",
                                            type: "layer",
                                            layerName:
                                                "Rolling Fork Tile 3 - 26 March     ",
                                            layerSource: "j4se1bw0ex",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Rolling Fork - 26 March",
                                },
                                {
                                    id: "7k6a6ru4wy",
                                    type: "group",
                                    asLayer: true,
                                    children: [
                                        {
                                            id: "3ld6bnc3qz",
                                            type: "layer",
                                            layerName:
                                                "Amory Tile 1 - 26 March    ",
                                            layerSource: "ydepz64a3m",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "5hyvzhs5ef",
                                            type: "layer",
                                            layerName:
                                                "Amory Tile 2 - 26 March       ",
                                            layerSource: "jc799kkjtj",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Amory - 26 March   ",
                                },
                                {
                                    id: "n369c8mk6",
                                    type: "group",
                                    asLayer: true,
                                    children: [
                                        {
                                            id: "zly57zmcev",
                                            type: "layer",
                                            layerName:
                                                "Silver City Tile 1 - 26 March        ",
                                            layerSource: "7h8d3j9kec",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "rhh9gycbt9",
                                            type: "layer",
                                            layerName:
                                                "Silver City Tile 1 - 26 March         ",
                                            layerSource: "a4mqq12b4w",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "bke7jgbpxo",
                                            type: "layer",
                                            layerName:
                                                "Silver City Tile 1 - 26 March          ",
                                            layerSource: "6krt1hlbcd",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Silver City - 26 March   ",
                                },
                                {
                                    id: "o61pcuzwoh",
                                    type: "group",
                                    asLayer: true,
                                    children: [
                                        {
                                            id: "s2jm1gkq3r",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 1 - 26 March           ",
                                            layerSource: "b89vt2q932",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "r6ibg6bwy1",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 2 - 26 March            ",
                                            layerSource: "8bn7ebuxgb",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "kii6m15sab",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 3 - 26 March             ",
                                            layerSource: "zsyjbfz0fz",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "e09cqb8xis",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 4 - 26 March              ",
                                            layerSource: "qdbpdsvmbv",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "hxl2kxephy",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 5 - 26 March               ",
                                            layerSource: "0dbg1h130w",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "u0t5eo8t9",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 6 - 26 March                ",
                                            layerSource: "xt5x4awivj",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Winona - 26 March    ",
                                },
                            ],
                            groupName: "7.5cm Imagery",
                        },
                    ],
                },
            },
            {
                type: "text",
                options: {
                    content: [
                        {
                            id: "ckymrc01tr",
                            text: "This exposure report delivers intelligence from the ground to users in the immediate aftermath of an event. It provides an initial assessment of the areas likely exposed to the event, and an indication of the likelihood of potential damage. Overlay your data, search for an individual location or download the shapefiles for use in your own system to understand your exposure.\n\n",
                            title: "Welcome",
                        },
                    ],
                },
            },
            {
                type: "summary",
                options: {
                    downloads: {},
                },
            },
        ],
    },
    steps: [
        {
            content: (
                <>
                    <p>Welcome to the Intelligence Layers tutorial</p>
                    <p>
                        The Layers Menu allows you to control how the layers are
                        shown on the map
                    </p>
                </>
            ),
            target: "body",
            disableOverlayClose: true,
            placement: "center",
        },
        {
            content: (
                <>
                    <p>
                        This is where you will find the intelligence layers for
                        a report.
                    </p>
                </>
            ),
            placement: "auto",
            target: "#tourId_LayerBody",
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
        {
            content: (
                <>
                    <h2>Welcome to the Exposure Layers!</h2>
                    <p>
                        This is our 1st iteration of Intelligence at a{" "}
                        <b>5km x 5km (3 miles)</b> level, within <b>24 hours</b>
                        .
                    </p>
                    <p>
                        This is an indication of the most <b>severe damage</b>{" "}
                        in each area based on early available data sources.
                    </p>
                </>
            ),
            target: "#touridLayerGroupExposure",
            placement: "left",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <h2>Layer Visibility!</h2>
                    <p>
                        Layer visibility can be toggled on and off using the
                        toggle on the left of the layer name.
                    </p>
                    <p>
                        Try toggling the visibility for the "Preliminary Tracks"
                        and "5km x 5km Exposure Grid" layers, and notice how it
                        affects the map.
                    </p>
                    <p>
                        Once you've finished, leave the "5km x 5km Exposure
                        Grid" layer visible and click next.
                    </p>
                </>
            ),
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <p>Let's take a look at filtering </p>
                    <p>
                        To do this, click on the{" "}
                        <Icon path={mdiMIS_legendPoly} size={1.5} /> Icon and
                        open up the "5km x 5km Exposure Grid" layer.
                    </p>
                </>
            ),
            target: "#tourIdlayer5km",
            placement: "left",
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            hideBackButton: true,
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <h2>Filtering data on the layer</h2>
                    <Icon path={mdiFilterOutline} size={1.5} />
                    <p>
                        The filter icon allows you to filter the data you see on
                        the map
                    </p>
                    <p>You can toggle the filter on and off</p>
                    <p>
                        For example, if you only wanted to see the most severely
                        impacted areas, you have the ability to filter by that
                        classification!
                    </p>
                    <p>
                        In this example, try selecting the "Moderate Damage"
                        filter. This will remove all classifications that don't
                        match Moderate Damage from the map.
                    </p>
                </>
            ),
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <h2>Pin the legend to the map</h2>
                    <p>You can also pin this legend to the map.</p>
                    <p>
                        Do this now by selected the{" "}
                        <Icon path={mdiPin} size={1.5} /> icon.
                    </p>
                    <p>
                        This icon is toggleable, so you can remove the legend
                        from the map by clicking the icon again.
                    </p>
                </>
            ),
            target: "#tourid_Layer",
            placement: "left",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <p>
                        Let's close the Exposure layer group before moving onto
                        learning about the Claims groups.
                    </p>
                </>
            ),
            placement: "auto",
            target: "#touridLayerGroupOpenExposure",
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            hideBackButton: true,
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <h2>Claims Layers</h2>
                    <p>
                        The MIS Claims layer provides a more <b>granular</b>{" "}
                        view based on refined data specific to an event.
                    </p>
                    <p>
                        Our powerful 1km x 1km* layer is swiftly accessible
                        within 72 hours after an event, though delivery times
                        may vary depending on the nature of the event. We then
                        build out this layer iteratively over several days
                        following the event.
                    </p>
                    <p style={{ fontSize: "1.2rem" }}>
                        *Flood Claims layers are at a 50m x 50m level due to the
                        localised nature of a flood event.
                    </p>
                </>
            ),
            displayDelay: 100,
            target: "#touridLayerGroupClaims",
            placement: "left",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <h2>Viewing data side-by-side</h2>
                    <p>
                        It can be useful to view this data side-by-side on a
                        slidermap view. Let's learn how to do this!
                    </p>
                    <p>To start, let's open up the map layout options.</p>
                </>
            ),
            target: "#tourid_MapLayout",
            placement: "left",
            disableOverlayClose: true,
            spotlightClicks: true,
            hideBackButton: true,
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            displayDelay: 100,
            content: (
                <>
                    <p>Let's now set the basemap view to "Slidermap"</p>
                </>
            ),
            target: "#tourid_MapLayoutSlider",
            placement: "right",
            disableOverlayClose: true,
            spotlightClicks: true,
            hideBackButton: true,
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <p>
                        First, let's place the 1km x 1km Claims grid on the left
                        hand side of the map.
                    </p>
                    <p>
                        Click the ... icon on the right of the 1km x 1km layer
                        name
                    </p>
                    <p>Then select "Left" in the context menu.</p>
                    <p>
                        When you are done click <b>Next</b> below.
                    </p>
                </>
            ),
            target: "#touridLayerGroupClaims",
            placement: "left",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <p>
                        Great! Now let's place an exposure layer on the right
                        side of the map.
                    </p>
                    <p>Open up the Exposure Group.</p>
                </>
            ),
            target: "#touridLayerGroupExposure",
            placement: "left",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <p>
                        Now let's place the 5km x 5km Exposure Grid on the right
                        hand side of the map.
                    </p>
                    <p>Click the ... icon, and select "Right"</p>
                </>
            ),
            target: "#touridLayerGroupExposure",
            placement: "left",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <p>
                        Notice how now you can see one layer on the left, and a
                        different layer on the right side of the map!
                    </p>

                    <p>Use the slider to view the two layers independently</p>
                </>
            ),
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            content:
                "This concludes our overview of Intelligence Layers in GEO",
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            disableBeacon: true,
            disableOverlay: true,
            spotlightClicks: true,
            styles: {
                spotlight: {
                    outlineColor: "transparent",
                },
            },
        },
    ],
    imageUrl: `https://geoassets.blob.core.windows.net/${
        import.meta.env.VITE_ENVIRONMENT
    }/intelligencelayers_lc_bg_${getCookie(NEW_THEME_COOKIE) ?? "light"}.png`,
};

export default tutorial;
