//@ts-nocheck
import React from "react";
import { TutorialConfig } from "./index";
import TutorialBaseConfig from "assets/configs/tutorial_template_config";
import { NEW_THEME_COOKIE, getCookie } from "../../utils/Cookies";

const tutorial: TutorialConfig = {
    name: "Map Tools",
    description: "How to use the map tools.",
    tags: ["maps"],
    version: 2.0,
    steps: [
        {
            content: "Welcome to the map tools tutorial",
            target: "body",
            placement: "center",
            disableOverlayClose: true,
        },
        {
            content:
                "We are going to look at the options available to you in the map toolbar.",
            placement: "right",
            target: "#tourid_Toolbar",
            disableOverlayClose: true,
        },
        {
            content: (
                <>
                    <p>
                        First, we will look at how to measure a distance on the
                        map.
                    </p>
                    <p>Click the measuring tape.</p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            target: "#tourid_drawLine",
            placement: "left",
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <p>
                        Try measuring the distance between two points on your
                        map by clicking in two different places.
                    </p>
                    <p>
                        Your results will be displayed in the top-left of the
                        map.
                    </p>
                    <p>Click next when you are ready.</p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            target: "#tourid_MapComponentContainer",
            placement: "auto",
        },
        {
            content: (
                <>
                    <p>To exit the measure area tool click this alert.</p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            target: "#tourid_ExitMeasureDistance",
            placement: "bottom",
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <p>
                        The area tool allows you to draw a shape and measure its
                        area.
                    </p>
                </>
            ),
            displayDelay: 100,
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            target: "#tourid_drawPoly",
            placement: "left",
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <p>Try drawing a triangle.</p>
                    <p>
                        Your results will, again, be in the display in the
                        top-left of the map.
                    </p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            target: "#tourid_MapComponentContainer",
            placement: "auto",
        },
        {
            content:
                "You can close a tool by clicking on the popup at the top of the map view.",
            target: "#tourid_ExitMeasureArea",
            placement: "bottom",
            targetTriggerEvent: "click",
            spotlightClicks: true,
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: "This concludes our overview of using the tools in GEO",
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            disableBeacon: true,
            disableOverlay: true,
            spotlightClicks: true,
            styles: {
                spotlight: {
                    outlineColor: "transparent",
                },
            },
        },
    ],
    appConfig: TutorialBaseConfig,
    imageUrl: `https://geoassets.blob.core.windows.net/${
        import.meta.env.VITE_ENVIRONMENT
    }/tools_lc_bg_${getCookie(NEW_THEME_COOKIE) ?? "light"}.png`,
};

export default tutorial;
