import { makeActionWithPayload } from "utils/ReduxHelpers";
import {
    REGISTER_ANALYTICS_EVENT,
    REGISTER_PAGE_CHANGE,
    SET_MATOMO_INSTANCE,
    SetMatomoInstancePayload,
    RegisterAnalyticsEventPayload,
    RegisterPageViewPayload,
} from "./matomoTypes";

export const setMatomoInstance = makeActionWithPayload<
    typeof SET_MATOMO_INSTANCE,
    SetMatomoInstancePayload
>(SET_MATOMO_INSTANCE);
export const registerPageChange = makeActionWithPayload<
    typeof REGISTER_PAGE_CHANGE,
    RegisterPageViewPayload
>(REGISTER_PAGE_CHANGE);
export const registerAnalyticsEvent = makeActionWithPayload<
    typeof REGISTER_ANALYTICS_EVENT,
    RegisterAnalyticsEventPayload
>(REGISTER_ANALYTICS_EVENT);
