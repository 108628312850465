//@ts-nocheck
import React from "react";
import { TutorialConfig } from "./index";
import TutorialBaseConfig from "assets/configs/tutorial_template_config";
import { Link } from "react-router-dom";
import { getCookie, NEW_THEME_COOKIE } from "../../utils/Cookies";

const tutorial: TutorialConfig = {
    name: "Data Upload",
    description:
        "This tutorial covers data upload functionality which allows you to input your own data into the system.",
    tags: ["data"],
    version: 2.0,
    steps: [
        {
            content: "Welcome to the Data Upload tutorial",
            target: "body",
            placement: "center",
        },
        {
            content: (
                <>
                    <p>
                        To start, you will find the data upload button on the
                        map's tool bar.
                    </p>
                    <p>Click it now.</p>
                </>
            ),
            placement: "auto",
            target: "#tourid_DataUpload",
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            hideBackButton: true,
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <p>This will open the file upload window.</p>
                    <p>You have two ways to upload files onto the map.</p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: false,
            disableOverlayClose: true,
            target: "#tourid_DragAndDropContainer",
            hideBackButton: true,
            displayDelay: 100,
        },
        {
            content: (
                <>
                    <h3>The "Select a file" button.</h3>
                    <p>
                        This will open a standard file explorer dialog on your
                        computer.
                    </p>
                </>
            ),
            target: "#tourid_DragAndDropFileInput",
            spotlightClicks: false,
            disableOverlayClose: true,
            locale: {
                skip: <Link to={"/learningcentre"}>Exit Tutorial</Link>,
            },
        },
        {
            content: (
                <>
                    <h3>The drag and drop zone.</h3>
                    <p>
                        Simply select the file on your computer and drag it into
                        the highlighted area.
                    </p>
                </>
            ),
            target: "#tourid_DragAndDropDropZone",
            spotlightClicks: false,
            disableOverlayClose: true,
        },
        {
            content: (
                <>
                    <h3>File Types</h3>
                    <p>
                        The following file types can be uploaded: .csv, .geojson
                    </p>
                </>
            ),
            target: "#tourid_DragAndDropDropZone",
            spotlightClicks: false,
            disableOverlayClose: true,
        },
        {
            content:
                "This concludes our overview of uploading your own data to GEO",
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            disableBeacon: true,
            disableOverlay: true,
            spotlightClicks: true,
            styles: {
                spotlight: {
                    outlineColor: "transparent",
                },
            },
        },
    ],
    appConfig: TutorialBaseConfig,
    imageUrl: `https://geoassets.blob.core.windows.net/${
        import.meta.env.VITE_ENVIRONMENT
    }/dataupload_lc_bg_${getCookie(NEW_THEME_COOKIE) ?? "dark"}.png`,
};

export default tutorial;
