import { AppConfig } from "../store/system/systemTypes";
import { genericAPIResponse } from "./genericResponse";

export interface ReportSchema {
    id: string;
    event_id: string;
    is_published: boolean;
    created_at: number;
    updated_at: number;
}

export type ReportSchemaBase = Omit<ReportSchema, "id" | "created_at">;

export const reportUpdate = async (
    token: string,
    reportId: string,
    reportUpdates: Partial<ReportSchema>,
) => {
    let res = await fetch(
        `${import.meta.env.VITE_API_ROOT}/events/reports/${reportId}`,
        {
            method: "PATCH",
            body: JSON.stringify(reportUpdates),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        },
    );

    return (await res.json()) as genericAPIResponse<ReportSchema[]>;
};

export const reportPublishRunInsights = async (
    token: string,
    reportId: string,
    reportUpdates: Partial<ReportSchema>,
) => {
    let res = await fetch(
        `${import.meta.env.VITE_API_ROOT}/events/reports/${reportId}/publish`,
        {
            method: "PATCH",
            body: JSON.stringify(reportUpdates),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        },
    );

    return (await res.json()) as genericAPIResponse<ReportSchema[]>;
};

export const configCreate = async (
    token: string,
    eventId: string,
    reportConfig: AppConfig,
) => {
    let res = await fetch(
        `${import.meta.env.VITE_API_ROOT}/events/${eventId}/reports/configs`,
        {
            method: "POST",
            body: JSON.stringify(reportConfig),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        },
    );

    return (await res.json()) as genericAPIResponse<ReportSchema[]>;
};

export const reportGetAllByEventId = async (token: string, eventId: string) => {
    let res = await fetch(
        `${import.meta.env.VITE_API_ROOT}/events/${eventId}/reports`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );

    return (await res.json()) as genericAPIResponse<ReportSchema[]>;
};

export const configGetByReportId = async (token: string, reportId: string) => {
    let res = await fetch(
        `${import.meta.env.VITE_API_ROOT}/events/reports/${reportId}/configs`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    ).catch((e) => {
        console.log(e);
    });

    if (!res) {
        return { detail: "Failed to fetch" };
    }

    return (await res.json()).data;
};

export const configGetLatestByEventId = async (
    token: string,
    eventId: string,
    published: boolean = true,
) => {
    let res = await fetch(
        `${import.meta.env.VITE_API_ROOT
        }/events/${eventId}/reports/configs/by_latest${published ? "?published=True" : ""
        }`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    ).catch((e) => {
        console.log(e);
    });

    if (!res) {
        return { detail: "Failed to fetch" };
    }

    return (await res.json()).data;
};
