/**
 * Layer List item is used in the layer tab component and links the layer displayed in map with its options.
 */

import React, { MouseEvent, ReactNode, RefObject } from "react";
import { bindActionCreators, Dispatch } from "redux";
import * as MapboxGL from "mapbox-gl";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { mdiArrowUpDownRightBold } from "assets/icons/paths";
import { mdiClose, mdiFilterOff, mdiTable } from "@mdi/js";
import cx from "classnames";
import Icon from "@mdi/react";
import { mdiDotsHorizontal, mdiEyeOutline } from "@mdi/js/commonjs/mdi";

import { RootState } from "store/store";
import { LayerType } from "types/mapbox-types";
import {
    ConfigSource,
    LayerActions,
    MapCenter,
    Visibility,
} from "store/map/mapTypes";
import {
    setCenter,
    setLayerFilter,
    setLayerView,
    setLayerVisibility,
    setLegendPopup,
} from "store/map/mapActions";
import Legend from "../../../../Legend/Legend";
import LayerIcon from "../../../../LayerIcon/LayerIcon";
import { Dict } from "types/misgis";
import { isStringArray, isZoomToBBox } from "utils/TypeGuards";
import ScrollableText from "../../../../../../../../_Library/ScrollableText/ScrollableText";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";

import { getCssVar } from "utils/CSSHelpers";
import classes from "./LayerListItem.module.css";
import Modal from "../../../../../../../../_Library/Modal/Modal";
import { MapRef, ViewportProps, WebMercatorViewport } from "react-map-gl";
import { registerAnalyticsEvent } from "store/matomo/matomoActions";
import { toggleDamageLabelFilter } from "store/insights/insightsActions";
import Toggle from "../../../../../../../../_Library/Inputs/Toggle/Toggle";
import { CustomLayerTable } from "./CustomLayerTable/CustomLayerTable";
import { Badges } from "components/_Library/Badges/Badges";

export interface OwnProps {
    id: string;
    layerName: string; // used to display the layers name in the list
    sourceName: string; // used to reference the layer in the mapconfig when toggling vis.
    visibility: Visibility; // used to style the checkbox on/off
    viewOn: "left" | "both" | "right"; // used to style the layer view selector
    paint: MapboxGL.AnyPaint; // used to style the layer icon
    layout: MapboxGL.AnyLayout;
    layerType: LayerType; // used to style the layer icon
    complexPaintProperties: Array<keyof MapboxGL.AnyPaint>;
    actions?: LayerActions;
    isCustomLayer: boolean;
    isFiltered: boolean;
    showAccessBadges?: boolean;
    beta?: boolean;
    tier?: string;
}

interface DispatchProps {
    setLayerVisibility: typeof setLayerVisibility;
    setLayerView: typeof setLayerView;
    setCenter: typeof setCenter;
    setLegendPopup: typeof setLegendPopup;
    setLayerFilter: typeof setLayerFilter;
    toggleDamageLabelFilter: typeof toggleDamageLabelFilter;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
}

interface StateProps {
    highlightedLayer: { layerName: string; sourceName: string } | null;
    legendPopup: { layerName: string; sourceName: string } | null;
    sources: Dict<any>;
    viewport: ViewportProps;
    mapRef: RefObject<MapRef>;
}

type LayerListItemProps = OwnProps & DispatchProps & StateProps;

interface LayerListItemState {
    legendOpen: boolean;
    contextMenuOpen: boolean;
    geoDataTableActive: boolean;
    values: string[] | string[][];
}

class LayerListItem extends React.Component<
    LayerListItemProps,
    LayerListItemState
> {
    state: LayerListItemState = {
        legendOpen: false,
        contextMenuOpen: false,
        geoDataTableActive: false,
        values: [],
    };

    toggleLayerVisibility = () => {
        this.props.setLayerVisibility({
            sourceName: this.props.sourceName,
            layerName: this.props.layerName,
            visibility:
                this.props.visibility === "visible" ? "none" : "visible",
        });

        this.props.registerAnalyticsEvent({
            category: "Layers",
            action: this.props.layerName + " visibility toggled",
        });
    };

    handleZoomToLayer = () => {
        let mapCenter: MapCenter = {
            latitude: 0,
            longitude: 0,
            zoom: 0,
        };
        let zoomTo = this.props.actions?.zoomTo;

        if (isZoomToBBox(zoomTo)) {
            const bbox = zoomTo.bbox;
            const container: HTMLElement = this.props.mapRef.current
                ?.getMap()
                .getContainer();
            const webMercatorViewport = new WebMercatorViewport({
                width: container?.offsetWidth || 800,
                height: container?.offsetHeight || 600,
            });
            let { zoom, latitude, longitude } = webMercatorViewport.fitBounds([
                [bbox[0], bbox[1]],
                [bbox[2], bbox[3]],
            ]);
            mapCenter.zoom = zoom;
            mapCenter.latitude = latitude;
            mapCenter.longitude = longitude;
        }

        this.props.setCenter(mapCenter);
        this.setState({
            contextMenuOpen: false,
        });
    };

    handleViewSelection = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.setLayerView({
            layerName: this.props.layerName,
            sourceName: this.props.sourceName,
            viewOn: (event.target as Element).innerHTML.toLowerCase() as
                | "left"
                | "right"
                | "both",
        });
        this.setState({
            contextMenuOpen: false,
        });
    };

    renderContextMenu = () => {
        let ContextMenu: ReactNode[] = [];
        if (this.props?.actions) {
            ContextMenu = Object.keys(this.props.actions).map((action) => {
                switch (action) {
                    case "zoomTo":
                        return (
                            <div
                                onClick={this.handleZoomToLayer}
                                className={classes.ContextItem}
                                key={action}
                            >
                                <Icon path={mdiArrowUpDownRightBold} /> Go to
                            </div>
                        );
                    default:
                        return null;
                }
            });
        }

        ContextMenu.push(
            <div className={cx(classes.ContextItem, classes.LayerViewToggle)}>
                <Icon path={mdiEyeOutline} />
                <span
                    onClick={this.handleViewSelection}
                    className={cx({
                        [classes.Active]: this.props.viewOn === "left",
                    })}
                >
                    Left
                </span>
                <span
                    onClick={this.handleViewSelection}
                    className={cx({
                        [classes.Active]: this.props.viewOn === "both",
                    })}
                >
                    Both
                </span>
                <span
                    onClick={this.handleViewSelection}
                    className={cx({
                        [classes.Active]: this.props.viewOn === "right",
                    })}
                >
                    Right
                </span>
            </div>,
        );
        return ContextMenu;
    };

    closeModal = () => {
        this.setState({
            geoDataTableActive: false,
        });
    };

    renderTable = (sourceName: string, layerName: string) => {
        return (
            <Modal closeModal={this.closeModal} width={90}>
                <CustomLayerTable
                    sourceName={sourceName}
                    layerName={layerName}
                />
            </Modal>
        );
    };

    toggleLegendPopup = () => {
        if (
            this.props.legendPopup === null ||
            this.props.layerName !== this.props.legendPopup.layerName
        ) {
            this.props.setLegendPopup({
                sourceName: this.props.sourceName,
                layerName: this.props.layerName,
            });
        } else {
            this.props.setLegendPopup(null);
        }
    };

    rebuildRanges = (
        processedExistingFilters: string[],
        arrayOfSteps: string[],
    ) => {
        let reinstatedRanges: string[][] = [];
        processedExistingFilters.forEach((endRangeNumber: string) => {
            let range = [endRangeNumber];
            let indexOfEndRangeNumber = arrayOfSteps.indexOf(endRangeNumber);
            let startRangeNumber = arrayOfSteps[indexOfEndRangeNumber - 1];
            range.unshift(startRangeNumber);
            reinstatedRanges.push(range);
        });
        return reinstatedRanges;
    };

    matchClickedLabelToSelectedLabels = (
        selectedLegendRowLabels: string[],
        clickedLabel: string,
    ): string[] => {
        if (selectedLegendRowLabels.includes(clickedLabel)) {
            selectedLegendRowLabels = selectedLegendRowLabels.filter(
                (existing: string) => existing !== clickedLabel,
            );
        } else {
            selectedLegendRowLabels.push(clickedLabel);
        }
        return selectedLegendRowLabels;
    };

    toggleFilter(
        label: string,
        isInterpolated: boolean,
        arrayOfSteps: string[],
    ) {
        let layerSource: ConfigSource =
            this.props.sources[this.props.sourceName];
        if (isInterpolated) {
            let arrayOfArrays =
                this.props.sources[this.props.sourceName].customFilter!.values;
            let processedExistingFilters = arrayOfArrays.map(
                ([a, b]: [string, string]) => b,
            );
            let interimFilters = this.matchClickedLabelToSelectedLabels(
                processedExistingFilters,
                label,
            );
            return this.rebuildRanges(interimFilters, arrayOfSteps);
        } else {
            if (isStringArray(layerSource.customFilter!.values)) {
                return this.matchClickedLabelToSelectedLabels(
                    [...layerSource.customFilter!.values],
                    label,
                );
            } else {
                //if array empty
                return [label];
            }
        }
    }

    setNewFilter = (
        label: string,
        featureProperty: string,
        arrayOfSteps: string[],
        isInterpolated: boolean,
    ) => {
        let layerSource: Dict<any> = this.props.sources[this.props.sourceName];
        let appliedFilters: string[] | string[][] = [];
        let value = "";
        if (
            layerSource.customFilter &&
            layerSource.customFilter.identifier === featureProperty
        ) {
            //if there is already a filter set for eg population
            appliedFilters = this.toggleFilter(
                label,
                isInterpolated,
                arrayOfSteps,
            );
        } else {
            appliedFilters = isInterpolated
                ? this.rebuildRanges([label], arrayOfSteps)
                : [label];
        }
        this.props.setLayerFilter({
            sourceName: this.props.sourceName,
            customFilter: {
                identifier: featureProperty,
                values: appliedFilters,
            },
        });

        this.setState({
            values: appliedFilters.length ? appliedFilters : value.split(","),
        });
    };

    clearFilter = () => {
        if (this.props.isFiltered) {
            this.props.setLayerFilter({
                sourceName: this.props.sourceName,
                customFilter: { identifier: null, values: [] },
            });

            this.setState({
                values: [],
            });
        }
    };

    closeContextMenu = () => {
        this.setState({ contextMenuOpen: false });
        document.removeEventListener("click", this.closeContextMenu);
    };

    toggleContextMenu = (e: MouseEvent) => {
        this.setState(({ contextMenuOpen }) => {
            if (!contextMenuOpen) {
                document.addEventListener("click", this.closeContextMenu);
            }
            return { contextMenuOpen: !contextMenuOpen };
        });
        e.stopPropagation();
    };


    render(): ReactNode {
        let complexLegend: boolean =
            this.props.complexPaintProperties.length !== 0;
        let selectedLayerName =
            this.props.highlightedLayer !== null
                ? this.props.highlightedLayer.layerName
                : null;
        let highlight = this.props.layerName === selectedLayerName;
        let customFilter;
        customFilter = this.props.sources[this.props.sourceName].customFilter;

        let tourId = this.props.layerName.replace(/ .*/, ""); //Take the first word from a layer name, for use on ID
        return (
            <div
                id={this.props.id}
                className={cx(
                    classes.LayerItem,
                    { [classes.LegendOpen]: this.state.legendOpen },
                    { [classes.Highlight]: highlight },
                )}
            >
                {this.state.geoDataTableActive &&
                    this.renderTable(
                        this.props.sourceName,
                        this.props.layerName,
                    )}
                <div className={classes.Header}>
                    <div className={cx(classes.ToggleTitle, {[classes.ToggleTitleWithBadges]: (this.props.beta || this.props.tier !== "basic")})}>
                        <span
                            data-tip={"Toggle Layer Visibility"}
                            data-for={"LayerVisibility"}
                            className={classes.VisibilityIcon}
                        >
                            <Toggle
                                heightRem={"1.5rem"}
                                active={this.props.visibility === "visible"}
                                onClick={this.toggleLayerVisibility}
                            />
                            <ReactTooltip
                                id={"LayerVisibility"}
                                place={"left"}
                                effect={"solid"}
                            />
                        </span>
                        <span className={classes.Label}>
                            <ScrollableText text={this.props.layerName} />
                        </span>
                    </div>
                    
                    <div className={classes.Icons}>
                        {(this.props.beta || this.props.tier !== "basic") && 
                            <Badges beta={this.props.beta} tier={this.props.tier}/>
                        }
                        
                        {this.props.isFiltered && (
                            <div
                                className={classes.LayerMenu}
                                onClick={this.clearFilter}
                                data-for={"LayerItemTooltip"}
                                data-tip={`Remove Filter`}
                            >
                                <Icon
                                    path={mdiFilterOff}
                                    color={getCssVar(
                                        this.props.isFiltered
                                            ? "--highlight-color"
                                            : "--secondary-color",
                                    )}
                                />
                                <ReactTooltip
                                    id={"LayerItemTooltip"}
                                    place={"top"}
                                    effect={"solid"}
                                    delayShow={300}
                                />
                            </div>
                        )}

                        {this.props.isCustomLayer && (
                            <div
                                className={classes.LayerMenu}
                                onClick={() => {
                                    this.setState(({ geoDataTableActive }) => ({
                                        geoDataTableActive: !geoDataTableActive,
                                    }));
                                }}
                                data-for={"LayerItemTooltip"}
                                data-tip={`Show in table`}
                            >
                                <Icon
                                    path={mdiTable}
                                    color={getCssVar(
                                        this.state.geoDataTableActive
                                            ? "--highlight-color"
                                            : "--text-color",
                                    )}
                                />
                                <ReactTooltip
                                    id={"LayerItemTooltip"}
                                    place={"top"}
                                    effect={"solid"}
                                    delayShow={300}
                                />
                            </div>
                        )}
                        
                        <div
                            className={classes.LayerIcon}
                            id={"tourIdlayer" + tourId}
                            onClick={
                                complexLegend
                                    ? (e) => {
                                        this.setState(({ legendOpen }) => ({
                                            legendOpen: !legendOpen,
                                        }));
                                        this.props.registerAnalyticsEvent({
                                            category: "Layers",
                                            action:
                                                this.props.layerName +
                                                " legend toggled",
                                        });
                                        e.preventDefault();
                                    }
                                    : undefined
                            }
                        >
                            <LayerIcon
                                complexLegend={complexLegend}
                                paint={this.props.paint}
                                type={this.props.layerType}
                            />
                        </div>

                        <div
                            className={classes.LayerMenu}
                            onClick={this.toggleContextMenu}
                        >
                            <Icon
                                path={
                                    this.state.contextMenuOpen
                                        ? mdiClose
                                        : mdiDotsHorizontal
                                }
                                color={getCssVar(
                                    this.state.contextMenuOpen
                                        ? "--highlight-color"
                                        : "--text-color",
                                )}
                            />
                            {this.state.contextMenuOpen && (
                                <div className={classes.ContextMenu}>
                                    {this.renderContextMenu()}
                                </div>
                            )}
                        </div>
                       
                    </div>
                    
                </div>
                {complexLegend && (
                    <div className={cx(classes.LegendContainer)}>
                        <Legend
                            key={this.props.sourceName}
                            paint={this.props.paint}
                            layout={this.props.layout}
                            complexPaintProperties={
                                this.props.complexPaintProperties
                            }
                            type={this.props.layerType}
                            toggleLegendPopup={this.toggleLegendPopup}
                            layerName={this.props.layerName}
                            legendPopup={this.props.legendPopup!}
                            parent="layerListItem"
                            filterClick={this.setNewFilter}
                            isFiltered={this.props.isFiltered}
                            customFilter={customFilter}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    highlightedLayer: getStoreAtNamespaceKey(state, "map").highlightedLayer,
    legendPopup: getStoreAtNamespaceKey(state, "map").legendPopup,
    sources: getStoreAtNamespaceKey(state, "map").mapConfig.sources,
    viewport: getStoreAtNamespaceKey(state, "map").mapConfig.viewport,
    mapRef: getStoreAtNamespaceKey(state, "ref").mapRef,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLayerVisibility: bindActionCreators(setLayerVisibility, dispatch),
    setCenter: bindActionCreators(setCenter, dispatch),
    setLayerView: bindActionCreators(setLayerView, dispatch),
    setLegendPopup: bindActionCreators(setLegendPopup, dispatch),
    setLayerFilter: bindActionCreators(setLayerFilter, dispatch),
    toggleDamageLabelFilter: bindActionCreators(
        toggleDamageLabelFilter,
        dispatch,
    ),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerListItem);
