import { Reducer } from "redux";
import {
    MatomoActionTypes,
    MatomoState,
    REGISTER_ANALYTICS_EVENT,
    RegisterAnalyticsEventAction,
    REGISTER_PAGE_CHANGE,
    RegisterPageChangeAction,
    SET_MATOMO_INSTANCE,
    SetMatomoInstanceAction,
    RegisterAnalyticsEventPayload,
} from "./matomoTypes";
import {
    SET_DASHBOARD_VIEW,
    SetDashboardViewAction,
    SystemActionTypes,
} from "../system/systemTypes";
import {
    MapActionTypes,
    TOGGLE_LOCATION_LABELS,
    ToggleLocationLabelsAction,
} from "../map/mapTypes";

const initState: MatomoState = {
    matomoInstance: null,
    pageTitle: null,
};

export const matomoReducer: Reducer<
    MatomoState,
    SystemActionTypes | MatomoActionTypes | MapActionTypes
> = (state = initState, action): MatomoState => {
    switch (action.type) {
        // Action hooks from other reducers
        case SET_DASHBOARD_VIEW:
            return Reduce_setDashboardView(state, action);
        case TOGGLE_LOCATION_LABELS:
            return Reduce_toggleLocationLabel(state, action);

        // Matomo Reducer Specific Actions
        case SET_MATOMO_INSTANCE:
            return Reduce_setMatomoInstance(state, action);
        case REGISTER_PAGE_CHANGE:
            return Reduce_registerPageChange(state, action);
        case REGISTER_ANALYTICS_EVENT:
            return Reduce_analyticsEvent(state, action);
        default:
            return state;
    }
};

const Reduce_toggleLocationLabel = (
    state = initState,
    action: ToggleLocationLabelsAction,
): MatomoState => {
    state.matomoInstance?.trackEvent({
        category: "Location Label Toggle",
        action: action.payload ? "on" : "off",
    } as RegisterAnalyticsEventPayload);

    return state;
};

const Reduce_setDashboardView = (
    state = initState,
    action: SetDashboardViewAction,
): MatomoState => {
    state.matomoInstance?.trackEvent({
        category: "Report View Navigation",
        action: action.payload.view,
    } as RegisterAnalyticsEventPayload);

    return state;
};

const Reduce_setMatomoInstance = (
    state = initState,
    action: SetMatomoInstanceAction,
): MatomoState => {
    return { ...state, matomoInstance: action.payload.matomoInstance };
};
const Reduce_registerPageChange = (
    state = initState,
    action: RegisterPageChangeAction,
): MatomoState => {
    document.title = action.payload.title || "GEO";

    state.matomoInstance?.trackPageView({
        href: action.payload.href || "",
        documentTitle: action.payload.title,
    });

    return { ...state, pageTitle: action.payload.title };
};

const Reduce_analyticsEvent = (
    state = initState,
    action: RegisterAnalyticsEventAction,
): MatomoState => {
    state.matomoInstance?.trackEvent({
        ...action.payload,
    });

    return state;
};
