//@ts-nocheck
import React from "react";

import { TutorialConfig } from "assets/tutorials/index";
import { NEW_THEME_COOKIE, getCookie } from "../../utils/Cookies";

const tutorial: TutorialConfig = {
    appConfig: {
        reportId: "",
        timelineConfig: {
            range: {
                max: "26 Jun 2023",
                min: "01 Jun 2023",
                format: "DD MMM YYYY",
            },
            hoursPerUpdate: 24,
            secondsPerUpdate: 1,
        },
        components: [
            {
                type: "map",
                options: {
                    mapType: "single",
                    sources: {
                        gzndomhqik: {
                            url: "mapbox://mike-mis.dr65er64",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "CONFIDENCE"],
                                    "High",
                                    "#e53935",
                                    "Nominal",
                                    "#ff9800",
                                    "Low",
                                    "#ffeb3b",
                                    "#888",
                                ],
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Quebec_VIIRS_20230601-0625_ti-1s3tie",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -79.8296863280835, 45.111206770267096,
                                        -65.8993244828671, 54.89139054133183,
                                    ],
                                },
                            },
                            layerType: "fill",
                            dataConfig: {
                                timeline: {
                                    data: {
                                        type: "internal",
                                        format: "X",
                                        dateColumnName: "date_from",
                                        dateToColumnName: "date_to",
                                    },
                                    type: "filter-between",
                                },
                            },
                            complexPaintProperties: [],
                        },
                        z55nyuv223: {
                            url: "mapbox://mike-mis.8t22nzg7",
                            type: "vector",
                            paint: {
                                "fill-color": "#e53935",
                                "fill-opacity": 0.4,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Quebec_NRCAN_Perimeters_20230-4qlk6c",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -79.83350228121559, 46.53505375713519,
                                        -65.80657327957017, 54.890660928850245,
                                    ],
                                },
                            },
                            layerType: "fill",
                            dataConfig: {
                                timeline: {
                                    data: {
                                        type: "internal",
                                        format: "X",
                                        dateColumnName: "date_from",
                                        dateToColumnName: "date_to",
                                    },
                                    type: "filter-between",
                                },
                            },
                            complexPaintProperties: [],
                        },
                    },
                    viewport: {
                        zoom: 6,
                        latitude: 50.072,
                        longitude: -71.242,
                    },
                    menuIndex: [
                        {
                            id: "1i11k7xot",
                            type: "group",
                            asLayer: false,
                            children: [
                                {
                                    id: "keiatrl9tt",
                                    type: "layer",
                                    layerName:
                                        " Observed Hotspots - 01 Jun to 25 Jun 2023",
                                    layerSource: "gzndomhqik",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "6hu22d06c",
                                    type: "layer",
                                    layerName:
                                        " Fire Perimeters - 06 Jun to 26 Jun 2023",
                                    layerSource: "z55nyuv223",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                            ],
                            groupName: "Exposure",
                        },
                    ],
                },
            },
            {
                type: "text",
                options: {
                    content: [
                        {
                            id: "w7p9zao3lu",
                            text: "This exposure report delivers intelligence from the ground to users in the immediate aftermath of an event. It provides an initial assessment of the areas likely exposed to the event. Overlay your data, search for an individual location or download the shapefiles for use in your own system to understand your exposure.",
                            title: "Welcome",
                        },
                    ],
                },
            },
            {
                type: "summary",
                options: {
                    downloads: {
                        "Wildfire - Quebec - 26 June 2023":
                            "https://web.tresorit.com/l/GOjGq#BB2CiBkAxmz3nfhDEEzSRA",
                    },
                },
            },
        ],
    },
    name: "Timeline",
    description:
        "This tutorial covers how the timeline affects the data on the map",
    tags: [],
    version: 1.0,
    steps: [
        {
            content: (
                <>
                    <p>Welcome to the Timeline Tutorial.</p>
                    <p>
                        When the map holds time-series data, the timeline will
                        appear.
                    </p>
                </>
            ),
            target: "body",
            placement: "center",
            disableOverlayClose: true,
        },
        {
            content: "The timeline will appear below the map when available.",
            placement: "auto",
            target: "#tourid_TimelineContainer",
            disableOverlayClose: true,
        },
        {
            content: (
                <>
                    <p>You have three ways to interact with the timeline.</p>
                    <h3>The Timeline Track</h3>
                    <p>
                        This allows you to either click, or drag the timeline
                        around with your mouse.
                    </p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: true,
            placement: "auto",
            target: "#tourid_TimelineTrack",
            disableOverlayClose: true,
        },
        {
            content: (
                <>
                    <h3>The Timeline Controls</h3>
                    <p>These allow you to play through the data in series.</p>
                    <p>
                        Or, navigate to the first, previous, next or last update
                        in the timeline.
                    </p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: true,
            disableOverlayClose: true,
            placement: "auto",
            target: "#tourid_TimelineControls",
        },
        {
            content: (
                <>
                    <h3>The Date Picker</h3>
                    <p>
                        This will allow you to navigate to specific date and
                        time within the timeline
                    </p>
                    <p>Click here to up the date picker.</p>
                </>
            ),
            placement: "top",
            disableBeacon: true,
            target: "#tourid_TimelineDatePicker",
            disableOverlayClose: true,
            spotlightClicks: true,
            targetTriggerEvent: "click",
            styles: {
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
                buttonNext: {
                    display: "none",
                },
            },
        },
        {
            content: (
                <>
                    <p>
                        Here you can change the date and time of data to view!
                    </p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: true,
            disableOverlayClose: true,
            placement: "auto",
            target: ".react-datepicker",
        },
        {
            content:
                "This concludes our overview of using the timeline feature in GEO",
            target: "#tourid_MapComponentContainer",
            placement: "auto",
            disableOverlayClose: true,
            disableBeacon: true,
            disableOverlay: true,
            spotlightClicks: true,
            styles: {
                spotlight: {
                    outlineColor: "transparent",
                },
            },
        },
    ],
    imageUrl: `https://geoassets.blob.core.windows.net/${
        import.meta.env.VITE_ENVIRONMENT
    }/timeline_lc_bg_${getCookie(NEW_THEME_COOKIE) ?? "light"}.png`,
};

export default tutorial;
