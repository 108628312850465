import React, { FC, useState } from "react";
import cx from "classnames";
import Icon, { Stack } from "@mdi/react";
import {
    mdiFire,
    mdiInformationOutline,
    mdiLoading,
    mdiWeatherHail,
    mdiWeatherTornado,
} from "@mdi/js";

import moment from "moment/moment";
import {
    mdiMIS_agriculture,
    mdiMIS_butterfly,
    mdiMIS_drought,
    mdiMIS_earthquake,
    mdiMIS_explosion,
    mdiMIS_flood,
    mdiMIS_freeze,
    mdiMIS_hurricane,
    mdiMIS_industrial,
    mdiMIS_riot,
    mdiMIS_sinkhole,
    mdiMIS_storm,
    mdiMIS_volcano,
} from "assets/icons/paths";
import { EventType } from "store/system/systemTypes";
import { registerAnalyticsEvent } from "store/matomo/matomoActions";
import Modal from "components/_Library/Modal/Modal";
import ScrollableText from "../../../../_Library/ScrollableText/ScrollableText";
import { RootState } from "../../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../../store/storeSelectors";
import { useDispatch, useSelector } from "react-redux";
import { EventSchema } from "../../../../../crud/eventsCRUD";

import classes from "./Summary.module.css";
import { useRouteMatch } from "react-router-dom";
import Tutorials from "assets/tutorials";
import { MOMENT_DATE_FORMAT } from "index";
import { setEventType } from "store/map/mapActions";

type SummaryProps = {
    eventId?: string;
    downloadModalOpen: boolean;
};

// The classname can be use to ensure sizing is consistent between the different icons
export const eventTypeToIconPathSwitch: Record<EventType, { path: string; className?: string }> = {
    agriculture: { path: mdiMIS_agriculture },
    drought: { path: mdiMIS_drought },
    earthquake: { path: mdiMIS_earthquake },
    explosion: { path: mdiMIS_explosion },
    fire: { path: mdiFire, className: classes.CustomFire },
    flood: { path: mdiMIS_flood },
    freeze: { path: mdiMIS_freeze },
    hail: { path: mdiWeatherHail },
    hurricane: { path: mdiMIS_hurricane, className: classes.CustomHurricane },
    industrial: { path: mdiMIS_industrial },
    riot: { path: mdiMIS_riot },
    sinkhole: { path: mdiMIS_sinkhole },
    storm: { path: mdiMIS_storm },
    tornado: { path: mdiWeatherTornado },
    volcano: { path: mdiMIS_volcano },
    other: { path: mdiMIS_butterfly },
};

const impactSwitch = {
    low: classes.LowImpact,
    moderate: classes.ModerateImpact,
    high: classes.HighImpact,
};

const Summary: FC<SummaryProps> = (props: SummaryProps) => {
    let dispatch = useDispatch();
    let allEventData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "service").eventData,
    );
    let [infoModalOpen, setInfoModalOpen] = useState(false);
    let currentEventData = allEventData!.find(
        (index) => index.id === props.eventId,
    );
    const eventType = useSelector((state: RootState) => state!.map!.eventType);

    if (currentEventData && currentEventData.type !== eventType) {
        dispatch(setEventType(currentEventData.type));
    }

    let match = useRouteMatch<{ tutorialName: string }>();
    let isTutorial = match.path.includes("/tutorial/");

    const renderInfoModal = (summaryOptions: EventSchema) => {
        const startDate = summaryOptions.start * 1000;
        const endDate = summaryOptions.end ? summaryOptions.end * 1000 : null;
        const lastUpdate = summaryOptions.updated_at * 1000;

        return (
            <Modal
                closeModal={() => setInfoModalOpen(!infoModalOpen)}
                title={`${summaryOptions.name} - Metadata`}
            >
                <div className={classes.SummaryContainer}>
                    <div className={cx(classes.SummaryItem)}>
                        <p className={classes.SummaryTitle}>Type:</p>
                        <p className={classes.Capitalise}>
                            {summaryOptions.type}
                        </p>
                    </div>
                    <div className={classes.SummaryItem}>
                        <p className={classes.SummaryTitle}>Impact:</p>
                        <p className={classes.Capitalise}>
                            {summaryOptions.impact}
                        </p>
                    </div>
                    <div className={classes.SummaryItem}>
                        <p className={classes.SummaryTitle}>Start Date:</p>
                        <p>{moment(startDate).format(MOMENT_DATE_FORMAT)}</p>
                    </div>
                    <div className={classes.SummaryItem}>
                        <p className={classes.SummaryTitle}>End Date:</p>
                        <p>
                            {endDate
                                ? moment(endDate).format(MOMENT_DATE_FORMAT)
                                : "Ongoing"}
                        </p>
                    </div>
                    <div className={classes.SummaryItem}>
                        <p className={classes.SummaryTitle}>Last Updated:</p>
                        <p>{moment(lastUpdate).format(MOMENT_DATE_FORMAT)}</p>
                    </div>
                </div>
            </Modal>
        );
    };

    let renderedElement;

    if (currentEventData && !isTutorial) {
        renderedElement = (
            <div className={classes.Container}>
                <div
                    className={cx(
                        classes.Icon,
                        impactSwitch[currentEventData.impact as keyof typeof impactSwitch],
                    )}
                >
                    <Icon
                        path={eventTypeToIconPathSwitch[currentEventData.type].path}
                    />
                </div>
                <ScrollableText text={currentEventData.name} />
                <div
                    className={cx(classes.Icon, classes.MetadataIcon)}
                    onClick={() => {
                        dispatch(
                            registerAnalyticsEvent({
                                category: "Report",
                                action: "view report info",
                            }),
                        );

                        setInfoModalOpen(!infoModalOpen);
                    }}
                >
                    <Icon path={mdiInformationOutline} />
                </div>
                {infoModalOpen && renderInfoModal(currentEventData)}
            </div>
        );
    } else if (isTutorial) {
        renderedElement = (
            <div className={classes.Container}>
                <ScrollableText
                    text={`${
                        Tutorials[match.params.tutorialName].name
                    } Tutorial`}
                />
            </div>
        );
    } else {
        renderedElement = (
            <div className={classes.Container}>
                <Stack size={1} spin>
                    <Icon path={mdiLoading} />
                    <Icon path={mdiLoading} rotate={180} />
                </Stack>
            </div>
        );
    }

    return renderedElement;
};

export default Summary;
