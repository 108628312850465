import { unsuccessfulAPIResponse } from "../crud/genericResponse";
import { MapCenter, ZoomToBBox } from "../store/map/mapTypes";

export const isString = (property: any): property is string => {
    return typeof property === "string";
};

export const isNumber = (property: any): property is number => {
    return typeof property === "number";
};

export const isObject = (property: any): property is object => {
    return property && typeof property === "object" && !Array.isArray(property);
};

export const isTupleArray = (property: any): property is [string, string][] => {
    return Array.isArray(property[0]);
};

export const isStringArray = (property: any): property is string[] => {
    return typeof property[0] == "string";
};

export const isUnsuccessfulAPIResponse = (
    property: any,
): property is unsuccessfulAPIResponse => {
    return property.hasOwnProperty("errors") || property.success === false;
};

export const isMapCenter = (property: any): property is MapCenter => {
    return property.hasOwnProperty("latitude");
};

export const isZoomToBBox = (property: any): property is ZoomToBBox => {
    return property.hasOwnProperty("bbox");
};
