import React from "react";
import ReactJoyride, {
    ACTIONS,
    CallBackProps,
    EVENTS,
    STATUS,
} from "react-joyride";
import { RootState } from "store/store";
import { connect } from "react-redux";
import Tutorials, { ProductTourConfig } from "assets/tutorials";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { SystemActionTypes } from "store/system/systemTypes";
import { setTour, loadTutorial } from "store/system/systemActions";
import "./ProductTour.css";
import classes from "./ProductTour.module.css";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";
import { registerAnalyticsEvent } from "store/matomo/matomoActions";
import { TutorialAnalyticsEvent } from "../../../../store/matomo/matomoTypes";
import { withRouter, RouteComponentProps, matchPath } from "react-router-dom";
import { replaceSearchParams } from "utils/URLs";

interface OwnProps {}
interface DispatchProps {
    setTour: typeof setTour;
    loadTutorial: typeof loadTutorial;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
}
interface StateProps {
    tourConfig: ProductTourConfig;
}

type ProductTourProps = OwnProps &
    DispatchProps &
    StateProps &
    RouteComponentProps;

class ProductTour extends React.Component<ProductTourProps> {
    private setStep = (stepIndex: number, displayDelay?: number) => {
        if (displayDelay) {
            setTimeout(() => {
                // Update state to advance the tour
                this.props.setTour({
                    stepIndex,
                });
            }, displayDelay);
        } else {
            this.props.setTour({
                stepIndex,
            });
        }
    };

    private handleJoyrideCallback = (data: CallBackProps) => {
        const { action, index, type, status, lifecycle } = data;

        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {

            let match = matchPath<{tutorialName: string}>(this.props.history.location.pathname, {
                path: '/tutorial/:tutorialName'
            });

            if(match === null){
                return;
            }

            let tutorialId = match.params.tutorialName;

            if (lifecycle === "init") {
                this.props.registerAnalyticsEvent({
                    category: "Tutorials",
                    action: status as TutorialAnalyticsEvent["action"],
                    name: match.params.tutorialName,
                });
            }
            // Need to set our running state to false, so we can restart if we click start again.
            this.props.loadTutorial("");

            let step = data.index + 1;

            if (step === this.props.tourConfig.steps.length) {
                //Save Version number in ls - indicated completed
                localStorage.setItem(
                    tutorialId,
                    Tutorials[tutorialId].version.toString(),
                );
                //Need to use this method to redirect to LC rather than <Link> as <Link> was being called before the callback had executed - causing the LC to render before LS update.
                this.props.history.push("/learningcentre");
            } else {
                let ls = localStorage.getItem(tutorialId);
                //Only set localStorage to incomplete if it has never been completed. This means "replaying" a tutorial won't remove its completion.
                if (!ls || ls === "incomplete") {
                    localStorage.setItem(tutorialId, "incomplete");
                }
                this.props.history.push("/learningcentre");
            }
        } else if (EVENTS.STEP_AFTER === type) {
            const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            const nextStep = this.props.tourConfig.steps[stepIndex];

            this.props.registerAnalyticsEvent({
                category: "Tutorials",
                action: status as TutorialAnalyticsEvent["action"],
                name:
                    (window as any).TUTORIAL_ID +
                    " Step " +
                    data.index +
                    "/" +
                    this.props.tourConfig.steps.length,
            });

            this.setStep(stepIndex, nextStep?.displayDelay);
        } else if (EVENTS.TARGET_NOT_FOUND === type) {
            const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            const nextStep = this.props.tourConfig.steps[stepIndex];

            this.setStep(stepIndex, nextStep?.displayDelay);
        } else if (EVENTS.STEP_BEFORE === type) {
            this.props.registerAnalyticsEvent({
                category: "Tutorials",
                action: status as TutorialAnalyticsEvent["action"],
                name:
                    (window as any).TUTORIAL_ID +
                    " Step " +
                    data.index +
                    "/" +
                    this.props.tourConfig.steps.length,
            });
            // Step which is about to be loaded
            const step = this.props.tourConfig.steps[data.index];
            const nextStep = this.props.tourConfig.steps[data.index + 1];

            this.props.history.push({
                pathname: this.props.history.location.pathname,
                search: replaceSearchParams(
                    this.props.history.location.search,
                    {
                        step: `${data.index}`,
                    },
                ),
            });


            // Set up event listener to trigger next step in tour
            if (step.targetTriggerEvent) {
                let targetElement: Element;

                if (typeof data.step.target === "string") {
                    targetElement =
                        document.querySelector(data.step.target) ??
                        document.querySelector("body")!;
                } else {
                    targetElement = data.step.target;
                }

                const triggerNext = () => {
                    this.setStep(index + 1, nextStep.displayDelay);
                    targetElement.removeEventListener(
                        step.targetTriggerEvent!,
                        triggerNext,
                    );
                };
                targetElement!.addEventListener(
                    step.targetTriggerEvent,
                    triggerNext,
                );
            }
        }
    };

    render() {
        return (
            <ReactJoyride
                callback={(data) => {
                    this.handleJoyrideCallback(data);
                }}
                {...this.props.tourConfig}
                showSkipButton
                disableOverlayClose={true}
                disableCloseOnEsc={true}
                styles={{
                    options: {
                        arrowColor: "rgba(12, 12, 12, 1, 0.8)",
                        backgroundColor: "rgba(19, 19, 19, 0.8)",
                        overlayColor: "rgba(27, 38, 44, 0.6)",
                        primaryColor: "rgba(139, 63, 144, 1)",
                        textColor: "#ffffff",
                        width: "45vw",
                        zIndex: 1000,
                    },
                    buttonClose: {
                        display: "none",
                    },
                }}
                locale={{
                    next: (
                        <div className={classes.Next}>
                            <p className={classes.Steps}>
                                Step {this.props.tourConfig.stepIndex! + 1} of{" "}
                                {this.props.tourConfig.steps.length}
                            </p>
                            <p className={classes.Text}>Next</p>
                        </div>
                    ),
                    skip: <p>Exit Tutorial</p>,
                    last: <p>Finish Tutorial</p>,
                }}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    tourConfig: getStoreAtNamespaceKey(state, "system").tourConfig,
});

// Thunk dispatch is used as fetchConfig is an async action.
const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes>,
) => ({
    setTour: bindActionCreators(setTour, dispatch),
    loadTutorial: bindActionCreators(loadTutorial, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductTour),
);
