import React, { useState } from "react";
import classes from "./HelpDropdown.module.css";
import { Link } from "react-router-dom";

import Icon from "@mdi/react";
import {
    mdiMapMarkerStar,
    mdiTextBoxSearch,
    mdiInformation,
    mdiOpenInNew,
    mdiLayersTriple,
    mdiDatabase,
    mdiAccountGroup,
    mdiStar,
} from "@mdi/js";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import FeedbackModal from "../FeedbackModal/FeedbackModal";

interface LinkWithIcon {
    name: string;
    iconLeft: string;
    pathname?: string;
    modal?: boolean;
    newBadge?: boolean;
    external?: boolean;
}

const menuItems: LinkWithIcon[] = [
    { name: "Events", iconLeft: mdiMapMarkerStar },
    { name: "Layers", iconLeft: mdiLayersTriple },
    { name: "Data Hosting", iconLeft: mdiDatabase },
    { name: "About Us", iconLeft: mdiAccountGroup },
    { name: "Leave Feedback", iconLeft: mdiStar, modal: true, newBadge: true },
    {
        name: "Support Documentation",
        iconLeft: mdiTextBoxSearch,
        pathname: "https://mckenzieintelligence.com/geo-help-and-support",
        external: true,
    },
    {
        name: "Status",
        iconLeft: mdiInformation,
        pathname: "https://status.misgeo.io/",
        external: true,
    },
];

interface DispatchProps {}

interface StateProps {
    closeDropdown: any;
    setHelpMenuVisible: any;
}

type AllProps = StateProps & DispatchProps;

function HelpDropdown({ closeDropdown, setHelpMenuVisible }: AllProps) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { trackUserEvent } = useAnalytics();
    const buildLinkProps = (menuItem: LinkWithIcon) => {
        return {
            to: {
                pathname: menuItem?.pathname || "/help",
                state: {
                    section: menuItem.name,
                },
            },
            target: menuItem?.pathname ? "_blank" : "",
        };
    };

    const closeModal = () => {
        setHelpMenuVisible(true);
        setModalOpen(false);
        closeDropdown();
    };

    const openFeedbackModal = () => {
        trackUserEvent({ name: "leave_feedback_clicked" });
        setModalOpen(true);
        setHelpMenuVisible(false);
    };

    return (
        <div className={classes.MenuContainer}>
            {menuItems.map((menuItem) =>
                menuItem.modal ? (
                    <div
                        className={classes.MenuItem}
                        onClick={() => openFeedbackModal()}
                    >
                        <Icon path={menuItem.iconLeft} size={1} />
                        {menuItem.name}
                        {menuItem.newBadge && (
                            <span className={classes.New}>NEW</span>
                        )}
                    </div>
                ) : (
                    <Link {...buildLinkProps(menuItem)}>
                        <div className={classes.MenuItem}>
                            <Icon path={menuItem.iconLeft} size={1} />
                            {menuItem.name}
                            {menuItem.external && (
                                <Icon path={mdiOpenInNew} size={0.75} />
                            )}
                        </div>
                    </Link>
                ),
            )}

            {modalOpen && <FeedbackModal closeModal={closeModal} />}
        </div>
    );
}

export default HelpDropdown;
