/**
 * Holds the majority of the map interactivity logic.
 * Passes props to map components for the actual rendering.
 *
 */

import React, { Component, ReactElement, ReactNode, RefObject } from "react";

import { MapRef, ViewportProps } from "react-map-gl";
import cx from "classnames";

import { connect } from "react-redux";

import { RootState } from "store/store";
import { TimelineConfig } from "store/system/systemTypes";
import { BasemapType, LayerInfo, MapConfig } from "store/map/mapTypes";
import { MarkerConfig } from "store/insights/insightsTypes";
import SingleMap from "../SingleMap/SingleMap";
import DualMap from "../DualMap/DualMap";
import CompareMap from "../CompareMap/CompareMap";
import GeocoderWrapper from "../Controls/GeocoderWrapper";
import Timeline from "../../../Timeline/Timeline";
import LayoutSlider from "assets/icons/layouts/LayoutSlider.svg?react";
import LayoutDual from "assets/icons/layouts/LayoutDual.svg?react";
import { mdiSquareOutline, mdiTapeMeasure, mdiVectorRectangle } from "@mdi/js";
import ActionDropdown from "../../../../../../_Library/Dropdown/ActionDropdown";
import containerClasses from "./MapContainer.module.css";
import dropdownClasses from "components/_Library/Dropdown/ActionDropdown.module.css";

import classes from "./MapContainer.module.css";
import MapMenu from "../../MapMenu/MapMenu";

import { MapEvent } from "react-map-gl/dist/es5/components/interactive-map";
import MapLayers, { MapSearchMarker } from "../MapLayers/DataLayers";
import {
    InsightsDonutMarker,
    InsightsLayers,
} from "../MapLayers/InsightsLayers";
import MapInteractionContainer, {
    InteractionModeMapProps,
} from "../MapInteractions/InteractionModeContainer/InteractionModeContainer";
import { setInteractionMode, setMapType } from "store/map/mapActions";
import { RefActionTypes } from "store/ref/refTypes";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { setRef } from "store/ref/refActions";
import { setViewport } from "store/map/mapActions";
import DragAndDrop from "components/Pages/Report/DragAndDrop/DragAndDrop";
import { getStoreAtNamespaceKey } from "../../../../../../../store/storeSelectors";
import { registerAnalyticsEvent } from "../../../../../../../store/matomo/matomoActions";
import {
    removeInsightsData,
    setContractIdFilter,
    setSelectedPortfolio,
} from "../../../../../../../store/insights/insightsActions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Icon from "@mdi/react";
import ReactTooltip from "react-tooltip";
import { GEOUserWithPortfolios } from "types/auth";
import { RangeSelect } from "components/_Library/RangeSelect/RangeSelect";

interface OwnProps {
    toggleDownloadModal: () => void;
}

interface DispatchProps {
    setRef: typeof setRef;
    setViewport: typeof setViewport;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
    removeInsightsLayer: typeof removeInsightsData;
    setSelectedPortfolio: typeof setSelectedPortfolio;
    setInteractionMode: typeof setInteractionMode;
    setMapType: typeof setMapType;
    setContractIdFilter: typeof setContractIdFilter;
}

interface StateProps {
    timelineConfig: TimelineConfig | undefined;
    mapConfig: MapConfig;
    geocoderContainerRef: RefObject<HTMLDivElement>;
    interactiveLayerIds: string[];
    basemaps: [BasemapType, BasemapType];
    basemapOptions: { [key in BasemapType]: string };
    insightsMarker: MarkerConfig | null;
    mapMarkerLocation: null | [number, number];
    locationLabels: boolean;
    interactionMode: string;
    mapboxToken: string;
    user: GEOUserWithPortfolios | null;
    policyData: {
        data: Record<string, any>[];
        stats: {
            total_estimated_exposure: number;
            total_exposed_policies: number;
        };
    } | null;
}

type MapContainerProps = OwnProps &
    DispatchProps &
    StateProps &
    RouteComponentProps;

// Used for populating the layerSelectPopup

interface PopupInfo {
    latitude: number;
    longitude: number;
}

export interface LayerSelectPopupInfo extends PopupInfo {
    layers: LayerInfo[];
}

export interface MapContextMenuInfo extends PopupInfo {
    display: boolean;
    mapRef: React.RefObject<MapRef>;
}

export interface MeasurePopupInfo extends PopupInfo {
    measurement: number | null;
}

interface MapContainerState {
    currentWidth: number;
    dragAndDropActive: boolean;
    expandedControlIndex: number; // index of either ruler, sliders or map views
}

export interface MapProps {
    interactiveLayerIds: string[]; // list of hoverable/clickable layers.
    basemaps: string[]; // left/right map styling (sat/light/dark)
    mapboxToken: string;
    layers: JSX.Element[];
    viewport: ViewportProps; // Controls zoom/lat/long of map.
    handleViewportChange: (viewport: ViewportProps) => void;
    handleMapOnHover: (event: MapEvent, mapRef?: MapRef) => void;
    handleMapOnClick: (event: MapEvent) => void;
    handleGetCursor: (state: any) => string;
    controls: { [key: string]: ReactNode };
    leftMapRef: React.RefObject<MapRef>;
    rightMapRef: React.RefObject<MapRef>;
    popup: ReactNode;
    contextMenu: ReactNode;
    additionalLayers: ReactNode;
    additionalInteractiveLayerIds: string[];
    onWheel: () => void;
    onDblClick: () => void;
    onMouseUp: () => void;
    onContextMenu: (mapSide: React.RefObject<MapRef>, event: MapEvent) => void;
    insightLayers: JSX.Element | null;
    insightsDonutMarker: JSX.Element;
    mapSearchMarker: JSX.Element;
}

const MAP_TYPE_SWITCH = {
    single: SingleMap,
    compare: CompareMap,
    dual: DualMap,
};

class MapContainer extends Component<MapContainerProps, MapContainerState> {
    state: MapContainerState = {
        currentWidth: 0,
        dragAndDropActive: false,
        expandedControlIndex: -1,
    };

    // Map ref used in geocoder component to connected to a map.
    // used to connect external layer data with features using setFeatureState
    private leftMapRef: React.RefObject<MapRef>;
    private rightMapRef: React.RefObject<MapRef>;
    private mapContainerRef: React.RefObject<HTMLDivElement>;

    constructor(props: MapContainerProps) {
        super(props);
        this.leftMapRef = React.createRef();
        this.rightMapRef = React.createRef();
        this.mapContainerRef = React.createRef();

        this.props.setRef({ refName: "mapRef", ref: this.leftMapRef });
    }

    componentDidMount() {
        this.setWidth();
        this.props.removeInsightsLayer();
        window.addEventListener("resize", this.setWidth);
    }
    componentWillUnmount() {
        this.props.setSelectedPortfolio({
            selectedPortfolio: null,
        });
        window.removeEventListener("resize", this.setWidth);
    }

    componentDidUpdate(prevProps: MapContainerProps) {
        if (prevProps.interactionMode !== this.props.interactionMode) {
            this.setControlIndex(-1);
        }
    }

    setControlIndex(index: number) {
        // If index is already expanded, collapse it
        if (this.state.expandedControlIndex === index) {
            this.setState({ ...this.state, expandedControlIndex: -1 });
        } else {
            this.setState({ ...this.state, expandedControlIndex: index });
        }
    }

    controlIndexIsActive(index: number) {
        return this.state.expandedControlIndex === index;
    }

    // -- MAP
    setWidth = () => {
        if (this.mapContainerRef.current) {
            this.setState({
                currentWidth: this.mapContainerRef.current?.offsetWidth || 800,
            });
        }
    };

    // Wrapper used to move the geocoder to menu via a portal
    createGeocoder = () => {
        return (
            <GeocoderWrapper
                leftMapRef={this.leftMapRef}
                geocoderContainerRef={this.props.geocoderContainerRef}
            />
        );
    };

    setDragAndDropState = (active: boolean) => {
        if (active) {
            this.props.registerAnalyticsEvent({
                category: "Drag And Drop",
                action: "click",
            });
        }

        this.setState({
            dragAndDropActive: active,
        });
    };

    updateURL = () => {
        const { longitude, latitude, zoom } = this.props.mapConfig.viewport;
        const pathSegments = this.props.location.pathname.split("/");
        const reportIndex = pathSegments.indexOf(pathSegments[1]);

        this.props.history.push({
            pathname: `/${pathSegments[1]}/${pathSegments[reportIndex + 1]}/${[
                longitude,
                latitude,
                zoom,
            ]
                .map((number) => number?.toFixed(5))
                .join("/")}`,
            search: this.props.location.search,
        });
    };

    toggleMapInteraction = (interactionMode: string) => {
        if (this.props.interactionMode === interactionMode) {
            this.props.setInteractionMode("standard");
            this.setControlIndex(-1);
        } else {
            if (interactionMode === "measure-distance") {
                this.setControlIndex(0);
                this.props.setInteractionMode("measure-distance");
            } else if (interactionMode === "measure-area") {
                this.setControlIndex(1);
                this.props.setInteractionMode("measure-area");
            } else {
                this.setControlIndex(-1);
            }
        }
    };

    renderCurrentLayoutIcon = (): { renderElementFn: () => ReactElement } => {
        switch (this.props.mapConfig.mapType) {
            case "single":
                return {
                    renderElementFn: () => (
                        <span onClick={() => this.props.setMapType("single")}>
                            <Icon
                                path={mdiSquareOutline}
                                size={1.5}
                                className={dropdownClasses.ToolBarIcon}
                            />
                        </span>
                    ),
                };
            case "dual":
                return {
                    renderElementFn: () => (
                        <LayoutDual
                            className={dropdownClasses.ToolBarIcon}
                            onClick={() => this.props.setMapType("dual")}
                        />
                    ),
                };
            case "compare":
                return {
                    renderElementFn: () => (
                        <LayoutSlider
                            className={dropdownClasses.ToolBarIcon}
                            onClick={() => this.props.setMapType("compare")}
                        />
                    ),
                };
        }
    };

    renderMap = (): ReactNode => {
        let mapType = this.props.mapConfig.mapType;
        let viewport = this.props.mapConfig.viewport;

        let mapRenderFunction: (props: InteractionModeMapProps) => JSX.Element;

        let basemaps = this.props.basemaps.map(
            (elem) => this.props.basemapOptions[elem],
        );

        // Collate all props for the map component
        let props = {
            basemaps,
            interactiveLayerIds: this.props.interactiveLayerIds,
            mapboxToken: this.props.mapboxToken,
            leftMapRef: this.leftMapRef,
            rightMapRef: this.rightMapRef,
            layers: MapLayers({
                leftMapRef: this.leftMapRef,
                rightMapRef: this.rightMapRef,
                sourcesObject: this.props.mapConfig.sources,
                mapConfig: this.props.mapConfig,
                timelineConfig: this.props.timelineConfig,
                mapType: this.props.mapConfig.mapType,
                locationLabels: this.props.locationLabels,
            }),
            handleViewportChange: this.props.setViewport,
            viewport,
            onWheel: this.updateURL,
            onDblClick: this.updateURL,
            onMouseUp: this.updateURL,
            insightLayers: <InsightsLayers />,
            insightsDonutMarker: InsightsDonutMarker({
                marker: this.props.insightsMarker,
                locationLabels: this.props.locationLabels,
            }),
            mapSearchMarker: MapSearchMarker({
                lnglat: this.props.mapMarkerLocation!,
            }),
        };

        const MapComponent = MAP_TYPE_SWITCH[mapType];

        mapRenderFunction = ({
            interactions,
            additionalInteractiveLayerIds,
            layers,
            popup,
            contextMenu,
            controls,
        }: InteractionModeMapProps) => {
            return (
                //@ts-ignore: This will be resolved when Dual and Compare are functional components.
                <MapComponent
                    key={"map"}
                    additionalInteractiveLayerIds={
                        additionalInteractiveLayerIds
                    }
                    {...interactions}
                    {...props}
                    contextMenu={contextMenu}
                    popup={popup}
                    controls={controls}
                    additionalLayers={layers}
                />
            );
        };

        let geocoder;
        if (this.props.geocoderContainerRef.current !== null) {
            geocoder = this.createGeocoder();
        }

        const all_estimated_exposure: number[] | undefined = this.props
            .policyData?.data
            ? this.props.policyData?.data.map((data) => data.estimated_exposure)
            : [];

        return (
            <>
                <div
                    className={classes.MapComponentContainer}
                    id={"tourid_MapComponentContainer"}
                >
                    <div
                        ref={this.mapContainerRef}
                        className={cx(classes.HorizontalContainer, {
                            [classes.CaptureCoords]: false,
                        })}
                    >
                        {this.props.user?.has_policy_access &&
                            this.props.policyData?.data && (
                                <RangeSelect
                                    minValue={Math.min(
                                        ...all_estimated_exposure,
                                    )}
                                    maxValue={Math.max(
                                        ...all_estimated_exposure,
                                    )}
                                    title={"Estimated Exposure"}
                                    format
                                    prefix={"$"}
                                    onSubmit={(value: {
                                        min: number;
                                        max: number;
                                    }) => {
                                        let contractIds: number[] | null =
                                            this.props
                                                .policyData!.data.filter(
                                                    (data) =>
                                                        data.estimated_exposure >=
                                                            value.min &&
                                                        data.estimated_exposure <=
                                                            value.max,
                                                )
                                                .map(
                                                    (data) =>
                                                        data.MIS_ContractID,
                                                );

                                        if (!contractIds.length)
                                            contractIds = null;

                                        this.props.setContractIdFilter({
                                            contractIds: contractIds,
                                        });
                                    }}
                                />
                            )}

                        <div
                            className={cx(
                                containerClasses.DropdownListContainer,
                                {
                                    [containerClasses.Hidden]:
                                        this.props.interactionMode !==
                                        "standard",
                                },
                            )}
                            id={"tourid_Toolbar"}
                        >
                            <div
                                className={
                                    dropdownClasses.ActionDropdownContainer
                                }
                                data-tip="Measure distance"
                                data-for={"DropdownMeasureDistance"}
                                id="tourid_drawLine"
                            >
                                <span
                                    onClick={() => {
                                        this.toggleMapInteraction(
                                            "measure-distance",
                                        );
                                    }}
                                >
                                    <Icon
                                        className={
                                            this.controlIndexIsActive(0)
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon
                                        }
                                        path={mdiTapeMeasure}
                                        size={1.5}
                                    />
                                </span>
                                <ReactTooltip
                                    id={"DropdownMeasureDistance"}
                                    place={"right"}
                                    type={"dark"}
                                    effect={"solid"}
                                />
                            </div>
                            <div
                                className={
                                    dropdownClasses.ActionDropdownContainer
                                }
                                data-tip="Measure area"
                                data-for={"DropdownMeasureArea"}
                                id="tourid_drawPoly"
                            >
                                <span
                                    onClick={() => {
                                        this.toggleMapInteraction(
                                            "measure-area",
                                        );
                                    }}
                                >
                                    <Icon
                                        className={
                                            this.controlIndexIsActive(1)
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon
                                        }
                                        path={mdiVectorRectangle}
                                        size={1.5}
                                    />
                                </span>

                                <ReactTooltip
                                    id={"DropdownMeasureArea"}
                                    place={"right"}
                                    type={"dark"}
                                    effect={"solid"}
                                />
                            </div>
                            <ActionDropdown
                                containerClassName={
                                    this.props.interactionMode !== "standard"
                                        ? dropdownClasses.Hidden
                                        : dropdownClasses.ActionDropdownContainer
                                }
                                expanded={this.controlIndexIsActive(2)}
                                expanderButtonProps={{
                                    ...this.renderCurrentLayoutIcon(),
                                    onClick: () => {
                                        this.setControlIndex(2);
                                    },
                                    tooltip: "Map Layout",
                                    tooltipPlacement: "right",
                                    className: this.controlIndexIsActive(2)
                                        ? dropdownClasses.Highlighted
                                        : dropdownClasses.ToolBarIcon,
                                    id: "tourid_MapLayout",
                                }}
                                innerButtonProps={[
                                    {
                                        renderElementFn: () => (
                                            <span
                                                onClick={() =>
                                                    this.props.setMapType(
                                                        "single",
                                                    )
                                                }
                                            >
                                                <Icon
                                                    path={mdiSquareOutline}
                                                    size={1.5}
                                                />
                                            </span>
                                        ),
                                        className:
                                            this.props.mapConfig.mapType ===
                                            "single"
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon,
                                        subHeading: "Single",
                                        id: "tourid_MapLayoutSingle",
                                    },
                                    {
                                        renderElementFn: () => (
                                            <LayoutDual
                                                onClick={() =>
                                                    this.props.setMapType(
                                                        "dual",
                                                    )
                                                }
                                            />
                                        ),
                                        className:
                                            this.props.mapConfig.mapType ===
                                            "dual"
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon,
                                        subHeading: "Dual",
                                        id: "tourid_MapLayoutDual",
                                    },
                                    {
                                        renderElementFn: () => (
                                            <LayoutSlider
                                                onClick={() =>
                                                    this.props.setMapType(
                                                        "compare",
                                                    )
                                                }
                                            />
                                        ),
                                        className:
                                            this.props.mapConfig.mapType ===
                                            "compare"
                                                ? dropdownClasses.Highlighted
                                                : dropdownClasses.ToolBarIcon,
                                        subHeading: "Slider",
                                        id: "tourid_MapLayoutSlider",
                                    },
                                ]}
                            />
                        </div>
                        <div className={classes.MapContainer}>
                            {geocoder}
                            <MapInteractionContainer
                                mapContainerWidth={this.state.currentWidth}
                                mapRenderFunction={mapRenderFunction}
                            />
                        </div>
                        <MapMenu
                            setDragAndDropState={this.setDragAndDropState}
                            toggleDownloadModal={this.props.toggleDownloadModal}
                        />
                    </div>
                    {this.props.timelineConfig !== undefined && (
                        <Timeline options={this.props.timelineConfig} />
                    )}
                </div>
                {this.state.dragAndDropActive && (
                    <DragAndDrop
                        setDragAndDropState={this.setDragAndDropState}
                    />
                )}
            </>
        );
    };
    render = () => this.renderMap();
}

const mapStateToProps = (
    state: RootState,
): Omit<StateProps, "expandedControlIndex"> => {
    return {
        timelineConfig: getStoreAtNamespaceKey(state, "system").timelineConfig,
        mapConfig: getStoreAtNamespaceKey(state, "map").mapConfig,
        mapboxToken: getStoreAtNamespaceKey(state, "map").mapboxToken,
        geocoderContainerRef: getStoreAtNamespaceKey(state, "ref")
            .geocoderContainerRef,
        interactiveLayerIds: getStoreAtNamespaceKey(state, "map")
            .interactiveLayerIds,
        basemaps: getStoreAtNamespaceKey(state, "map").basemaps,
        basemapOptions: getStoreAtNamespaceKey(state, "map").basemapOptions,
        insightsMarker: getStoreAtNamespaceKey(state, "insights").marker,
        mapMarkerLocation: getStoreAtNamespaceKey(state, "map")
            .mapMarkerLocation,
        locationLabels: getStoreAtNamespaceKey(state, "map").locationLabels,
        interactionMode: getStoreAtNamespaceKey(state, "map").interactionMode,
        user: getStoreAtNamespaceKey(state, "user").user,
        policyData: getStoreAtNamespaceKey(state, "insights").policyData,
    };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, RefActionTypes>,
) => ({
    setRef: bindActionCreators(setRef, dispatch),
    setViewport: bindActionCreators(setViewport, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
    removeInsightsLayer: bindActionCreators(removeInsightsData, dispatch),
    setSelectedPortfolio: bindActionCreators(setSelectedPortfolio, dispatch),
    setInteractionMode: bindActionCreators(setInteractionMode, dispatch),
    setMapType: bindActionCreators(setMapType, dispatch),
    setContractIdFilter: bindActionCreators(setContractIdFilter, dispatch),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MapContainer),
);
