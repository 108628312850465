import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "components/App/App";
import "./index.module.css";
import * as Sentry from "@sentry/react";
export const MOMENT_DATE_FORMAT = "DD MMM YYYY";
export const DATEPICKER_DATE_FORMAT = "dd MMM yyyy";

Sentry.init({
    dsn: "https://2a0647197a1644bc8f736f41c600327a@o4505471839764480.ingest.sentry.io/4505515489886208",
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "https://development.misgeo.io/",
                "https://staging.misgeo.io/",
                "https://misgeo.io/",
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.25, // Capture 25% of the transactions, sentry advises against capturing 100% in production.
    enabled:  !["development", "local"].includes(import.meta.env.VITE_ENVIRONMENT),
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
