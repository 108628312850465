// @ts-nocheck
import { AppConfig } from "store/system/systemTypes";
import moment from "moment";
import { generateHash } from "../../utils/Maths";

const reportConfig: AppConfig = {
    datasets: {
        CountryData: {
            url: "https://jsonplaceholder.typicode.com/users/1/todos",
        },
        TodoData: {
            url: "https://jsonplaceholder.typicode.com/users/1/todos",
        },
    },
    timelineConfig: {
        range: {
            min: "10 May 2021",
            max: "11 May 2021",
            format: "DD MMM YYYY",
        },
        secondsPerUpdate: 1,
    },
    theme: "dark",
    components: [
        {
            type: "map",
            display: {
                topLeft: [0, 0],
                width: 5,
                height: 3,
                view: "report",
            },
            options: {
                mapboxToken:
                    "pk.eyJ1IjoibWlrZS1taXMiLCJhIjoidjBXS01iUSJ9.RW5YqGmR3hyfu28gO7l8ow",
                mapType: "single",
                basemapOptions: {
                    dark: "mike-mis/cjz9un4sf01c91cp4qtyxs2vg",
                    light: "mike-mis/ckcfxe3fg0krb1iqrcm0fiyy1",
                    satellite: "mike-mis/ckebg0mb317xs1aqlqcbspm7y",
                },
                viewport: {
                    latitude: 30.3,
                    longitude: -87,
                    zoom: 3,
                },
                sources: {
                    "Prod Example Source": {
                        type: "vector",
                        tiles: [
                            "https://geoserver.mis-intel.co.uk:8443/geoserver/gwc/service/wmts/rest/zeus:gdacs_poly_1000780/poly/EPSG:900913x2/EPSG:900913x2:{z}/{y}/{x}?format=application/vnd.mapbox-vector-tile",
                        ],
                        tileSize: 512,
                        complexPaintProperties: [],
                        source: "Prod Example",
                        "source-layer": "gdacs_poly_1000780",
                        dataConfig: {
                            timeline: {
                                type: "filter-between",
                                data: {
                                    type: "internal",
                                    attribute: "pub_start_time",
                                    attribute2: "pub_end_time",
                                    format: "X",
                                },
                            },
                        },
                        layerType: "fill",
                        layout: {
                            visibility: "visible",
                        },
                        paint: {
                            "fill-color": [
                                "match",
                                ["get", "wind_category"],
                                "60 km/h",
                                "rgba(255, 168, 0, 0.6)",
                                "90 km/h",
                                "rgba(255, 92, 0, 0.6)",
                                "120 km/h",
                                "rgba(255, 0, 0, 0.6)",
                                "rgba(128, 128, 128, 0.75)",
                            ],
                        },
                        viewOn: "both",
                    },
                    "Tornados0 Source": {
                        type: "vector",
                        url: "mapbox://mike-mis.2zjj2cpr",
                        complexPaintProperties: [],
                        source: "19-25_April_2020_Tornado_Outb-8bmc0c",
                        actions: {
                            zoomTo: {
                                latitude: 30.3,
                                longitude: -87,
                                zoom: 5,
                            },
                        },
                        dataConfig: {
                            timeline: {
                                type: "filter-on",
                                data: {
                                    type: "internal",
                                    attribute: "Date",
                                    format: "YYYY/MM/DD",
                                },
                            },
                        },
                        layerType: "line",
                        layout: {
                            visibility: "visible",
                        },
                        paint: {
                            "line-width": [
                                "match",
                                ["get", "EF Scale"],
                                "EF5",
                                5,
                                "EF4",
                                4,
                                "EF3",
                                3,
                                "EF2",
                                2,
                                "EF1",
                                1,
                                "EF0",
                                1,
                                "Thunderstorm",
                                5,
                                2,
                            ],
                            "line-color": [
                                "match",
                                ["get", "EF Scale"],
                                "EF5",
                                "#F00",
                                "EF4",
                                "#F80",
                                "EF3",
                                "#FF0",
                                "EF2",
                                "#8F8",
                                "EF1",
                                "#0FF",
                                "EF0",
                                "#08F",
                                "Thunderstorm",
                                "#888",
                                "#F0F",
                            ],
                        },
                        viewOn: "both",
                    },
                    "Tornados1 Source": {
                        type: "vector",
                        url: "mapbox://mike-mis.2zjj2cpr",
                        complexPaintProperties: [],
                        source: "19-25_April_2020_Tornado_Outb-8bmc0c",
                        actions: {
                            zoomTo: {
                                latitude: 30.3,
                                longitude: -87,
                                zoom: 5,
                            },
                        },
                        dataConfig: {
                            // timeline: {
                            //     type: "filter-on",
                            //     data: {
                            //         type: "internal",
                            //         attribute: "Date",
                            //         format: "YYYY/MM/DD"
                            //     }
                            // }
                        },
                        layerType: "line",
                        layout: {
                            visibility: "visible",
                        },
                        paint: {
                            "line-width": 5,
                            "line-color": "#000",
                        },
                        viewOn: "both",
                    },
                    ygn2fmgr8x: {
                        url: "mapbox://mike-mis.dbfvo19q",
                        type: "vector",
                        color: "#f00",
                        paint: {
                            "fill-color": [
                                "match",
                                ["get", "Damage"],
                                "No Damage",
                                "rgba(33,150,243,0.4)",
                                "Light Damage",
                                "rgba(0,175,37,0.4)",
                                "Moderate Damage",
                                "rgba(175,220,0,0.4)",
                                "Substantial Damage",
                                "rgba(253,196,0,0.4)",
                                "Severe Damage",
                                "rgba(251,122,0,0.4)",
                                "Destroyed",
                                "rgba(244,11,0,0.4)",
                                "Low Risk of Damage",
                                "rgba(33,243,180,0.4)",
                                "Risk of Internal Damage",
                                "rgba(253,196,0,0.4)",
                                "rgba(136,136,136,0.4)",
                            ],
                        },
                        layout: {
                            visibility: "visible",
                        },
                        source: "1km_x_1km_Damage_Grid-3fer9l",
                        viewOn: "both",
                        actions: {
                            zoomTo: {
                                zoom: 4.3446377303453305,
                                latitude: 48.437325127973715,
                                longitude: 30.784349186182407,
                            },
                        },
                        layerType: "fill",
                        styleName: "Damage - Polygon",
                        dataConfig: {},
                    },
                    "Point Example Source": {
                        type: "vector",
                        url: "mapbox://mike-mis.d4j4wj16",
                        complexPaintProperties: [],
                        source: "2019-04-2019_Best_Path_Points-98eixh",
                        actions: {
                            zoomTo: {
                                latitude: 26.89709,
                                longitude: -78.29883,
                                zoom: 5,
                            },
                        },
                        dataConfig: {},
                        layerType: "circle",
                        layout: {
                            visibility: "visible",
                        },
                        paint: {
                            "circle-color": "#fff",
                            "circle-radius": 5,
                            "circle-stroke-color": "#f00",
                            "circle-stroke-width": 2,
                        },
                        viewOn: "both",
                    },
                    "VIIRS Source": {
                        type: "vector",
                        url: "mapbox://mike-mis.0xeii0gl",
                        complexPaintProperties: [],
                        source: "Active_Fires_17-Jan-2020-7p8607",
                        actions: {
                            zoomTo: {
                                latitude: -25.38839,
                                longitude: 135.12287,
                                zoom: 3,
                            },
                        },
                        dataConfig: {},
                        layerType: "circle",
                        layout: {
                            visibility: "visible",
                        },
                        paint: {
                            "circle-color": [
                                "match",
                                ["get", "CONFIDENCE"],
                                "high",
                                "#e53935",
                                "nominal",
                                "#ff9800",
                                "low",
                                "#ffeb3b",
                                "#ccc",
                            ],
                            "circle-stroke-color": "#000",
                            "circle-stroke-width": 2,
                        },
                        viewOn: "both",
                    },
                    "Polygon Example Source0": {
                        type: "vector",
                        url: "mapbox://mike-mis.85kkgxe6",
                        complexPaintProperties: [],
                        source: "countries-8mds39",
                        actions: {
                            zoomTo: {
                                latitude: 54.34837,
                                longitude: -2.2411,
                                zoom: 4,
                            },
                        },
                        dataConfig: {
                            blacklist: [],
                            dataset: {
                                name: "CountryData",
                                join: ["ISO_A3", "alpha3Code"],
                            },
                        },
                        layerType: "fill",
                        layout: {
                            visibility: "none",
                        },
                        paint: {
                            // "fill-color":  "rgba(49,130,189,1)",
                            "fill-color": [
                                "interpolate",
                                // ["exponential", 0.1],
                                ["cubic-bezier", 0.6, 0.7, 0.8, 1],
                                // ["linear"],
                                ["feature-state", "population"],
                                0,
                                "#fff",
                                677422166,
                                "rgba(49,130,189,1)",
                            ],
                            //  "fill-outline-color":  "rgba(49,130,189,1)",
                            // 'fill-outline-color': [
                            //     "interpolate",
                            //     ["linear"],
                            //     ["feature-state", "population"],
                            //     0, "#fff",
                            //     677422166, "rgba(49,130,189,1)",
                            // ],
                        },
                        viewOn: "both",
                    },
                    "Polygon Example Source0A": {
                        type: "vector",
                        url: "mapbox://mike-mis.85kkgxe6",
                        complexPaintProperties: [],
                        source: "countries-8mds39",
                        actions: {
                            zoomTo: {
                                latitude: 54.34837,
                                longitude: -2.2411,
                                zoom: 4,
                            },
                        },
                        dataConfig: {
                            blacklist: [],
                            dataset: {
                                name: "CountryData",
                                join: ["ISO_A3", "alpha3Code"],
                            },
                        },
                        layerType: "circle",
                        layout: {
                            visibility: "none",
                        },
                        paint: {
                            // "circle-color": "rgba(49,130,189,1)",
                            "circle-color": [
                                "interpolate",
                                ["linear"],
                                ["feature-state", "population"],
                                0,
                                "#000",
                                677422166,
                                "rgba(129, 45, 211, 1)",
                            ],
                            // "circle-stroke-color": "#000",
                            "circle-stroke-color": [
                                "interpolate",
                                ["linear"],
                                ["feature-state", "population"],
                                0,
                                "#000",
                                677422166,
                                "rgba(49,130,189,1)",
                            ],
                            // "circle-stroke-width": 4
                            "circle-stroke-width": [
                                "interpolate",
                                ["linear"],
                                ["feature-state", "population"],
                                0,
                                3,
                                677422166,
                                20,
                            ],
                        },
                        viewOn: "both",
                    },
                    "Polygon Example Source0B": {
                        type: "vector",
                        url: "mapbox://mike-mis.85kkgxe6",
                        complexPaintProperties: [],
                        source: "countries-8mds39",
                        actions: {
                            zoomTo: {
                                latitude: 54.34837,
                                longitude: -2.2411,
                                zoom: 4,
                            },
                        },
                        dataConfig: {
                            blacklist: [],
                            dataset: {
                                name: "CountryData",
                                join: ["ISO_A3", "alpha3Code"],
                            },
                        },
                        layerType: "line",
                        layout: {
                            visibility: "none",
                        },
                        paint: {
                            //"line-color": "rgba(49,130,189,1)",
                            "line-color": [
                                "interpolate",
                                ["linear"],
                                ["feature-state", "population"],
                                0,
                                "#fff",
                                677422166,
                                "rgba(49,130,189,1)",
                            ],
                            // "line-width": 2
                            "line-width": [
                                "interpolate",
                                ["linear"],
                                ["feature-state", "population"],
                                0,
                                3,
                                677422166,
                                12,
                            ],
                        },
                        viewOn: "both",
                    },
                    "Polygon Example Source0C": {
                        type: "vector",
                        url: "mapbox://mike-mis.85kkgxe6",
                        complexPaintProperties: [],
                        source: "countries-8mds39",
                        actions: {
                            zoomTo: {
                                latitude: 54.34837,
                                longitude: -2.2411,
                                zoom: 4,
                            },
                        },
                        dataConfig: {
                            blacklist: [],
                            dataset: {
                                name: "CountryData",
                                join: ["ISO_A3", "alpha3Code"],
                            },
                        },
                        layerType: "circle",
                        layout: {
                            visibility: "none",
                        },
                        paint: {
                            // "circle-color": "rgba(49,130,189,1)",
                            "circle-color": [
                                "interpolate",
                                ["linear"],
                                ["feature-state", "population"],
                                0,
                                "#fff",
                                677422166,
                                "rgba(129, 45, 211, 1)",
                            ],
                            "circle-stroke-color": "#000",
                            // "circle-stroke-color": [
                            //     "interpolate",
                            //     ["linear"],
                            //     ["feature-state", "population"],
                            //     0, "#000",
                            //     677422166, "rgba(49,130,189,1)"
                            // ],
                            "circle-stroke-width": 1,
                            "circle-radius": [
                                "interpolate",
                                ["linear"],
                                ["feature-state", "population"],
                                0,
                                5,
                                677422166,
                                20,
                            ],
                        },
                        viewOn: "both",
                    },
                    "Polygon Example Source1": {
                        type: "vector",
                        url: "mapbox://mike-mis.85kkgxe6",
                        complexPaintProperties: [],
                        source: "countries-8mds39",
                        actions: {
                            zoomTo: {
                                latitude: 54.34837,
                                longitude: -2.2411,
                                zoom: 4,
                            },
                        },
                        dataConfig: {
                            blacklist: ["currencies", "name"],
                            featureData: {
                                localProperty: "ISO_A3",
                                url: "https://restcountries.eu/rest/v2/alpha/{0}",
                            },
                        },
                        layerType: "fill",
                        layout: {
                            visibility: "none",
                        },
                        paint: {
                            "fill-color": "#80a26f",
                        },
                        viewOn: "both",
                    },
                    "Polygon Example Source2": {
                        type: "vector",
                        url: "mapbox://mike-mis.85kkgxe6",
                        complexPaintProperties: [],
                        source: "countries-8mds39",
                        actions: {
                            zoomTo: {
                                latitude: 54.34837,
                                longitude: -2.2411,
                                zoom: 4,
                            },
                        },
                        dataConfig: {
                            blacklist: [],
                        },
                        layerType: "fill",
                        layout: {
                            visibility: "none",
                        },
                        paint: {
                            "fill-color": [
                                "match",
                                ["get", "id"],
                                "USA",
                                "#a20808",
                                "GBR",
                                "#2a415a",
                                "#ddd",
                            ],
                            "fill-outline-color": "#000",
                        },
                        viewOn: "both",
                    },
                    "Polygon Example Source3": {
                        type: "vector",
                        url: "mapbox://mike-mis.85kkgxe6",
                        complexPaintProperties: [],
                        source: "countries-8mds39",
                        actions: {
                            zoomTo: {
                                latitude: 54.34837,
                                longitude: -2.2411,
                                zoom: 4,
                            },
                        },
                        dataConfig: {
                            blacklist: [],
                        },
                        layerType: "fill",
                        layout: {
                            visibility: "none",
                        },
                        paint: {},
                        viewOn: "both",
                    },
                    "Raster WMTS Source": {
                        type: "raster",
                        paint: {},
                        tiles: [
                            "https://stormscdn.ngs.noaa.gov/20200830c-rgb/{z}/{x}/{y}",
                        ],
                        layout: { visibility: "visible" },
                        viewOn: "both",
                        actions: {
                            zoomTo: {
                                zoom: 10,
                                latitude: 30.21578,
                                longitude: -93.18213,
                            },
                        },
                        layerName: "30 August 2020 C",
                        layerType: "raster",
                        dataConfig: {},
                        complexPaintProperties: [],
                    },
                    "Raster Example Source": {
                        type: "raster",
                        url: "mapbox://mike-mis.82qi4q7b",
                        complexPaintProperties: [],
                        actions: {
                            zoomTo: {
                                latitude: 36.33362,
                                longitude: 140.56681,
                                zoom: 10,
                            },
                        },
                        dataConfig: {},
                        layerType: "raster",
                        layout: {
                            visibility: "visible",
                        },
                        paint: {},
                        viewOn: "both",
                    },
                    cgh5mxtxi3: {
                        url: "mapbox://mike-mis.3watrz2d",
                        type: "vector",
                        paint: {
                            "icon-color": "#3a48f3",
                            "icon-halo-color": "#fff",
                            "icon-halo-width": 10,
                        },
                        layout: {
                            "icon-size": 0.5,
                            "icon-image": [
                                "match",
                                ["get", "INFRA_TYPE"],
                                "Airport",
                                "Airport",
                                "Dam",
                                "Dam",
                                "Medical",
                                "Medical",
                                "Oil Refinery",
                                "OilRefinery",
                                "Port",
                                "Port",
                                "Power Plant",
                                "PowerPlant",
                                "Railway Station",
                                "RailwayStation",
                                "Water Treatment Plant",
                                "WaterTreatmentPlant",
                                "",
                            ],
                            visibility: "visible",
                        },
                        source: "HurricanIda_TX-LA-MS-AL_KeyIn-5p0zpw",
                        viewOn: "both",
                        actions: {
                            zoomTo: {
                                zoom: 2.462537553858533,
                                latitude: 23.914668294139005,
                                longitude: -79.09432638858253,
                            },
                        },
                        layerType: "symbol",
                        styleName: "Infrastructure",
                        dataConfig: {},
                    },
                },
                menuIndex: [
                    {
                        id: generateHash(),
                        type: "layer",
                        layerName: "Icon test",
                        layerSource: "cgh5mxtxi3",
                    },
                    {
                        id: generateHash(),
                        type: "group",
                        groupName: "Claims",
                        children: [
                            {
                                id: generateHash(),
                                type: "group",
                                groupName: "Tornado",
                                children: [
                                    {
                                        id: generateHash(),
                                        type: "layer",
                                        layerName:
                                            "Tornados Simple Legend Layer",
                                        layerSource: "Tornados1 Source",
                                    },
                                    {
                                        id: generateHash(),
                                        type: "layer",
                                        layerName:
                                            "Tornados Complex Legend Layer",
                                        layerSource: "Tornados0 Source",
                                    },
                                ],
                            },
                            {
                                id: "1qevoo72zm",
                                type: "layer",
                                layerName: "1km x 1km Damage Grid",
                                layerSource: "ygn2fmgr8x",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "POLY Layer",
                                layerSource: "Prod Example Source",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "Point Example Layer",
                                layerSource: "Point Example Source",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "VIIRS Layer",
                                layerSource: "VIIRS Source",
                            },
                        ],
                    },
                    {
                        id: generateHash(),
                        type: "group",
                        groupName: "Exposure",
                        children: [
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName:
                                    "Countries Layer Full(interpolated color) Layer",
                                layerSource: "Polygon Example Source0",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "CLF Interpolated Radius Layer",
                                layerSource: "Polygon Example Source0C",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "CLF Interpolated LineWidth Layer",
                                layerSource: "Polygon Example Source0B",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "CLF Interpolated BorderWidth Layer",
                                layerSource: "Polygon Example Source0A",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "Raster WMTS Layer",
                                layerSource: "Raster WMTS Source",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "Countries Individual Layer",
                                layerSource: "Polygon Example Source1",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "Countries No External Layer ",
                                layerSource: "Polygon Example Source2",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "Countries No External No Icon v",
                                layerSource: "Polygon Example Source3",
                            },
                            {
                                id: generateHash(),
                                type: "layer",
                                layerName: "Raster Example Layer",
                                layerSource: "Raster Example Source",
                            },
                        ],
                    },
                ],
            },
        },
        {
            type: "text",
            display: {
                topLeft: [0, 3],
                width: 3,
                height: 1,
                view: "report",
            },
            options: {
                content: [
                    {
                        title: "Welcome",
                        text: "welcome",
                        id: generateHash(),
                    },
                    {
                        title: "Help",
                        text: "help",
                        id: generateHash(),
                    },
                    {
                        title: "Help Welcome",
                        text: "help",
                        id: generateHash(),
                    },
                    {
                        title: "HelpWelcome",
                        text: "help",
                        id: generateHash(),
                    },
                    {
                        title: "Welcome Help",
                        text: "help",
                        id: generateHash(),
                    },
                    {
                        title: "Welcome Welcome WelcomeHelp",
                        text: "help",
                        id: generateHash(),
                    },
                    {
                        title: "Help 2",
                        text: "help",
                        id: generateHash(),
                    },
                    {
                        title: "Help 3",
                        text: "help",
                        id: generateHash(),
                    },
                ],
            },
        },
        {
            type: "summary",
            display: {
                topLeft: [3, 3],
                width: 2,
                height: 1,
                view: "report",
            },
            options: {
                id: 0,
                report: true,
                position: [-83.74376, 30.50853],
                impact: "low",
                type: "hurricane",
                location: "Florida, USA",
                from_date: moment().unix(),
                to_date: null,
                last_updated: moment().unix(),
                name: "Hurricane Yolande",
                downloads: {
                    "hurricane_layers.csv": "https://download.com",
                    "overview_report.txt": "https://download.com",
                    "overview_report.pdf": "https://download.com",
                    "overview_report.png": "https://download.com",
                },
            },
        },
        {
            type: "image",
            display: {
                topLeft: [0, 0],
                width: 3,
                height: 2,
                view: "media",
            },
            options: {
                title: "Image Gallery",
                images: [
                    {
                        url: "https://images.squarespace-cdn.com/content/v1/511f4a7ce4b083f0a83f6a49/1365344927578-N9RC8SI5C0DD8FZP3WKU/image-asset.png",
                        alt: "Image 1 of Hurricane",
                        caption: "Michael Jackson",
                    },
                    {
                        url: "https://cdn.britannica.com/74/121674-050-C458B2B5/satellite-image-National-Oceanic-and-Atmospheric-Administration-August-28-2005.jpg",
                        alt: "Image 2 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://ichef.bbci.co.uk/news/976/cpsprodpb/15674/production/_117586678_iota_goes_2020321_lrg.jpg",
                        alt: "Image 3 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://images.squarespace-cdn.com/content/v1/511f4a7ce4b083f0a83f6a49/1365344927578-N9RC8SI5C0DD8FZP3WKU/image-asset.png",
                        alt: "Image 1 of Hurricane",
                        caption: "Michael Jackson",
                    },
                    {
                        url: "https://cdn.britannica.com/74/121674-050-C458B2B5/satellite-image-National-Oceanic-and-Atmospheric-Administration-August-28-2005.jpg",
                        alt: "Image 2 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://ichef.bbci.co.uk/news/976/cpsprodpb/15674/production/_117586678_iota_goes_2020321_lrg.jpg",
                        alt: "Image 3 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://images.squarespace-cdn.com/content/v1/511f4a7ce4b083f0a83f6a49/1365344927578-N9RC8SI5C0DD8FZP3WKU/image-asset.png",
                        alt: "Image 1 of Hurricane",
                        caption: "Michael Jackson",
                    },
                    {
                        url: "https://images.squarespace-cdn.com/content/v1/511f4a7ce4b083f0a83f6a49/1365344927578-N9RC8SI5C0DD8FZP3WKU/image-asset.png",
                        alt: "Image 1 of Hurricane",
                        caption: "Michael Jackson",
                    },
                    {
                        url: "https://cdn.britannica.com/74/121674-050-C458B2B5/satellite-image-National-Oceanic-and-Atmospheric-Administration-August-28-2005.jpg",
                        alt: "Image 2 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://ichef.bbci.co.uk/news/976/cpsprodpb/15674/production/_117586678_iota_goes_2020321_lrg.jpg",
                        alt: "Image 3 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://images.squarespace-cdn.com/content/v1/511f4a7ce4b083f0a83f6a49/1365344927578-N9RC8SI5C0DD8FZP3WKU/image-asset.png",
                        alt: "Image 1 of Hurricane",
                        caption: "Michael Jackson",
                    },
                    {
                        url: "https://cdn.britannica.com/74/121674-050-C458B2B5/satellite-image-National-Oceanic-and-Atmospheric-Administration-August-28-2005.jpg",
                        alt: "Image 2 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://ichef.bbci.co.uk/news/976/cpsprodpb/15674/production/_117586678_iota_goes_2020321_lrg.jpg",
                        alt: "Image 3 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://images.squarespace-cdn.com/content/v1/511f4a7ce4b083f0a83f6a49/1365344927578-N9RC8SI5C0DD8FZP3WKU/image-asset.png",
                        alt: "Image 1 of Hurricane",
                        caption: "Michael Jackson",
                    },
                    {
                        url: "https://images.squarespace-cdn.com/content/v1/511f4a7ce4b083f0a83f6a49/1365344927578-N9RC8SI5C0DD8FZP3WKU/image-asset.png",
                        alt: "Image 1 of Hurricane",
                        caption: "Michael Jackson",
                    },
                    {
                        url: "https://cdn.britannica.com/74/121674-050-C458B2B5/satellite-image-National-Oceanic-and-Atmospheric-Administration-August-28-2005.jpg",
                        alt: "Image 2 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description This is an image of a hurricane and a very detailed description",
                    },
                    {
                        url: "https://ichef.bbci.co.uk/news/976/cpsprodpb/15674/production/_117586678_iota_goes_2020321_lrg.jpg",
                        alt: "Image 3 of Hurricane",
                        caption:
                            "This is an image of a hurricane and a very detailed description",
                    },
                ],
            },
        },
        {
            type: "social",
            display: {
                topLeft: [2, 2],
                width: 3,
                height: 2,
                view: "media",
            },
            options: {
                title: "Social Media",
                socials: {
                    Twitter: {
                        socialname: "Twitter",
                        hashtag: "hurricaneyolande",
                    },
                    LinkedIn: {
                        socialname: "LinkedIn",
                        hashtag: "hurricaneyolande",
                    },
                },
            },
        },
        {
            type: "article",
            display: {
                topLeft: [0, 2],
                width: 2,
                height: 2,
                view: "media",
            },
            options: {
                title: "News and Blogs",
                articles: [
                    {
                        title: "Hurricane Sweeps Nation",
                        source: "BBC News",
                        date: 1626945001,
                        url: "https://test.com",
                    },
                    {
                        title: "Hurricane Yolande hits America",
                        source: "Channel 4",
                        date: 1629432400,
                        url: "https://test.com",
                    },
                    {
                        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas tempus tempor felis, luctus lacinia ante bibendum non. Vivamus accumsan quis tellus",
                        source: "BBC News",
                        date: 1629932400,
                        url: "https://test.com",
                    },
                ],
            },
        },
        {
            type: "yt",
            display: {
                topLeft: [3, 0],
                width: 2,
                height: 2,
                view: "media",
            },
            options: {
                title: "Videos",
                videos: [
                    { url: "https://www.youtube.com/watch?v=MiDvqDDAHx0" },
                    { url: "https://www.youtube.com/watch?v=1yYvkH8ybsU" },
                    { url: "https://www.youtube.com/watch?v=Hp7QhJYESx4" },
                ],
            },
        },
        // {
        //     type:  "yt",
        //     display: {
        //         topLeft: [0,2],
        //         width: 2,
        //         height: 1,
        //         view: "media"
        //     },
        //     options: {
        //         url: "https://www.youtube.com/watch?v=MiDvqDDAHx0"
        //     }
        // },
        {
            type: "insights",
            display: {
                topLeft: [0, 0],
                width: 5,
                height: 4,
                view: "insights",
            },
        },
        // {
        //     type: "metric",
        //     display: {
        //         topLeft: [0,0],
        //         width: 1,
        //         height: 2,
        //         view: "insights"
        //     },
        //     options: {
        //         sections: [{
        //             data: "count",
        //             label: "States",
        //             icon: "mdiAccount"
        //         },
        //         {
        //             data: "Florida",
        //             label: "Most affected state",
        //             icon: "mdiEarth"
        //             }]
        //     }
        // },
        // {
        //     type: "dummy",
        //     display: {
        //         topLeft: [0, 0],
        //         width: 2,
        //         height: 2,
        //         view: "insights"
        //     }
        // }, {
        //     type: "dummy",
        //     display: {
        //         topLeft: [2, 0],
        //         width: 3,
        //         height: 1,
        //         view: "insights"
        //     }
        // }, {
        //     type: "dummy",
        //     display: {
        //         topLeft: [3, 1],
        //         width: 2,
        //         height: 3,
        //         view: "insights"
        //     }
        // }, {
        //     type: "dummy",
        //     display: {
        //         topLeft: [0, 2],
        //         width: 3,
        //         height: 2,
        //         view: "insights"
        //     }
        // }
    ],
};

// const damageGeojsonConfig: AppConfig = {
//     datasets: {
//         CountryData: {
//             url: "https://jsonplaceholder.typicode.com/users/1/todos"
//         },
//         TodoData: {
//             url: "https://jsonplaceholder.typicode.com/users/1/todos"
//         }
//     },
//     theme: "dark",
//     components: [
//         {
//             type: "map",
//             display: {
//                 topLeft: [0, 0],
//                 width: 5,
//                 height: 4,
//                 view: "report"
//             },
//             options: {
//                 mapboxToken: "pk.eyJ1IjoibWlrZS1taXMiLCJhIjoidjBXS01iUSJ9.RW5YqGmR3hyfu28gO7l8ow",
//                 mapType: "single",
//                 basemapOptions: {
//                     dark: "mike-mis/cjz9un4sf01c91cp4qtyxs2vg",
//                     light: "mike-mis/ckcfxe3fg0krb1iqrcm0fiyy1",
//                     satellite: "mike-mis/ckebg0mb317xs1aqlqcbspm7y"
//                 },
//                 viewport: {
//                     latitude: 30.3,
//                     longitude: -87,
//                     zoom: 3
//                 },
//                 sources: {
//                     "New Damage Category": {
//                         "type": "geojson",
//                         data: {
//                             "type": "FeatureCollection",
//                             "features": [
//                                 { "type": "Feature", "properties": { "Damage": "No Damage" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -91.92678057222156, 32.951213377468008 ], [ -91.949017335785271, 32.229909592397036 ], [ -91.015073266111031, 32.192280988861157 ], [ -91.081783556802037, 32.867205200819043 ], [ -91.92678057222156, 32.951213377468008 ] ] ] } },
//                                 { "type": "Feature", "properties": { "Damage": "Light Damage" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -90.592574758401227, 32.895216782743653 ], [ -90.648166667310406, 32.154636821359816 ], [ -89.736459361199863, 32.182871405724562 ], [ -89.680867452290684, 32.801810428486085 ], [ -90.592574758401227, 32.895216782743653 ] ] ] } },
//                                 { "type": "Feature", "properties": { "Damage": "Moderate Damage" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -89.469618198435768, 32.783117363591167 ], [ -89.425144671308416, 32.258120825307365 ], [ -88.735805000834603, 32.276923445081984 ], [ -88.791396909743767, 32.81115548733105 ], [ -89.469618198435768, 32.783117363591167 ] ] ] } },
//                                 { "type": "Feature", "properties": { "Damage": "Substantial Damage" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -88.535674128761542, 32.81115548733105 ], [ -88.546792510543369, 32.32391294473009 ], [ -87.724032258687487, 32.305120068451295 ], [ -87.679558731560149, 32.895216782743653 ], [ -88.535674128761542, 32.81115548733105 ] ] ] } },
//                                 { "type": "Feature", "properties": { "Damage": "Severe Damage" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -87.434954332359752, 32.848525895305073 ], [ -87.446072714141607, 32.276923445081984 ], [ -86.656667607631249, 32.380268171589215 ], [ -86.678904371194903, 32.932551783403426 ], [ -87.434954332359752, 32.848525895305073 ] ] ] } },
//                                 { "type": "Feature", "properties": { "Damage": "Destroyed" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -86.45653673555816, 32.867205200819043 ], [ -86.45653673555816, 32.352094947164993 ], [ -85.68936839261147, 32.370878072367439 ], [ -85.756078683302519, 32.932551783403426 ], [ -86.45653673555816, 32.867205200819043 ] ] ] } },
//                                 { "type": "Feature", "properties": { "Damage": "Low Risk of Damage" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -85.511474284102107, 32.904552009115335 ], [ -85.522592665883934, 32.408432613927189 ], [ -84.755424322937245, 32.436588270113099 ], [ -84.888844904319285, 32.904552009115335 ], [ -85.511474284102107, 32.904552009115335 ] ] ] } },
//                                 { "type": "Feature", "properties": { "Damage": "Risk of Internal Damage" }, "geometry": { "type": "Polygon", "coordinates": [ [ [ -84.577530214427881, 32.848525895305073 ], [ -84.544175069082357, 32.32391294473009 ], [ -83.643586144753641, 32.427204027874168 ], [ -83.821480253263019, 32.91388625144517 ], [ -84.577530214427881, 32.848525895305073 ] ] ] } }
//                             ]
//                         },
//                         "paint": {
//                             "fill-color": [
//                                 "match",
//                                 [
//                                     "get",
//                                     "Damage"
//                                 ],
//                                 "No Damage", "rgba(33,150,243,0.4)",
//                                 "Light Damage", "rgba(0,175,37,0.4)",
//                                 "Moderate Damage", "rgba(175,220,0,0.4)",
//                                 "Substantial Damage", "rgba(253,196,0,0.4)",
//                                 "Severe Damage", "rgba(251,122,0,0.4)",
//                                 "Destroyed", "rgba(244,11,0,0.4)",
//                                 "Low Risk of Damage", "rgba(102,115,131,0.4)",
//                                 "Risk of Internal Damage", "rgba(131,124,97,0.4)",
//                                 "rgba(136,136,136,0.4)"
//                             ],
//                             "fill-outline-color": [
//                                 "match", ["get", "Damage"],
//                                 "No Damage", "rgba(33,150,243,0.4)",
//                                 "Light Damage", "rgba(0,175,37,0.4)",
//                                 "Moderate Damage", "rgba(175,220,0,0.4)",
//                                 "Substantial Damage", "rgba(253,196,0,0.4)",
//                                 "Severe Damage", "rgba(251,122,0,0.4)",
//                                 "Destroyed", "rgba(244,11,0,0.4)",
//                                 "Low Risk of Damage", "rgb(0,116,255)",
//                                 "Risk of Internal Damage", "rgb(253,196,0)",
//                                 "rgba(136,136,136,0.4)"
//                             ]
//                         },
//                         "layout": {
//                             "visibility": "visible"
//                         },
//                         "viewOn": "both",
//                         "actions": {
//                             "zoomTo": {
//                                 "zoom": 2.462537553858533,
//                                 "latitude": 23.914668294139005,
//                                 "longitude": -79.09432638858253
//                             }
//                         },
//                         "layerType": "fill",
//                         "dataConfig": {}
//                     },
//                 },
//                 menuIndex: [
//                     {
//                         id: generateHash(),
//                         type: "layer",
//                         layerName: "New Damage Category",
//                         layerSource: "New Damage Category",
//                     }
//                 ]
//             }
//         }
//     ]
// };
//
// const timelineTestConfig: AppConfig = {
//     datasets: {},
//     theme: "dark",
//     timelineConfig:{
//         range: {
//             min: "10/10/2022",
//             max: "14/10/2022",
//             format: "DD/MM/YYYY",
//         },
//         secondsPerUpdate: 1,
//         hoursPerUpdate: 12,
//     },
//     components: [
//         {
//             type: "map",
//             display: {
//                 topLeft: [0, 0],
//                 width: 5,
//                 height: 4,
//                 view: "report"
//             },
//             options: {
//                 mapboxToken: "pk.eyJ1IjoibWlrZS1taXMiLCJhIjoidjBXS01iUSJ9.RW5YqGmR3hyfu28gO7l8ow",
//                 mapType: "single",
//                 basemapOptions: {
//                     dark: "mike-mis/cjz9un4sf01c91cp4qtyxs2vg",
//                     light: "mike-mis/ckcfxe3fg0krb1iqrcm0fiyy1",
//                     satellite: "mike-mis/ckebg0mb317xs1aqlqcbspm7y"
//                 },
//                 viewport: {
//                     latitude: 30.3,
//                     longitude: -87,
//                     zoom: 3
//                 },
//                 sources: {
//                     "timeline": {
//                         "type": "geojson",
//                         data: {
//                             "type": "FeatureCollection",
//                             "name": "timeline_test",
//                             "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
//                             "features": [
//                                 { "type": "Feature", "properties": { "id": 1, "date": "10/10/2022" }, "geometry": { "type": "Point", "coordinates": [ -2.797824951405065, 52.42932935539276 ] } },
//                                 { "type": "Feature", "properties": { "id": 2, "date": "11/10/2022" }, "geometry": { "type": "Point", "coordinates": [ -2.744697270385308, 52.430154673691298 ] } },
//                                 { "type": "Feature", "properties": { "id": 3, "date": "12/10/2022" }, "geometry": { "type": "Point", "coordinates": [ -2.693599946601974, 52.431186299832575 ] } },
//                                 { "type": "Feature", "properties": { "id": 4, "date": "13/10/2022" }, "geometry": { "type": "Point", "coordinates": [ -2.643517801436852, 52.433249479676256 ] } }
//                             ]
//                         },
//                         "paint": {
//                             "circle-color": "#084",
//                             "circle-radius": 5,
//                             "circle-stroke-width": 2,
//                             "circle-stroke-color": "#fff"
//                         },
//                         "layout": {
//                             "visibility": "visible"
//                         },
//                         "viewOn": "both",
//                         "actions": {
//                             "zoomTo": {
//                                 "zoom": 2.462537553858533,
//                                 "latitude": 23.914668294139005,
//                                 "longitude": -79.09432638858253
//                             }
//                         },
//                         "layerType": "circle",
//                         "dataConfig": {
//                             // timeline: {
//                             //     type: 'filter-on',
//                             //     data: {
//                             //         type: 'internal',
//                             //         dateColumnName: "date",
//                             //         format: "DD/MM/YYYY hh:mm",
//                             //     }
//                             // }
//                         }
//                     },
//                     "timeline_to_from": {
//                         "type": "geojson",
//                         data: {
//                             "type": "FeatureCollection",
//                             "name": "timeline_test_to_from",
//                             "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
//                             "features": [
//                                 { "type": "Feature", "properties": { "id": 1, "date_from": "10/10/2022", "date_to": "10/10/2022 11:59" }, "geometry": { "type": "Point", "coordinates": [ -2.797136829918196, 52.411307760888242 ] } },
//                                 { "type": "Feature", "properties": { "id": 2, "date_from": "10/10/2022 12:00", "date_to": "10/10/2022 23:59" }, "geometry": { "type": "Point", "coordinates": [ -2.772445485949767, 52.412200513244429 ] } },
//                                 { "type": "Feature", "properties": { "id": 3, "date_from": "11/10/2022", "date_to": "11/10/2022 11:59" }, "geometry": { "type": "Point", "coordinates": [ -2.746050841876257, 52.413851775772116 ] } },
//                                 { "type": "Feature", "properties": { "id": 4, "date_from": "11/10/2022 12:00", "date_to": "11/10/2022 23:59" }, "geometry": { "type": "Point", "coordinates": [ -2.718641019184535, 52.414470983280793 ] } },
//                                 { "type": "Feature", "properties": { "id": 5, "date_from": "12/10/2022", "date_to": "12/10/2022 11:59" }, "geometry": { "type": "Point", "coordinates": [ -2.693261553729236, 52.415915766990985 ] } },
//                                 { "type": "Feature", "properties": { "id": 6, "date_from": "12/10/2022 12:00", "date_to": "12/10/2022 23:59" }, "geometry": { "type": "Point", "coordinates": [ -2.666190123910252, 52.417360503367604 ] } },
//                                 { "type": "Feature", "properties": { "id": 7, "date_from": "13/10/2022", "date_to": "13/10/2022 11:59" }, "geometry": { "type": "Point", "coordinates": [ -2.64250262281864, 52.417566890414633 ] } }
//                             ]
//                         }
//                         ,
//                         "paint": {
//                             "circle-color": "#048",
//                             "circle-radius": 5,
//                             "circle-stroke-width": 2,
//                             "circle-stroke-color": "#fff"
//                         },
//                         "layout": {
//                             "visibility": "visible"
//                         },
//                         "viewOn": "both",
//                         "actions": {
//                             "zoomTo": {
//                                 "zoom": 2.462537553858533,
//                                 "latitude": 23.914668294139005,
//                                 "longitude": -79.09432638858253
//                             }
//                         },
//                         "layerType": "circle",
//                         "dataConfig": {
//                             timeline: {
//                                 type: 'filter-between',
//                                 data: {
//                                     type: 'internal',
//                                     dateColumnName: "date_from",
//                                     dateToColumnName: "date_to",
//                                     format: "DD/MM/YYYY hh:mm",
//                                 }
//                             }
//                         }
//                     },
//                 },
//                 menuIndex: [
//                     {
//                         id: generateHash(),
//                         type: "layer",
//                         layerName: "timeline",
//                         layerSource: "timeline",
//                     },
//                     {
//                         id: generateHash(),
//                         type: "layer",
//                         layerName: "timeline_to_from",
//                         layerSource: "timeline_to_from",
//                     }
//                 ]
//             }
//         }
//     ]
// };

export default reportConfig;
