//@ts-nocheck
import React from "react";
import { TutorialConfig } from "./index";
import { NEW_THEME_COOKIE, getCookie } from "../../utils/Cookies";
import { mdiDotsHorizontal } from "@mdi/js";
import Icon from "@mdi/react";

const tutorial: TutorialConfig = {
    name: "Basemaps",
    description: "How to use the basemap control.",
    tags: ["maps"],
    appConfig: {
        reportId: "",
        components: [
            {
                type: "map",
                options: {
                    mapType: "single",
                    sources: {
                        "0dbg1h130w": {
                            url: "mapbox://mike-mis.3pw2dlgi",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___1-6y0zh2",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.72108093950247, 33.47746009087332,
                                        -89.68570676081177, 33.51349546743882,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        "6krt1hlbcd": {
                            url: "mapbox://mike-mis.47emb53o",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area2_JPEG_YCBCR_EPSG3857__0-850b2s",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.53836562451387, 33.05556452484732,
                                        -90.50645930739002, 33.13707889711214,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        "7h8d3j9kec": {
                            url: "mapbox://mike-mis.06jp75dc",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area2_JPEG_YCBCR_EPSG3857__2-49b7cf",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.50809559485357, 33.054354336628045,
                                        -90.47989046548847, 33.138149852904014,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        "8bn7ebuxgb": {
                            url: "mapbox://mike-mis.4zn2pab4",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___5-a9994u",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.72108093950247, 33.43879717753206,
                                        -89.68309574179834, 33.477460809875794,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        "98c4pb4auy": {
                            url: "mapbox://mike-mis.7fre6nt7",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgba(102,115,131,0.4)",
                                    "Risk of Internal Damage",
                                    "rgba(131,124,97,0.4)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(80,80,80,0.4)",
                                ],
                                "fill-outline-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgb(0,116,255)",
                                    "Risk of Internal Damage",
                                    "rgb(253,196,0)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(136,136,136,0.4)",
                                ],
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Mississippi_Tornado_5x5_Expos-55w4nc",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -91.03104032131196, 32.81029592910344,
                                        -83.12586582106017, 35.156365209490026,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        a4mqq12b4w: {
                            url: "mapbox://mike-mis.9vveg431",
                            type: "raster",

                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area2_JPEG_YCBCR_EPSG3857__1-4eoo3u",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.48135324635484, 33.054354336628045,
                                        -90.45712932034594, 33.138149852904014,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        axzo23pvk1: {
                            url: "mapbox://mike-mis.b08su6pp",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgba(102,115,131,0.4)",
                                    "Risk of Internal Damage",
                                    "rgba(131,124,97,0.4)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(80,80,80,0.4)",
                                ],
                                "fill-outline-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgb(0,116,255)",
                                    "Risk of Internal Damage",
                                    "rgb(253,196,0)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(136,136,136,0.4)",
                                ],
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Mississippi_Tornado_1x1_Claim-0f65ci",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90527618153523, 32.88576448226199,
                                        -88.43490915020655, 34.017491185170485,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        b89vt2q932: {
                            url: "mapbox://mike-mis.aelbrrn5",
                            type: "raster",

                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___4-bd26s6",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.78982426874745, 33.43653919272074,
                                        -89.75080552472537, 33.47650381230386,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        bn190kk6ni: {
                            url: "mapbox://mike-mis.38vbdp34",
                            type: "vector",
                            paint: {
                                "fill-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgba(102,115,131,0.4)",
                                    "Risk of Internal Damage",
                                    "rgba(131,124,97,0.4)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(80,80,80,0.4)",
                                ],
                                "fill-outline-color": [
                                    "match",
                                    ["get", "Damage"],
                                    "No Damage",
                                    "rgba(33,150,243,0.4)",
                                    "Light Damage",
                                    "rgba(0,175,37,0.4)",
                                    "Moderate Damage",
                                    "rgba(175,220,0,0.4)",
                                    "Substantial Damage",
                                    "rgba(253,196,0,0.4)",
                                    "Severe Damage",
                                    "rgba(251,122,0,0.4)",
                                    "Destroyed",
                                    "rgba(244,11,0,0.4)",
                                    "Low Risk of Damage",
                                    "rgb(0,116,255)",
                                    "Risk of Internal Damage",
                                    "rgb(253,196,0)",
                                    "Damage Cannot be Ascertained",
                                    "rgba(136,136,136,0.4)",
                                    "rgba(136,136,136,0.4)",
                                ],
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Mississippi_Building_Level_Da-1se6q0",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90094295969713, 32.89585047538141,
                                        -88.47977662209021, 34.00263817722039,
                                    ],
                                },
                            },
                            layerType: "fill",
                            complexPaintProperties: [],
                        },
                        cgykefep7i: {
                            url: "mapbox://mike-mis.b6cffiwk",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "RollingFork_JPEG_YCBCR_EPSG38-bo919e",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.88192578207787, 32.87573855341861,
                                        -90.86212906065536, 32.931753744607796,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        eas0gokjws: {
                            url: "mapbox://mike-mis.114l21tx",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "RollingFork_JPEG_YCBCR_EPSG38-7ovexj",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.86302921748124, 32.87596334279747,
                                        -90.8461722108755, 32.931753744607796,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        j4se1bw0ex: {
                            url: "mapbox://mike-mis.7h1yqmwn",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "RollingFork_JPEG_YCBCR_EPSG38-3977hc",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.90558704721552, 32.87573855341861,
                                        -90.88158784944889, 32.931416772692366,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        jc799kkjtj: {
                            url: "mapbox://mike-mis.6f40vpin",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Amory_JPEG_YCBCR_EPSG3857_Cli-bfi4dx",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -88.51618000159509, 33.97223816722221,
                                        -88.43760386546629, 34.02000208971503,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        qdbpdsvmbv: {
                            url: "mapbox://mike-mis.0y7jfuze",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___6-3bifsp",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.75170459796108, 33.437869245680474,
                                        -89.71914918195574, 33.477460809875794,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        vzmjas5esp: {
                            url: "mapbox://mike-mis.0lyeng8b",
                            type: "vector",
                            paint: {
                                "line-color": [
                                    "match",
                                    ["get", "Wind Speed"],
                                    "< 65 mph",
                                    "#003bbe",
                                    "65 - 85 mph",
                                    "#0087E9",
                                    "86 - 110 mph",
                                    "#15cb00",
                                    "111 - 135 mph",
                                    "#eefb00",
                                    "136 - 165 mph",
                                    "#fba300",
                                    "166 - 200 mph",
                                    "#fb0000",
                                    "#888",
                                ],
                                "line-width": 3,
                                "line-dasharray": [1, 0.5],
                            },
                            layout: {
                                visibility: "none",
                            },
                            source: "Mississippi_Preliminary_Track-4d9rjp",
                            viewOn: "both",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -90.999299222, 32.84139758700002,
                                        -83.14714371835633, 35.15158775899999,
                                    ],
                                },
                            },
                            layerType: "line",
                            complexPaintProperties: [],
                        },
                        xt5x4awivj: {
                            url: "mapbox://mike-mis.1wxh9y1g",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___2-aky3go",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.75170459796108, 33.47631327433539,
                                        -89.72106628538454, 33.51349546743882,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        ydepz64a3m: {
                            url: "mapbox://mike-mis.5vli2zev",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Amory_JPEG_YCBCR_EPSG3857_Cli-b51uvh",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -88.51618000159509, 33.97223816722221,
                                        -88.43760386546629, 34.02000208971503,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                        zsyjbfz0fz: {
                            url: "mapbox://mike-mis.4ms1gt5r",
                            type: "raster",
                            paint: {
                                "raster-opacity": 1,
                            },
                            layout: {
                                visibility: "visible",
                            },
                            source: "Area3_JPEG_YCBCR_EPSG3857___0-05bttq",
                            viewOn: "right",
                            actions: {
                                zoomTo: {
                                    bbox: [
                                        -89.7880218122413, 33.47631327433539,
                                        -89.75083741898204, 33.513458094860184,
                                    ],
                                },
                            },
                            layerType: "raster",
                            complexPaintProperties: [],
                        },
                    },
                    viewport: {
                        zoom: 11.97988,
                        latitude: 33.47627,
                        longitude: -89.73407,
                    },
                    menuIndex: [
                        {
                            id: "z3lgcs8jvu",
                            type: "group",
                            children: [
                                {
                                    id: "a7yaz9bllm",
                                    type: "layer",
                                    layerName: "Preliminary Tracks",
                                    layerSource: "vzmjas5esp",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "b8rfuggxas",
                                    type: "layer",
                                    layerName: "5km x 5km Exposure Grid",
                                    layerSource: "98c4pb4auy",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                            ],
                            groupName: "Exposure",
                        },
                        {
                            id: "lchwhl2m3i",
                            type: "group",
                            children: [
                                {
                                    id: "866060eayj",
                                    type: "layer",
                                    layerName: "1km x 1km Claims Grid",
                                    layerSource: "axzo23pvk1",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                                {
                                    id: "kxytrkwl8j",
                                    type: "layer",
                                    layerName: "Building Level Damage - Beta",
                                    layerSource: "bn190kk6ni",
                                    layerType: "claims",
                                    layerTypeId:
                                        "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                    beta: false,
                                    tier: "basic",
                                },
                            ],
                            groupName: "Claims",
                        },
                        {
                            id: "8c0tzj03ei",
                            type: "group",
                            asLayer: false,
                            children: [
                                {
                                    id: "nbzcinlwfi",
                                    type: "group",
                                    asLayer: true,
                                    children: [
                                        {
                                            id: "dmeyx3fl7u",
                                            type: "layer",
                                            layerName:
                                                "Rolling Fork Tile 1 - 26 March",
                                            layerSource: "cgykefep7i",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "7rimrn4lpa",
                                            type: "layer",
                                            layerName:
                                                "Rolling Fork Tile 2 - 26 March  ",
                                            layerSource: "eas0gokjws",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "k7auhhr3p8",
                                            type: "layer",
                                            layerName:
                                                "Rolling Fork Tile 3 - 26 March     ",
                                            layerSource: "j4se1bw0ex",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Rolling Fork - 26 March",
                                },
                                {
                                    id: "7k6a6ru4wy",
                                    type: "group",
                                    asLayer: true,
                                    children: [
                                        {
                                            id: "3ld6bnc3qz",
                                            type: "layer",
                                            layerName:
                                                "Amory Tile 1 - 26 March    ",
                                            layerSource: "ydepz64a3m",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "5hyvzhs5ef",
                                            type: "layer",
                                            layerName:
                                                "Amory Tile 2 - 26 March       ",
                                            layerSource: "jc799kkjtj",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Amory - 26 March   ",
                                },
                                {
                                    id: "n369c8mk6",
                                    type: "group",
                                    asLayer: true,
                                    children: [
                                        {
                                            id: "zly57zmcev",
                                            type: "layer",
                                            layerName:
                                                "Silver City Tile 1 - 26 March        ",
                                            layerSource: "7h8d3j9kec",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "rhh9gycbt9",
                                            type: "layer",
                                            layerName:
                                                "Silver City Tile 1 - 26 March         ",
                                            layerSource: "a4mqq12b4w",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "bke7jgbpxo",
                                            type: "layer",
                                            layerName:
                                                "Silver City Tile 1 - 26 March          ",
                                            layerSource: "6krt1hlbcd",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Silver City - 26 March   ",
                                },
                                {
                                    id: "o61pcuzwoh",
                                    type: "group",
                                    asLayer: true,
                                    children: [
                                        {
                                            id: "s2jm1gkq3r",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 1 - 26 March           ",
                                            layerSource: "b89vt2q932",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "r6ibg6bwy1",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 2 - 26 March            ",
                                            layerSource: "8bn7ebuxgb",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "kii6m15sab",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 3 - 26 March             ",
                                            layerSource: "zsyjbfz0fz",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "e09cqb8xis",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 4 - 26 March              ",
                                            layerSource: "qdbpdsvmbv",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "hxl2kxephy",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 5 - 26 March               ",
                                            layerSource: "0dbg1h130w",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                        {
                                            id: "u0t5eo8t9",
                                            type: "layer",
                                            layerName:
                                                "Winona Tile 6 - 26 March                ",
                                            layerSource: "xt5x4awivj",
                                            layerType: "claims",
                                            layerTypeId:
                                                "223deb3c-4c95-4a8d-927f-ec85b04c28f5",
                                            beta: false,
                                            tier: "basic",
                                        },
                                    ],
                                    groupName: "Winona - 26 March    ",
                                },
                            ],
                            groupName: "7.5cm Imagery",
                        },
                    ],
                },
            },
            {
                type: "text",
                options: {
                    content: [
                        {
                            id: "ckymrc01tr",
                            text: "This exposure report delivers intelligence from the ground to users in the immediate aftermath of an event. It provides an initial assessment of the areas likely exposed to the event, and an indication of the likelihood of potential damage. Overlay your data, search for an individual location or download the shapefiles for use in your own system to understand your exposure.\n\n",
                            title: "Welcome",
                        },
                    ],
                },
            },
            {
                type: "summary",
                options: {
                    downloads: {},
                },
            },
        ],
    },
    version: 3.0,
    steps: [
        {
            content: "Welcome to the Basemap Tutorial",
            target: "body",
            placement: "center",
            disableOverlayClose: true,
        },
        {
            content: (
                <>
                    <p>
                        You will always find the basemap control in the lower
                        corner of the map.
                    </p>
                    <p>
                        Click the highlighted basemap to show what options we
                        have.
                    </p>
                </>
            ),
            placement: "right",
            spotlightClicks: true,
            disableOverlayClose: true,
            disableScrolling: true,
            target: "#tourid_BasemapControl",
            targetTriggerEvent: "click",
            styles: {
                buttonNext: {
                    display: "none",
                },
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
            },
        },
        {
            displayDelay: 100,
            content: (
                <>
                    <p>
                        There are three ways to view a basemap. Light mode, Dark
                        mode, and Satellite mode.
                    </p>
                    <p>Let's ensure we are viewing this map in "Light mode".</p>
                </>
            ),
            disableBeacon: false,
            spotlightClicks: true,
            target: "#tourid_Minimap_Light",
            placement: "top",
            targetTriggerEvent: "click",
            disableScrolling: true,
            styles: {
                buttonNext: {
                    display: "none",
                },
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
            },
        },
        {
            displayDelay: 100,
            content: (
                <>
                    <p>
                        As you can see, the basemap has changed to light mode.
                    </p>
                </>
            ),
            spotlightClicks: true,
            target: "body",
            placement: "center",
            disableScrolling: true,
        },
        {
            content: (
                <>
                    <p>
                        The tool bar can be used to select map layouts or
                        measuring tools.
                    </p>
                </>
            ),
            placement: "right",
            disableBeacon: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            hideBackButton: true,
            target: "#tourid_Toolbar",
            disableScrolling: true,
        },
        {
            content: (
                <>
                    <p>
                        The bottom icon shows us the currently active layout
                        we're using.
                    </p>
                    <p>
                        Click on it to see the other layouts we can make use of.
                    </p>
                </>
            ),
            placement: "right",
            disableBeacon: true,
            disableScrolling: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            hideBackButton: true,
            target: "#tourid_MapLayout",
            targetTriggerEvent: "click",
            spotlightPadding: 0,
            styles: {
                buttonNext: {
                    display: "none",
                },
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
            },
        },
        {
            displayDelay: 100,
            content: (
                <>
                    <p>
                        There are three ways to view the map. Single Map, Dual
                        Map, and Slider Map.
                    </p>
                    <p>Click on Dual Map to show two basemaps at one time.</p>
                </>
            ),
            placement: "bottom",
            target: "#tourid_MapLayoutDual",
            disableBeacon: true,
            hideCloseButton: true,
            spotlightClicks: true,
            disableOverlayClose: true,
            disableScrolling: true,
            targetTriggerEvent: "click",
            styles: {
                buttonNext: {
                    display: "none",
                },
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
            },
        },
        {
            displayDelay: 100,
            content: (
                <>
                    <p>
                        Notice each side of the map holds its own basemap
                        versions. You can change these independently from one
                        another.
                    </p>
                </>
            ),
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            hideBackButton: true,
            disableOverlayClose: true,
            target: "body",
        },
        {
            content: (
                <>
                    <p>
                        Selecting the best basemap for the data will aide your
                        analysis and interpretation.
                    </p>
                    <p>
                        Let's change to the slider map view by first selecting
                        our currently active layout.
                    </p>
                </>
            ),
            placement: "auto",
            target: "#tourid_MapLayout",
            disableBeacon: true,
            spotlightClicks: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            hideBackButton: true,
            targetTriggerEvent: "click",
            spotlightPadding: 0,
            styles: {
                buttonNext: {
                    display: "none",
                },
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
            },
        },
        {
            displayDelay: 100,
            content: (
                <>
                    <p>Select the slider icon to proceed.</p>
                </>
            ),
            target: "#tourid_MapLayoutSlider",
            placement: "right",
            targetTriggerEvent: "click",
            spotlightClicks: true,
            disableScrolling: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            styles: {
                buttonNext: {
                    display: "none",
                },
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
            },
        },
        {
            displayDelay: 500,
            content: (
                <>
                    <p>
                        Use the slider in the middle of the map to reveal
                        details of an alternative layer.
                    </p>
                    <p>
                        This is particularly useful when comparing imagery from
                        an event.
                    </p>
                    <p>
                        Let's take a look at how we can display different data
                        on different sides of the map.
                    </p>
                </>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            target: "body",
            placement: "center",
        },
        {
            content: (
                <>
                    <p>
                        We can select each individual layer, and decide where to
                        display them.
                    </p>
                    <p>
                        Click on the <Icon path={mdiDotsHorizontal} size={1} />{" "}
                        icon to open up the context menu of the "1km x 1km
                        Claims Grid" Layer
                    </p>
                </>
            ),
            placement: "auto",
            target: "#tourid866060eayj [class*='LayerMenu'] svg",
            targetTriggerEvent: "click",
            disableBeacon: true,
            spotlightClicks: true,
            disableOverlayClose: true,
            styles: {
                buttonNext: {
                    display: "none",
                },
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
            },
        },
        {
            content: (
                <>
                    <p>
                        Finally, select "Right" to display this layer on only
                        the right side of the map.
                    </p>
                </>
            ),
            displayDelay: 100,
            placement: "auto",
            target: "#tourid866060eayj div[class*='ContextMenu'] span:last-child",
            disableBeacon: true,
            spotlightClicks: true,
            disableOverlayClose: true,
            styles: {
                buttonNext: {
                    display: "none",
                },
                spotlight: {
                    outlineColor: "rgb(255, 0, 68)",
                },
            },
            targetTriggerEvent: "click",
        },
        {
            content: (
                <>
                    <p>Now, on your own!</p>
                    <p>
                        Use the context menu of the "5km x 5km Exposure Grid" to
                        display this layer on the left. The same way we did with
                        the first layer.
                    </p>
                    <p>
                        Click <b>Next</b> when you are done.
                    </p>
                </>
            ),
            placement: "auto",
            target: "#tourId_LayerBody",
            disableBeacon: true,
            spotlightClicks: true,
            disableOverlayClose: true,
        },
        {
            content: (
                <>
                    <p>
                        Notice how now you can see both layers in one view, and
                        can use the slider to adjust which layer you see over a
                        location.
                    </p>
                    <p>This concludes our overview of GEO Basemaps.</p>
                </>
            ),
            target: "#tourid_MapComponentContainer",
            placement: "center",
            disableOverlayClose: false,
            disableBeacon: true,
            disableOverlay: false,
            disableScrolling: true,
            spotlightClicks: false,
        },
    ],
    imageUrl: `https://geoassets.blob.core.windows.net/${
        import.meta.env.VITE_ENVIRONMENT
    }/basemaps_lc_bg_${getCookie(NEW_THEME_COOKIE) ?? "dark"}.png`,
};

export default tutorial;
