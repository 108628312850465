import moment from "moment";
import * as _ from "lodash";

import {
    ADD_DOWNLOAD,
    ADD_NEW_GROUP,
    ADD_NEW_LAYER,
    ADD_PRODUCT,
    ADD_SOURCE_API_RESPONSE,
    ADD_TEXT_TAB,
    AddDownloadAction,
    AddNewGroupAction,
    AddNewLayerAction,
    AddProductAction,
    AddSourceApiResponseAction,
    AddTextTabAction,
    BuilderActionTypes,
    BuilderState,
    ConfigSource_MB,
    DUPLICATE_SELECTED_MENU_ITEM,
    DuplicateSelectedMenuItemAction,
    LayerIndex,
    MOVE_ITEM_TO_GROUP,
    MOVE_ITEM_TO_ITEM,
    MoveItemToGroupAction,
    MoveItemToItemAction,
    REMOVE_DOWNLOAD,
    REMOVE_SELECTED_MENU_ITEM,
    REMOVE_TEXT_TAB,
    RemoveDownloadAction,
    RemoveSelectedMenuItemAction,
    RemoveTextTabAction,
    RemoveTimelineAction,
    RENAME_TEXT_TAB,
    RenameTextTabAction,
    SET_DRAGGED_MENU_ITEM,
    SET_MAP_BUILDER_CONFIG,
    SET_MAP_BUILDER_DISABLED,
    SET_PRODUCT_CONFIG,
    SET_SELECTED_MENU_ITEM,
    SetConfigAction,
    SetDraggedMenuItemAction,
    SetMapBuilderDisabledAction,
    SetProductConfigAction,
    SetSelectedMenuItemAction,
    TOGGLE_LAYER_TIMELINE,
    TOGGLE_TIMELINE,
    ToggleLayerTimelineAction,
    UPDATE_ITEM_NAME,
    UPDATE_LAYER_META,
    UPDATE_LAYER_STYLE,
    UPDATE_LAYER_TIMELINE_FIELDS,
    UPDATE_LAYER_TIMELINE_TYPE,
    UPDATE_MAP_META,
    UPDATE_MAP_VIEW_OVERRIDES,
    UPDATE_SUMMARY_META,
    UPDATE_TEXT,
    UPDATE_TIMELINE_RANGE,
    UPDATE_TIMELINE_SECONDS_HOURS,
    UpdateLayerMetaAction,
    UpdateItemNameAction,
    UpdateLayerStyleAction,
    UpdateLayerTimelineFieldsAction,
    UpdateLayerTimelineTypeAction,
    UpdateMapMetaAction,
    UpdateMapViewOverridesAction,
    UpdateSummaryMetaAction,
    UpdateTextAction,
    UpdateTimelineRangeAction,
    UpdateTimelineSecondsHoursAction,
    UPDATE_GROUP_AS_LAYER,
    UpdateGroupMetaAction,
} from "./builderTypes";

import {
    AppConfig,
    ConfigMenuGroup,
    ConfigMenuLayer,
    MapComponentConfig,
    SummaryComponentConfig,
    TextComponentConfig,
} from "store/system/systemTypes";
import { ConfigSource, MapConfig } from "../map/mapTypes";
import { Dict } from "../../types/misgis";
import { generateHash } from "../../utils/Hash";
import { MOMENT_DATE_FORMAT } from "index";
import { StyleConfig, StyleConfig_Style } from "crud/layerStylesCRUD";

const sourceTypeSwitch = Object.freeze({
    Vector: "vector",
    Raster: "raster",
});

const layerTypeSwitch = Object.freeze({
    Point: "circle",
    Line: "line",
    Polygon: "fill",
    Symbol: "symbol",
    Raster: "raster",
});

export const templateConfig: AppConfig = {
    components: [
        {
            type: "map",
            options: {
                mapType: "single",
                viewport: {
                    latitude: 0,
                    longitude: 0,
                    zoom: 0,
                },
                sources: {},
                menuIndex: [],
            },
        },
        {
            type: "text",
            options: {
                content: [
                    {
                        title: "Welcome",
                        text: "",
                        id: generateHash(),
                    },
                    {
                        title: "Help",
                        text: "",
                        id: generateHash(),
                    },
                    {
                        title: "Release Schedule",
                        text: "The current view is Exposure Layer Version 1 defined as E1\n\n\n---\n\n## Timeline\n\n DD/MM/YY - E1\n\n\n---\n\n## Change Log\n\n__E1__\n\n",
                        id: generateHash(),
                    },
                ],
            },
        },
        {
            type: "summary",
            options: {
                downloads: {},
            },
        },
    ],
};

const initState: BuilderState = {
    productConfig: null,
    selectedMenuItem: null,
    draggedMenuItem: null,
    outputConfig: null,
    tilesetApiResponses: {},
    disabled: true,
    mapBuilderMetadata: {
        overrides: {
            latitude: 0,
            longitude: 0,
            zoom: 0,
        },
        minBounds: null,
        minZoom: 0,
    },
};

export const builderReducer = (
    state = initState,
    action: BuilderActionTypes,
): BuilderState => {
    switch (action.type) {
        case SET_MAP_BUILDER_CONFIG:
            return Reduce_SetMapBuilderConfig(state, action);
        case SET_MAP_BUILDER_DISABLED:
            return Reduce_SetMapBuilderDisabled(state, action);
        case SET_PRODUCT_CONFIG:
            return Reduce_SetProductConfig(state, action);
        case UPDATE_MAP_META:
            return Reduce_UpdateMapMeta(state, action);
        case UPDATE_MAP_VIEW_OVERRIDES:
            return Reduce_UpdateMapViewOverrides(state, action);
        case ADD_PRODUCT:
            return Reduce_AddProduct(state, action);
        case SET_DRAGGED_MENU_ITEM:
            return Reduce_SetDraggedMenuItem(state, action);
        case SET_SELECTED_MENU_ITEM:
            return Reduce_SetSelectedMenuItem(state, action);
        case DUPLICATE_SELECTED_MENU_ITEM:
            return Reduce_DuplicateSelectedMenuItem(state, action);
        case REMOVE_SELECTED_MENU_ITEM:
            return Reduce_RemoveSelectedMenuItem(state, action);
        case ADD_NEW_LAYER:
            return Reduce_AddNewLayer(state, action);
        case ADD_NEW_GROUP:
            return Reduce_AddNewGroup(state, action);
        case MOVE_ITEM_TO_GROUP:
            return Reduce_MoveItemToGroup(state, action);
        case MOVE_ITEM_TO_ITEM:
            return Reduce_MoveItemToItem(state, action);
        case UPDATE_ITEM_NAME:
            return Reduce_UpdateItemName(state, action);
        case UPDATE_LAYER_STYLE:
            return Reduce_UpdateLayerStyle(state, action);
        case UPDATE_LAYER_META:
            return Reduce_UpdateLayerMeta(state, action);
        case UPDATE_GROUP_AS_LAYER:
            return Reduce_UpdateGroupAsLayer(state, action);
        case UPDATE_TEXT:
            return Reduce_UpdateText(state, action);
        case REMOVE_TEXT_TAB:
            return Reduce_RemoveTextTab(state, action);
        case ADD_TEXT_TAB:
            return Reduce_AddTextTab(state, action);
        case RENAME_TEXT_TAB:
            return Reduce_RenameTextTab(state, action);
        case UPDATE_SUMMARY_META:
            return Reduce_UpdateSummaryMeta(state, action);
        case ADD_DOWNLOAD:
            return Reduce_AddDownload(state, action);
        case REMOVE_DOWNLOAD:
            return Reduce_RemoveDownload(state, action);
        case TOGGLE_TIMELINE:
            return Reduce_ToggleTimeline(state, action);
        case UPDATE_TIMELINE_RANGE:
            return Reduce_UpdateTimelineRange(state, action);
        case UPDATE_TIMELINE_SECONDS_HOURS:
            return Reduce_UpdateTimelineSecondsHours(state, action);
        case ADD_SOURCE_API_RESPONSE:
            return Reduce_AddSourceApiResponse(state, action);
        case TOGGLE_LAYER_TIMELINE:
            return Reduce_ToggleLayerTimeline(state, action);
        case UPDATE_LAYER_TIMELINE_FIELDS:
            return Reduce_UpdateLayerTimelineFields(state, action);
        case UPDATE_LAYER_TIMELINE_TYPE:
            return Reduce_UpdateLayerTimelineType(state, action);
        default:
            return state;
    }
};

const Reduce_SetMapBuilderConfig = (
    state: BuilderState,
    action: SetConfigAction,
): BuilderState => {
    // Workaround for when loading in a new config with the same text ids. Forces text editor refresh.
    if (action.payload) {
        let textConfig = action.payload.components.find(
            (element) => element.type === "text",
        );
        if (textConfig) {
            (textConfig as TextComponentConfig).options.content.forEach(
                (element) => (element.id = generateHash()),
            );
        }
    }
    return { ...state, outputConfig: action.payload };
};

const Reduce_SetMapBuilderDisabled = (
    state: BuilderState,
    action: SetMapBuilderDisabledAction,
): BuilderState => {
    return { ...state, disabled: action.payload };
};

const Reduce_SetProductConfig = (
    state: BuilderState,
    action: SetProductConfigAction,
): BuilderState => {
    return { ...state, productConfig: action.payload };
};

const Reduce_UpdateMapMeta = (
    state: BuilderState,
    action: UpdateMapMetaAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];

    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;

    mapComponent.options = {
        ...mapComponent.options,
        ...action.payload,
    };

    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};
const Reduce_UpdateMapViewOverrides = (
    state: BuilderState,
    action: UpdateMapViewOverridesAction,
): BuilderState => {
    return {
        ...state,
        mapBuilderMetadata: {
            ...state.mapBuilderMetadata,
            overrides: {
                ...state.mapBuilderMetadata.overrides,
                ...action.payload,
            },
        },
    };
};

const Reduce_AddProduct = (
    state: BuilderState,
    action: AddProductAction,
): BuilderState => {
    let productCategory = action.payload.categories[1];
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];

    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let textComponent = components.find(
        (component) => component.type === "text",
    ) as TextComponentConfig;

    let menuIndex = mapComponent.options.menuIndex;
    let sources = mapComponent.options.sources;
    let textContent = textComponent.options.content;

    let existingGroup = menuIndex.find((indexItem) => {
        return (
            indexItem.type === "group" &&
            indexItem.groupName === productCategory
        );
    }) as ConfigMenuGroup;

    let children: (ConfigMenuGroup | ConfigMenuLayer)[];
    if (existingGroup) {
        children = existingGroup.children;
    } else {
        if (
            !textContent.find(
                (item) => item.title === productCategory + " Report",
            )
        ) {
            textContent.push({
                title: productCategory + " Report",
                text: "",
                id: generateHash(),
            });
        }

        menuIndex.push({
            type: "group",
            id: generateHash(),
            groupName: productCategory,
            children: [],
        });
        children = (menuIndex[menuIndex.length - 1] as ConfigMenuGroup)
            .children;
    }

    addProductToConfig(
        action.payload.config.layerIndex,
        children,
        sources as Dict<ConfigSource_MB>,
        action.payload.reportLayerStyles,
    );

    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};

const Reduce_SetDraggedMenuItem = (
    state: BuilderState,
    action: SetDraggedMenuItemAction,
): BuilderState => {
    return { ...state, draggedMenuItem: action.payload };
};
const Reduce_SetSelectedMenuItem = (
    state: BuilderState,
    action: SetSelectedMenuItemAction,
): BuilderState => {
    return { ...state, selectedMenuItem: action.payload };
};
const Reduce_DuplicateSelectedMenuItem = (
    state: BuilderState,
    action: DuplicateSelectedMenuItemAction,
): BuilderState => {
    // if there is nothing selected there is nothing to do.
    if (state.selectedMenuItem === null) {
        return state;
    }

    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;

    let menuIndex = mapComponent.options.menuIndex;
    let selectedItem = state.selectedMenuItem!;
    let sources = mapComponent.options.sources;

    const duplicateSources = (
        menuIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
    ) => {
        for (let item of menuIndex) {
            item.id = generateHash();
            if (item.type === "group") {
                duplicateSources(item.children);
            } else if (item.type === "layer") {
                let sourceName = generateHash();
                sources[sourceName] = _.cloneDeep(sources[item.layerSource]);
                item.layerSource = sourceName;
            }
        }
    };

    const duplicateInTree = (
        menuIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
        selectedItem: ConfigMenuGroup | ConfigMenuLayer,
    ) => {
        for (let treeItemIndex in menuIndex) {
            let treeItem = menuIndex[treeItemIndex];
            if (treeItem.id === selectedItem.id) {
                let duplicateItem: ConfigMenuGroup | ConfigMenuLayer =
                    _.cloneDeepWith(selectedItem);
                duplicateSources([duplicateItem]);

                menuIndex.splice(parseInt(treeItemIndex + 1), 0, duplicateItem);
                return duplicateItem;
            } else {
                if (treeItem.type === "group") {
                    duplicateInTree(treeItem.children, selectedItem);
                }
            }
        }
    };

    let newSelected = duplicateInTree(menuIndex, selectedItem);

    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
        selectedMenuItem: newSelected!,
    };
};
const Reduce_RemoveSelectedMenuItem = (
    state: BuilderState,
    action: RemoveSelectedMenuItemAction,
): BuilderState => {
    // if there is nothing selected there is nothing to do.
    if (state.selectedMenuItem === null) {
        return state;
    }
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let menuIndex = mapComponent.options.menuIndex;
    let sources = mapComponent.options.sources;
    let selectedItem = state.selectedMenuItem!;

    removeItemFromMenuIndex(menuIndex, selectedItem.id, sources);

    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
        selectedMenuItem: null,
    };
};
const Reduce_AddNewLayer = (
    state: BuilderState,
    action: AddNewLayerAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let menuIndex = mapComponent.options.menuIndex;
    let sources = mapComponent.options.sources as Dict<ConfigSource_MB>;

    let sourceId = generateHash();

    let newItem: ConfigMenuLayer = {
        type: "layer",
        id: generateHash(),
        layerName: "New Layer",
        layerSource: sourceId,
    };
    sources[sourceId] = createSourceFromStyle(
        action.payload["Generic"]["Point - Red"],
    );
    menuIndex.push(newItem);
    return {
        ...state,
        outputConfig: { ...currentConfig, components: _.cloneDeep(components) },
    };
};
const Reduce_AddNewGroup = (
    state: BuilderState,
    action: AddNewGroupAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let menuIndex = mapComponent.options.menuIndex;

    let newItem: ConfigMenuGroup = {
        type: "group",
        id: generateHash(),
        groupName: "New Group",
        children: [],
        asLayer: false,
    };
    menuIndex.push(newItem);
    return {
        ...state,
        outputConfig: { ...currentConfig, components: _.cloneDeep(components) },
    };
};

const Reduce_MoveItemToGroup = (
    state: BuilderState,
    action: MoveItemToGroupAction,
): BuilderState => {
    // if there is nothing dragging there is nothing to do.
    if (
        state.draggedMenuItem === null ||
        action.payload.dropTargetId === state.draggedMenuItem.id
    ) {
        return state;
    }

    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let menuIndex = mapComponent.options.menuIndex;
    let newItem = _.cloneDeep({ ...state.draggedMenuItem, id: generateHash() });

    const pushItemToGroup = (
        menuIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
        dropTargetId: string,
    ) => {
        for (let treeItem of menuIndex) {
            if (treeItem.id === dropTargetId) {
                (treeItem as ConfigMenuGroup).children.push(newItem);
                return;
            } else if (treeItem.type === "group") {
                pushItemToGroup(treeItem.children, dropTargetId);
            }
        }
    };

    pushItemToGroup(menuIndex, action.payload.dropTargetId);
    removeItemFromMenuIndex(menuIndex, state.draggedMenuItem.id);

    return {
        ...state,
        outputConfig: { ...currentConfig, components: _.cloneDeep(components) },
    };
};
const Reduce_MoveItemToItem = (
    state: BuilderState,
    action: MoveItemToItemAction,
): BuilderState => {
    // if there is nothing dragging there is nothing to do.
    if (state.draggedMenuItem === null) {
        return state;
    }
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let menuIndex = mapComponent.options.menuIndex;

    let layerGroup;
    let layerIndex;

    const findItemGroup = (
        menuIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
        dropTargetId: string,
    ) => {
        for (let treeItemIndex in menuIndex) {
            let treeItem = menuIndex[treeItemIndex];

            if (treeItem.id === dropTargetId) {
                layerGroup = menuIndex;
                layerIndex = parseInt(treeItemIndex);
                layerIndex += action.payload.position === "above" ? 0 : 1;
                break;
            } else if (
                treeItem.type === "group" &&
                state.draggedMenuItem?.id !== treeItem.id
            ) {
                findItemGroup(treeItem.children, dropTargetId);
            }
        }
    };

    findItemGroup(menuIndex, action.payload.itemId);

    if (layerGroup !== undefined) {
        let newItem = _.cloneDeep({
            ...state.draggedMenuItem,
            id: generateHash(),
        });
        // @ts-ignore
        layerGroup.splice(layerIndex, 0, newItem);
        removeItemFromMenuIndex(menuIndex, state.draggedMenuItem.id);
        return {
            ...state,
            outputConfig: {
                ...currentConfig,
                components: _.cloneDeep(components),
            },
        };
    } else {
        return state;
    }
};
const Reduce_UpdateItemName = (
    state: BuilderState,
    action: UpdateItemNameAction,
): BuilderState => {
    // if there is nothing selected there is nothing to do.
    if (state.selectedMenuItem === null) {
        return state;
    }
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let menuIndex = mapComponent.options.menuIndex;
    let selectedItem = state.selectedMenuItem;

    if (selectedItem.type === "layer") {
        (
            findItemInTree(menuIndex, selectedItem.id) as ConfigMenuLayer
        ).layerName = action.payload;
    } else {
        (
            findItemInTree(menuIndex, selectedItem.id) as ConfigMenuGroup
        ).groupName = action.payload;
    }

    return {
        ...state,
        selectedMenuItem: selectedItem,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};
const Reduce_UpdateLayerStyle = (
    state: BuilderState,
    action: UpdateLayerStyleAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let selectedItem = state.selectedMenuItem;
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let sources = mapComponent.options.sources;
    sources[(selectedItem as ConfigMenuLayer).layerSource] = {
        ...sources[(selectedItem as ConfigMenuLayer).layerSource],
        ...createSourceFromStyle(
            action.payload,
            sources[(selectedItem as ConfigMenuLayer).layerSource],
        ),
    };
    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};

const Reduce_UpdateLayerMeta = (
    state: BuilderState,
    action: UpdateLayerMetaAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let selectedItem = state.selectedMenuItem;
    let sources = mapComponent.options.sources;

    sources[(selectedItem as ConfigMenuLayer).layerSource] = {
        ...sources[(selectedItem as ConfigMenuLayer).layerSource],
        ...action.payload,
    };

    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};

const Reduce_UpdateGroupAsLayer = (
    state: BuilderState,
    action: UpdateGroupMetaAction,
): BuilderState => {
    if (state.selectedMenuItem === null) {
        return state;
    }

    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let selectedItem = state.selectedMenuItem;
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let menuIndex = mapComponent.options.menuIndex;

    (findItemInTree(menuIndex, selectedItem.id) as ConfigMenuGroup).asLayer =
        action.payload;

    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};

const Reduce_UpdateText = (
    state: BuilderState,
    action: UpdateTextAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let textComponent = components.find(
        (component) => component.type === "text",
    ) as TextComponentConfig;
    let textItemChange = textComponent.options.content.find((textItem) => {
        return textItem.id === action.payload.id;
    });

    if (textItemChange) {
        textItemChange.text = action.payload.text;
        return { ...state, outputConfig: { ...currentConfig, components } };
    } else {
        return state;
    }
};
const Reduce_RemoveTextTab = (
    state: BuilderState,
    action: RemoveTextTabAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let textComponent = components.find(
        (component) => component.type === "text",
    ) as TextComponentConfig;
    let textItemArray = textComponent.options.content;
    let index = textItemArray.findIndex((textItem) => {
        return textItem.id === action.payload;
    });
    textItemArray.splice(index, 1);
    return { ...state, outputConfig: { ...currentConfig, components } };
};
const Reduce_AddTextTab = (
    state: BuilderState,
    action: AddTextTabAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let textComponent = components.find(
        (component) => component.type === "text",
    ) as TextComponentConfig;
    textComponent.options.content.push({
        title: "New Section",
        text: "",
        id: generateHash(),
    });

    return { ...state, outputConfig: { ...currentConfig, components } };
};
const Reduce_RenameTextTab = (
    state: BuilderState,
    action: RenameTextTabAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let textComponent = components.find(
        (component) => component.type === "text",
    ) as TextComponentConfig;
    let textItemChange = textComponent.options.content.find((textItem) => {
        return textItem.id === action.payload.id;
    });

    if (textItemChange) {
        textItemChange.title = action.payload.name;
        return { ...state, outputConfig: { ...currentConfig, components } };
    } else {
        return state;
    }
};

const Reduce_UpdateTimelineSecondsHours = (
    state: BuilderState,
    action: UpdateTimelineSecondsHoursAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;

    let timelineConfig = {
        ...currentConfig.timelineConfig!,
        ...action.payload,
    };

    return { ...state, outputConfig: { ...currentConfig, timelineConfig } };
};

const Reduce_UpdateTimelineRange = (
    state: BuilderState,
    action: UpdateTimelineRangeAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;

    let range = {
        ...currentConfig.timelineConfig!.range,
        ...action.payload,
    };
    let timelineConfig = {
        ...currentConfig.timelineConfig!,
        range,
    };

    return { ...state, outputConfig: { ...currentConfig, timelineConfig } };
};

const Reduce_ToggleLayerTimeline = (
    state: BuilderState,
    action: ToggleLayerTimelineAction,
): BuilderState => {
    const currentConfig: AppConfig = state.outputConfig!;
    const components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    const sourceConfig: ConfigSource =
        mapComponent.options.sources[
            (state.selectedMenuItem as ConfigMenuLayer).layerSource
        ];

    if (sourceConfig.dataConfig) {
        delete sourceConfig.dataConfig;
    } else {
        sourceConfig.dataConfig = {
            timeline: {
                type: "filter-on",
                data: {
                    type: "internal",
                    dateColumnName: "date",
                    format: "X",
                },
            },
        };
    }
    return { ...state, outputConfig: { ...currentConfig, components } };
};

const Reduce_UpdateLayerTimelineFields = (
    state: BuilderState,
    action: UpdateLayerTimelineFieldsAction,
): BuilderState => {
    const currentConfig: AppConfig = state.outputConfig!;
    const components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    const sourceConfig: ConfigSource =
        mapComponent.options.sources[
            (state.selectedMenuItem as ConfigMenuLayer).layerSource
        ];

    sourceConfig.dataConfig!.timeline.data = {
        ...sourceConfig.dataConfig!.timeline.data,
        ...action.payload,
    };

    return { ...state, outputConfig: { ...currentConfig, components } };
};

const Reduce_UpdateLayerTimelineType = (
    state: BuilderState,
    action: UpdateLayerTimelineTypeAction,
): BuilderState => {
    const currentConfig: AppConfig = state.outputConfig!;
    const components = [...currentConfig.components];
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    const sourceConfig: ConfigSource =
        mapComponent.options.sources[
            (state.selectedMenuItem as ConfigMenuLayer).layerSource
        ];

    if (sourceConfig.dataConfig?.timeline) {
        sourceConfig.dataConfig.timeline.type = action.payload;

        if (
            sourceConfig.dataConfig.timeline.type === "filter-on" ||
            sourceConfig.dataConfig.timeline!.type === "filter-on-array"
        ) {
            sourceConfig.dataConfig.timeline.data.dateColumnName = "date";
        } else if (sourceConfig.dataConfig.timeline.type === "filter-between") {
            sourceConfig.dataConfig.timeline.data.dateColumnName = "date_from";
            sourceConfig.dataConfig.timeline.data.dateToColumnName = "date_to";
        }
    }

    return { ...state, outputConfig: { ...currentConfig, components } };
};

const Reduce_ToggleTimeline = (
    state: BuilderState,
    action: RemoveTimelineAction,
): BuilderState => {
    const currentConfig: AppConfig = state.outputConfig!;
    if (currentConfig.timelineConfig) {
        delete currentConfig.timelineConfig;
    } else {
        currentConfig.timelineConfig = {
            range: {
                format: MOMENT_DATE_FORMAT,
                max: moment().format(MOMENT_DATE_FORMAT),
                min: moment().format(MOMENT_DATE_FORMAT),
            },
            secondsPerUpdate: 1,
            hoursPerUpdate: 1,
        };
    }

    return { ...state, outputConfig: { ...currentConfig } };
};

const Reduce_UpdateSummaryMeta = (
    state: BuilderState,
    action: UpdateSummaryMetaAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let summaryComponent = components.find(
        (component) => component.type === "summary",
    ) as SummaryComponentConfig;
    summaryComponent.options = {
        ...summaryComponent.options,
        ...action.payload,
    };
    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};
const Reduce_AddDownload = (
    state: BuilderState,
    action: AddDownloadAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let summaryComponent = components.find(
        (component) => component.type === "summary",
    ) as SummaryComponentConfig;
    let downloads = summaryComponent.options.downloads;

    downloads[action.payload.filename] = action.payload.url;

    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};
const Reduce_RemoveDownload = (
    state: BuilderState,
    action: RemoveDownloadAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = [...currentConfig.components];
    let summaryComponent = components.find(
        (component) => component.type === "summary",
    ) as SummaryComponentConfig;
    let downloads = summaryComponent.options.downloads;

    if (downloads[action.payload.filename] === action.payload.url) {
        delete downloads[action.payload.filename];
    }

    return {
        ...state,
        outputConfig: { ...currentConfig, components: [...components] },
    };
};

const Reduce_AddSourceApiResponse = (
    state: BuilderState,
    action: AddSourceApiResponseAction,
): BuilderState => {
    const currentConfig = state.outputConfig!;
    let components = _.cloneDeep(currentConfig.components);
    let mapComponent = components.find(
        (component) => component.type === "map",
    ) as MapComponentConfig;
    let sources = mapComponent.options.sources;

    let tileset = action.payload.tileset;
    let tilestat = action.payload.tilestat;

    let sourceApiResponses: BuilderState["tilesetApiResponses"] = {
        ...state.tilesetApiResponses,
        [action.payload.id]: [tileset, tilestat],
    };

    sources[action.payload.sourceId].source = tileset.name;
    sources[action.payload.sourceId].actions = {
        zoomTo: { bbox: tileset.bounds },
    };

    return {
        ...state,
        tilesetApiResponses: sourceApiResponses,
        outputConfig: { ...currentConfig, components },
    };
};

// -- HELPERS -- //
const createSourceFromStyle = (
    style: StyleConfig_Style,
    currentValues: Partial<ConfigSource_MB> = {},
): ConfigSource_MB => {
    let source: Partial<ConfigSource_MB> = {};

    switch (style!.meta_type) {
        case "Mapbox":
            //@ts-ignore
            source.type = sourceTypeSwitch[style.layer_type];
            source.source = currentValues.source || "";
            source.url = currentValues.url || "";
            break;
        case "Point":
            source.type = "geojson";
            source.data = currentValues.data || {
                type: "FeatureCollection",
                bbox: [0, 0, 0, 0],
                features: [
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [0, 0],
                        },
                        properties: {
                            Epicenter: "lat: 0, lng: 0",
                        },
                    },
                ],
            };
            break;
        case "WMTS":
            //@ts-ignore
            source.type = sourceTypeSwitch[style.layer_type];
            source.tiles = currentValues.tiles || [""];
            source.bounds = currentValues.bounds || [
                [0, 0],
                [0, 0],
            ];
            break;
    }

    //@ts-ignore
    source.layerType = layerTypeSwitch[style.geometry_type];
    source.paint = style!.style.paint;
    source.layout = style!.style.layout;
    source.layout = { visibility: "visible", ...style!.style.layout };
    source.viewOn = "both";
    source.layerStyleId = style.id;

    return source as ConfigSource_MB;
};

const findItemInTree = (
    menuIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
    IdToFind: string,
): ConfigMenuGroup | ConfigMenuLayer | undefined => {
    let foundItem;

    for (let treeItem of menuIndex) {
        if (treeItem.id === IdToFind) {
            return treeItem;
        } else if (treeItem.type === "group") {
            foundItem = findItemInTree(treeItem.children, IdToFind);
            if (foundItem) {
                return foundItem;
            }
        }
    }

    return foundItem;
};
const removeSources = (
    treeItemsArray: (ConfigMenuGroup | ConfigMenuLayer)[],
    sources: MapConfig["sources"],
) => {
    for (let item of treeItemsArray) {
        if (item.type === "layer") {
            delete sources[item.layerSource];
        } else if (item.type === "group") {
            removeSources(item.children, sources);
        }
    }
};

const removeItemFromMenuIndex = (
    menuIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
    idToRemove: string,
    sources: MapConfig["sources"] | null = null,
) => {
    for (let treeItemIndex in menuIndex) {
        let treeItem = menuIndex[treeItemIndex];
        if (treeItem.id === idToRemove) {
            menuIndex.splice(parseInt(treeItemIndex), 1);
            if (sources) {
                removeSources([treeItem], sources);
            }
            return;
        } else if (treeItem.type === "group") {
            removeItemFromMenuIndex(treeItem.children, idToRemove, sources);
        }
    }
};

const addProductToConfig = (
    productBranch: LayerIndex,
    menuIndexBranch: (ConfigMenuGroup | ConfigMenuLayer)[],
    sources: Dict<ConfigSource_MB>,
    reportLayerStyles: StyleConfig,
) => {
    for (let layerConfig of productBranch) {
        if (layerConfig.type === "layer") {
            let newId = generateHash();

            menuIndexBranch.push({
                type: "layer",
                id: generateHash(),
                layerName: layerConfig.name,
                layerSource: newId,
            });

            let style: StyleConfig_Style | null = null;

            for (let categoryName in reportLayerStyles) {
                style =
                    reportLayerStyles[categoryName]?.[layerConfig.styles[0]];
                if (style) {
                    break;
                }
            }
            if (style) {
                sources[newId] = createSourceFromStyle(style);
            } else {
                console.error(
                    `Could not find style in style list: ${layerConfig.styles[0]}`,
                );
            }
        } else if (layerConfig.type === "group") {
            menuIndexBranch.push({
                type: "group",
                id: generateHash(),
                groupName: layerConfig.name,
                children: [],
            });

            let grandchildren = (
                menuIndexBranch[menuIndexBranch.length - 1] as ConfigMenuGroup
            ).children;
            addProductToConfig(
                layerConfig.children,
                grandchildren,
                sources,
                reportLayerStyles,
            );
        }
    }
};
