/**
 * Displays complex legends with data driven paints.
 */

import React, { ReactNode } from "react";
import * as MapboxGL from "mapbox-gl";
import { SymbolLayout } from "mapbox-gl";
import {
    mdiClose,
    mdiFilterOffOutline,
    mdiFilterOutline,
    mdiPin,
} from "@mdi/js";
import Icon from "@mdi/react";
import cx from "classnames";
import ReactTooltip from "react-tooltip";

import { LayerType } from "types/mapbox-types";
import { Dict } from "types/misgis";
import {
    extractStylingPropertiesForIcons,
    findLegendRowTexts,
    LayerTypeRenderData,
} from "utils/ExtractLegendData";
import { getCssVar } from "utils/CSSHelpers";
import { isTupleArray } from "utils/TypeGuards";
import MapIcons from "assets/images/MapIcons";

import classes from "./Legend.module.css";
import ScrollableText from "components/_Library/ScrollableText/ScrollableText";

interface LegendProps {
    paint: MapboxGL.AnyPaint;
    layout: MapboxGL.AnyLayout;
    complexPaintProperties: Array<keyof MapboxGL.AnyPaint>;
    type: LayerType;
    toggleLegendPopup: (() => void) | null;
    layerName?: string | null;
    legendPopup: { layerName: string; sourceName: string } | null;
    parent: string;
    filterClick?: (
        label: string,
        featureProperty: string,
        legendRowDescriptions: string[],
        isInterpolated: boolean,
    ) => void;
    isFiltered: boolean;
    customFilter: {
        identifier: null | string;
        values: string[] | [string, string][];
    };
}

interface LegendState {
    isInterpolated: boolean;
}

class Legend extends React.Component<LegendProps, LegendState> {
    state: LegendState = {
        isInterpolated: false,
    };

    makeMillionHumanReadable(label: string) {
        //only for displayed number - if number is greater than one million round to nearest million or hundred million
        let parsedNumber = parseInt(label);
        let numberLength = Math.floor(Math.log10(parsedNumber)) + 1;
        if (numberLength >= 7) {
            parsedNumber = Math.round(parsedNumber / 1000000); //round to nearest million
            if (numberLength >= 9) {
                parsedNumber = Math.round(parsedNumber / 100) * 100; //round to nearest 100 million
            }
        }
        return parsedNumber + " million";
    }

    extractEndPointsOfSelectedRanges = (appliedFilters: [string, string][]) => {
        return appliedFilters.map(([a, b]: [string, string]) => b);
    };

    isFilterSetOnCurrentRow = (
        label: string,
        appliedFilters: string[] | [string, string][],
    ) => {
        if (isTupleArray(appliedFilters)) {
            // range eg ['135484433', '270968866']  = true   ['USA','GBR'] = false
            appliedFilters =
                this.extractEndPointsOfSelectedRanges(appliedFilters);
        }
        return appliedFilters.includes(label);
    };

    generateSymbolLegendItem = (legendLabel: keyof typeof MapIcons) => {
        return (
            <div className={classes.LegendItem}>
                <p className={classes.LegendLabel} style={{ width: "100%" }}>
                    {legendLabel}
                </p>
                <div className={classes.IconContainer}>
                    <img
                        alt={MapIcons[legendLabel] + " Icon"}
                        src={MapIcons[legendLabel]}
                    />
                </div>
            </div>
        );
    };

    generateSymbolLegend = () => {
        let legendRows: Array<ReactNode> = [];
        let iconImagePaint = (this.props.layout as SymbolLayout)["icon-image"];

        if (typeof iconImagePaint === "string") {
            legendRows.push(
                this.generateSymbolLegendItem(
                    iconImagePaint as keyof typeof MapIcons,
                ),
            );
        } else if (Array.isArray(iconImagePaint)) {
            // Mapbox Expression
            let expressionIndex = iconImagePaint.findIndex(
                (expressionIndice) => {
                    return Array.isArray(expressionIndice);
                },
            );

            if (expressionIndex >= 0) {
                for (
                    let i = expressionIndex + 1;
                    i < iconImagePaint.length;
                    i += 2
                ) {
                    let indice = iconImagePaint[i];
                    if (indice) {
                        legendRows.push(this.generateSymbolLegendItem(indice));
                    }
                }
            }
        }
        return legendRows;
    };

    generateTheLegendRows = (
        propertiesForLegend: Dict<any>,
        legendTitle: string,
        isInterpolated: boolean,
        standardProperties: Dict<any>,
    ) => {
        let legendRows: Array<ReactNode> = [];
        let appliedFilters = this.props.customFilter
            ? this.props.customFilter.values
            : [];
        let renderData = LayerTypeRenderData[this.props.type];
        let prevLabel = "0";
        for (let label in propertiesForLegend[legendTitle]) {
            let labelValue = Object.assign(
                propertiesForLegend[legendTitle][label],
                standardProperties,
            );
            let filterOnThisValue: boolean =
                appliedFilters.length > 0 &&
                this.isFilterSetOnCurrentRow(label, appliedFilters);
            legendRows.push(
                <div key={label} className={classes.LegendItem}>
                    {this.props.parent === "layerListItem" && (
                        <div
                            className={classes.IconContainer}
                            onClick={() => {
                                if (this.props.filterClick) {
                                    this.props.filterClick(
                                        label,
                                        legendTitle,
                                        findLegendRowTexts(propertiesForLegend)
                                            .legendRowTexts,
                                        isInterpolated,
                                    );
                                }
                            }} //passing legendRowTexts which is the full list of texts/(can be numbers) enables filterClick to infer a range
                        >
                            {isInterpolated &&
                                findLegendRowTexts(propertiesForLegend)
                                    .firstRowText !== label && ( //range filters need the filter icon offset to lie between the two rows signifying the bounds of that range, to facilitate this the first row's icon must be absent
                                    <Icon
                                        path={
                                            filterOnThisValue
                                                ? mdiFilterOffOutline
                                                : mdiFilterOutline
                                        }
                                        color={getCssVar(
                                            filterOnThisValue
                                                ? "--highlight-color"
                                                : "--text-color",
                                        )}
                                        data-for={"FilterTooltip"}
                                        data-tip={
                                            "Filter between " +
                                            prevLabel +
                                            " & " +
                                            (label.match(/^[0-9]+$/) != null &&
                                            parseInt(label) >= 1000000
                                                ? this.makeMillionHumanReadable(
                                                      label,
                                                  )
                                                : label)
                                        }
                                    />
                                )}
                            {!isInterpolated && (
                                <Icon
                                    path={
                                        filterOnThisValue
                                            ? mdiFilterOffOutline
                                            : mdiFilterOutline
                                    }
                                    color={getCssVar(
                                        filterOnThisValue
                                            ? "--highlight-color"
                                            : "--text-color",
                                    )}
                                />
                            )}
                        </div>
                    )}
                    <p
                        className={classes.LegendLabel}
                        style={{ width: "100%" }}
                    >
                        {label.match(/^[0-9]+$/) != null &&
                        parseInt(label) >= 1000000
                            ? this.makeMillionHumanReadable(label)
                            : label}
                    </p>
                    <div className={cx(classes.IconContainer, this.props.type)}>
                        {renderData.renderFunction(labelValue)}
                    </div>
                    <ReactTooltip
                        id={"FilterTooltip"}
                        place={"left"}
                        effect={"float"}
                    />
                </div>,
            );
            prevLabel =
                label.match(/^[0-9]+$/) != null && parseInt(label) >= 1000000
                    ? this.makeMillionHumanReadable(label)
                    : label;
        }
        return legendRows;
    };

    generateLegendFromPaint = () => {
        const layerType = this.props.type;
        let renderData = LayerTypeRenderData[layerType];
        let paintToComponentProps = renderData.paintToComponentProps;
        let standardProperties: Dict<any> = extractStylingPropertiesForIcons(
            paintToComponentProps,
            this.props.paint,
        ).standardProperties;
        let propertiesForLegend: Dict<any> = extractStylingPropertiesForIcons(
            paintToComponentProps,
            this.props.paint,
        ).propertiesForLegend;
        let legendItems: Array<ReactNode> = [];
        let isInterpolated: boolean = false;

        for (let featureProperty in propertiesForLegend) {
            if (featureProperty === "isInterpolated") {
                //first featureProperty in propertiesForLegend is isInterpolated? flag, remaining featureProperties build the legend
                isInterpolated = true;
            } else {
                if (this.props.parent === "InteractionModeStandard") {
                    legendItems.push(
                        <div
                            key={featureProperty}
                            className={classes.PinnedLegendItem}
                            style={{ marginTop: 20 }}
                        >
                            <div className={classes.IconContainer}>
                                <span className={classes.Bold}>{"Key:"}</span>
                                {featureProperty}
                            </div>
                            <div className={classes.PinnedLegend}>
                                {this.generateTheLegendRows(
                                    propertiesForLegend,
                                    featureProperty,
                                    isInterpolated,
                                    standardProperties,
                                )}
                            </div>
                        </div>,
                    );
                } else {
                    legendItems.push(
                        <p
                            key={featureProperty}
                            className={classes.LegendTitle}
                        >
                            {featureProperty}
                        </p>,
                        this.generateTheLegendRows(
                            propertiesForLegend,
                            featureProperty,
                            isInterpolated,
                            standardProperties,
                        ),
                    );
                }
            }
        }
        return legendItems;
    };

    render(): ReactNode {
        const { parent, legendPopup, layerName, toggleLegendPopup } =
            this.props;
        const calculatedColor = legendPopup
            ? legendPopup.layerName === layerName
                ? "--highlight-color"
                : "--text-color"
            : "--text-color";
        const legendItems =
            this.props.type === "symbol"
                ? this.generateSymbolLegend()
                : this.generateLegendFromPaint();
        return (
            <div className={classes.Legend}>
                <div className={classes.LegendEntry}>
                    {parent === "InteractionModeStandard" &&
                        !!toggleLegendPopup && (
                            <div
                                onClick={toggleLegendPopup}
                                className={classes.Close}
                            >
                                <span style={{ paddingRight: 20, maxWidth: "30rem" }}>
                                    <ScrollableText text={layerName!} />
                                </span>
                                <Icon path={mdiClose} size={1.5} />
                            </div>
                        )}
                    {parent !== "InteractionModeStandard" &&
                        toggleLegendPopup && (
                            <div
                                onClick={toggleLegendPopup}
                                className={classes.Pin}
                            >
                                <Icon
                                    path={mdiPin}
                                    color={getCssVar(calculatedColor)}
                                />
                            </div>
                        )}
                    {legendItems}
                </div>
            </div>
        );
    }
}
export default Legend;
