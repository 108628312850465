// ACTION TYPE CONSTANTS
import { AnyPaint, LngLatLike } from "mapbox-gl";
import { ViewportProps } from "react-map-gl";
import { ConfigSource, MapConfig } from "../map/mapTypes";
import {
    AppConfig,
    ConfigMenuGroup,
    ConfigMenuLayer,
    LayerTimelineConfig,
    MapBuilderMetaData,
    SummaryComponentConfig,
    TimelineConfig,
} from "store/system/systemTypes";
import { StyleConfig, StyleConfig_Style } from "crud/layerStylesCRUD";

export const SET_MAP_BUILDER_CONFIG = "SET_MAP_BUILDER_CONFIG";
export const SET_MAP_BUILDER_DISABLED = "SET_MAP_BUILDER_DISABLED";

export const SET_PRODUCT_CONFIG = "SET_PRODUCT_CONFIG";

export const SET_STYLE_CONFIG = "SET_STYLE_CONFIG";

export const UPDATE_MAP_META = "UPDATE_MAP_META";
export const UPDATE_MAP_VIEW_OVERRIDES = "UPDATE_MAP_VIEW_OVERRIDES";

export const ADD_PRODUCT = "ADD_PRODUCT";

export const SET_DRAGGED_MENU_ITEM = "SET_DRAGGED_MENU_ITEM";
export const SET_SELECTED_MENU_ITEM = "SET_SELECTED_MENU_ITEM";

export const DUPLICATE_SELECTED_MENU_ITEM = "DUPLICATE_SELECTED_MENU_ITEM";
export const REMOVE_SELECTED_MENU_ITEM = "REMOVE_SELECTED_MENU_ITEM";
export const ADD_NEW_LAYER = "ADD_NEW_LAYER";
export const ADD_NEW_GROUP = "ADD_NEW_GROUP";
export const MOVE_ITEM_TO_GROUP = "MOVE_ITEM_TO_GROUP";
export const MOVE_ITEM_TO_ITEM = "MOVE_ITEM_TO_ITEM";

export const UPDATE_ITEM_NAME = "UPDATE_ITEM_NAME";
export const UPDATE_LAYER_STYLE = "UPDATE_LAYER_STYLE";
export const UPDATE_LAYER_META = "UPDATE_LAYER_META";

export const UPDATE_GROUP_AS_LAYER = "UPDATE_GROUP_META";

export const UPDATE_TEXT = "UPDATE_TEXT";
export const REMOVE_TEXT_TAB = "REMOVE_TEXT_TAB";
export const ADD_TEXT_TAB = "ADD_TEXT_TAB";
export const RENAME_TEXT_TAB = "RENAME_TEXT_TAB";

export const UPDATE_TIMELINE_SECONDS_HOURS = "UPDATE_TIMELINE_SECONDS_HOURS";
export const UPDATE_TIMELINE_RANGE = "UPDATE_TIMELINE_RANGE";
export const UPDATE_SUMMARY_META = "UPDATE_SUMMARY_META";
export const ADD_DOWNLOAD = "ADD_DOWNLOAD";
export const REMOVE_DOWNLOAD = "REMOVE_DOWNLOAD";
export const TOGGLE_TIMELINE = "TOGGLE_TIMELINE";
export const TOGGLE_LAYER_TIMELINE = "TOGGLE_LAYER_TIMELINE";
export const UPDATE_LAYER_TIMELINE_FIELDS = "UPDATE_LAYER_TIMELINE_FIELDS";
export const UPDATE_LAYER_TIMELINE_TYPE = "UPDATE_LAYER_TIMELINE_TYPE";

export const ADD_SOURCE_API_RESPONSE = "ADD_SOURCE_API_RESPONSE";

// PAYLOAD TYPES
export type SetMapBuilderConfigPayload = AppConfig | null;
export type SetMapBuilderDisabledPayload = boolean;

export type SetProductConfigPayload = ProductConfig;

export type UpdateMapMetaPayload = Partial<MapConfig>;
export type UpdateMapViewOverridesPayload = Partial<ViewportProps>;

export interface AddProductPayload {
    config: ProductConfig_Product;
    categories: string[];
    reportLayerStyles: StyleConfig;
}

export type SetDraggedMenuItemPayload = BuilderState["draggedMenuItem"];
export type SetSelectedMenuItemPayload = BuilderState["selectedMenuItem"];

export type MoveItemToGroupPayload = {
    dropTargetId: string;
};
export type MoveItemToItemPayload = {
    itemId: string;
    position: "above" | "below";
};

export type UpdateItemNamePayload = string;

export type UpdateLayerStylePayload = StyleConfig_Style;
export type UpdateLayerMetaPayload = Partial<ConfigSource_MB>;

export type UpdateGroupAsLayerPayload = boolean;

export type UpdateTextPayload = {
    text: string;
    id: string;
};
export type RemoveTextTabPayload = string;
export type AddTextTabPayload = string;
export type RenameTextTabPayload = {
    id: string;
    name: string;
};

export type UpdateTimelineSecondsHoursPayload = Partial<TimelineConfig>;
export type RemoveTimelinePayload = TimelineConfig;
export type UpdateTimelineRangePayload = Partial<TimelineConfig["range"]>;
export type UpdateSummaryMetaPayload = Partial<
    SummaryComponentConfig["options"]
>;
export type AddDownloadPayload = { filename: string; url: string };
export type AddVideoPayload = { url: string };
export type AddImagePayload = { url: string; alt: string; caption: string };
export type RemoveVideoPayload = { key: number };
export type RemoveImagePayload = { key: number };
export type RemoveDownloadPayload = { filename: string; url: string };
export type ToggleLayerTimelinePayload = string;
export type UpdateLayerTimelineFieldsPayload = Partial<
    LayerTimelineConfig["data"]
>;
export type UpdateLayerTimelineTypePayload = LayerTimelineConfig["type"];

export type AddSourceApiResponsePayload = {
    id: string; // mapbox tileset id
    sourceId: string; // sourceName
    tileset: TilesetAPIResponse;
    tilestat: TilestatAPIResponse | null;
};

export type AddNewLayerPayload = StyleConfig;

// -- ACTION TYPES -- //

export interface SetConfigAction {
    type: typeof SET_MAP_BUILDER_CONFIG;
    payload: SetMapBuilderConfigPayload;
}

export interface SetMapBuilderDisabledAction {
    type: typeof SET_MAP_BUILDER_DISABLED;
    payload: SetMapBuilderDisabledPayload;
}

export interface SetProductConfigAction {
    type: typeof SET_PRODUCT_CONFIG;
    payload: SetProductConfigPayload;
}

export interface UpdateMapMetaAction {
    type: typeof UPDATE_MAP_META;
    payload: UpdateMapMetaPayload;
}

export interface UpdateMapViewOverridesAction {
    type: typeof UPDATE_MAP_VIEW_OVERRIDES;
    payload: UpdateMapViewOverridesPayload;
}

export interface AddProductAction {
    type: typeof ADD_PRODUCT;
    payload: AddProductPayload;
}

export interface SetSelectedMenuItemAction {
    type: typeof SET_SELECTED_MENU_ITEM;
    payload: SetSelectedMenuItemPayload;
}

export interface SetDraggedMenuItemAction {
    type: typeof SET_DRAGGED_MENU_ITEM;
    payload: SetDraggedMenuItemPayload;
}

export interface MoveItemToGroupAction {
    type: typeof MOVE_ITEM_TO_GROUP;
    payload: MoveItemToGroupPayload;
}

export interface MoveItemToItemAction {
    type: typeof MOVE_ITEM_TO_ITEM;
    payload: MoveItemToItemPayload;
}

export interface UpdateItemNameAction {
    type: typeof UPDATE_ITEM_NAME;
    payload: UpdateItemNamePayload;
}

export interface UpdateLayerStyleAction {
    type: typeof UPDATE_LAYER_STYLE;
    payload: UpdateLayerStylePayload;
}

export interface UpdateLayerMetaAction {
    type: typeof UPDATE_LAYER_META;
    payload: UpdateLayerMetaPayload;
}

export interface UpdateGroupMetaAction {
    type: typeof UPDATE_GROUP_AS_LAYER;
    payload: UpdateGroupAsLayerPayload;
}

export interface DuplicateSelectedMenuItemAction {
    type: typeof DUPLICATE_SELECTED_MENU_ITEM;
}

export interface RemoveSelectedMenuItemAction {
    type: typeof REMOVE_SELECTED_MENU_ITEM;
}

export interface AddNewLayerAction {
    type: typeof ADD_NEW_LAYER;
    payload: AddNewLayerPayload;
}

export interface AddNewGroupAction {
    type: typeof ADD_NEW_GROUP;
}

export interface UpdateTextAction {
    type: typeof UPDATE_TEXT;
    payload: UpdateTextPayload;
}

export interface RemoveTextTabAction {
    type: typeof REMOVE_TEXT_TAB;
    payload: RemoveTextTabPayload;
}

export interface AddTextTabAction {
    type: typeof ADD_TEXT_TAB;
    payload: AddTextTabPayload;
}

export interface RenameTextTabAction {
    type: typeof RENAME_TEXT_TAB;
    payload: RenameTextTabPayload;
}

export interface UpdateSummaryMetaAction {
    type: typeof UPDATE_SUMMARY_META;
    payload: UpdateSummaryMetaPayload;
}
export interface AddDownloadAction {
    type: typeof ADD_DOWNLOAD;
    payload: AddDownloadPayload;
}
export interface RemoveDownloadAction {
    type: typeof REMOVE_DOWNLOAD;
    payload: RemoveDownloadPayload;
}

export interface RemoveTimelineAction {
    type: typeof TOGGLE_TIMELINE;
    payload: RemoveTimelinePayload;
}

export interface ToggleLayerTimelineAction {
    type: typeof TOGGLE_LAYER_TIMELINE;
    payload: ToggleLayerTimelinePayload;
}

export interface UpdateLayerTimelineFieldsAction {
    type: typeof UPDATE_LAYER_TIMELINE_FIELDS;
    payload: UpdateLayerTimelineFieldsPayload;
}

export interface UpdateLayerTimelineTypeAction {
    type: typeof UPDATE_LAYER_TIMELINE_TYPE;
    payload: UpdateLayerTimelineTypePayload;
}

export interface AddSourceApiResponseAction {
    type: typeof ADD_SOURCE_API_RESPONSE;
    payload: AddSourceApiResponsePayload;
}

export interface UpdateTimelineRangeAction {
    type: typeof UPDATE_TIMELINE_RANGE;
    payload: UpdateTimelineRangePayload;
}

export interface UpdateTimelineSecondsHoursAction {
    type: typeof UPDATE_TIMELINE_SECONDS_HOURS;
    payload: UpdateTimelineSecondsHoursPayload;
}

export type BuilderActionTypes =
    | SetConfigAction
    | SetMapBuilderDisabledAction
    | SetProductConfigAction
    | AddProductAction
    | SetSelectedMenuItemAction
    | SetDraggedMenuItemAction
    | MoveItemToGroupAction
    | MoveItemToItemAction
    | UpdateItemNameAction
    | UpdateLayerStyleAction
    | UpdateLayerMetaAction
    | UpdateGroupMetaAction
    | UpdateMapMetaAction
    | UpdateMapViewOverridesAction
    | UpdateTextAction
    | DuplicateSelectedMenuItemAction
    | RemoveSelectedMenuItemAction
    | AddNewLayerAction
    | AddNewGroupAction
    | RemoveTextTabAction
    | AddTextTabAction
    | RenameTextTabAction
    | UpdateSummaryMetaAction
    | AddDownloadAction
    | RemoveDownloadAction
    | AddSourceApiResponseAction
    | UpdateTimelineRangeAction
    | UpdateTimelineSecondsHoursAction
    | RemoveTimelineAction
    | ToggleLayerTimelineAction
    | UpdateLayerTimelineFieldsAction
    | UpdateLayerTimelineTypeAction;

// REDUCER TYPES
export interface BuilderState {
    productConfig: ProductConfig | null;
    outputConfig: AppConfig | null;
    selectedMenuItem: ConfigMenuLayer | ConfigMenuGroup | null;
    draggedMenuItem: ConfigMenuLayer | ConfigMenuGroup | null;
    tilesetApiResponses: {
        [id: string]: [TilesetAPIResponse, TilestatAPIResponse | null];
    };
    disabled: boolean;
    mapBuilderMetadata: MapBuilderMetaData;
}

export interface ConfigSource_MB
    extends Omit<ConfigSource, "complexPaintProperties"> {
    complexPaintProperties?: Array<keyof AnyPaint>;
    styleName: string;
    bounds?: [LngLatLike, LngLatLike];
    color: string;
    layerStyleId: string;
}

export type ProductConfig = ProductConfig_Category;

export interface ProductConfig_Category {
    [ProductEntry: string]: ProductConfig_Category | ProductConfig_Product;
}

export type LayerIndex = (ProductConfig_Group | ProductConfig_Layer)[];

export interface ProductConfig_Product {
    type: "product";
    help: string;
    layerIndex: LayerIndex;
}

export interface ProductConfig_Layer {
    type: "layer";
    name: string;
    styles: string[];
}

export interface ProductConfig_Group {
    type: "group";
    name: string;
    children: LayerIndex;
}

export interface TilestatAPIResponse {
    layerCount: number; // 1
    layers: {
        layer: string; //"Dixie-Postfire_9_Aug_v1-dv6zah",
        count: number; //1609,
        attributeCount: number; //2,
        attributes: {
            values: (string | number)[]; //["No Damage", "Destroyed", "Light Damage", "Substantial Damage"],
            count: number; //4,
            attribute: string; //"Damage",
            type: string; // "string"
        }[];
        geometry: string; //"MultiPolygon"
    }[];
}

export interface TilesetAPIResponse {
    bounds: [number, number, number, number];
    center: [number, number, number];
    created: number; // unix
    created_by_client: "studio";
    filesize: number;
    format: "pbf" | "webp";
    id: string; //tileset-id
    mapbox_logo: true;
    maxzoom: number;
    minzoom: number;
    modified: number; //unix
    name: string; //tileset name
    private: true;
    scheme: "xyz";
    tilejson: "2.2.0";
    tiles: [string, string];
    upload_id: string;
    vector_layers: [
        {
            description: "";
            fields: {
                [fieldname: string]: "String";
            };
            id: string; // layer-name
            maxzoom: number;
            minzoom: number;
            source: string; // tileset-id
            source_name: string; //tileset name
        },
    ];
    version: "1.0.0";
    webpage: string;
}
