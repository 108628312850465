/**
 * Holds the tab components and displays the currently active one.
 * All tabs are rendered to the tree (but not displayed) even when not active as we need the geocoder tab ref to stay alive for its portal.
 */

import React, { Component } from "react";
import { mdiInformationOutline, mdiLayersTriple, mdiSearchWeb } from "@mdi/js";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { RootState } from "store/store";
import {
    InteractionModeType,
    MapActionTypes,
    MapType,
    TabType,
} from "store/map/mapTypes";
import {
    setActiveTab,
    setInteractionMode,
    setMapType,
} from "store/map/mapActions";

import LayersTab from "./Tabs/LayersTab/LayersTab";
import InfoTab from "./Tabs/InfoTab/InfoTab";
import GeocoderTab from "./Tabs/GeocoderTab/GeocoderTab";
import TabbedMenu, {
    TabConfig,
} from "../../../../../_Library/Menus/TabbedMenu";
import Icon from "@mdi/react";
import { getStoreAtNamespaceKey } from "../../../../../../store/storeSelectors";
import { registerAnalyticsEvent } from "store/matomo/matomoActions";

interface OwnProps {
    setDragAndDropState: (active: boolean) => void;
    toggleDownloadModal: () => void;
}

interface StateProps {
    mapType: MapType;
    activeTab: TabType;
    menuHidden: boolean;
    interactionMode: InteractionModeType;
}

interface DispatchProps {
    setMapType: typeof setMapType; // Used for compare/dual toggle.
    setActiveTab: typeof setActiveTab;
    setInteractionMode: typeof setInteractionMode;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
}

type TabContainerProps = OwnProps & StateProps & DispatchProps;

interface TabContainerState {}

class MapMenu extends Component<TabContainerProps, TabContainerState> {
    state = {};

    tabs: TabConfig = {
        Geocoder: {
            name: "Location Search",
            iconComponent: <Icon path={mdiSearchWeb} />,
            component: () => {
                return <GeocoderTab />;
            },
        },
        Layer: {
            name: "Layers",
            iconComponent: <Icon path={mdiLayersTriple} />,
            component: () => {
                return (
                    <LayersTab
                        setDragAndDropState={this.props.setDragAndDropState}
                        toggleDownloadModal={this.props.toggleDownloadModal}
                    />
                );
            },
        },
        Info: {
            name: "Layer Info",
            iconComponent: <Icon path={mdiInformationOutline} />,
            component: () => {
                return <InfoTab />;
            },
        },
    };

    render() {
        return (
            <TabbedMenu
                domPositions={"MTB"}
                tabs={this.tabs}
                open={!this.props.menuHidden}
                activeTab={this.props.activeTab}
                setActiveTab={this.props.setActiveTab}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    mapType: getStoreAtNamespaceKey(state, "map").mapConfig.mapType,
    activeTab: getStoreAtNamespaceKey(state, "map").menuConfig.activeTab,
    menuHidden: getStoreAtNamespaceKey(state, "map").menuConfig.menuHidden,
    interactionMode: getStoreAtNamespaceKey(state, "map").interactionMode,
});

const mapDispatchToProps = (dispatch: Dispatch<MapActionTypes>) => ({
    setMapType: bindActionCreators(setMapType, dispatch),
    setActiveTab: bindActionCreators(setActiveTab, dispatch),
    setInteractionMode: bindActionCreators(setInteractionMode, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapMenu);
