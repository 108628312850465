import {
    applyMiddleware,
    combineReducers,
    createStore,
    Reducer,
    Store,
} from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { systemReducer } from "./system/systemReducer";

import { SystemActionTypes, SystemState } from "./system/systemTypes";
import { MapActionTypes, MapState, SET_MOUSE_POSITION } from "./map/mapTypes";
import { RefActionTypes, RefState } from "./ref/refTypes";
import { BuilderActionTypes, BuilderState } from "./builder/builderTypes";
import { ServiceActionTypes, ServiceState } from "./service/serviceTypes";
import { createLogger } from "redux-logger";
import { UserActionTypes, UserState } from "./user/userTypes";
import { userReducer } from "./user/userReducer";
import { matomoReducer } from "./matomo/matomoReducer";
import { MatomoActionTypes, MatomoState } from "./matomo/matomoTypes";
import { InsightsActionTypes, InsightsState } from "./insights/insightsTypes";
import { serviceReducer } from "./service/serviceReducer";
import { refReducer } from "./ref/refReducer";

const composeEnhancers = composeWithDevTools({
    actionsBlacklist: [SET_MOUSE_POSITION],
    stateSanitizer: function (state) {
        return {
            ...state,
            map: undefined,
            system: undefined,
            data: undefined,
            refs: undefined,
            builder: undefined,
            service: undefined,
            insights: undefined,
            group: undefined,
        };
    },
});

const logger = createLogger({
    predicate: (getState, action) => action.type !== SET_MOUSE_POSITION,
});

export const enhancers =
    import.meta.env.NODE_ENV === `development`
    // @ts-ignore
        ? applyMiddleware(thunkMiddleware, logger)
        : applyMiddleware(thunkMiddleware);

export type FullRootState = {
    system: SystemState;
    map: MapState;
    ref: RefState;
    builder: BuilderState;
    service: ServiceState;
    user: UserState;
    matomo: MatomoState;
    insights: InsightsState;
};

type ActionMap = {
    system: SystemActionTypes;
    map: MapActionTypes;
    ref: RefActionTypes;
    builder: BuilderActionTypes;
    service: ServiceActionTypes;
    user: UserActionTypes;
    matomo: MatomoActionTypes;
    insights: InsightsActionTypes;
};

export type RootState = Partial<FullRootState>;
export type NamespaceKey = keyof RootState;
export type ReducerMap = Partial<{
    [k in NamespaceKey]: Reducer<FullRootState[k], ActionMap[k]>;
}>;

let reducerMap: ReducerMap = {
    system: systemReducer,
    service: serviceReducer,
    user: userReducer,
    matomo: matomoReducer,
    ref: refReducer,
};
const store = createStore(
    combineReducers(reducerMap),
    composeEnhancers(enhancers),
);

export function getStore() {
    return store;
}

export function registerReducer(newReducers: ReducerMap): Store<RootState> {
    reducerMap = { ...reducerMap, ...newReducers };
    // We probably want to validate we're not replacing reducers that already
    // exist here but that exercise is left up to the reader.
    store.replaceReducer(combineReducers(reducerMap));
    return store;
}
