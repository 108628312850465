import { bbox } from "@turf/turf";
import { useMemo } from "react";
import { WebMercatorViewport } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { setCenter } from "store/map/mapActions";
import { RootState } from "store/store";

export interface Props {
    mapWidth?: number;
    mapHeight?: number;
    features: GeoJSON.FeatureCollection;
    recalculateOn?: any[];
}

export interface ReturnType {
    centerMapFn: () => void;
    zoom: number;
    latitude: number;
    longitude: number;
}

export const useCenterFeatures = ({
    mapWidth,
    mapHeight,
    features,
    recalculateOn,
}: Props): ReturnType => {
    /**
     * Draw a bounding box around the features. The current
     * mapRef is used to calculate the map center, else
     * if mapWidth and mapHeight are provided, use those.
     * Passing recalculateOn allows you to decide when
     * the most taxing business logic is run.
     */
    const dispatch = useDispatch();
    const mapRef = useSelector((state: RootState) => state.ref?.mapRef);
    const { centerMapFn, zoom, latitude, longitude } = useMemo(() => {
        const hasCustomSizing = mapWidth && mapHeight;
        const container: HTMLElement = hasCustomSizing
            ? null
            : mapRef?.current?.getMap().getContainer();

        const width =
            (hasCustomSizing ? mapWidth : container?.offsetWidth) || 800;
        const height =
            (hasCustomSizing ? mapHeight : container?.offsetHeight) || 600;

        const webMercatorViewport = new WebMercatorViewport({
            width,
            height,
        });
        if (!features) {
            return {
                centerMapFn: () => {},
                zoom: 0,
                latitude: 0,
                longitude: 0,
            };
        }
        const bBox = bbox(features);
        let { zoom, latitude, longitude } = webMercatorViewport.fitBounds([
            [bBox[0], bBox[1]],
            [bBox[2], bBox[3]],
        ]);
        zoom = Math.min(13.5, zoom);

        return {
            centerMapFn: () => {
                dispatch(setCenter({ zoom, latitude, longitude }));
            },
            zoom,
            latitude,
            longitude,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapWidth, mapHeight, features, mapRef, dispatch, recalculateOn]);

    return { centerMapFn, zoom, latitude, longitude };
};
