/**
 * Homepage. Parses system components and displays.
 */

import React, { Component } from "react";
import { connect } from "react-redux";

import { RootState } from "../../../../store/store";
import {
    SummaryComponentConfig,
    SummaryConfig,
    SystemActionTypes,
    SystemState,
    TextComponentConfig,
} from "../../../../store/system/systemTypes";
import MapContainer from "../DashboardComponents/Map/Mapping/MapContainer/MapContainer";
import Summary from "components/Pages/Report/DashboardComponents/Summary/Summary";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";
import cx from "classnames";
import Modal from "../../../_Library/Modal/Modal";

import classes from "./Dashboard.module.css";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import {
    setAlert,
    setConfig,
    setDashboardView,
    setTextReportOpen,
} from "../../../../store/system/systemActions";
import { AccessManagementUser } from "../../../../types/auth";
import {
    setUser,
} from "../../../../store/user/userActions";
import { registerAnalyticsEvent } from "../../../../store/matomo/matomoActions";
import { ProductTourConfig } from "assets/tutorials";
import { toggleMenu } from "../../../../store/map/mapActions";
import { ReportModal } from "../ReportModal";
import { ViewTabs } from "./ViewTabs/ViewTabs";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Downloads from "../Dashboard/Downloads/Downloads";
import { InsightsHeader } from "../DashboardComponents/Insights/InsightsHeader";
import { InsightsState } from "store/insights/insightsTypes";
import withAnalytics, {
    withAnalyticsProps,
} from "components/_Library/HOC/withAnalytics";
import withCurrentEvent, { withCurrentEventProps } from "components/_Library/HOC/withCurrentEvent";

interface OwnProps {
    eventId: string | undefined;
    reportId: string | undefined;
}

interface StateProps {
    hasConfig: SystemState["hasConfig"];
    components: SystemState["components"];
    dashboardView: SystemState["dashboardView"];
    user: AccessManagementUser | null;
    tourConfig: ProductTourConfig;
    showTextReport: boolean;
    isInsightsLoaded: InsightsState["isInsightsLoaded"];
    insightsType: InsightsState["insightsType"];
    insightsData: InsightsState["insightsData"];
}

interface DispatchProps {
    setConfig: typeof setConfig;
    setUser: typeof setUser;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
    setDashboardView: typeof setDashboardView;
    toggleMenu: typeof toggleMenu;
    toggleTextReport: typeof setTextReportOpen;
    setAlert: typeof setAlert;
}

type DashboardProps = OwnProps &
    StateProps &
    DispatchProps &
    RouteComponentProps &
    withAnalyticsProps & 
    withCurrentEventProps;

interface DashboardState {
    downloadModalOpen: boolean;
    insightsViewed: boolean;
}

class Dashboard extends Component<DashboardProps, DashboardState> {
    state: DashboardState = {
        downloadModalOpen: false,
        insightsViewed: false,
    };

    componentWillUnmount() {
        this.props.setConfig(null);
    }

    componentDidUpdate(prevProps: Readonly<DashboardProps>): void {
        if (
            this.props.isInsightsLoaded &&
            !prevProps.isInsightsLoaded &&
            this.props.dashboardView !== "insights" &&
            this.props.dashboardView !== "policy_insights" &&
            this.props.insightsType === null
        ) {
            this.props.setAlert({
                message: "Insights are available",
                timeout: 5000,
            });
        }
    }

    toggleDownloadModal = () => {
        this.setState((state) => ({
            downloadModalOpen: !state.downloadModalOpen,
        }));
    };

    generateDownloads = (summaryOptions: SummaryConfig) => {
        return (
            <Downloads
                externalDownloads={summaryOptions.downloads}
                reportId={this.props.reportId}
            />
        );
    };

    renderDownloadModal = () => {
        let summaryOptions = (
            this.props.components.find(
                (component) => component.type === "summary",
            ) as SummaryComponentConfig
        ).options;

        return (
            <Modal closeModal={this.toggleDownloadModal}>
                <div className={classes.DownloadContainer}>
                {this.generateDownloads(summaryOptions)}
                </div>
            </Modal>
        );
    };

    render() {
        const textReportOptions = (
            this.props.components.find(
                (component) => component.type === "text",
            ) as TextComponentConfig
        )?.options;

        return (
            <div
                className={cx(classes.AppGrid, {
                    [classes.ReportGrid]:
                        this.props.dashboardView === "report" &&
                        this.props.hasConfig,
                })}
            >
                {this.state.downloadModalOpen && this.renderDownloadModal()}
                <div
                    className={classes.Component}
                    style={{
                        gridArea: "1 / 1 / 4 / 6",
                        display:
                            this.props.dashboardView === "report"
                                ? "initial"
                                : "none",
                    }}
                    key={"map"}
                >
                    <MapContainer
                        toggleDownloadModal={this.toggleDownloadModal}
                    />
                </div>
                <div
                    className={classes.Component}
                    style={{
                        gridArea: "1 / 1 / 5 / 6",
                        display: ["insights", "policy_insights"].includes(
                            this.props.dashboardView,
                        )
                            ? "initial"
                            : "none",
                    }}
                    key={"insights"}
                >
                    <InsightsHeader eventId={this.props.eventId!} />
                </div>
                <Summary
                    downloadModalOpen={this.state.downloadModalOpen}
                    eventId={this.props.eventId}
                />
                {textReportOptions && this.props.showTextReport && (
                    <ReportModal
                        options={textReportOptions}
                        onClose={() => this.props.toggleTextReport(false)}
                    />
                )}
                <ViewTabs
                    onInsightsClick={() => {
                        if(this.props.currentEvent){
                            this.props.analytics.trackUserEvent({
                                name: "insights_tab_clicked",
                                payload: {
                                    event_id: this.props.currentEvent.id,
                                    event_name: this.props.currentEvent.name,
                                },
                            });
                        }
                        this.props.setDashboardView({
                            view: "insights",
                            history: this.props.history,
                        });
                        this.setState({
                            insightsViewed: true,
                        });
                    }}
                    onMapClick={() => {
                        if(this.props.currentEvent){
                            this.props.analytics.trackUserEvent({
                                name: "map_tab_clicked",
                                payload: {
                                    event_id: this.props.currentEvent.id,
                                    event_name: this.props.currentEvent.name,
                                },
                            });
                        }
                        this.props.setDashboardView({
                            view: "report",
                            history: this.props.history,
                        });
                    }}
                    onReportClick={() => {
                        if(this.props.currentEvent){
                            this.props.analytics.trackUserEvent({
                                name: "report_tab_clicked",
                                payload: {
                                    event_id: this.props.currentEvent.id,
                                    event_name: this.props.currentEvent.name,
                                },
                            });
                        }
                        this.props.toggleTextReport(true);
                    }}
                    mapActive={this.props.dashboardView === "report"}
                    insightsActive={["insights", "policy_insights"].includes(
                        this.props.dashboardView,
                    )}
                    insightsAvailable={
                        Boolean(this.props.insightsData) &&
                        !this.state.insightsViewed
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    hasConfig: getStoreAtNamespaceKey(state, "system").hasConfig,
    components: getStoreAtNamespaceKey(state, "system").components,
    dashboardView: getStoreAtNamespaceKey(state, "system").dashboardView,
    user: getStoreAtNamespaceKey(state, "user").user,
    tourConfig: getStoreAtNamespaceKey(state, "system").tourConfig,
    showTextReport: getStoreAtNamespaceKey(state, "system").textReportOpen,
    insightsType: getStoreAtNamespaceKey(state, "insights").insightsType,
    isInsightsLoaded: getStoreAtNamespaceKey(state, "insights")
        .isInsightsLoaded,
    insightsData: getStoreAtNamespaceKey(state, "insights").insightsData,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes>,
) => ({
    setConfig: bindActionCreators(setConfig, dispatch),
    setUser: bindActionCreators(setUser, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
    setDashboardView: bindActionCreators(setDashboardView, dispatch),
    toggleMenu: bindActionCreators(toggleMenu, dispatch),
    toggleTextReport: bindActionCreators(setTextReportOpen, dispatch),
    setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(withAnalytics(withCurrentEvent(Dashboard))));
