import React from "react";
import { mdiMapMarker } from "@mdi/js";

// @ts-ignore
import { _useMapControl as useMapControl } from "react-map-gl";
import { MapControlProps } from "react-map-gl/dist/es5/components/use-map-control";
import Icon from "@mdi/react";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";

import cx from "classnames";
import { getCssVar } from "utils/CSSHelpers";
import classes from "./LatLongControl.module.css";

export interface OwnProps extends MapControlProps {
    mapContainerWidth: number;
}

interface StateProps {}

interface DispatchProps {}

type LatLongControlProps = OwnProps & StateProps & DispatchProps;

function LatLongControl(props: LatLongControlProps) {
    const { containerRef } = useMapControl(props);

    const smallControlBreakpoint = 500;

    const center = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "map").mousePosition,
    );

    let latitude = center[1];
    let longitude = center[0];

    while (longitude > 180 || longitude < -180) {
        if (longitude > 180) {
            longitude = longitude - 360;
        }
        if (longitude < -180) {
            longitude = longitude + 360;
        }
    }

    return (
        <div className={cx(classes.Container)} ref={containerRef}>
            <div
                className={cx(classes.LatLongDisplay, {
                    [classes.SmallControl]:
                        props.mapContainerWidth < smallControlBreakpoint,
                })}
            >
                <span>lat: {latitude.toFixed(5)}</span>
                <span>lng: {longitude.toFixed(5)}</span>
            </div>
            <div className={classes.Marker}>
                <Icon
                    color={getCssVar("--highlight-color")}
                    data-for="LatLongControlToolTip"
                    data-tip="Right click anywhere on the map to capture coordinates"
                    path={mdiMapMarker}
                />
            </div>

            <ReactTooltip id={"LatLongControlToolTip"} />
        </div>
    );
}

export default LatLongControl;
