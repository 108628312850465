import { AnyAction } from "redux";
import { EventSchema } from "../../crud/eventsCRUD";
import { AppConfig } from "../system/systemTypes";
import {
    GroupSchema,
    PaginatedNewCompanySchema,
    PaginatedNewGroupSchema,
    PaginatedNewUserSchema,
    UserSchema,
} from "../../crud/accessCRUD";
import { StyleConfig } from "crud/layerStylesCRUD";
import { CompanySchema } from "store/user/userTypes";

// ACTION TYPE CONSTANTS
export const SET_EVENT_DATA = "SET_EVENT_DATA";

export const SET_USER_DATA = "SET_USER_DATA";

export const SET_ALL_USER_DATA = "SET_ALL_USER_DATA";
export const SET_ALL_GROUP_DATA = "SET_ALL_GROUP_DATA";
export const SET_ALL_COMPANY_DATA = "SET_ALL_COMPANY_DATA";

export const SET_LOADING_USER_DATA = "SET_LOADING_USER_DATA";
export const SET_LOADING_GROUP_DATA = "SET_LOADING_GROUP_DATA";
export const SET_LOADING_COMPANY_DATA = "SET_LOADING_COMPANY_DATA";

export const SET_GROUP_DATA = "SET_GROUP_DATA";

export const SET_REPORT_LAYER_STYLES_DATA = "SET_REPORT_LAYER_STYLES_DATA";

export const SET_REPORT_LAYER_TYPES_DATA = "SET_REPORT_LAYER_TYPES_DATA";
// ACTION PAYLOADS
export interface SetEventDataPayload {
    eventData: EventSchema[];
}

export type SetPreviewConfigPayload = AppConfig | null;

export type SetGroupDataPayload = PaginatedNewGroupSchema;

export type SetUserDataPayload = PaginatedNewUserSchema;

export type SetAllUserDataPayload = UserSchema[];

export type SetAllGroupDataPayload = GroupSchema[];

export type SetAllCompanyDataPayload = CompanySchema[];

export type SetReportLayerStylesDataPayload = StyleConfig;

export type SetReportLayerTypesDataPayload = LayerTypeConfig[];
export type LayerTypeConfig = {
    name: string;
    tier: string;
    beta: boolean;
    id: string;
};

export type SetLayerTypeConfigPayload = LayerTypeConfig;

// ACTION TYPES
export interface SetEventDataAction extends AnyAction {
    type: typeof SET_EVENT_DATA;
    payload: SetEventDataPayload;
}

export interface SetGroupDataAction extends AnyAction {
    type: typeof SET_GROUP_DATA;
    payload: SetGroupDataPayload;
}

export interface SetUserDataAction extends AnyAction {
    type: typeof SET_USER_DATA;
    payload: SetUserDataPayload;
}

export interface SetAllUserDataAction extends AnyAction {
    type: typeof SET_ALL_USER_DATA;
    payload: SetAllUserDataPayload;
}

export interface SetAllGroupDataAction extends AnyAction {
    type: typeof SET_ALL_GROUP_DATA;
    payload: SetAllGroupDataPayload;
}

export interface SetAllCompanyDataAction extends AnyAction {
    type: typeof SET_ALL_COMPANY_DATA;
    payload: SetAllCompanyDataPayload;
}

export interface SetLoadingUserDataAction extends AnyAction {
    type: typeof SET_LOADING_USER_DATA;
    payload: boolean;
}

export interface SetLoadingGroupDataAction extends AnyAction {
    type: typeof SET_LOADING_GROUP_DATA;
    payload: boolean;
}

export interface SetLoadingCompanyDataAction extends AnyAction {
    type: typeof SET_LOADING_COMPANY_DATA;
    payload: boolean;
}

export interface SetReportLayerStylesDataAction extends AnyAction {
    type: typeof SET_REPORT_LAYER_STYLES_DATA;
    payload: SetReportLayerStylesDataPayload;
}

export interface SetReportLayerTypesDataAction {
    type: typeof SET_REPORT_LAYER_TYPES_DATA;
    payload: SetReportLayerTypesDataPayload; // Define the appropriate type for the payload
}

export type ServiceActionTypes =
    | SetEventDataAction
    | SetGroupDataAction
    | SetAllGroupDataAction
    | SetUserDataAction
    | SetAllUserDataAction
    | SetLoadingUserDataAction
    | SetLoadingGroupDataAction
    | SetReportLayerStylesDataAction
    | SetAllGroupDataAction
    | SetReportLayerTypesDataAction
    | SetAllCompanyDataAction
    | SetLoadingCompanyDataAction;

// REDUCER TYPES
export interface ServiceState {
    eventData: EventSchema[];
    groupData: PaginatedNewGroupSchema | null;
    paginatedCompanyData: PaginatedNewCompanySchema | null;
    userData: PaginatedNewUserSchema | null;
    allUserData: UserSchema[] | null;
    allGroupData: GroupSchema[] | null;
    allCompanyData: CompanySchema[] | null;
    loadingUserData: boolean;
    loadingGroupData: boolean;
    loadingCompanyData: boolean;
    reportLayerStylesData: StyleConfig;
    reportLayerTypesData: LayerTypeConfig[];
}
