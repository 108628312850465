import { AppConfig } from "../../store/system/systemTypes";
import { generateHash } from "../../utils/Maths";

const tutorialBaseConfig: AppConfig = {
    reportId: "",
    components: [
        {
            type: "map",
            options: {
                mapType: "single",
                viewport: {
                    latitude: 27.74042,
                    longitude: -81.57868,
                    zoom: 8.19224,
                },
                sources: {},
                menuIndex: [],
            },
        },
        {
            type: "text",
            options: {
                content: [
                    {
                        title: "Welcome",
                        text: "",
                        id: generateHash(),
                    },
                ],
            },
        },
        {
            type: "summary",
            options: {
                downloads: {}
            },
        },
    ],
};

export default tutorialBaseConfig;
