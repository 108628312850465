import { AnyAction } from "redux";
import {
    ExtendedAccessManagementUser,
    GEOUserWithPortfolios,
} from "../../types/auth";
import {
    LicenseType,
    CompanyRegion,
    IndustryDescription,
} from "store/system/systemTypes";
import { ThemeOption } from "store/system/systemTypes";

// ACTION TYPE CONSTANTS
export const SET_USER = "SET_USER";
export const SET_LICENSE_POPUP_SHOWN = "SET_LICENSE_POPUP_SHOWN";
export const SET_THEME = "SET_THEME";

// ACTION TYPES
export interface SetUserAction extends AnyAction {
    type: typeof SET_USER;
    payload: SetUserPayload;
}

export type SetUserPayload =
    | ExtendedAccessManagementUser
    | GEOUserWithPortfolios
    | null;


export interface SetUserThemeAction extends AnyAction {
    type: typeof SET_THEME;
    payload: ThemeOption;
}

export type UserActionTypes =
    | SetUserAction
    | SetUserThemeAction;

// REDUCER TYPES
export interface UserState {
    theme: ThemeOption | null;
    user: ExtendedAccessManagementUser | GEOUserWithPortfolios | null;
}

export type PortfolioItem = {
    id: string;
    name: string;
};

export type PortfolioState = {
    portfolios: PortfolioItem[];
};

export type CompanySchema = {
    id: string;
    name: string;
    description: IndustryDescription;
    license_type: LicenseType;
    region: CompanyRegion;
};

export type CompanyCreateSchema = Omit<CompanySchema, "id">;
