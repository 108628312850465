import {
    SET_THEME,
    SET_USER,
    SetUserPayload,
    UserActionTypes,
} from "./userTypes";
import { makeActionWithPayload } from "utils/ReduxHelpers";
import {
    AccessManagementUser,
    GEOUserWithPortfolios,
    MisgisUserClaims,
} from "../../types/auth";
import { ThunkDispatch } from "redux-thunk";
import { getUserMe, getUserMeAccess, portfolioGet } from "crud/accessCRUD";
import { isUnsuccessfulAPIResponse } from "utils/TypeGuards";
import { ThemeOption } from "store/system/systemTypes";

export const setUser = makeActionWithPayload<typeof SET_USER, SetUserPayload>(
    SET_USER,
);

export const setTheme = makeActionWithPayload<typeof SET_THEME, ThemeOption>(
    SET_THEME,
);


export const initiateUser = (
    user: MisgisUserClaims,
    accessToken: string,
    identify: (user: AccessManagementUser) => void,
): ((
    dispatch: ThunkDispatch<any, any, UserActionTypes>,
) => Promise<GEOUserWithPortfolios | null>) => {
    return async (
        dispatch: ThunkDispatch<any, any, UserActionTypes>,
    ): Promise<GEOUserWithPortfolios | null> => {
        const portfolios = await portfolioGet(accessToken);
        let newUser: GEOUserWithPortfolios | null = null;
        if (!isUnsuccessfulAPIResponse(portfolios)) {
            let sortedPortfolios = portfolios.data.sort((a, b) => {
                if (a.name.replace(/\s+/g, "") < b.name.replace(/\s+/g, "")) {
                    return -1;
                }
                if (a.name.replace(/\s+/g, "") > b.name.replace(/\s+/g, "")) {
                    return 1;
                }
                return 0;
            });
            newUser = {
                ...user,
                portfolios: sortedPortfolios,
            } as GEOUserWithPortfolios;
            delete user.tos;
            const userResponse = await getUserMe(accessToken);
            if (!isUnsuccessfulAPIResponse(userResponse) && newUser) {
                newUser = {
                    ...newUser,
                    ...userResponse.data,
                };

                identify(userResponse.data);
                dispatch(setUser(newUser));
            }
        }
        return newUser;
    };
};

export const getUserAccessFromApi = (
    accessToken: string,
    user: GEOUserWithPortfolios | MisgisUserClaims,
) => {
    return async (dispatch: ThunkDispatch<any, any, UserActionTypes>) => {
        const accessInfo = await getUserMeAccess(accessToken);
        if (!isUnsuccessfulAPIResponse(accessInfo)) {
            dispatch(setUser({...user, ...accessInfo.data} as GEOUserWithPortfolios));
        }
    };
};
