import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { extractColumnNames } from "utils/MantineTable";

import classes from "./CustomLayerTable.module.css";
import { mdiMenuDown, mdiMenuSwap, mdiMenuUp } from "@mdi/js";
import Icon from "@mdi/react";
import { getCssVar } from "utils/CSSHelpers";

interface CustomLayerTableProps {
    layerName: string;
    sourceName: string;
}

export const CustomLayerTable: FC<CustomLayerTableProps> = ({
    sourceName,
}: CustomLayerTableProps) => {
    const mapConfig = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "map").mapConfig,
    );

    const geojson = mapConfig.sources[sourceName]
        .data as GeoJSON.FeatureCollection;

    const tableData = geojson.features.map((feature) => {
        return feature.properties as Record<string, any>;
    });

    const columnHeaders = extractColumnNames(
        tableData,
        Object.keys(geojson.features[0].properties!),
    );

    const table = useMantineReactTable({
        data: tableData,
        columns: columnHeaders,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnDragging: false,
        globalFilterFn: "contains",
        initialState: {
            columnVisibility: { description: false },
            columnPinning: {
                right: ["estimated_exposure"],
            },
            showColumnFilters: false,
            showGlobalFilter: true,
        },
        mantineHighlightProps: {
            highlightColor: "var(--highlight-color)",
        },
        mantineTableProps: {
            verticalSpacing: "0.2rem",
        },
        mantineSearchTextInputProps: {
            placeholder: "Search across all columns",
            sx: {
                marginRight: "1rem",
            },
        },
        icons: {
            IconSortDescending: () => (
                <Icon
                    path={mdiMenuDown}
                    style={{
                        transform: "scale(1.5)",
                    }}
                />
            ),
            IconSortAscending: () => (
                <Icon
                    path={mdiMenuUp}
                    style={{
                        transform: "scale(1.5)",
                    }}
                />
            ),
            IconArrowsSort: () => (
                <Icon
                    path={mdiMenuSwap}
                    style={{
                        transform: "scale(1.5)",
                    }}
                />
            ),
        },
        mantineTableBodyRowProps: {
            sx: {
                ":hover": {
                    opacity: 0.75,
                },
            },
        },
        mantineCopyButtonProps: {
            sx: {
                ":hover": {
                    backgroundColor: "var(--highlight-color-50)",
                },
                ":active": {
                    backgroundColor: "var(--highlight-color-75)",
                },
            },
        },
        mantinePaginationProps: {
            showRowsPerPage: false,
        },
        mantineTableBodyCellProps: {
            sx: {
                textWrap: "nowrap",
                border: "none",
            },
            style: {
                maxWidth: "max-content",
            },
        },
        mantineTopToolbarProps: {
            sx: {
                borderBottom: `0.1rem solid ${getCssVar("--border-color")}`,
            },
        },
        mantineTableHeadProps: {
            sx: {
                padding: "1rem !important",
            },
        },
        mantinePaperProps: {
            sx: {
                border: "none !important",
                width: "100%",
            },
        },
    });

    return (
        <div className={classes.Container}>
            <MantineReactTable table={table} />
        </div>
    );
};
