import { AnyAction } from "redux";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { TrackEventParams } from "@datapunt/matomo-tracker-react/lib/types";

import { DashboardView } from "../system/systemTypes";
import Tutorials from "assets/tutorials";

// ACTION TYPE CONSTANTS
export const SET_MATOMO_INSTANCE = "SET_MATOMO_INSTANCE";
export const REGISTER_PAGE_CHANGE = "REGISTER_PAGE_VIEW";
export const REGISTER_ANALYTICS_EVENT = "REGISTER_ANALYTICS_EVENT";

// ACTION PAYLOADS
export interface SetMatomoInstancePayload {
    matomoInstance: ReturnType<typeof useMatomo> | null;
}
export interface RegisterPageViewPayload {
    title: string;
    href: string;
}

export interface ReportInteractionAnalyticsEvent extends TrackEventParams {
    category: "Report";
    action:
        | "view report info"
        | "view report text"
        | "location search"
        | "switched insights company"
        | "download data (via large bottom button)"
        | "download data (via Layers Tab)"
        | "upload data (via Tabbed Menu)"
        | "upload data (via Layers Tab)"
        | "minimised tabbed menu"
        | "opened tabbed menu";
}

export interface HelpAboutUsAnalyticsEvent extends TrackEventParams {
    category: "Support Tabs";
    action: string;
}

export interface ContactUsAnalyticsEvent extends TrackEventParams {
    category: "Contact Us";
    action:
        | "media upsell contact button clicked"
        | "insights upsell contact button clicked";
}

export interface UpdateDetailsEvent extends TrackEventParams {
    category: "Account Details";
    action: "Account details updated" | "Account details update failed";
}

export interface LicenseInteractionAnalyticsEvent extends TrackEventParams {
    category: "GEO License";
    action:
        | "download license prompt accepted"
        | "download license prompt declined";
}

export interface LayersAnalyticsEvent extends TrackEventParams {
    category: "Layers";
    action: string;
}

export interface SearchFilterAnalyticsEvent extends TrackEventParams {
    category: "Search And Filter";
    action:
        | "add filter"
        | "remove filter"
        | "hide/show column"
        | "hide/show all columns";
}

export interface TimelineAnalyticsEvent extends TrackEventParams {
    category: "Timeline";
    action:
        | "play"
        | "pause"
        | "next"
        | "previous"
        | "jump to start"
        | "jump to end"
        | "used timeline scrubber"
        | "used date selector";
}

export interface MapInteractionAnalyticsEvent extends TrackEventParams {
    category: "Map";
    action:
        | "dual map"
        | "slider map"
        | "single map"
        | "measure distance"
        | "measure area";
}

export interface TutorialAnalyticsEvent extends TrackEventParams {
    category: "Tutorials";
    action: "click" | "start" | "skipped" | "finished";
    name?: Extract<keyof typeof Tutorials, string>;
}

export interface ThemeAnalyticsEvent extends TrackEventParams {
    category: "Theme";
    action: "changed to light mode" | "changed to dark mode";
}

interface DragAndDropAnalyticsEvent extends TrackEventParams {
    category: "Drag And Drop";
    action: "click" | "upload csv" | "upload geojson";
}

interface InsightsInteractionAnalyticsEvent extends TrackEventParams {
    category: "Insights Interaction";
    action: string;
    name?: string;
}

interface ReportViewNavigationAnalyticsEvent extends TrackEventParams {
    category: "Report View Navigation";
    action: DashboardView;
}

interface PanoramaAnalyticsEvent extends TrackEventParams {
    category: "Panorama Imagery";
    action: "open" | "close";
}

interface StreetviewAnalyticsEvent extends TrackEventParams {
    category: "Streetview Imagery";
    action: "open" | "close";
}

interface LocationLabelAnalyticsEvent extends TrackEventParams {
    category: "Location Label Toggle";
    action: "off" | "on";
}

export type RegisterAnalyticsEventPayload =
    | TutorialAnalyticsEvent
    | DragAndDropAnalyticsEvent
    | ReportViewNavigationAnalyticsEvent
    | InsightsInteractionAnalyticsEvent
    | ReportInteractionAnalyticsEvent
    | LicenseInteractionAnalyticsEvent
    | MapInteractionAnalyticsEvent
    | SearchFilterAnalyticsEvent
    | TimelineAnalyticsEvent
    | LayersAnalyticsEvent
    | HelpAboutUsAnalyticsEvent
    | ContactUsAnalyticsEvent
    | UpdateDetailsEvent
    | PanoramaAnalyticsEvent
    | StreetviewAnalyticsEvent
    | ThemeAnalyticsEvent
    | LocationLabelAnalyticsEvent;

// ACTIONS
export interface SetMatomoInstanceAction extends AnyAction {
    type: typeof SET_MATOMO_INSTANCE;
    payload: SetMatomoInstancePayload;
}

export interface RegisterPageChangeAction extends AnyAction {
    type: typeof REGISTER_PAGE_CHANGE;
    payload: RegisterPageViewPayload;
}

export interface RegisterAnalyticsEventAction extends AnyAction {
    type: typeof REGISTER_ANALYTICS_EVENT;
    payload: RegisterAnalyticsEventPayload;
}

export type MatomoActionTypes =
    | SetMatomoInstanceAction
    | RegisterPageChangeAction
    | RegisterAnalyticsEventAction;

// REDUCER TYPES
export interface MatomoState {
    matomoInstance: ReturnType<typeof useMatomo> | null;
    pageTitle: string | null;
}
