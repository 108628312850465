import React from "react";
import Icon from "@mdi/react";
import { mdiCheck, mdiClose } from "@mdi/js";
import cx from "classnames";

import Toggle from "../../../_Library/Inputs/Toggle/Toggle";
import {
    CookieBannerClass,
    CookieOptions,
    CookieSettings,
} from "../CookieBanner/CookieBanner";
import Button from "../../../_Library/Button/Button";
import Modal from "../../../_Library/Modal/Modal";

import classes from "./CookieSettings.module.css";

interface OwnProps {
    closeModal: () => void;
    updateCookieSettings: CookieBannerClass["updateCookieSettings"];
    cookieSettings: CookieSettings;
}

type CookieSettingsModalProps = OwnProps;
type PanelType = "About Cookies" | CookieOptions;

interface CookieSettingsModalState {
    activePanel: PanelType;
}

class CookieSettingsModal extends React.Component<
    CookieSettingsModalProps,
    CookieSettingsModalState
> {
    state: CookieSettingsModalState = {
        activePanel: "About Cookies",
    };

    panelText: { [key in PanelType]: string } = {
        "About Cookies": `This site uses cookies. We use cookies mainly to improve and analyze your experience on our websites and 
            for marketing purposes. Because we respect your right to privacy, you can choose not to allow some types 
            of cookies. Click on the different category headings to find out more and change your default settings. 
            Blocking some types of cookies may negatively impact your experience on the site and limit the services 
            we are able to provide.`,

        Necessary: `These cookies are necessary for the website to function and cannot be switched off in our systems. They
            are usually only set in response to actions made by you which amount to a request for services, such as 
            setting your privacy preferences, logging in, or filling in forms.`,

        Analytics: `These cookies help us to understand how visitors engage with the website. We may use a set of cookies to
             collect information and report site usage statistics.`,

        Functionality: `We use a set of cookies that are optional for the website to function. They are usually only set in 
            response to information provided to the website to personalize and optimize your experience as well as 
            remember your chat history.`,

        Advertising: `We use cookies to make our ads more engaging and valuable to site visitors. Some common applications of 
            cookies are to select advertising based on what’s relevant to a user; to improve reporting on ad campaign 
            performance; and to avoid showing ads the user has already seen.`,
    };

    render() {
        return (
            <Modal closeModal={this.props.closeModal}>
                <div className={classes.Container}>
                    <div className={classes.SettingsContainer}>
                        <div className={classes.Categories}>
                            {Object.keys(this.panelText).map((name) => {
                                return (
                                    <div
                                        key={name}
                                        className={cx(classes.Category, {
                                            [classes.Active]:
                                                this.state.activePanel === name,
                                        })}
                                        onClick={() => {
                                            this.setState({
                                                activePanel: name as PanelType,
                                            });
                                        }}
                                    >
                                        <h3>{name}</h3>
                                        {name !== "About Cookies" && (
                                            <Icon
                                                path={
                                                    this.props.cookieSettings[
                                                        name as CookieOptions
                                                    ]
                                                        ? mdiCheck
                                                        : mdiClose
                                                }
                                            />
                                        )}
                                    </div>
                                );
                            })}
                            <div className={classes.FillCategory} />
                        </div>
                        <div className={classes.Panel}>
                            <div className={classes.PanelHead}>
                                <h1 className={classes.PanelTitle}>
                                    {this.state.activePanel}
                                </h1>
                                {this.state.activePanel !== "About Cookies" && (
                                    <Toggle
                                        active={
                                            this.props.cookieSettings[
                                                this.state.activePanel
                                            ]
                                        }
                                        onClick={() => {
                                            if (
                                                this.state.activePanel !==
                                                "Necessary"
                                            ) {
                                                this.props.updateCookieSettings(
                                                    {
                                                        [this.state
                                                            .activePanel]:
                                                            !this.props
                                                                .cookieSettings[
                                                                this.state
                                                                    .activePanel as CookieOptions
                                                            ],
                                                    },
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            <p className={classes.PanelText}>
                                {this.panelText[this.state.activePanel]}
                            </p>
                        </div>
                    </div>
                    <div className={classes.ButtonContainer}>
                        <Button
                            size={{ width: "39.9%" }}
                            onClick={() => {
                                this.props.updateCookieSettings({}, true);
                            }}
                        >
                            Save Settings
                        </Button>
                        <Button
                            size={{ width: "39.9%" }}
                            onClick={() => {
                                this.props.updateCookieSettings(
                                    {
                                        Analytics: true,
                                        Functionality: true,
                                        Advertising: true,
                                    },
                                    true,
                                );
                            }}
                        >
                            Accept All
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default CookieSettingsModal;
