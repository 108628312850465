import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { toggleLocationLabels } from "store/map/mapActions";

import classes from "./LabelControl.module.css";
import ReactTooltip from "react-tooltip";
import Toggle from "../../../../../../../_Library/Inputs/Toggle/Toggle";

const LabelControl: FC = () => {
    const locationLabels = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "map").locationLabels,
    );
    const dispatch = useDispatch();

    return (
        <div
            className={classes.Container}
            data-for="LocationLabelTooltip"
            data-tip="Force location labels above all other layers"
        >
            <Toggle
                heightRem={"1.5rem"}
                active={locationLabels}
                onClick={() => dispatch(toggleLocationLabels(!locationLabels))}
            />{" "}
            <p>Location Labels</p>
            <ReactTooltip
                id={"LocationLabelTooltip"}
                place={"top"}
                effect={"float"}
            />
        </div>
    );
};

export default LabelControl;
