import { ThunkDispatch } from "redux-thunk";

import { setMapConfig, setViewport } from "../map/mapActions";
import { MapActionTypes } from "../map/mapTypes";
import {
    AppConfig,
    DELETE_ALERT,
    SET_ALERT,
    SET_CONFIG,
    SET_CURRENT_DATE,
    SET_DASHBOARD_VIEW,
    SET_TOUR,
    DeleteAlertPayload,
    SetAlertPayload,
    SetConfigPayload,
    SetDashboardViewPayload,
    SystemActionTypes,
    SET_COOKIES_SET,
    SetCookiesSetPayload,
    SET_PREVIEW_CONFIG,
    SET_TEXT_REPORT_OPEN,
    TOGGLE_MODAL,
} from "./systemTypes";
import Tutorials, { ProductTourConfig } from "assets/tutorials";
import { makeActionWithPayload } from "utils/ReduxHelpers";
import { registerAnalyticsEvent } from "../matomo/matomoActions";
import { MatomoActionTypes } from "../matomo/matomoTypes";
import { ViewportProps } from "react-map-gl";
import { SetPreviewConfigPayload } from "../service/serviceTypes";

export const initiateReport = (
    viewport: ViewportProps | null = null,
    config: AppConfig,
) => {
    return async (
        dispatch: ThunkDispatch<any, any, SystemActionTypes | MapActionTypes>,
    ) => {
        loadConfig(dispatch, config);
        if (viewport) {
            dispatch(setViewport({ ...viewport }));
        }
    };
};

const loadConfig = (
    dispatch: ThunkDispatch<
        any,
        any,
        SystemActionTypes | MapActionTypes | MatomoActionTypes
    >,
    appConfig: AppConfig,
) => {
    if (appConfig.components) {
        for (let component of appConfig.components) {
            if (component.type === "map") {
                dispatch(setMapConfig(component.options));
            }
        }

        dispatch(setConfig(appConfig));

    } else {
        dispatch(setConfig(null));
        dispatch(
            setAlert({
                message: "Config failed to load",
                timeout: 2000,
                type: "Error",
            }),
        );
    }
};

export const loadTutorial = (
    tutorialId: Extract<keyof typeof Tutorials, string>,
) => {
    return (
        dispatch: ThunkDispatch<
            any,
            any,
            SystemActionTypes | MapActionTypes | MatomoActionTypes
        >,
    ) => {
        if (tutorialId === "") {
            dispatch(
                setTour({
                    steps: [],
                    run: false,
                }),
            );
        } else {
            if (Tutorials[tutorialId]) {
                const tutorial = Tutorials[tutorialId];
                (window as any).TUTORIAL_ID = tutorialId;

                loadConfig(dispatch, tutorial.appConfig);
                dispatch(
                    registerAnalyticsEvent({
                        category: "Tutorials",
                        action: "start",
                        name: tutorialId,
                    }),
                );

                dispatch(setDashboardView({ view: "report" }));

                dispatch(
                    setTour({
                        steps: tutorial.steps,
                        run: true,
                        stepIndex: 0,
                    }),
                );
            }
        }
    };
};

export const setConfig = makeActionWithPayload<
    typeof SET_CONFIG,
    SetConfigPayload
>(SET_CONFIG);
export const setCurrentDate = makeActionWithPayload<
    typeof SET_CURRENT_DATE,
    number
>(SET_CURRENT_DATE);
export const setAlert = makeActionWithPayload<
    typeof SET_ALERT,
    SetAlertPayload
>(SET_ALERT);
export const deleteAlert = makeActionWithPayload<
    typeof DELETE_ALERT,
    DeleteAlertPayload
>(DELETE_ALERT);
export const setTour = makeActionWithPayload<
    typeof SET_TOUR,
    Partial<ProductTourConfig>
>(SET_TOUR);
export const setDashboardView = makeActionWithPayload<
    typeof SET_DASHBOARD_VIEW,
    SetDashboardViewPayload
>(SET_DASHBOARD_VIEW);
export const setCookiesSet = makeActionWithPayload<
    typeof SET_COOKIES_SET,
    SetCookiesSetPayload
>(SET_COOKIES_SET);
export const SetPreviewConfig = makeActionWithPayload<
    typeof SET_PREVIEW_CONFIG,
    SetPreviewConfigPayload
>(SET_PREVIEW_CONFIG);
export const setTextReportOpen = makeActionWithPayload<
    typeof SET_TEXT_REPORT_OPEN,
    boolean
>(SET_TEXT_REPORT_OPEN);
export const toggleModal = makeActionWithPayload<
    typeof TOGGLE_MODAL,
    boolean | null
>(TOGGLE_MODAL);

