import React, { useState, useEffect } from "react";
import Report from "../Report/Report";
import { configGetLatestByEventId } from "../../../crud/reportsCRUD";
import { useOktaAuth } from "@okta/okta-react";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../_Library/LoadingScreen/LoadingScreen";

const EventIdReport = () => {
    const [config, setConfig] = useState(null);
    const { oktaAuth } = useOktaAuth();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const fetchConfig = async () => {
            const token = oktaAuth.getAccessToken();
            try {
                const config = await configGetLatestByEventId(token!, id, true);
                setConfig(config);
            } catch (error) {
                console.error("Error fetching config:", error);
            }
        };

        fetchConfig();
    }, [id, oktaAuth]);

    return config ? <Report reportConfig={config} /> : <LoadingScreen />;
};

export default EventIdReport;
